import { atom } from 'recoil';

export const pickupDatetimeState = atom<'' | Date | undefined>({
  key: 'pickupDatetimeState',
  default: '',
});

export const returnDatetimeState = atom<'' | Date | undefined>({
  key: 'returnDatetimeState',
  default: '',
});
