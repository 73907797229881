export const priceWithComma = (price: string | number) => {
  if (typeof price === 'string') {
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const HEDGE_RATE = 1.02; // 환헷징 비율

/**
 *
 * @param exchangeRate 환율
 * @param price 원화로 변환할 가격
 * @param withComma 변환된 가격에 콤마 붙여서 리턴할 것인지 여부
 * @returns 원화로 변환된 가격(number) || 원화로 변환된 가격(string)
 */
export const exchangePrice = (exchangeRate: number, price: number, withComma?: boolean) => {
  const calculatedPrice = Math.ceil((exchangeRate * price * HEDGE_RATE) / 100) * 100;
  if (withComma === true) {
    return calculatedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return calculatedPrice;
};

/** 소수점이 있는 가격 String을 Numer로 변환하는 함수 */
export const parsePrice = (price) => {
  return Number(parseFloat(price).toFixed(2));
};
