import React from 'react';

import { css, cx } from '@emotion/css';

import { SPACING, font } from '^/constants/commonStyles';

import { TimeSelectHourNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const HourRenderer: React.FC<{
  value: TimeSelectHourNullable;
  isOpen?: boolean;
  isListItem?: boolean;
  disabled?: boolean;
}> = ({
  value, isOpen, isListItem, disabled,
}) => {
  const numberValue = Number(value);
  const isAfternoon = numberValue > 11;
  const hour = numberValue > 12 ? numberValue - 12 : numberValue;
  return (
    <div
      className={[
        isListItem
          ? cx(classNames.btnRendererBasic,
            css`
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: #1E1E1E;
              `)
          : classNames.btnRendererBasic,
        disabled ? css`
          opacity: 0.3;
        ` : '',
      ].join(' ')}
    >
      <span>
        {isAfternoon ? '오후' : '오전'} {hour}시
      </span>
      {!isListItem && (
      <img
        alt="hour-list-toggle"
        src={isOpen
          ? 'arrow_drop_up_20.svg'
          : 'arrow_drop_down_20.svg'}
      />
      )}
    </div>
  );
};
