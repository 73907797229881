import { PropsWithChildren } from 'react';

import { css } from '@emotion/css';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import {
  EventAreaExtendingDummyBlock,
} from '^/commonComponents/ui/EventAreaExtendingDummyBlock';
import { borders } from '^/constants/commonStyles';

const hoverToggleStyle = css`
  > section {
    display: none
  }
  :hover > section {
    display: block;
  }
  :hover > div > s {
    transform: scale(3, 5);
    /* height: 200px; */
  }
`;
export const HoverToReveal: React.FC<PropsWithChildren<{
  toRevealAreaStyle?: string;
  toReveal: React.ReactNode;
}>> = ({
  toRevealAreaStyle,
  children = (
    <>
      <InfoIcon
        width={20}
        height={20}
      />
      <EventAreaExtendingDummyBlock
        childProps={{
          className: [
            css`
              width: 30px;
              left: -5px;
              height: 10px;
            `,
            // borders.DEBUG_orangeBorderDashed,
          ].join(' '),
        }}
      />
    </>
  ),
  toReveal,
}) => {
  return (
    <div
      className={hoverToggleStyle}
    >
      {children}
      <section
        id="toRevealArea"
        className={toRevealAreaStyle}
      >
        {toReveal}
      </section>
    </div>
  );
};
