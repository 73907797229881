import { makeAutoObservable, reaction } from 'mobx';

import { MobxInputValue, IInputValue } from './MobxInputValue';

export class ExtendedMobxInputValue<T> implements IInputValue<T> {
  constructor(
    public mValue: MobxInputValue<T>,
    public options?: {
      beforeOnChange?(value: T): T | undefined | void;
      afterOnChange?(value: T): void;
    },
    public value = mValue.value,
  ) {
    makeAutoObservable(this);
    reaction(
      () => mValue.value,
      (mValueValue) => {
        this.value = mValueValue;
      },
    );
  }

  set(value: T) {
    let resultValue = value;
    if (this.options?.beforeOnChange) {
      resultValue = this.options
        .beforeOnChange(resultValue)
        || resultValue;
    }
    this.mValue.set(resultValue);

    if (this.options?.afterOnChange) {
      this.options
        .afterOnChange(resultValue);
    }
    this.value = resultValue;
  }

  setIsFocused(focused: boolean) {
    this.mValue.setIsFocused(focused);
  }

  setHasBlurred(blurred: boolean) {
    this.mValue.setHasBlurred(blurred);
  }
}
