import { isDefined } from '^/types/utils/isDefined';

export function minNullable(
  nums: (number | null | undefined)[],
) {
  const a = nums.filter(isDefined);
  if (a.length === 0) return null;
  return Math.min(...a);
}

export function maxNullable(
  nums: (number | null | undefined)[],
) {
  const a = nums.filter(isDefined);
  if (a.length === 0) return null;
  return Math.max(...a);
}
