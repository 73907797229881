import React from 'react';

import { css } from '@emotion/css';

interface BlackFridayPopupProps {
  setPopupVisible: (isVisible: boolean) => void;
}

const BlackFridayPopup: React.FC<BlackFridayPopupProps> = ({ setPopupVisible }) => {
  return (
    <div
      className={css`
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100; 
      position: absolute; 
      top: 0; 
      left: 0; 
      height: 100vh; 
      width: 100vw; 
      background-color: rgba(0,0,0,0.4);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smooth: never;
       `}
    >
      <div
        className={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 328px; 
        height: 399px; 
        background-color: #fff; 
        border-radius: 10px; 
        `}
      >
        <img
          src="/hertz-logo-high-quality.png"
          alt="Hertz"
          width="76px"
          height="27px"
          style={{ marginTop: '36px' }}
        />
        <h1 className={css`
        margin: 11px 0 19px; 
        color: #1e1e1e; 
        font-size: 22px; 
        font-weight: 700;`}
        >블랙프라이데이 특별 프로모션
        </h1>
        <div className={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 159px;
        height: 95px;
        background-color: #eef6fe;
        border-radius: 10px; 
        `}
        >
          <div className={css`
          position: absolute;
          right: -14px;
          top: 34px;
          width: 27px; 
          height: 27px; 
          background-color: #fff; 
          border-radius: 27px;
          z-index: 1;
          `}
          />
          <span className={css`color: #55A9F3; font-size: 16px; font-weight: 700; line-height: 16px;`}>최대</span>
          <span className={css`color: #55A9F3; font-size: 48px; font-weight: 700; line-height: 48px;`}>25%</span>
        </div>
        <div className={css`
        margin: 17px auto; 
        color: #1e1e1e; 
        font-size: 12px; 
        font-weight: 300; 
        letter-spacing: -0.24px;`}
        >
          <p>대상 지역 : 미국, 캐나다, 유럽(독일, 이탈리아, 프랑스, 스페인)</p>
          <p>예약 기간 : ~2023년 11월 28일 </p>
          <p>픽업 기간 : ~2024년 3월 21일</p>
        </div>
        <span className={css`color: #717171; font-size: 10px; font-weight: 300;`}>*프로모션 가격은 자동으로 적용되어 있음</span>
        <button
          type="button"
          className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        height: 52px;
        border-radius: 0 0 10px 10px;
        background-color: #55a9f3 !important;
        `}
          onClick={() => { setPopupVisible(false); }}
        >
          <span className={css`
          color: #fff;
          font-size: 20px; 
          font-weight: 700;`}
          >확인
          </span>
        </button>
        <button
          type="button"
          className={css`
          position: absolute;
          top: -24px;
          right: 5px;
          color: white;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
       `}
          onClick={() => { setPopupVisible(false); }}
        >
          X 닫기
        </button>
      </div>
    </div>
  );
};

export default BlackFridayPopup;
