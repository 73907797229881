import React from 'react';

import { css } from '@emotion/css';

import {
  borders, SPACING, COLORS2_AS_BACKGROUND, COLORS2_AS_FOREGROUND,
} from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { BaseErrorView } from './BaseErrorView';

const ButtonCommonStyle = css`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.02px;

  border-radius: 5px;
`;
export const ServerErrorView: React.FC<{
  title?: string;
  description?: string;
  onClickRetry: () => void;
  onClickBack?: () => void;
}> = ({
  title = '에러가 발생하였습니다',
  description = '이용에 불편을 드려 죄송합니다.',
  onClickRetry,
  onClickBack = () => window.location.reload(),
}) => {
  return (
    <BaseErrorView title={title} description={description}>
      <div
        className={[
          // borders.DEBUG_orangeBorderDashed,
          css`
            display: flex;
            width: 100%;
            max-width: 400px;
            gap: ${SPACING.spacing16};
            margin-top: ${SPACING.spacing48};
            > * {
              flex: 1;
            }
          `,
        ].join(' ')}
      >
        <button
          type="button"
          onClick={(e) => {
            onClickRetry();
            e.preventDefault();
            return false;
          }}
          className={[
            css`
              padding-top: 20px;
              padding-bottom: 20px;
            `,
            ButtonCommonStyle,
            COLORS2_AS_BACKGROUND.subWithImportant.MrtGray3,
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          다시 시도
        </button>
        <button
          type="button"
          onClick={(e) => {
            onClickBack();
            e.preventDefault();
            return false;
          }}
          className={[
            ButtonCommonStyle,
            COLORS2_AS_FOREGROUND.main.MrtWhite,
            COLORS2_AS_BACKGROUND.mainWithImportant.MrtBlue1,
          ].join(' ')}
        >
          이전 페이지
        </button>
      </div>
    </BaseErrorView>
  );
};
