import { PropsWithChildren } from 'react';

export const PreTagForDebugging: React.FC<
React.HTMLProps<HTMLPreElement> & PropsWithChildren<{
  obj: any,
  SHOW_DEBUGGING_VIEW: boolean,
}>
> = ({
  obj,
  SHOW_DEBUGGING_VIEW: visible,
  children,
  ...props
}) => {
  return (
    <>
      <pre
        id="PRE_TAG_FOR_DEBUGGING"
        style={{
          backgroundColor: 'rgb(255, 164, 139)',
          display: visible ? 'block' : 'none',
          pointerEvents: 'none',
        }}
        {...props}
      >
        {
          JSON.stringify(obj, null, 2)
        }
      </pre>
      {children}
    </>
  );
};
