/* eslint-disable jsx-a11y/no-static-element-interactions */
import { RefObject, useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { InputElemProps } from '^/commonComponents/inputs/TextInput';
import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { CitySearchInputViewModel } from
  '^/components/CarSearchBox/base/desktop/headerArea/CitySearchInput/CitySearchInput.viewmodel';
import { SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { MRTCitySearchInput } from
  '^/components/CarSearchBox/myrealtrip/desktop/headerArea/SearchInput/MRTCitySearchInput';
import { COLORS2 } from '^/constants/commonStyles';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';
import { CityCodeType, CityNameType, LocationNameType } from '^/types/__BrandedLocationTypes';

import { classNames } from '../../../base/desktop/contentsArea/CarSearchBox.contentsArea.style';

export const MRTCitySearchInputButton: React.FC<{
  placeholder: string;
  inputRef?: RefObject<HTMLInputElement>;
  viewmodel: CitySearchInputViewModel;
  title: string | null;
  onSelectCity(cityCode: CityCodeType, cityKoName: CityNameType): void;
  showSuggestions?: boolean;
  inputElemProps?: InputElemProps;
  smaller?: boolean;
  onClickCitySearchInputBtn?: () => void;
  isDifferentReturn?: boolean;
  disabled?: boolean;
  office?: LocationNameType;
  onTyping?: () => void;
  stepId?: SearchBoxSteps['type'];
}> = observer(function MRTCitySearchInputButton({
  placeholder,
  inputRef,
  viewmodel,
  title,
  onSelectCity,
  showSuggestions,
  inputElemProps,
  onClickCitySearchInputBtn,
  isDifferentReturn,
  disabled,
  office,
  onTyping,
  stepId,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const rootElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showSuggestions) {
      // viewmodel.showAutosuggestList.set(true);
    }
  }, [showSuggestions]);
  useClickOutsideComponent(rootElem, () => {
    viewmodel.showAutosuggestList.set(false);
  });
  return (
    <div
      ref={rootElem}
      className={cx(
        classNames.common.searchBoxInputWrapper,
      )}
      tabIndex={-1}
      role="button"
      onBlur={(e) => {
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        viewmodel.showAutosuggestList.set(false);
      }}
    >
      <div className={cx(classNames.common.searchBoxInputTitle,
        isDifferentReturn && css`margin-bottom: 4px;`,
      )}
      >{title}
      </div>
      <PreTagForDebugging
        obj={{
          showSuggestions: viewmodel.showAutosuggestList.value,
        }}
        SHOW_DEBUGGING_VIEW={false}
      />
      {
      isMobile
        ? (
          <div
            onClick={onClickCitySearchInputBtn}
            className={cx(
              classNames.common.searchBoxInputs,
              css`
            gap: 8px;
            margin-top: 4px;
            width: 100%;
            position: relative;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: ${COLORS2.main.MrtWhite};
              border: 1px solid #E1E1E1;
            :focus-within {
              border: 1px solid ${COLORS2.main.MrtBlue1};
              box-shadow: 0px 0px 0px 2px rgba(85, 169, 243, 0.20);
            }
          `,
            )}
          >
            <MRTCitySearchInput
              placeholder={placeholder}
              {...inputElemProps}
              inputRef={inputRef}
              viewmodel={viewmodel}
              onSelectCity={onSelectCity}
              placeholderIcon="search-md.svg"
              styleOverride={{ textInput: cx(css`font-size: 14px; font-weight: 500; line-height: 20px;`) }}
            />
          </div>
        )
        : (
          <MRTCitySearchInput
            stepId={stepId}
            placeholder={placeholder}
            onClick={onClickCitySearchInputBtn}
            {...inputElemProps}
            inputRef={inputRef}
            viewmodel={viewmodel}
            onSelectCity={onSelectCity}
            placeholderIcon={disabled ? 'marker-pin_20-disabled.svg' : 'marker-pin_20.svg'}
            styleOverride={{
              textInput: cx(
                css`
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                `,
              ),
            }}
            disabled={disabled}
            office={office}
            onTyping={onTyping}
          />
        )
      }
    </div>
  );
});
