// import * as MdIcons from 'react-icons/md';
// import * as AiIcons from 'react-icons/ai';
import React from 'react';

import * as FiIcons from 'react-icons/fi';

export const DynamicIcon: React.FC<{
  name: keyof typeof FiIcons,
  style?: string,
}> = ({ name, style }) => {
  const type = name.slice(0, 2);
  // let IconComponent = '';
  if (type === 'Fi') {
    const IconComponent = FiIcons[name];
    return <IconComponent className={style} />;
  }
  return <></>;
};

export default DynamicIcon;
