import { css } from '@emotion/css';

import {
  borders, SPACING, MARGINS, PADDINGS,
  COLORS2,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';

export const ConfirmDialogDefaultStyles = {
  // root:
  modalBackground: [
    'fixed inset-0',
    css`
      background-color: rgba(0, 0, 0, 0.3);
    `,
    // borders.DEBUG_greenBorderDashed,
  ].join(' '),

  modalRoot: [
    'transform relative overflow-y-auto rounded-trimo bg-white text-left align-middle shadow-xl transition-all',
    css`
      width: 100%;
      max-width: 450px;
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
    `,
    // borders.DEBUG_orangeBorderDashed,
    PADDINGS.TOP_AND_BOTTOM.spacing24,
  ].join(' '),

  buttonGroup: [
    css`
      display: flex;
      gap: ${SPACING.spacing16};
      width: 100%;
      > * {
        flex: 1;
        padding-top: ${SPACING.spacing16};
        padding-bottom: ${SPACING.spacing16};
      }
    `,
    // borders.DEBUG_greenBorderDashed,
    MARGINS.TOP.spacing40,
  ].join(' '),
  button: [
    // borders.DEBUG_blueBorderDashed,
    font2.Body1_Bold,
    'rounded-trimo',
    css`
      &:disabled {
        background-color: ${COLORS2.main.MrtBlue2} !important;
      }
    `,
  ].join(' '),

  buttonCancel: [
    css`
      background-color: ${COLORS2.sub.MrtGray3} !important;
    `,
    COLORS2_AS_FOREGROUND.main.MrtBlack,
  ].join(' '),

  buttonConfirm: [
    css`
      background-color: ${COLORS2.main.MrtBlue1} !important;
    `,
    COLORS2_AS_FOREGROUND.main.MrtWhite,
  ].join(' '),
} as const;

export const ConfirmDialogDefaultMobileStyles: ConfirmDialogStyles = {
  ...ConfirmDialogDefaultStyles,
};

export type ConfirmDialogStyles = typeof ConfirmDialogDefaultStyles;
