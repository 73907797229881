import { css, cx } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';

import { OpenCloseTimes } from '^/types/RawOpenHours';

const WEEK = {
  en: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  ko: ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'],
} as const;

interface SelectedOfficeOpenHoursProps {
  isJapan: boolean | undefined,
  openCloseTimes?: OpenCloseTimes,
}
export const SelectedOfficeOpenHours = ({
  isJapan,
  openCloseTimes,
}: SelectedOfficeOpenHoursProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  if (isJapan) return null;

  if (openCloseTimes) {
    return (
      <>
        <div className={cx(
          css`
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            margin-top: 20px;
            margin-bottom: ${isMobile ? 20 : 0}px;
          `,
        )}
        >
          {WEEK.en.map((weekDay, i) => (
            <div
              key={weekDay}
              className={
                css`
                  display: flex;
                  gap: 8px;
                  align-items: flex-start;
                `
              }
            >
              <div
                className={
                  css`
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #434343;
                  `
                }
              >
                {WEEK.ko[i]}
              </div>
              {openCloseTimes[weekDay].length
                ? (
                  <div className={css`
                    display: flex;
                    flex-direction: column;
                  `}
                  >
                    {openCloseTimes[weekDay].map(
                      (openingHour) => (
                        <div
                          key={`${openingHour.open}-${openingHour.close}`}
                          className={
                          css`
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            color: #717171;
                        `
                      }
                        >
                          {openingHour.open}~{openingHour.close}
                        </div>
                      ))}
                  </div>
                )
                : (
                  <div
                    className={
                      css`
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #717171;
                      `
                    }
                  >
                    OFF
                  </div>
                )}
            </div>
          ))}
        </div>
      </>
    );
  }

  return null;
};
