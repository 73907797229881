import { atom } from 'recoil';

import {
  CityCodeType,
  CityNameType,
  LocationCategoryNameType,
  LocationCodeType, LocationNameType,
} from '^/types/__BrandedLocationTypes';
import {
  LocationType,
} from '^/types/LocationType';

export const pickupLocationState = atom<LocationType>({
  key: 'pickupLocationState',
  default: {
    cityCode: '' as CityCodeType,
    cityName: '' as CityNameType,
    parentCategoryCode: '' as LocationCategoryNameType,
    categories: {},
    locationCode: '' as LocationCodeType,
    locationName: '' as LocationNameType,
  },
});

export const returnLocationState = atom({
  key: 'returnLocationState',
  default: {
    cityCode: '' as CityCodeType,
    cityName: '' as CityNameType,
    parentCategoryCode: '' as LocationCategoryNameType,
    categories: {},
    locationCode: '' as LocationCodeType,
    locationName: '' as LocationNameType,
    isLoading: false,
  },
});
