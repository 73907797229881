import qs from 'qs';
import { useLocation, useNavigate } from 'react-router';

import Button from '^/components/Button';
import { Layout2 } from '^/components/Layout/Layout2';
import { PERSONAL_PAYMENT } from '^/constants/routes';

const PersonalPaymentResult = () => {
  const buttonClassNameMobile = 'tablet-max:text-big tablet-max:tracking-default tablet-max:font-bold tablet-max:py-[14px]';
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    imp_uid, merchant_uid, imp_success, error_code, error_msg,
  } = queryParams;

  const goToHome = () => {
    window.location.href = window.location.origin;
    // 현재 페이지를 새로운 상태로 history에 추가
    window.history.replaceState({}, document.title, window.location.href);
  };

  if (imp_success == 'true') {
    return (
      <Layout2>
        <div className="my-[150px] flex flex-col items-center">
          <p className="text-large font-bold">결제 완료되었습니다.</p>
          <Button
            classNames={`rounded-trimo-5 mt-[10px] h-[56px] text-base font-bold tracking-default px-6 py-[18px] ${buttonClassNameMobile}`}
            color="textBlackSkyblue"
            onClick={goToHome}
            content="홈으로"
          />
        </div>
      </Layout2>
    );
  }

  const paymentQuery = JSON.parse(sessionStorage.getItem('PERSONAL_PAYMENT') || '');
  return (
    <Layout2 withoutOverflowScroll>
      <div className="my-[150px] flex flex-col items-center px-[24px]">
        <h1 className="text-large font-bold">결제에 실패했습니다.</h1>
        <p className="mt-[6px] text-center">{`${error_msg}`}</p>
        <div className="mt-3 flex content-center">
          {!!paymentQuery && (
            <Button
              classNames={`
                disabled:text-white
                disabled:secondary-gray-light
                bg-primary-skyblue
                hover:bg-primary-skyblue 
                rounded-trimo-5 
                mt-[10px] 
                mr-[6px] 
                h-[56px]
                text-base 
                font-bold 
                tracking-default 
                px-6 
                py-[18px] 
                ${buttonClassNameMobile}`}
              color="skyblue"
              content="다시 결제하기"
              onClick={() => navigate(`${PERSONAL_PAYMENT}?${qs.stringify(paymentQuery)}`, { replace: true })}
            />
          )}
          <Button
            classNames={`rounded-trimo mt-[10px] h-[56px] text-base font-bold tracking-default px-6 py-[18px] ${buttonClassNameMobile}`}
            color="textBlackSkyblue"
            onClick={goToHome}
            content="홈으로"
          />
        </div>
      </div>
    </Layout2>
  );
};

export default PersonalPaymentResult;
