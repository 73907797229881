/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';

import {
  ReservationDetailsBillPODBooked,
} from './ReservationDetailDesktop.bill.pod.booked';
import {
  ReservationDetailsBillPODCancelled,
} from './ReservationDetailDesktop.bill.pod.cancelled';
import {
  ReservationDetailsBillPPDBooked,
} from './ReservationDetailDesktop.bill.ppd.booked';
import {
  ReservationDetailsBillPPDCancelled,
} from './ReservationDetailDesktop.bill.ppd.cancelled';

export const ReservationDetailsBill: React.FC<{
  data: ReservationDetailed,
  onClickCancelReservation(
    reservationToCancel: ReservationDetailed,
  ): void;
}> = observer(function ReservationDetailsBill({
  data,
  onClickCancelReservation,
}) {
  if (data.pay_type === 'POD') {
    if (data.res_status === 'booked') {
      return (
        <PreTagForDebugging
          obj={data}
          SHOW_DEBUGGING_VIEW={false}
        >
          <ReservationDetailsBillPODBooked
            data={data}
            onClickCancelReservation={onClickCancelReservation}
          />
        </PreTagForDebugging>
      );
    }
    return (
      <PreTagForDebugging
        obj={data}
        SHOW_DEBUGGING_VIEW={false}
      >
        <ReservationDetailsBillPODCancelled
          data={data}
        />
      </PreTagForDebugging>
    );
  }
  if (data.res_status === 'booked') {
    return (
      <PreTagForDebugging
        obj={data}
        // SHOW_DEBUGGING_VIEW
        // className={css`
        //   position: fixed;
        //   right: 0;
        //   max-width: 90%;
        //   opacity: 0.2;
        // `}
        SHOW_DEBUGGING_VIEW={false}
      >
        <ReservationDetailsBillPPDBooked
          data={data}
          onClickCancelReservation={onClickCancelReservation}
        />
      </PreTagForDebugging>
    );
  }
  return (
    <PreTagForDebugging
      obj={data}
      SHOW_DEBUGGING_VIEW={false}
    >
      <ReservationDetailsBillPPDCancelled
        data={data}
      />
    </PreTagForDebugging>
  );
});
