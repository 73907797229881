import React from 'react';

import { css } from '@emotion/css';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import { observer } from 'mobx-react-lite';
import { useRifm } from 'rifm';

import { countriesPhoneCode } from '^/constants/countriesPhoneCode';

import { ReservationCheckPageIDs } from './ids';
import { DefaultPhoneInputStyle, PhoneInputClassNameOverrides } from './PhoneInput.styles';
import { PhoneInputViewModel } from './PhoneInput.viewmodel';
import { TypedSelect } from './TypedSelect/TypedSelect';

export const PhoneInput: React.FC<{
  viewmodel: PhoneInputViewModel;
  classNameOverrides?: PhoneInputClassNameOverrides;
}> = observer(function ReservationCheckPage({ viewmodel, classNameOverrides }) {
  const rifm = useRifm({
    value: viewmodel.phoneNumber.value,
    onChange: viewmodel.phoneNumber.set,
    format: viewmodel.formatter,
    accept: /\d+/g,
  });
  return (
    <div id="PhoneInput_root" className={classNameOverrides?.root || DefaultPhoneInputStyle.root}>
      <div
        id="PhoneInput_inputRoot"
        className={classNameOverrides?.inputRoot || DefaultPhoneInputStyle.inputRoot}
      >
        <TypedSelect
          selected={viewmodel.phoneCountryCode}
          list={countriesPhoneCode}
          toId={(item) => item.code}
          renderItem={(item, selected) => (
            <div
              className={css`
                display: flex;
              `}
            >
              <div
                className={css`
                  width: 50px;
                `}
              >
                {(selected || null) && (
                  <CheckIcon
                    className={css`
                      width: 24px;
                      height: 24px;
                    `}
                  />
                )}
              </div>
              <div>{`${item.label}: ${item.phone}`}</div>
            </div>
          )}
          renderSelected={(item) => (
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              {item.phone}
              <ChevronDownIcon
                className={css`
                  width: 20px;
                  height: 20px;
                `}
              />
            </div>
          )}
        />
        <input
          id={ReservationCheckPageIDs.phoneInput}
          type="tel"
          className={classNameOverrides?.input || DefaultPhoneInputStyle.input}
          aria-label="phone"
          value={rifm.value}
          onChange={rifm.onChange}
          onBlur={() => viewmodel.phoneNumber.setHasBlurred(true)}
          placeholder="휴대폰 번호"
        />
      </div>
      {viewmodel.isValidPhone === false ? (
        <span
          className={css`
            color: red;
          `}
        >
          유효한 전화 번호를 입력해주세요.
        </span>
      ) : null}
    </div>
  );
});
