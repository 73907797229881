import React, { useEffect, useLayoutEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Listbox } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { COLORS2, font, SCROLL_BAR } from '^/constants/commonStyles';
import { ExtractReactProps } from '^/types/utils/extractReactProps';
import { MobxInputValue } from '^/util/MobxInputValue';

import { useRecoilValue } from 'recoil';
import { renewalUIState } from '^/recoil/atoms';

export type ListboxRootProps<TType> = Partial<ExtractReactProps<typeof Listbox>> & {
  value?: TType | undefined;
  defaultValue?: TType | undefined;
  onChange?(value: TType): void;
};

export const SelectBoxInput = observer(function SelectBoxInput<T extends string | null>(args: {
  inputValue: MobxInputValue<T>;
  overrides?: {
    openDirection?: string;
    rootProps?: ListboxRootProps<T>;
    buttonProps?: Partial<ExtractReactProps<(typeof Listbox)['Button']>>;
    buttonRender?: React.ComponentType<{
      value: T;
      isOpen?: boolean;
      isListItem?: boolean;
    }>;
    optionsProps?: Partial<ExtractReactProps<(typeof Listbox)['Options']>>;
    optionProps?: Partial<ExtractReactProps<(typeof Listbox)['Option']>>;
    optionRender?: React.ComponentType<{
      value: T;
      isChosen?: boolean;
      isListItem?: boolean;
      disabled?: boolean;
    }>;
  };
  choices: Readonly<T[]>;
  disabledItems?: Set<T>;
  enabledItems?: Set<T>;
}) {
  const ButtonRenderer = args.overrides?.buttonRender;
  const OptionRenderer = args.overrides?.optionRender;
  const isRenewal = useRecoilValue(renewalUIState);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const timeSelectBtn = document.getElementById('timeSelectDropDown');
  const rect = timeSelectBtn?.getBoundingClientRect();
  const rectBottom = rect?.bottom || 800;
  const isOpenUp = rectBottom + 224 >= window.innerHeight;
  const [shouldDropDownOpenUp, setShouldDropDownOpenUp] = useState(isOpenUp);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useLayoutEffect(() => {
    setShouldDropDownOpenUp(isOpenUp);
  }, [isOpenUp]);

  return (
    <Listbox
      as="div"
      style={{
        position: 'relative',
        width: isRenewal ? '86px' : '50%',
      }}
      // {...args.overrides?.rootProps}
      value={args.inputValue.value}
      tabIndex={-1}
      onChange={(value) => {
        if (args.overrides?.rootProps?.onChange) {
          args.overrides?.rootProps.onChange(value);
        }
        args.inputValue.set(value);
      }}
      id="timeSelectDropDown"
    >
      {({ open }) => {
        return (
          <>
            <Listbox.Button
              {...args.overrides?.buttonProps}
              className={cx(
                css`
                  width: 100%;
                  border: 1px solid #e1e1e1;
                  border-radius: 5px !important;

                  background-color: ${COLORS2.main.MrtWhite} !important;
                `,
                open &&
                  (args.overrides?.openDirection === 'UP' || shouldDropDownOpenUp) &&
                  css`
                    border: 1px solid #e1e1e1;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                  `,
                open &&
                  (!args.overrides?.openDirection || !shouldDropDownOpenUp) &&
                  css`
                    border: 1px solid #e1e1e1;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                  `,

                isRenewal &&
                  css`
                    border-radius: 6px;
                  `,
              )}
              onClick={handleDropdownToggle}
            >
              <Listbox.Options
                id="LISTBOX_OPTIONS"
                className={cx(
                  // borders.DEBUG_greenBorderDashed,
                  SCROLL_BAR.hide,
                  font.Body1_Regular,
                  css`
                    position: absolute;

                    width: 100%;
                    max-height: 224px;

                    z-index: 50;
                    background-color: white;
                    overflow-y: scroll;

                    & > li:not(:last-child) {
                      border-bottom: 1px solid ${COLORS2.sub.MrtGray3};
                    }
                  `,
                  // args.overrides?.openDirection !== 'UP'
                  shouldDropDownOpenUp || isMobile
                    ? css`
                        bottom: 42px;
                        left: 0;
                        filter: drop-shadow(10px -10px 30px rgba(0, 0, 0, 0.1));
                        /* for safari issue (https://github.com/mdn/browser-compat-data/issues/17726) */
                        transform: translateZ(0);

                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                      `
                    : css`
                        /* down by default */
                        top: 100%;
                        left: 0;
                        filter: drop-shadow(10px 30px 30px rgba(0, 0, 0, 0.1));
                        /* for safari issue (https://github.com/mdn/browser-compat-data/issues/17726) */
                        transform: translateZ(0);

                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                      `,
                )}
                // css`
                //     font-size: 14px;
                //     line-height: 20px;
                //     font-weight: 500;
                //     color: #1e1e1e;
                //   `,
                {...args.overrides?.optionsProps}
              >
                {args.choices.map((choice) => {
                  const disabled =
                    args.disabledItems?.has(choice) ||
                    (args.enabledItems && !args.enabledItems.has(choice));
                  return (
                    <Listbox.Option
                      {...args.overrides?.optionProps}
                      key={choice}
                      value={choice}
                      disabled={disabled}
                    >
                      {OptionRenderer ? (
                        <OptionRenderer value={choice} isListItem disabled={disabled} />
                      ) : (
                        choice
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>

              {ButtonRenderer ? (
                <ButtonRenderer value={args.inputValue.value} isOpen={open} />
              ) : (
                args.inputValue.value
              )}
            </Listbox.Button>
          </>
        );
      }}
    </Listbox>
  );
});
