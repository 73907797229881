import React, { Fragment } from 'react';

import { css } from '@emotion/css';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';

import { borders } from '^/constants/commonStyles';
import { errorMsgState, errorState } from '^/recoil/atoms';
import { MobxInputValue } from '^/util/MobxInputValue';

import { cancelReservationV2 } from '../../../api/reservation';
import Button from '../../Button/index';

export const WrapperCancelPopup: React.FC<{
  title: string;
  popOpen: boolean;
  setPopOpen(a: boolean): void;
  // popOpen: MobxInputValue<boolean>;
  cancelInfo: any,
  setOpen: any,
  isMobile?: boolean,
}> = ({
  title,
  popOpen,
  cancelInfo,
  setPopOpen,
  setOpen,
  isMobile,
}) => {
  const setIsError = useSetRecoilState(errorState);
  const setErrorMsg = useSetRecoilState(errorMsgState);
  const cancelHandler = async () => {
    const data: any = await cancelReservationV2(
      cancelInfo.reservation_id,
      cancelInfo.last_name,
      cancelInfo.vendor_id,
    );

    if (data.status === 200 || data.status === 201) {
      setPopOpen(false);

      if (isMobile) {
        window.location.pathname = '/my-reservation';
      } else {
        setOpen(false);
        window.location.reload();
      }
    } else {
      setIsError(true);
      setErrorMsg('Cancelled failed');
    }
  };

  return (
    <>
      <Transition appear show={popOpen} as={Fragment}>
        {isMobile ? (
          <div className="sticky z-[50] grid place-items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                id="ReservationCancelPopup::BackgroundDiv"
                className="fixed inset-0 bg-black bg-opacity-25"
              />
            </Transition.Child>

            <div className={[
              'fixed inset-0 overflow-hidden overscroll-contain',
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
            >
              <div
                className={[
                  'flex justify-center tablet:px-2 tablet-max:px-2 mt-[250px] text-center relative',
                  // borders.DEBUG_purpleBorderDashed,
                ].join(' ')}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div
                    className={[
                      'transform relative overflow-y-auto rounded-trimo bg-white tablet:px-6 tablet-max:px-4 text-left align-middle shadow-xl transition-all ',
                      // borders.DEBUG_greenBorderDashed,
                    ].join(' ')}
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <h3 className="w-80 text-large font-bold text-gray-900">
                      {title}
                    </h3>
                    <div className="hide-scrollbar mt-4 flex overflow-y-auto">
                      <Button
                        type="button"
                        classNames="mt-1 mr-2 rounded-trimo flex-auto"
                        color="noneActive"
                        size="full"
                        fullWidth
                        content="취소"
                        onClick={() => setPopOpen(false)}
                      />
                      <Button
                        type="button"
                        classNames="mt-1 ml-2 rounded-trimo flex-auto"
                        color="secondarySkyblue"
                        size="full"
                        fullWidth
                        content="예약 취소"
                        onClick={cancelHandler}
                      />
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </div>
        ) : (
          <div className="sticky z-[50] grid place-items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0">
              <div
                className={[
                  'flex justify-center items-center tablet:px-6 tablet-max:px-4 mb-12 text-center relative',
                  // borders.DEBUG_blueBorderDashed,
                  css`
                    height: 100vh;
                    overscroll-behavior: contain;
                    overflow: hidden;
                  `,
                ].join(' ')}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div
                    className={[
                      'transform relative overflow-y-auto rounded-trimo bg-white tablet:px-6 tablet-max:px-4 text-left align-middle shadow-xl transition-all tablet:py-6',
                      // borders.DEBUG_greenBorderDashed,
                    ].join(' ')}
                  >
                    <h3 className="w-80 text-large font-bold text-gray-900">
                      {title}
                    </h3>
                    <div className="hide-scrollbar mt-4 flex overflow-y-auto">
                      <Button
                        type="button"
                        classNames="mt-1 mr-2 rounded-trimo flex-auto"
                        color="noneActive"
                        size="full"
                        fullWidth
                        content="취소"
                        onClick={() => setPopOpen(false)}
                      />
                      <Button
                        type="button"
                        classNames="mt-1 ml-2 rounded-trimo flex-auto"
                        color="secondarySkyblue"
                        size="full"
                        fullWidth
                        content="예약 취소"
                        onClick={cancelHandler}
                      />
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};
