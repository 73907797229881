import Big from 'big.js';

export function roundNumberToDigitsIn10s(
  n: number,
  roundToDigitIn10s?: number,
) {
  if (!roundToDigitIn10s) return String(n);
  const b = Big(n);
  return b.div(roundToDigitIn10s)
    .round()
    .mul(roundToDigitIn10s)
    .toString();
  // return Math.round((n / roundToDigitIn10s)) * roundToDigitIn10s;
}

export function formatKRWWonAmount(
  amount?: string | number | null,
  roundToDigitIn10s?: number, // eg. amount 37.142 and roundToDigitIn10s = 0.01 --> 37.14
) {
  if (amount === 0) return '0';
  if (!amount) return null;
  const a = typeof amount === 'number'
    ? roundNumberToDigitsIn10s(amount, roundToDigitIn10s)
    : String(amount);

  return a.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// roundNumberToDigitsIn10s(3.14234, 0.01); // ?
roundNumberToDigitsIn10s(37.05000000000001, 0.01); // ?
