/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useEffect,
  useRef,
} from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { RentalCarOfficeLocationType } from '^/AppContext/RentalOfficeLocationRepo';
import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import { SPACING } from '^/constants/commonStyles';

import { CalendarStateViewModel } from '../BasicCalendar/Calendar.state.viewmodel';
import { CalendarListViewModel } from '../BasicCalendar/components/List/Calendar.List.viewmodel';

import { CalendarMonth } from './components/Month/Calendar.Month';

export const MRTCalendarRoot: React.FC<{
  selectedReturnDayOffsetTop?: CalendarStateViewModel['selectedReturnDayOffsetTop']
  hasDifferentPickupReturnLocations: CarListingSearchBoxInputViewModel['hasDifferentPickupReturnLocations'],
  pickupLocation: RentalCarOfficeLocationType,
  returnLocation: RentalCarOfficeLocationType,
  calendarStateViewModel: CalendarStateViewModel,
  calendarListViewModel: CalendarListViewModel,
  onBlur?: () => void,
  isMobile: boolean,
  isCalendarWidthTimeMaxSize?: boolean,
}> = observer(function MRTCalendarRoot({
  selectedReturnDayOffsetTop,
  hasDifferentPickupReturnLocations,
  pickupLocation,
  returnLocation,
  calendarStateViewModel,
  calendarListViewModel,
  onBlur,
  isMobile,
  isCalendarWidthTimeMaxSize,
}) {
  const elemRef = useRef<HTMLDivElement>(null);
  const calendarScrollAreaRef = useRef<HTMLDivElement>(null);

  const selectionEndRef = useRef<HTMLDivElement>(null);

  const calendarPaginationOffset = calendarStateViewModel
    .calendarPaginationOffset
    .value;
  const calendarPaginationCount = isMobile ? 13 : 2;

  // move to endDay
  useEffect(() => {
    if (!isMobile) return;

    if (calendarScrollAreaRef.current
      && selectionEndRef.current
    ) {
      const scrollCorrectionValue = window.innerHeight - 100 - 300 - 10;

      calendarStateViewModel.selectedReturnDayOffsetTop.setValue( // for reentry
        selectionEndRef.current.offsetTop - scrollCorrectionValue,
      );

      const top = selectionEndRef.current
        .offsetTop
        - scrollCorrectionValue;
      calendarScrollAreaRef.current
        .scrollTo({
          top,
          behavior: 'smooth',
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMobile,
    calendarScrollAreaRef.current,
    selectionEndRef.current,
    calendarStateViewModel.selection.end,
    calendarStateViewModel.selectedReturnDayOffsetTop,
  ]);

  // for reentry step0 -> step3
  useEffect(() => {
    if (!isMobile) return;
    if (!calendarScrollAreaRef.current) return;
    if (!selectedReturnDayOffsetTop?.value) return;
    if (!calendarStateViewModel.selection.start) return;
    if (!calendarStateViewModel.selection.end) return;

    calendarScrollAreaRef.current
      .scrollTo({
        top: selectedReturnDayOffsetTop.value,
        behavior: 'smooth', // can change 'auto'
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={elemRef}
      className={cx(
        // borders.DEBUG_blueBorderDashed,
        css`
          width: 100%;
          border: 1px solid #E1E1E1;
          border-radius: 12px;
          padding: 16px;
          /* height: fit-content; */
        `,

        isCalendarWidthTimeMaxSize && css`
          width: 100%;
        `,
      )}
      tabIndex={-1}
      onBlur={(e) => {
        // see: https://stackoverflow.com/a/60094794/19531789
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        if (onBlur) {
          onBlur();
        }
      }}
    >
      <div
        ref={calendarScrollAreaRef}
        id="calendar-scroll-area"
        className={cx(
          !isMobile
            ? css`
              position: relative;

              display: flex;
              gap: ${SPACING.spacing24};
            `
            : css`
              overflow-y: hidden;
              height: calc(100% - 110px);
              `,
          isMobile && calendarStateViewModel.selection.end
          && css`
              height: calc(100% - 360px);
            `,
        )}
      >
        {calendarListViewModel
          .paginatedList({
            offset: calendarPaginationOffset,
            count: calendarPaginationCount,
          })
          .map((
            vm,
            nth,
            arr,
          ) => (
            <CalendarMonth
              isCalendarWidthTimeMaxSize={isCalendarWidthTimeMaxSize}
              selectionEndRef={selectionEndRef}
              containerRef={calendarScrollAreaRef}
              hasDifferentPickupReturnLocations={hasDifferentPickupReturnLocations}
              pickupLocation={pickupLocation}
              returnLocation={returnLocation}
              stateViewModel={calendarStateViewModel}
              showPrevButton={!isMobile
                && nth === 0
                && calendarPaginationOffset > 0}
              showNextButton={!isMobile
                && nth === arr.length - 1
                && (
                  calendarListViewModel.numCalendars - calendarPaginationCount
                ) > calendarPaginationOffset}
              viewmodel={vm}
              key={`${vm.yearMonth.year}-${vm.yearMonth.monthFrom0}`}
            />
          ))}
      </div>

      {/* <pre>{JSON.stringify(calendarListViewModel.current, null, 2)}</pre> */}
    </div>
  );
});
