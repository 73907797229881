/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { MobxInputValue } from '^/util/MobxInputValue';

export const CheckboxesWithAllToggle = observer(
  function CheckboxesWithAllToggle<T extends string>(
    p: {
      possibleValues: Readonly<T[]>,
      checked: MobxInputValue<Set<T>>,
      overrides: {
        ItemRenderer: React.ComponentType<{
          value: T | Readonly<T>,
          checked: MobxInputValue<Set<T>>,
        }>,
        AllCheckRenderer: React.ComponentType<{
          allChecked: boolean;
          setAllChecked(checked: boolean): void;
        }>
      }
    },
  ) {
    const { t } = useTranslation();
    const { AllCheckRenderer } = p.overrides;
    const { ItemRenderer } = p.overrides;

    const allChecked = p.possibleValues.length === p.checked.value.size
      || p.checked.value.size === 0;

    useEffect(() => {
      if (p.possibleValues.length === p.checked.value.size) {
        p.checked.set(new Set());
      }
    }, [
      p.possibleValues.length,
      p.checked.value.size,
      p.checked,
    ]);

    return (
      <>
        <AllCheckRenderer
          allChecked={allChecked}
          setAllChecked={(checked) => {
            if (checked) {
              p.checked.set(new Set(p.possibleValues));
            } else {
              p.checked.set(new Set());
            }
          }}
        />
        {p.possibleValues.map((value) => {
          return (
            <ItemRenderer
              key={value}
              value={value}
              checked={p.checked}
            />
          );
        })}
      </>
    );
  },
);
