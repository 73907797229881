import React, { useEffect, useRef } from 'react';

import { css } from '@emotion/css';

import './index.scss';

export const defaultMultiRangeSliderStyles = {
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
} as const;

const getPercent = (
  value,
  min,
  max,
) => {
  return Math.round(((value - min) / (max - min)) * 100);
};

export const MultiRangeSlider: React.FC<{
  possibleMin: number;
  possibleMax: number;
  onClick?: () => void;
  onChange: (_: { min: number, max: number }) => void;
  minValue: number | null;
  maxValue: number | null;
  classOverrides?: Partial<typeof defaultMultiRangeSliderStyles>;
}> = ({
  possibleMin, possibleMax,
  onClick,
  onChange,
  minValue: rawMinValue,
  maxValue: rawMaxValue,
  classOverrides,
}) => {
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef<HTMLDivElement>(null);

  const minValue = rawMinValue || possibleMin;
  const maxValue = rawMaxValue || possibleMax;
  // Convert to percentages

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minValue, possibleMin, possibleMax);

      // Preceding with '+' converts the value from type string to type number
      const maxPercent = getPercent(maxValue, possibleMin, possibleMax);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxValue, minValue, possibleMax, possibleMin]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(minValue, possibleMin, possibleMax);
      const maxPercent = getPercent(maxValue, possibleMin, possibleMax);
      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxValue, minValue, possibleMax, possibleMin]);

  return (
    <div
      id="multirange-slider"
      className={[
        classOverrides?.root
        || defaultMultiRangeSliderStyles.root,
      ].join(' ')}
    >
      <input
        type="range"
        min={possibleMin}
        max={possibleMax}
        value={minValue}
        ref={minValRef}
        onClick={onClick}
        onChange={(event) => {
          const value = Math.min(+event.target.value, possibleMax - 1);
          onChange({ min: value, max: maxValue });
          // event.target.value = value.toString();
        }}
        className={[
          'thumb thumb--zindex-3',
          minValue > possibleMax - 100 ? 'thumb--zindex-5' : '',
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      />
      <input
        type="range"
        min={possibleMin}
        max={possibleMax}
        value={maxValue}
        ref={maxValRef}
        onClick={onClick}
        onChange={(event) => {
          const value = Math.max(+event.target.value, possibleMin + 1);
          onChange({ min: minValue, max: value });
          // event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
    </div>
  );
};

export default MultiRangeSlider;
