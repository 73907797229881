import axios from '../axios/index';

// 예시: 요청 큐 사용
const requestQueue = [];
let isProcessingQueue = false;

async function processRequestQueue() {
  if (isProcessingQueue) {
    return; // 이미 큐 처리 중인 경우, 중복 실행 방지
  }

  isProcessingQueue = true;

  while (requestQueue.length > 0) {
    const request = requestQueue.shift();

    try {
      await axios.fetch(request);
    } catch (error) {
      console.error('오류 발생:', error);
    }
  }

  isProcessingQueue = false;
}

export function enqueueRequest(request) {
  requestQueue.push(request);

  if (!isProcessingQueue) {
    processRequestQueue();
  }
}

// 예시: 요청 추가
// enqueueRequest({ method: 'get', url: 'URL_1' });
// enqueueRequest({ method: 'get', url: 'URL_2' });
