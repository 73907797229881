import { css, cx } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';

import { MON_START_WEEK_KO, WEEK_KO } from '../MRTCalendar.constants';

export const WeekDays = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (

    <div
      className={
        css`
          display: flex;
          width: 100%;
          color: ${COLORS2.main.MrtBlack};
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #9F9F9F;
          margin-top: 8px;
        `
      }
    >
      {MON_START_WEEK_KO.map((day) => (
        <div
          key={day}
          className={
            css`
              display: flex;
              justify-content: center;
              width: 14.28%;
            `
          }
        >
          <span
            className={
              css`
                display: flex;
                justify-content:center;
                align-items:center;
              `
            }
          >
            {day}
          </span>
        </div>
      ))}
    </div>
  );
};
