import React, { Fragment } from 'react';

import { Transition } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

import { borders } from '^/constants/commonStyles';
import { MobxInputValue } from '^/util/MobxInputValue';

import { ConfirmDialogDefaultMobileStyles, ConfirmDialogStyles } from './ConfirmDialogPopup.styles';

export const ConfirmDialogPopupMobile: React.FC<{
  title: React.ReactNode;
  visible: MobxInputValue<boolean>;
  cancelText: string;
  confirmText: string;
  isDisabled?: boolean;
  handleConfirm(e: React.MouseEvent): void;
  handleCancel(e: React.MouseEvent): void;
  stylesOverrides?: Partial<ConfirmDialogStyles>;
}> = observer(function ConfirmDialogPopupMobile({
  title,
  visible,
  cancelText,
  confirmText,
  isDisabled,
  handleCancel,
  handleConfirm,
  stylesOverrides,
}) {
  const styles = {
    ...ConfirmDialogDefaultMobileStyles,
    ...stylesOverrides,
  };
  return (
    <Transition appear show={visible.value} as={Fragment}>
      <div className="sticky z-[50] grid place-items-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div id="ReservationCancelPopup::BackgroundDiv" className={styles.modalBackground} />
        </Transition.Child>

        <div
          className={[
            'fixed inset-0  flex justify-center items-center w-screen h-screen',
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <div
            className={[
              'flex justify-center tablet:px-2 tablet-max:px-2 text-center relative',
              // borders.DEBUG_purpleBorderDashed,
            ].join(' ')}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={[
                  'transform relative overflow-y-auto rounded-trimo bg-white tablet:px-6 tablet-max:px-4 text-left align-middle shadow-xl transition-all',
                  // borders.DEBUG_greenBorderDashed,
                  styles.modalRoot,
                ].join(' ')}
              >
                {title}
                <div className={styles.buttonGroup}>
                  <button
                    type="button"
                    className={[styles.button, styles.buttonCancel].join(' ')}
                    onClick={(e) => {
                      visible.set(false);
                      handleCancel(e);
                    }}
                  >
                    {cancelText}
                  </button>
                  <button
                    disabled={isDisabled}
                    type="button"
                    className={[styles.button, styles.buttonConfirm].join(' ')}
                    onClick={(e) => {
                      visible.set(false);
                      handleConfirm(e);
                    }}
                  >
                    {confirmText}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
});
