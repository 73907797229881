import { useState } from 'react';

import { css } from '@emotion/css';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import { DialogWithCloseButton } from '^/commonComponents/ui/DialogWithCloseButton';
import { MARGINS, COLORS2_AS_FOREGROUND, font2 } from '^/constants/commonStyles';
import AdditionalFeesOnlineTravel from '^/pages/Booking/onlinetravel/Bill/Bill.AdditionalFees';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { priceWithComma } from '^/util/priceFormat';

import BillCanceled from '../../components/ReservationDetails.Bill.Canceled';
import * as styles from '../../mobile/components/Bill/ReservationDetailsMobile.bill.styles';
import { PODOnsitePaymentHoverMessage } from '../../onsiteInfoMessages';

import { CreditCardOnlyNotice } from './CreditCardOnlyNotice';

const ReservationDetailBillMobile = ({ data, totalOptionsFee }) => {
  const [isOnSiteInfoDialogOpen, setIsOnSiteInfoDialogOpen] = useState(false);
  const { payment_info: payment, option_info_list: options } = data;

  const rentalPrice = payment.basic_price + (payment.insurance_price || 0); // 대여금액 (basic_price + insurance_price)
  return (
    <div
      className={[
        COLORS2_AS_FOREGROUND.main.MrtBlack,
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <h3 className={font2.Headline1_Bold}>
        {data.booking_info.status === 'booked' ? '요금 내역' : '취소 내역'}
      </h3>

      <Horizon marginTop="30px" />
      <div
        className={[
          'flex flex-col gap-4',
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <DialogWithCloseButton
          isOpen={isOnSiteInfoDialogOpen}
          setIsOpen={setIsOnSiteInfoDialogOpen}
          contentWrapperClassName={styles.OnsiteHoverMessageStyles.contentWrapperClassName}
          content={(
            <pre className={styles.OnsiteHoverMessageStyles.contentClassName}>
              {PODOnsitePaymentHoverMessage}
            </pre>
          )}
        />
        <div className="mt-4 flex flex-row justify-between tablet:mt-0">
          <p className="text-big tablet:text-semilarge">대여 요금</p>
          <p className="text-semilarge font-bold">
            {priceWithComma(rentalPrice)} {payment?.foreign_currency}
          </p>
        </div>
        {data.option_info_list.length > 0 ? (
          <div
            className={[
              MARGINS.TOP.spacing12,
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
          >
            <AdditionalFeesOnlineTravel
              totalOptionsFee={totalOptionsFee}
              options={options}
              payment={payment}
            />
          </div>
        ) : (
          <></>
        )}
        <Horizon />
        <div className="flex flex-row justify-between">
          <div
            className={[
              css`
                display: flex;
                flex-direction: row;
                gap: 6px;
                align-items: center;
                height: fit-content;
              `,
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
          >
            <p className="text-big tablet:text-semilarge">현장 결제 예상금액</p>
            <InfoIcon width={20} height={20} onClick={() => setIsOnSiteInfoDialogOpen(true)} />
          </div>
          <div className="flex flex-col items-end gap-1.5">
            <p className="text-semihuge font-bold tablet:text-semilarge">
              {payment?.foreign_total_price.toLocaleString()}
              &nbsp;
              {payment?.foreign_currency}
            </p>
            <p
              className="
                  text-small
                  font-light
                  text-neutral-300
                "
            >
              약 {formatKRWWonAmount(payment?.total_price)} 원
            </p>
          </div>
        </div>
        {data.booking_info.status === 'canceled' && <BillCanceled data={data} />}
        {data.booking_info.status == 'booked' && payment?.card_flag ? (
          <p className="text-right text-small font-bold">
            <CreditCardOnlyNotice />
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default ReservationDetailBillMobile;
