import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { ReservationIDType, UserLastNameType, VendorCompanyIDType } from '^/types/__BrandedTypes';
import { getCurrentFreshness } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';

export class ReservationDetailsPageViewModel {
  constructor(
    public appContext: AppContext,
    public reservationId: ReservationIDType,
    public lastName: UserLastNameType,
    public vendorId: VendorCompanyIDType,
    public freshness = getCurrentFreshness(),
    public showInsuranceInfoMobilePopupPage: boolean = false,
    public showCancelConfirmPopup = new MobxInputValue(false),
    public showLoading = new MobxInputValue(false)
  ) {
    makeAutoObservable(this);
  }

  get reservationDetail() {
    return this.appContext.getReservationDetailCore.getReservationDetail(
      this.reservationId,
      this.lastName,
      this.vendorId,
      this.freshness
    );
  }

  setShowInsurancePopup(showInsurance: boolean) {
    typeRouter.routes
      .reservationDetails({
        id: this.reservationId,
        lastName: this.lastName,
        vendorId: this.vendorId,
        freshness: this.freshness,
        showInsurance,
      })
      .push();
  }

  async cancelReservation(cancelReason) {
    this.showLoading.set(true);
    const result = await this.appContext.getReservationDetailCore.cancelReservation(
      this.reservationId,
      this.lastName,
      this.vendorId,
      cancelReason
    );
    this.showLoading.set(false);
    return result;
  }
}
