import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { MRTPickupReturnDifferentInputsDesktop } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/MRTPickupReturnDifferentInputs.desktop';
import { MRTPickupReturnSameInputsDesktop } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/MRTPickupReturnSameInputs.desktop';
import { COLORS2 } from '^/constants/commonStyles';

import { CarListingSearchBoxViewModel } from '../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';

export const MRTCarListingSearchBoxDesktop: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  onBlur: () => void;
  smaller?: boolean;
}> = observer(function CarListingSearchBoxDesktop({ viewmodel, onBlur, smaller }) {
  return (
    <div
      tabIndex={-1}
      onBlur={(e) => {
        // see: https://stackoverflow.com/a/60094794/19531789
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        if (onBlur) {
          onBlur();
        }
      }}
      className={cx(
        css`
          position: relative;
          width: 100%;
          max-width: 1320px;
          min-height: 80px;

          z-index: 10;
          background-color: ${COLORS2.main.MrtWhite};
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          &:focus-visible {
            outline: none;
          }
        `,
      )}
    >
      {viewmodel.i.hasDifferentPickupReturnLocations.value ? (
        <MRTPickupReturnDifferentInputsDesktop viewmodel={viewmodel} smaller={smaller} />
      ) : (
        <MRTPickupReturnSameInputsDesktop viewmodel={viewmodel} smaller={smaller} />
      )}
    </div>
  );
});
