import { css, cx } from '@emotion/css';

import {
  borders, SPACING, COLORS2, font, SCROLL_BAR,
} from '^/constants/commonStyles';

const suggestionsList = cx(
  css`
    position: absolute;
    top: 55px; left: 0;
    z-index: 10;

    width: calc(100% + 2px);
    margin-left: -1px;
    max-height: 238px;
    overflow-y: scroll;

    background-color: ${COLORS2.main.MrtWhite};
    color: ${COLORS2.main.MrtBlack};

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `,
  font.Body1_Regular,
  SCROLL_BAR.hide,
);
export const defaultCitySearchInputStyle = {
  root: cx(
    css`
    `,
    // borders.DEBUG_blueBorderDashed,
  ),
  textInput: css`
    background-color: transparent;
  `,

  suggestionsList,
  suggestionsListItem: css`
    border-bottom: 1px solid ${COLORS2.sub.MrtGray3};
    height: ${SPACING.spacing56};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  noCitySuggestion: cx(
    suggestionsList,
    css`
      border-bottom: 1px solid ${COLORS2.sub.MrtGray3};
      height: ${SPACING.spacing56};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: ${SPACING.spacing16};
      color: ${COLORS2.sub.MrtGray1};
    `,
  ),
  location_en: font.Caption2_Light,
} as const;

export type CitySearchInputStyle =
  typeof defaultCitySearchInputStyle;
