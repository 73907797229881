import React from 'react';

import { observer } from 'mobx-react-lite';

import { AlertPopup } from '^/components/Popup/AlertPopup/AlertPopup';

import { ListingPageOnlineTravelDesktop } from './desktop/ListingPage.OnlineTravel.Desktop';
import { ListingPageOnlineTravelMobile } from './mobile/ListingPage.OnlineTravel.Mobile';
import { ListingPageOnlineTravelViewModel } from './viewmodel/ListingPage.OnlineTravel.viewmodel';

export const ListingPageOnlineTravelWrapper: React.FC<{
  viewmodel: ListingPageOnlineTravelViewModel;
}> = observer(function ListingPageOnlineTravel({ viewmodel }) {
  if (viewmodel.isMobile) {
    return (
      <>
        <AlertPopup
          visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
          title="해당 지역은 대여/반납 장소를 다르게 지정할 수 없습니다."
          closeText="닫기"
          // eslint-disable-next-line react/jsx-no-bind
          handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
          isMobile
        />
        <ListingPageOnlineTravelMobile viewmodel={viewmodel} />
      </>
    );
  }
  return (
    <>
      <AlertPopup
        visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
        title="해당 지역은 대여/반납 장소를 다르게 지정할 수 없습니다."
        closeText="닫기"
        // eslint-disable-next-line react/jsx-no-bind
        handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
        isMobile
      />
      <ListingPageOnlineTravelDesktop viewmodel={viewmodel} />
    </>
  );
});
