/* eslint-disable max-len */
import React from 'react';

import { borders } from '^/constants/commonStyles';
import { CURRENCY_CODE_KEYS } from '^/constants/currencyCode.constants';
import {
  RawReservationDetailExtraChange,
} from '^/types/ReservationDetailed/RawReservationDetailed';

export const AdditionFees: React.FC<{
  extra_charge: RawReservationDetailExtraChange,
  onSiteCurrency: CURRENCY_CODE_KEYS,
  stylesOverride?: {
    titleText?: string;
    totalText?: string;
    itemTitleText?: string;
    itemTotalText?: string;
  }
}> = ({
  extra_charge,
  onSiteCurrency,
  stylesOverride,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <p className={[
          stylesOverride?.titleText ?? 'text-big tablet:text-semilarge',
        ].join(' ')}
        >추가 요금
        </p>
        <p className={[
          stylesOverride?.totalText ?? 'font-bold text-semilarge',
          // borders.DEBUG_orangeBorderDashed,
        ].join(' ')}
        >
          {extra_charge.additional_amount.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
        </p>
      </div>
      {extra_charge.extra_charge_detail.map((item, index) => (
        <div key={index} className="flex flex-row justify-between">
          <p
            className={[
              stylesOverride?.itemTitleText
              ?? 'text-small font-light text-trimo-gray tablet:text-default',
            ].join(' ')}
          >
            {`${item.name} (${item.quantity})`}
          </p>
          <p
            className={[
              stylesOverride?.itemTotalText
              ?? 'text-small font-light text-trimo-gray tablet:text-default',
            ].join(' ')}
          >
            {item.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
          </p>
        </div>
      ))}
      {/* { */}
      {/*  tax && */}
      {/*  <div className='flex flex-row justify-between'> */}
      {/*    <p className="text-small tablet:text-default font-light text-primary-gray">Tax Country ({tax?.['@_Percentage']})</p> */}
      {/*    <p className="text-small tablet:text-default font-light text-primary-gray">{tax?.['@_Total']?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p> */}
      {/*  </div> */}
      {/* } */}
      {extra_charge.dropFee > 0
        && (
        <div className="flex flex-row justify-between">
          <p className="text-small font-light text-trimo-gray tablet:text-default">Drop fee</p>
          <p className="text-small font-light text-trimo-gray tablet:text-default">
            {extra_charge.dropFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
          </p>
        </div>
        )}
      {extra_charge.etc_amount > 0
        && (
        <div className="flex flex-row justify-between">
          <p className="text-small font-light text-trimo-gray tablet:text-default">기타 (세금 등)</p>
          <p className="text-small font-light text-trimo-gray tablet:text-default">
            {extra_charge.etc_amount.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
          </p>
        </div>
        )}
    </div>
  );
};
