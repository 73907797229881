import { css } from '@emotion/css';

import { EmptyCar } from '../../common/EmptyCar';

export const ListingPage2DesktopNoCar = () => {
  return (
    <div
      id="ListingPageDesktopNoCar"
      className={[
        css`
          /* background-color: #fff; */
          flex-grow: 1;
          /* top: 0; */
          /* left: 0; */

          /* width: 100%;
          height: 100%; */
          min-height: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `,
      ].join(' ')}
    >
      <div
        className={css`
          /* margin-top: 166px; */
        `}
      >
        <EmptyCar />
      </div>
    </div>
  );
};
