import Button from '../../../../components/Button';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DAY_OF_WEEK } from '../../constant';
import { VENDOR_LOGOS } from '^/constants/LogoImages.constants';
import { calculateHours } from '../../../../util/index';
import { useMediaQuery } from 'react-responsive';
import { GroupCar, NameCar } from '../../../../components/ModelCar';
import { borders } from '^/constants/commonStyles';
import { css } from '@emotion/css';
import { formatDateWithTimezone } from '../../../../util/dateFormat';
import locationNames from '^/constants/locationNames.constants';
// import { sendTrackingLog, actions } from '^/api/trackingLog';

const ReservationCard = ({ data, onButton, index }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const pickUpDate = new Date(formatDateWithTimezone(data.pickup_datetime));
  const returnDate = new Date(formatDateWithTimezone(data.return_datetime));
  const isOnlineTravel = data?.supplier == 'OT';

  return (
    <>
      {index !== 0 && isMobile && <div className="h-1.5 bg-trimo-bg -mx-6"></div>}
      <div
        onClick={
          isMobile
            ? (e) => {
                e.preventDefault();
                // sendTrackingLog({
                //   action: actions.RESERVATION_LIST.CLICK_RESERVATION_DETAIL,
                //   data: data.res_id,
                // });
                onButton(data);
              }
            : undefined
        }
        className={[
          'flex flex-col bg-white tablet-max:bg-trimo-bg rounded-lg p-4 tablet:pt-6 tablet:pb-[34px] tablet:pl-2 tablet:pr-6 desktop-max:h-fit tablet:shadow-banner h-[260px]',
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        {VENDOR_LOGOS[data.supplier] && (
          <div className="flex flex-row justify-start align-top relative">
            <img
              alt="company-image"
              src={VENDOR_LOGOS[data.supplier]}
              className="object-contain absolute right-0 top-2.5 tablet:w-[59px] tablet:h-[21px] mobile:h-5 mobile:w-11"
            />
          </div>
        )}
        <div className="flex flex-row h-[100%]">
          <div className="tablet:col-span-6 col-span-10 laptop:ml-4 flex flex-col justify-between w-full tablet-max:gap-3">
            <div className={isMobile ? 'flex gap-2' : 'flex flex-col gap-2'}>
              {isOnlineTravel ? (
                <>
                  <h4 className="text-primary-gray font-bold w-auto mt-1 text-semilarge tablet-max:text-big tablet:text-semigiant capitalize">{`[${data?.size_car}] ${data?.car_model}`}</h4>
                  {isMobile ? (
                    <img src={'./ic_arrow_right.png'} className="mt-[1.5px]" width="24" />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <GroupCar
                    modelName={data?.car_model}
                    className="text-primary-gray font-bold w-auto mt-1 text-semilarge tablet-max:text-big tablet:text-semigiant capitalize"
                    size={data?.size_car}
                    type={data.type_car}
                    isMobile={isMobile}
                  />
                  <NameCar
                    modelName={data?.car_model}
                    className="text-primary-gray tablet-max:hidden tablet-max:text-default font-light text-semilarge"
                  />
                </>
              )}
              <p
                className={[
                  'tablet:hidden text-neutral-300 text-small tablet-max:hidden',
                  // borders.DEBUG_blueBorderDashed,
                ].join(' ')}
              >
                {JSON.parse(data?.services)
                  ?.filter((service) => service.info && service.info !== 'undefined')
                  .map(
                    (service) =>
                      `${
                        Number(service.info)
                          ? `${t(service.name).includes('${num}') ? '' : service.info}`
                          : t(service.info)
                      } ${t(service.name).replace('${num}', service.info)}`,
                  )
                  .join(' / ')}
              </p>
            </div>
            {isMobile ? (
              <>
                <div className="flex flex-col gap-1.5">
                  <p className="text-default font-bold">예약 번호</p>
                  <p className="text-small font-light">
                    {data?.is_confirm_hertz ? data?.res_id : '-'}
                  </p>
                </div>
                <div
                  className={[
                    'flex flex-col gap-1.5',
                    // borders.DEBUG_greenBorderDashed,
                  ].join(' ')}
                >
                  <p className="text-default font-bold">
                    {t('rentaled')} / {t('return')} {t('schedule')}
                  </p>
                  <p className="text-small font-light">
                    {format(pickUpDate, 'yyyy.MM.dd')} ({t(DAY_OF_WEEK[pickUpDate.getDay()])}){' '}
                    {format(pickUpDate, 'HH:mm')} ~ {format(returnDate, 'MM.dd')} (
                    {t(DAY_OF_WEEK[returnDate.getDay()])}) {format(returnDate, 'HH:mm')} (
                    {calculateHours(returnDate, pickUpDate)} {t('hourUnit')})
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <p className="text-default font-bold">
                    {t('rentaled')} / {t('return')} {t('location1')}
                  </p>
                  <p className="text-small font-light">
                    {`${t(`location_name.${data.pickup_loc}`)} / ${t(
                      `location_name.${data.return_loc}`,
                    )}`}
                  </p>
                </div>
              </>
            ) : (
              <div className="flex flex-col tablet:flex-row justify-between mt-[22px]">
                <table
                  className={[
                    'table-auto text-primary-gray',
                    // borders.DEBUG_orangeBorderDashed,
                  ].join(' ')}
                >
                  <tbody>
                    <tr>
                      <td className="">
                        <p className="font-bold tablet:text-semihuge text-primary-gray ">
                          예약 번호
                        </p>
                      </td>
                      <td className="">
                        <div className="mx-12 text-big font-light">
                          {data?.is_confirm_hertz ? data?.res_id : '-'}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="">
                        <p className="font-bold tablet:text-semihuge text-primary-gray mt-4">
                          대여 / 반납 위치
                        </p>
                      </td>
                      <td className="align-bottom">
                        <div className="ml-12 text-default tablet:text-big font-light">
                          {`${t(`location_name.${data.pickup_loc}`)} / ${t(
                            `location_name.${data.return_loc}`,
                          )}`}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="tablet-max:w-[100px]">
                        <p className="font-bold text-big tablet:text-semihuge text-primary-gray mt-4">
                          대여 일정
                        </p>
                      </td>
                      <td>
                        <div className="ml-12 text-default tablet:text-big mt-4 font-light">
                          {/* yyyy.mm.dd (day) hh:mm ~ mm.dd (day) hh:mm (hours) */}
                          {format(pickUpDate, 'yyyy.MM.dd')} ({t(DAY_OF_WEEK[pickUpDate.getDay()])}){' '}
                          {format(pickUpDate, 'HH:mm')} ~ {format(returnDate, 'MM.dd')} (
                          {t(DAY_OF_WEEK[returnDate.getDay()])}) {format(returnDate, 'HH:mm')} (
                          {calculateHours(returnDate, pickUpDate)} 시간)
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="tablet:flex tablet:flex-col-reverse tablet-max:mt-4">
                  <Button
                    color="secondarySkyblue"
                    classNames="bg-primary-skyblue text-whiterounded-lg w-full py-[15px]"
                    content="예약 상세보기"
                    onClick={(e) => {
                      e.preventDefault();
                      // sendTrackingLog({
                      //   action: actions.RESERVATION_LIST.CLICK_RESERVATION_DETAIL,
                      //   data: data.res_id,
                      // });
                      onButton(data);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ReservationCard.propTypes = {
  data: PropTypes.object.isRequired,
  onButton: PropTypes.func,
  index: PropTypes.number,
};

export default ReservationCard;
