import { priceWithComma } from '^/util/priceFormat';

import { ONLINE_TRAVEL_OPTIONS } from '../CarDetails/components/OptionalServices.constant';

const AdditionalFeesOnlineTravel = ({ totalOptionsFee, options, payment }) => {
  const isOptionSelected = options.some((option) => option.quantity > 0);
  if (!isOptionSelected) return null;
  return (
    <>
      <div className="flex items-center justify-between">
        <p
          className="
            font-normal mobile:text-big
            mobile:text-primary-gray tablet:text-semilarge
            tablet:text-primary-gray
          "
        >
          추가 요금
        </p>
        <p className="text-semilarge font-bold text-primary-gray">
          {totalOptionsFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
          {payment.foreign_currency}
        </p>
      </div>
      {options?.map((item, index) => {
        if (!item?.quantity) return null;
        return (
          <div
            key={`options-${index}`}
            className="flex items-center justify-between font-light text-neutral-300 mobile:mt-2 mobile:text-small tablet:text-default"
          >
            <p className="capitalize">
              {`${ONLINE_TRAVEL_OPTIONS[item?.code]} (${item?.quantity || 0})`}
            </p>
            <p>
              {priceWithComma(item?.foreign_price * item?.quantity)} {item.foreign_currency_code}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default AdditionalFeesOnlineTravel;
