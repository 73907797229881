import { css } from '@emotion/css';

import { COLORS2 } from '^/constants/commonStyles';

export const Style = {
  scrollbarStyle: css`
    &::-webkit-scrollbar {
      width: 12px;
      height: 20px;
      padding: 4px 2px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      height: 80px;
      background: #e1e1e1;
      border-radius: 99px;
      border: 2px solid #fff;
    }
  `,
  tabList: css`
    width: 136px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #FAFAFA;
    border-radius: 8px 0 0 8px;

    & > button {
      width: 100%;
      padding: 12px 16px;
      text-align: left;
      border-bottom: 1px solid var(--gray-100, #F4F4F4);
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 0 0 8px;
      }
    }
  `,
  tabSelected: css`background-color: ${COLORS2.main.MrtWhite} !important; color: ${COLORS2.main.MrtBlack}`,
  tabDefault: css`background-color: #FAFAFA !important; color: #575757`,
  cityPanelWrapper: css`
    width: 100%;
    height: 277px;
    border-radius: 0 8px 8px 0;
  `,
  citySelectContainer: css`
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 12px;
      height: 20px;
      padding: 4px 2px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      height: 80px;
      background: #e1e1e1;
      border-radius: 99px;
      border: 2px solid #fff;
    }
  `,
  countryWrapper: css`
    width: 100%;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #e1e1e1;
    }
  `,
  countryLabel: css`
    font-size: 12px;
    color: ${COLORS2.sub.MrtGray1};
  `,
  cityGrid: css`
    display: grid;
    width: 100%;
    color: #434343;
    grid-template-columns: repeat(5, 100px);
    row-gap: 24px;
    justify-content: space-between;
  `,
  cityLabel: css`
    min-width: 100px;
    padding: 2px 0;
    &:hover{
      border-radius: 4px;
      background-color: #f4f4f4;
      margin-left: -8px;
      padding-left: 8px;
      cursor: pointer;
    }
    &:active, &.selected {
      color: #4d9add;
    }

  `,
};
