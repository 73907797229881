import { AxiosInstance } from 'axios';

import { ReservationIDType, UserLastNameType, VendorCompanyIDType } from '^/types/__BrandedTypes';
import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';

import { api2BaseAxios } from '../api2BaseAxios';
import { defineResponseWithData } from '../DefineResponse';
import { zodValidateAxiosResp } from '../zodValidate';

import { CancelReservationResponse } from './cancelReservationResponseType';
import { CancellationReasonType } from '^/types/CancellationReasonType';

const response = defineResponseWithData(CancelReservationResponse, 201);
const validator = zodValidateAxiosResp(response, (err) => {
  console.error('getReservation zodError', err);
});
export function createCancelReservationAPI(axiosInstance: AxiosInstance) {
  return async function cancelReservationAPI(args: {
    reservation_id: ReservationIDType;
    last_name: UserLastNameType;
    vendor_id: VendorCompanyIDType;
    cancel_reason: CancellationReasonType;
  }) {
    const rawResponse = await axiosInstance.post('/v2/reservation/cancel-v2', args);
    const validated = validator(rawResponse);
    if (validated.data.status !== 201) {
      return asFailedPromise({
        reason: 'HTTPError',
        error: rawResponse.data,
        rawResponse,
      } as const);
    }
    if (!validated.data.data.success) {
      // FIXME: 응답에 맞게 조정 필요
      return asFailedPromise({
        reason: 'CancelFailed',
        error: validated.data.data,
      } as const);
    }
    return asSuccessPromise({
      ...validated.data.data,
      zodError: validated.ZOD_ERROR,
      zodValidation: validated.ZOD_VALIDATION,
    });
  };
}

export const cancelReservationAPI = createCancelReservationAPI(api2BaseAxios);

export type CancelReservationAPIType = typeof cancelReservationAPI;
