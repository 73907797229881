export const ONLINE_TRAVEL_OPTIONS = {
  "0001": "영아 시트",
  "10BB": "영아 시트",
  "0002": "유아 시트",
  "10CH": "유아 시트",
  "0003": "아동 시트",
  "10JH": "아동 시트",
}

export const ONLINE_TRAVEL_OPTIONS_DESCRIPTION = {
  "0001": "1세 미만 및 몸무게 20 lbs\n혹은 9 kg 미만의 유아용 카 시트",
  "10BB": "1세 미만 및 몸무게 20 lbs\n혹은 9 kg 미만의 유아용 카 시트",
  "0002": "몸무게 20-40 lbs 혹은 8-19 kg의\n아동을 위해 전방 장착형 카 시트",
  "10CH": "몸무게 20-40 lbs 혹은 8-19 kg의\n아동을 위해 전방 장착형 카 시트",
  "0003": "몸무게 40-80 lbs 혹은\n25 kg까지의 아동을 위한 카 시트",
  "10JH": "몸무게 40-80 lbs 혹은\n25 kg까지의 아동을 위한 카 시트",
}