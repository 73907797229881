/* eslint-disable @typescript-eslint/naming-convention */
import { z } from 'zod';

import { CURRENCY_CODE_KEYS } from '^/constants/currencyCode.constants';
import { FULL_VEHCLASS_ZOD } from '^/constants/envConfig';
import {
  NumberOrNumberString,
  NumberString,
} from '^/util/zod/numberFromString';

import { DatetimeWithoutTimezone } from '../__BrandedDateTimeTypes';
import { LocationCodeTypeRel_OAG } from '../__BrandedLocationTypes';
import { AirlineMileageProgramCode, ReservationIDType } from '../__BrandedTypes';

export const TaxStr = z.string().brand<'TaxStr'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TaxStr = z.infer<typeof TaxStr>;

export const RawReservationPODDetails = z.object({
  pay_type: z.literal('POD'),
  cancel_confirm: z.boolean().optional(),
});
export const RawReservationPPDDetails = z.object({
  pay_type: z.literal('PPD'),

  // true: enable cancel  false: disable cancel
  //   "cancelEnabled"
  cancel_confirm: z.boolean(),
});

const ExtraChargeDetailItem = z.object({
  name: z.string(),
  quantity: NumberOrNumberString,
  price: NumberOrNumberString,
});
// eslint-disable-next-line @typescript-eslint/naming-convention
export const extra_charge = z.object({
  additional_amount: z.number(),
  extra_total_amount: z.number(),
  additional_rate_amount: z.number().optional(),
  etc_amount: z.number(),
  dropFee: z.number(),
  extra_charge_detail: ExtraChargeDetailItem.array(),
});

export type RawReservationDetailExtraChange = z.infer<typeof extra_charge>;
// extra_total: 0, extra_charge_detail: {}

export const ExtraEquipmentsType = z.object({
  currency: CURRENCY_CODE_KEYS,
  price: NumberOrNumberString,
  info: z.array(
    z.object({
      code: z.string(),
      quantity: z.string(),
      price: z.string(),
    }),
  ),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ExtraEquipmentsType = z.infer<typeof ExtraEquipmentsType>;
export const RawExtraEquipments = z.union([
  ExtraEquipmentsType,
  z.object({
    currency: CURRENCY_CODE_KEYS,
  }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RawExtraEquipments = z.infer<typeof RawExtraEquipments>;

export function isValidExtraEquipments(
  e: RawExtraEquipments,
): e is ExtraEquipmentsType {
  return !(!(e as ExtraEquipmentsType).info);
}

export const RawReservationBookedDetails = z.object({
  res_status: z.literal('booked'),
  date_cancel: z.null(),
});
export const RawReservationCanceledDetails = z.object({
  res_status: z.literal('canceled'),
  date_cancel: z.string(),
});

export const ServicesJson = z.object({
  name: z.string(),
  info: z.string(),
});

export const InsuranceInfoJson = z.object({
  code: z.string(),
  insurancesServices: z.string(),
});

export const RawReservationDetailed = z.object({
  supplier: z.string(),
  agent_name: z.null().optional(),
  agent: z.null().optional(),
  date_request: z.string(),
  invoice: z.null().optional(),
  res_id: ReservationIDType,
  korean_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  image: z.string().optional(),
  pickup_loc: LocationCodeTypeRel_OAG,
  return_loc: LocationCodeTypeRel_OAG,
  pickup_datetime: DatetimeWithoutTimezone,
  return_datetime: DatetimeWithoutTimezone,
  pay_type: z.string(),
  currency: CURRENCY_CODE_KEYS,
  exchange_rate: NumberString.optional(),
  user_id: z.null().optional(),
  car_model: z.string(),
  extra_equipmens: z.string(),
  extra_equipments_json: RawExtraEquipments,
  insurance_info: z.string(),
  insurance_info_json: InsuranceInfoJson,
  insurances_detail: z.string().array().optional(),
  office_address: z.string(),
  office_name: z.string(),
  office_time: z.string(),
  phone_code: z.string(),
  phone_number: z.string(),
  services: z.string(),
  services_json: z.array(ServicesJson),
  size_car: FULL_VEHCLASS_ZOD,
  type_car: z.string(),
  email: z.string(),
  flight_brand: z.string(),
  flight_number: z.string(),
  frequent_flyer: z.string(),
  frequent_brand: AirlineMileageProgramCode.optional(),
  hertz_membership: z.string(),
  office_second_address: z.string(),
  office_second_name: z.string(),
  office_phone: z.string(),
  office_second_phone: z.string(),
  office_second_time: z.string(),
  dropFee: NumberOrNumberString,
  rate_amount: NumberOrNumberString,
  rate_foreigner_amount: NumberOrNumberString,
  tax: TaxStr.nullable().optional(),
  total_amount: NumberOrNumberString,
  total_foreigner_amount: NumberOrNumberString,
  agent_data: z.null().optional(),
  createdAt: z.string().optional(),
  deletedAt: z.null().optional(),
  // false 일 때: reservationId 를 숨겨야됨
  is_confirm_hertz: z.boolean().optional(),
  is_mail_send: z.boolean().optional(),
  model_code: z.unknown().nullable(),
  extra_charge: extra_charge.optional(),
  isDiscount: z.boolean(),
})
  .and(z.union([
    RawReservationBookedDetails,
    RawReservationCanceledDetails,
  ]))
  .and(z.union([
    RawReservationPODDetails,
    RawReservationPPDDetails,
  ]));

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RawReservationDetailed = z.infer<typeof RawReservationDetailed>;
export type RawReservationDetailedI = z.input<typeof RawReservationDetailed>;

export function convertReservationDetailedFromRaw(
  raw: RawReservationDetailed,
) {
  const rawModelName = raw.car_model;
  const orSimilarCar = rawModelName
    .toUpperCase()
    .endsWith('OR SIMILAR');
  const actualModelName = orSimilarCar
    ? rawModelName.slice(0, -10)
    : rawModelName;
  const total_foreigner_amount = Number(raw.total_foreigner_amount);
  const rate_foreigner_amount = Number(raw.rate_foreigner_amount);
  return {
    ...raw,
    actualModelName,
    orSimilarCar,
    cancelEnabled: raw.cancel_confirm !== false,
    insuranceServices: raw
      .insurance_info_json
      .insurancesServices // LDW / LI / LIS / PPP / UM
      .split('/')
      .map((a) => a.trim()),
    paymentDetails: {
      onSiteCurrency: raw.extra_equipments_json.currency,
      toPayOnSiteInLocalCurrency: raw.pay_type === 'PPD'
        ? total_foreigner_amount - rate_foreigner_amount
        : total_foreigner_amount,
      // total_foreigner_amount - rate_foreigner_amount
    },
    displayReservationId: !raw.is_confirm_hertz
      ? '-'
      : raw.res_id,
  };
}

export type ReservationDetailed = ReturnType<
  typeof convertReservationDetailedFromRaw
>;
