import isEmail from 'isemail';
import { parseDigits } from 'libphonenumber-js';
import { action, makeAutoObservable } from 'mobx';

import { typeRouter } from '^/typeRoute/typeRoutes';
import { MobxInputValue } from '^/util/MobxInputValue';

import {
  PhoneInputViewModel,
} from './PhoneInput.viewmodel';

const key = '__PHONE_EMAIL__';
type EmailAndPhoneType = {
  phoneCountryCode: string,
  phone: string,
  email: string,
};
export function savePhoneEmailToStorage(
  toSave: EmailAndPhoneType,
) {
  sessionStorage.setItem(key, JSON.stringify(toSave));
}

export function loadPhoneEmailFromStorage() {
  const raw = sessionStorage.getItem(key);
  if (!raw) return raw;
  return JSON.parse(raw) as EmailAndPhoneType;
}

function cleanupPhoneNumber(phoneNumber: string) {
  const digitsOnly = parseDigits(phoneNumber);
  if (digitsOnly.startsWith('0')) {
    return digitsOnly.slice(1);
  }
  return digitsOnly;
}

export class ReservationCheckPageFormViewModel {
  constructor(
    public phone = new PhoneInputViewModel(),
    public email = new MobxInputValue(''),
  ) {
    makeAutoObservable(this, {
      submit: action.bound,
    });
  }

  get isValidEmail() {
    return isEmail.validate(this.email.value);
  }

  get isValid() {
    return this.phone.isValidPhone && this.isValidEmail;
  }

  submit() {
    // action here.

    savePhoneEmailToStorage({
      phoneCountryCode: this.phone.phoneCountryCode.value.phone,
      phone: cleanupPhoneNumber(this.phone.phoneNumber.value),
      email: this.email.value,
    });

    // rel: https://rencar.atlassian.net/jira/software/projects/FQ23/boards/27?selectedIssue=FQ23-149
    // back btn inside myReservationsList should redirect to home
    typeRouter.routes.myReservationsList().replace();
  }
}
