/* eslint-disable no-nested-ternary */
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';

import { YearMonth } from '../../BasicCalendar/helpers/YearMonth';

const size32px = css`
  width: 32px;
  height: 32px;
`;

export const CalendarHeader: React.FC<{
  yearMonth: YearMonth;
  calendarStateVM: CalendarStateViewModel,
  showPrevButton?: boolean;
  showNextButton?: boolean;
}> = observer(function CalendarHeader({
  yearMonth,
  calendarStateVM,
  showNextButton,
  showPrevButton,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
    >
      {showPrevButton ? (
        <input
          type="image"
          alt="이전 달로 가기"
          src="Arrow-Left.svg"
          onClick={() => {
            const current = calendarStateVM
              .calendarPaginationOffset
              .value;
            calendarStateVM
              .calendarPaginationOffset
              .set(current - 1);
          }}
          className={size32px}
        />
      )
        : isMobile ? <></> : <div className={size32px} />}
      <div
        className={
          css`
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            align-items: center;
          `
        }
      >
        {yearMonth.year}년 {yearMonth.monthFrom0 + 1}월
      </div>
      {showNextButton ? (
        <input
          type="image"
          alt="다음 달로 가기"
          src="Arrow-Right.svg"
          onClick={() => {
            const current = calendarStateVM
              .calendarPaginationOffset
              .value;
            calendarStateVM
              .calendarPaginationOffset
              .set(current + 1);
          }}
          className={size32px}
        />
      )
        : isMobile ? <></> : <div className={size32px} />}
    </div>

  );
});
