/* eslint-disable mobx/no-anonymous-observer */
import React, { Ref } from 'react';

import { observer } from 'mobx-react-lite';

import { IInputValue } from '^/util/MobxInputValue';

export type InputElemProps = React.InputHTMLAttributes<HTMLInputElement>;
type PropsType<T extends string = string> = {
  isUsingFocus?: boolean;
  viewmodel: IInputValue<T>;
  rootRef?: Ref<HTMLInputElement>;
} & InputElemProps;

export const TextInput = observer(function TextInput<T extends string = string>({
  isUsingFocus = true,
  viewmodel,
  rootRef,
  onChange,
  ...inputProps
}: PropsType<T>) {
  return (
    <input
      ref={rootRef}
      onFocus={(e) => {
        if (!isUsingFocus) return;

        viewmodel.setIsFocused(true);
      }}
      onBlur={(e) => {
        if (!isUsingFocus) return;

        if (inputProps.onBlur) {
          inputProps.onBlur(e);
        }
        viewmodel.setHasBlurred(true);
      }}
      value={viewmodel.value}
      onChange={(e) => {
        viewmodel.set(e.currentTarget.value as T);
        if (onChange) {
          onChange(e);
        }
      }}
      {...inputProps}
    />
  );
});
