import { css } from '@emotion/css';
import { differenceInHours, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { MARGINS, COLORS2, font2 } from '^/constants/commonStyles';
import { dateWithHyphen } from '^/util/dateFormat';
import { formatPhoneNumber, insertHyphen } from '^/util/phoneNumber';

import { ONLINE_TRAVEL_OPTIONS } from '../../CarDetails/components/OptionalServices.constant';

import ShopInfoDesktop from './ShopInfo.Desktop';
import { translateServicesForOntra } from '^/pages/Listing/common/translateServices';

const ReservationConfirmDesktop = ({ data, options, isResult, isDetail }) => {
  const { t } = useTranslation();

  const selectedOptions = options.filter((option) => option.quantity && option.quantity > 0);
  const formattedPhoneNumber = formatPhoneNumber(insertHyphen(data.plan_info.shop_tel));

  return (
    <div
      className={
        isDetail
          ? 'text-primary-gray'
          : 'rounded-trimo bg-primary-white text-primary-gray shadow-trimodropdown mobile:p-4 tablet:p-[30px]'
      }
    >
      <div
        className={isDetail ? 'flex flex-col gap-[25px]' : 'flex flex-col justify-between gap-4'}
      >
        {/* 차량정보 */}
        <div className="flex items-start justify-between">
          <div className="mb-4 flex flex-col">
            <h2
              className={
                isResult
                  ? 'table:text-enomous font-bold capitalize mobile:text-semilarge desktop:text-huge'
                  : isDetail
                  ? 'table:text-enomous mt-[32px] font-bold capitalize mobile:text-semilarge'
                  : 'table:text-enomous font-bold capitalize mobile:text-semilarge'
              }
            >
              {`[${data.car_info.class_name}] ${data.car_info.model_name}`}
            </h2>
            <h3 className="mt-2 font-normal text-neutral-300 mobile:text-small tablet:text-semilarge">
              {translateServicesForOntra(data.equipment_info_list, data.plan_info.shuttle_flag, t)}
            </h3>
          </div>
          <div>
            <img
              alt={data.car_info.model_name}
              src={data.car_info.image}
              onError={(e) => {
                e.currentTarget.src = '/NOT_FOUND_CAR.png';
              }}
              className={css`
                height: 102px;
                margin-right: 55px;
                margin-top: 10px;
              `}
            />
          </div>
        </div>

        <div
          className={css`
            display: flex;
            flex-direction: column;
            & > * {
              margin-bottom: 20px;
            }
            & > *:last-child {
              margin-bottom: 0;
            }
          `}
        >
          {/* 예약번호 */}
          {isDetail && (
            <div>
              <h2 className={[font2.Headline1_Bold, MARGINS.BOTTOM.spacing6].join(' ')}>
                예약 번호
              </h2>
              <p>{data.booking_info?.id || '-'}</p>
            </div>
          )}
          {/* 대여 / 반납 일정 */}
          <div>
            <h2 className={[font2.Headline1_Bold, MARGINS.BOTTOM.spacing6].join(' ')}>
              대여 / 반납 일정
            </h2>
            <p>
              {`${format(
                new Date(dateWithHyphen(data.schedule_info.pickup.time)),
                'yyyy년 LL월 dd일 HH:mm',
              )} ~ ${format(
                new Date(dateWithHyphen(data.schedule_info.return.time)),
                'yyyy년 LL월 dd일 HH:mm',
              )} (${differenceInHours(
                new Date(dateWithHyphen(data.schedule_info.return.time)),
                new Date(dateWithHyphen(data.schedule_info.pickup.time)),
              )} 시간)`}
            </p>
          </div>
          {/* 대여 영업소 */}
          <div>
            <h2 className={[font2.Headline1_Bold, MARGINS.BOTTOM.spacing6].join(' ')}>
              대여 영업소
            </h2>
            <p>
              {data.plan_info.shop_name || '-'}&nbsp;
              {data.plan_info.shop_tel && (
                <a
                  className={css`
                    color: ${COLORS2.main.MrtBlue1};
                    text-decoration: underline;
                  `}
                  // onClick={() => {
                  //   sendTrackingLog({
                  //     action: actions.DRIVER_DETAIL.CLICK_SHOP_PHONE_NUMBER,
                  //     data: formattedPhoneNumber,
                  //   });
                  // }}
                  href={`tel:${formattedPhoneNumber}`}
                >
                  {formattedPhoneNumber}
                </a>
              )}
            </p>
            <p>{data.plan_info.shop_address || '-'}</p>
            {data.plan_info.shuttle_flag == 1 && (
              <p className={[font2.Body1_Regular].join(' ')} style={{ color: COLORS2.sub.orange }}>
                매장까지 무료 셔틀을 이용할 수 있습니다.
              </p>
            )}
          </div>
          {/* 반납 정보 */}
          <div>
            <h2 className={[font2.Headline1_Bold, MARGINS.BOTTOM.spacing6].join(' ')}>
              반납 영업소
            </h2>
            <p>
              {data.plan_info.shop_name}&nbsp;
              {data.plan_info.shop_tel && (
                <a
                  className={css`
                    color: ${COLORS2.main.MrtBlue1};
                    text-decoration: underline;
                  `}
                  // onClick={() => {
                  //   sendTrackingLog({
                  //     action: actions.DRIVER_DETAIL.CLICK_SHOP_PHONE_NUMBER,
                  //     data: formattedPhoneNumber,
                  //   });
                  // }}
                  href={`tel:${formattedPhoneNumber}`}
                >
                  {formattedPhoneNumber}
                </a>
              )}
            </p>
            <p>{data.plan_info.shop_address || '-'}</p>
            {data.plan_info.shuttle_flag == 1 && (
              <p className={[font2.Body1_Regular].join(' ')} style={{ color: COLORS2.sub.orange }}>
                매장까지 무료 셔틀을 이용할 수 있습니다.
              </p>
            )}
          </div>
          {/* 보험 내용 */}
          <div>
            <h2 className={[font2.Headline1_Bold, MARGINS.BOTTOM.spacing6].join(' ')}>보험 내용</h2>
            <p>기본 보험</p>
          </div>
          {/* 추가 옵션 */}
          <div>
            <h2 className={[font2.Headline1_Bold, MARGINS.BOTTOM.spacing6].join(' ')}>추가 옵션</h2>
            <p>
              {selectedOptions.length > 0
                ? selectedOptions
                    .map((option) => `${ONLINE_TRAVEL_OPTIONS[option.code]}(${option.quantity})`)
                    .join(' / ')
                : '없음'}
            </p>
          </div>
        </div>
        {!isResult && <ShopInfoDesktop data={data} isDetail={isDetail} />}
      </div>
    </div>
  );
};

export default ReservationConfirmDesktop;
