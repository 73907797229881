import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useRecoilValue } from 'recoil';

import { LOGO_BY_CHANNEL } from '^/constants/LogoImages.constants';
import { subdomainState } from '^/recoil/atoms';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { getItemByChannel } from '^/util/byChannel';

const classNames = {
  headerWrapper: css`
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    padding: 0 24px;
  `,
};
export const Header3Mobile = observer(function Header3Mobile(props: {
  withoutFullRefresh?: boolean;
}) {
  const subdomain = useRecoilValue(subdomainState);

  return (
    <div>
      <header className={classNames.headerWrapper}>
        <button
          type="button"
          onClick={() => {
            if (props.withoutFullRefresh) {
              typeRouter.routes.home2().push();
            } else {
              window.location.href = window.location.origin;
              window.scrollTo(0, 0);
            }
          }}
        >
          <img
            src={getItemByChannel(subdomain, LOGO_BY_CHANNEL)}
            alt="trimo-logo"
            className={css`
              max-width: 225px;
              max-height: 28px;
            `}
          />
        </button>
      </header>
    </div>
  );
});
