/* eslint-disable max-len */
import { css, cx } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';

import {
  COLORS, cursor, fontSize, fontWeights,
} from '^/constants/commonStyles';

export function SubEmptyReservation() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="relative w-full rounded-trimo border-[3px] border-dashed border-neutral-200 tablet-max:border-2">
      <div className={[
        'flex flex-col items-center tablet-max:items-center relative z-10',
        isMobile
          ? css`
              padding-top: 40px;
              padding-bottom: 40px;
            `
          : css`
              padding-top: 34px;
              padding-bottom: 33px;
            `,
      ].join(' ')}
      >
        <h4 className="text-semihuge font-bold tablet-max:text-big">
          아직 예약된 렌트카가 없습니다.
        </h4>
        <p className="text-big mobile:mt-[13px] mobile:mb-4 tablet:mt-5 tablet:mb-[30px] tablet-max:text-default tablet-max:font-light">
          이번 휴가는 해외여행 어떠신가요 ?
        </p>
        <button
          type="button"
          onClick={() => {
            window.location.href = window.location.origin;
          }}
          className={[cx(
            css`
              width: 150px;
              height: 56px;

              background-color: ${COLORS.LIGHT_GREY_2} !important;
              border-radius: 10px;

              padding-top: 19px;
              padding-bottom: 18px;

              font-size: ${fontSize.big};
              font-weight: ${fontWeights.bold};
              color: ${COLORS.BLACK};
            `,
            fontWeights.bold,
            cursor.hover_pointer,
          ),
          !isMobile && css`
            &:hover {
              background-color: ${COLORS.SKY_BLUE} !important;
              color: ${COLORS.WHITE} !important;
            }
            `,
          ].join(' ')}
        >
          예약하러 가기
        </button>
      </div>
    </div>
  );
}
