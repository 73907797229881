import PropTypes from 'prop-types';
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DynamicIcon from '../Icon/index';
import { FieldValues, UseFormRegister } from 'react-hook-form';

const CONTAINER_CLASSNAME = 'flex flex-col gap text-neutral-300 relative';

const DEFAULT_CLASSNAME = `pt-[19px] pb-[18px] w-full focus:text-primary-gray tablet:px-6 mobile:px-4
                            rounded-trimo-5 border focus:border focus:outline-none
                            invalid:border-secondary-red invalid:text-secondary-red
                            disabled:border-trimo-gray disabled:bg-trimo-shadow disabled:text-trimo-gray
                            `;

const Input = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    label,
    classNames,
    containerClassNames,
    placeholder,
    required,
    onChange,
    onFocus,
    onBlur,
    size = '',
    height,
    isIcon = false,
    initialValue = null,
    positionIcon,
    sizeIcon = 5,
    type = 'text',
    invalid,
    register,
    name,
    nameIcon,
    onClick,
    isCounter,
    counterLabel,
    isRequired = false,
    noMargin = false,
    iconRight = false,
    value,
    isDisabled = false,
    bottomCaption,
    children,
    styleLabel,
    maxLength,
    onlyNumber = false,
    marginBottomCaption = false,
    index,
    onKeyUp,
    noMess = false,
    isSpec = false,
  } = props;
  const [item, setItem] = useState('');
  const containerStyle = `${CONTAINER_CLASSNAME} ${size} ${containerClassNames}`;
  const className = ` ${classNames} ${DEFAULT_CLASSNAME} ${invalid ? 'border-secondary-red' : ''} ${
    isIcon && iconRight ? 'pr-10' : isIcon ? 'pl-10' : ''
  } h-[${height || '56px'}]`;
  const handleChange = (e) => {
    const content = onlyNumber ? e.target.value.replace(/\D/g, '') : e.target.value;
    onChange && onChange(e);
    setItem(content);
  };
  const registerItem = register
    ? register(`${name}`, { onChange: handleChange, onBlur: onBlur })
    : null;
  const [subClassName, setSubClassName] = useState('');
  useEffect(() => {
    if (!invalid) {
      item
        ? setSubClassName('border-primary-gray focus:border-primary-gray text-primary-gray')
        : setSubClassName('border-neutral-200 focus:border-primary-gray');
    } else {
      setSubClassName(
        'text-secondary-red border-secondary-600 focus:text-primary-gray focus:border-primary-gray',
      );
    }
  }, [invalid, item]);
  useEffect(() => {
    initialValue && setItem(initialValue?.[name] || '');
  }, [initialValue]);

  return (
    <div className={containerStyle}>
      {label ? (
        <label className={`text-primary-gray ${styleLabel}`}>
          {t(label)}
          <span className="text-red-600">{isRequired && '*'}</span>
        </label>
      ) : (
        <></>
      )}
      <div className={`relative ${!label && !noMargin && 'tablet:mt-7'}`}>
        {register ? (
          isSpec ? (
            <input
              inputMode={onlyNumber ? 'numeric' : 'text'}
              onKeyUp={onKeyUp}
              value={item || value}
              maxLength={maxLength}
              type={type}
              placeholder={t(placeholder) || ''}
              className={`${className} ${subClassName}`}
              required={required}
              onChange={handleChange}
              disabled={isDisabled}
              onFocus={onFocus}
              data-index={index}
              {...registerItem}
              ref={(e) => {
                if (registerItem) registerItem.ref(e);
                if (ref?.current) ref.current = e;
              }}
            />
          ) : (
            <input
              ref={ref}
              inputMode={onlyNumber ? 'numeric' : 'text'}
              onKeyUp={onKeyUp}
              value={item || value}
              maxLength={maxLength}
              type={type}
              placeholder={t(placeholder) || ''}
              className={`${className} ${subClassName}`}
              required={required}
              onChange={handleChange}
              disabled={isDisabled}
              onFocus={onFocus}
              data-index={index}
              {...register(`${name}`, { onChange: handleChange, onBlur: onBlur })}
            />
          )
        ) : (
          <input
            ref={ref}
            inputMode={onlyNumber ? 'numeric' : 'text'}
            maxLength={maxLength}
            type={type}
            placeholder={t(placeholder) || ''}
            className={className}
            required={required}
            onChange={onChange}
            disabled={isDisabled}
            onFocus={onFocus}
            value={value}
            onBlur={onBlur}
          />
        )}
        {isIcon && (
          <span className={`absolute inset-y-0 flex items-center pl-2 ${iconRight && 'right-2'}`}>
            <button className={`${positionIcon}`} onClick={onClick}>
              <DynamicIcon name={nameIcon} style={`w-${sizeIcon} h-${sizeIcon}`} />
            </button>
          </span>
        )}
        {isCounter ? (
          <span className={`absolute bottom-1/4 ${positionIcon} text-primary-gray font-bold`}>
            {counterLabel}
          </span>
        ) : (
          <></>
        )}
        {children}
      </div>
      {invalid && !noMess && (
        <div className="flex items-center mt-3">
          <img src={'ic_!.png'} className={'w-6 h-6 mr-1'} />
          <span className="text-default text-secondary-red dark:text-red-500">
            {t(invalid?.message)}
          </span>
        </div>
      )}
      {bottomCaption && (
        <span
          className={`mt-2 text-small text-primary-gray ${
            marginBottomCaption && 'tablet-max:-mr-28'
          }`}
        >
          {t(bottomCaption)}
        </span>
      )}
    </div>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  label: PropTypes.string,
  classNames: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.string,
  warningMess: PropTypes.string,
  positionIcon: PropTypes.string,
  sizeIcon: PropTypes.number,
  isIcon: PropTypes.bool,
  type: PropTypes.string,
  register: PropTypes.func,
  invalid: PropTypes.object,
  name: PropTypes.string,
  nameIcon: PropTypes.string,
  onClick: PropTypes.func,
  isCounter: PropTypes.bool,
  counterLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  noMargin: PropTypes.bool,
  iconRight: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  isDisabled: PropTypes.bool,
  bottomCaption: PropTypes.string,
  children: PropTypes.element,
  styleLabel: PropTypes.string,
  onlyNumber: PropTypes.bool,
  marginBottomCaption: PropTypes.bool,
  index: PropTypes.number,
  onKeyUp: PropTypes.func,
  noMess: PropTypes.bool,
  isSpec: PropTypes.bool,
  initialValue: PropTypes.object,
};

export default Input;
