/* eslint-disable max-len */
import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { Footer3Mobile } from '^/components/Footer3/Footer3.Mobile';
import { Header3Mobile } from '^/components/Header3/Header3.Mobile';
import { borders, hideScrollBar } from '^/constants/commonStyles';

export const Layout2Mobile: React.FC<React.PropsWithChildren<{
  withoutFooter?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutNavigationFullRefresh?: boolean;
  classOverrides?: {
    layout2MobileContentWrapperBg?: string;
    footerBgColor?: string;
  }
}>> = observer(function Layout2Mobile({
  children,
  withoutFooter,
  withoutOverflowScroll,
  scrollAreaKey,
  withoutNavigationFullRefresh,
  classOverrides,
}) {
  return (
    <div
      id="Layout2_Mobile_Root"
      className={cx(
        css`
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          overscroll-behavior: contain;
        `,
        // borders.DEBUG_blueBorderDashed,
      )}
    >
      <Header3Mobile
        withoutFullRefresh={withoutNavigationFullRefresh}
      />
      <div
        id="Layout2_Mobile_Content"
        key={scrollAreaKey}
        className={cx(
          css`
            position: absolute;
            top: 60px;
            left: 0; right: 0;
            bottom: 0;
            overflow: scroll;
            overscroll-behavior: contain;
            background-color: ${classOverrides?.layout2MobileContentWrapperBg};
          `,
          hideScrollBar,
          // borders.DEBUG_redBorderDashed,
        )}
      >
        <div
          id="Layout2_Mobile_Content_inner"
          className={css`
            display: flex;
            flex-direction: column;
            min-height: 100%;
            justify-content: space-between;
          `}
          style={withoutOverflowScroll ? {
            height: '100%',
          } : undefined}
        >
          {children}
          {!withoutFooter && <Footer3Mobile />}
        </div>
      </div>
    </div>
  );
});
