import { css, cx } from '@emotion/css';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';

export const classNames = {
  btnRendererBasic: cx(
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: ${SPACING.spacing40};
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      min-width: 86px;

      @media (max-width: 768px) {
        padding: 0 3px 0 8px;
      }
    `,
  ),
};
