import { AxiosInstance } from 'axios';

import { api2BaseAxios } from '../api2BaseAxios';

import { GetCarListArgsType, WithRedisKey } from './ArgsType';

export function createGetCarListStep2(
  axiosInstance: AxiosInstance,
) {
  return async function getCarListStep2Loading(
    args: WithRedisKey,
  ) {
    const params = new URLSearchParams({
      key: args.key,
    });
    const response = await axiosInstance
      .get('/search/cars/loading', {
        params,
      });
    return response;
  };
}

export const getCarListStep2 = createGetCarListStep2(
  api2BaseAxios,
);
