let mostRecentFreshness = 0;

export type FreshnessType = number & {
  __FreshnessType: null;
};

export function getCurrentFreshness() {
  const freshness = Date.now();
  if (mostRecentFreshness > freshness) {
    // monotonic time...?
    return mostRecentFreshness as FreshnessType;
  }
  mostRecentFreshness = freshness;
  return freshness as FreshnessType;
}

export function increaseFreshness(f: FreshnessType) {
  return (f + 1) as FreshnessType;
}
export type FailType<TFail> = {
  status: 'failure',
  failure: TFail,
};
export type SuccessType<TSuccess> = {
  status: 'success',
  value: TSuccess,
};

export type ResultType<TSuccess, TFailure> =
  SuccessType<TSuccess> | FailType<TFailure>;

export function asSuccessType<TValue>(value: TValue) {
  return {
    status: 'success',
    value,
  } as SuccessType<TValue>;
}

export function asFailureType<TValue>(failure: TValue) {
  return {
    status: 'failure',
    failure,
  } as FailType<TValue>;
}

export function isSuccess<TSuccess, TFailure>(
  result: SuccessType<TSuccess> | FailType<TFailure>,
): result is SuccessType<TSuccess> {
  return result.status === 'success';
}

export type WithFreshness<T> = T & {
  freshness: FreshnessType;
};

export type FailedPromise<TFail> = WithFreshness<FailType<TFail>>;
export type SuccessPromise<TSuccess> = WithFreshness<SuccessType<TSuccess>>;
export type LoadingPromise<TLoading=unknown> = WithFreshness<{
  status: 'loading',
  value?: TLoading,
}>;
export type PromiseResultType<
  TSuccess,
  TFailure=unknown,
  TLoading=unknown,
> =
  | SuccessPromise<TSuccess>
  | FailedPromise<TFailure>
  | LoadingPromise<TLoading>;

export const NotInitiatedPromiseStale: LoadingPromise = {
  status: 'loading',
  freshness: -1 as FreshnessType,
};

export function isSuccessPromise<TValue>(
  promiseResult: (
    SuccessPromise<TValue>
    | LoadingPromise<any>
    | FailedPromise<any>
  ),
): promiseResult is SuccessPromise<TValue> {
  return promiseResult.status === 'success';
}

export function asFailedPromise<TFailure>(
  failure: TFailure,
  freshness: FreshnessType = getCurrentFreshness(),
) {
  return {
    failure,
    status: 'failure',
    freshness,
  } as FailedPromise<TFailure>;
}

export function asSuccessPromise<TSuccess>(
  value: TSuccess,
  freshness: FreshnessType = getCurrentFreshness(),
) {
  return {
    status: 'success',
    freshness,
    value,
  } as SuccessPromise<TSuccess>;
}

export type IsResultType<T extends ResultType<any, any>> =
  T extends ResultType<infer TSuccess, infer TFailure> ? {
    success: TSuccess,
    failure: TFailure,
  } : never;

export function asPromiseResult<TSuccess, TFailure>(
  result: ResultType<TSuccess, TFailure>,
  freshness: FreshnessType = getCurrentFreshness(),
): PromiseResultType<TSuccess, TFailure> {
  if (result.status === 'failure') {
    return {
      status: 'failure',
      failure: result.failure,
      freshness,
    };
  }
  return {
    status: 'success',
    value: result.value,
    freshness,
  };
}

export type ExtractFailures<T> =
  T extends (FailedPromise<infer F1> | FailedPromise<infer F2>) ? (
    F1 | F2 | ExtractFailures<F1> | ExtractFailures<F2>
  ) : T extends FailedPromise<infer F1> ? F1 : never;

export type ExtractFailuresAsFailures<T> =
T extends (FailedPromise<infer F1> | FailedPromise<infer F2>) ? (
  FailedPromise<F1> | FailedPromise<F2>
  | ExtractFailuresAsFailures<F1> | ExtractFailuresAsFailures<F2>
) : T extends FailedPromise<infer F1> ? FailedPromise<F1> : never;

// export type ExtractFailures<TFail extends PromiseResultType<any, any, any>> =
//   TFail extends FailedPromise<infer T> ? T : never;

export type ExtractLoading<TLoading extends PromiseResultType<any, any, any>> =
  TLoading extends LoadingPromise<infer T> ? T : never;

export type ExtractPromiseSuccess<T> =
T extends (SuccessPromise<infer F1> | SuccessPromise<infer F2>) ? (
  F1 | F2 | ExtractPromiseSuccess<F1> | ExtractPromiseSuccess<F2>
) : T extends SuccessPromise<infer F1> ? F1 : never;

export function mergeSuccessResult<
T extends PromiseResultType<any, any, any>,
TAdd,
>(
  original: T,
  merged: TAdd,
  /* eslint-disable @typescript-eslint/indent */
): PromiseResultType<
    [ExtractPromiseSuccess<T>, TAdd],
    ExtractFailures<T>,
    ExtractLoading<T>
> {
  /* eslint-enable @typescript-eslint/indent */
  if (original.status !== 'success') {
    return original;
  }
  return asSuccessPromise([
    original.value,
    merged,
  ], original.freshness);
}
