import { observer } from 'mobx-react-lite';

import Map from '^/components/Map';
import Pin from '^/components/Map/Pin';

import { RentalOfficeInputViewModel } from '../../base/common/RentalOfficeInput/RentalOfficeInput.viewmodel';

export const MRTGoogleMap: React.FC<{
  viewmodel: RentalOfficeInputViewModel
  officePosition: {
    lat: number;
    lng: number;
  },
  containerStyle?: object,
}> = observer(function MRTGoogleMap({
  viewmodel,
  officePosition,
  containerStyle,
}) {
  return (
    <Map
      containerStyle={{ width: 'auto', height: '206px', ...containerStyle }}
      center={officePosition || undefined}
      zoom={17}
      options={{ disableDefaultUI: true }}
    >
      <Pin
        {...officePosition}
        place={{}}
        type={viewmodel.selectedCategory.value || 'LDT'}
      />
    </Map>
  );
},
);
