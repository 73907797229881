const CancellationAndRefundHertzPOD = () => {
  return (
    <>
      <p className="text-default font-light tablet-max:text-small">
        트리모를 통해 예약한 경우, 트리모 취소 및 환불 규정에 따라 환불이 진행됩니다. 예약 취소
        시점에 따라 다음과 같이 취소 수수료를 제한 후 환불이 진행될 수 있습니다.
      </p>
      <div className="mt-3 text-default font-light tablet-max:text-small">
        <p className="font-bold">예약 변경</p>
        <p className="mt-2">- 예약 변경은 취소 후 재 예약하셔야 합니다.</p>
        <br />
        <p className="font-bold">예약 취소</p>
        <p className="mt-2">
          - 취소는 반드시 트리모를 통해 진행하셔야 합니다. 만약 다른 경로(타 홈페이지 또는 고객센터
          등)를 통해 취소할 경우 불이익이 발생할 수 있습니다.
          <br />- 픽업 전 취소/NO-SHOW/취소 시 수수료 : 없음
          <br />- 잦은 취소, NO-SHOW 등은 차후 예약 관련 불이익을 받으실 수 있습니다.
        </p>
      </div>
    </>
  );
};

export default CancellationAndRefundHertzPOD;
