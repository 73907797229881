export type KeyOfMap<M extends Map<unknown, unknown>> =
  M extends Map<infer K, unknown> ? K : never;

export type ValueOfMap<M extends Map<unknown, unknown>> =
  M extends Map<unknown, infer V> ? V : never;

export class FixedEnumMap<V extends string> {
  constructor(
    public readonly values: Readonly<V[]>,
    private readonly store = Object.fromEntries(
      values.map((v) => [v, v] as const),
    ) as Record<V, V>,
  ) {

  }

  get(k: V): V {
    return this.store[k];
  }
}
