import React from 'react';

import { observer } from 'mobx-react-lite';

import { RentalOfficeInput } from '^/components/CarSearchBox/base/common/RentalOfficeInput/RentalOfficeInput';

import { CarListingSearchBoxViewModel } from '../viewmodel/CarListingSearchBox.viewmodel';

import { PopularCity } from './SelectCity/PopularCity';
import { popularCityDefaultStyles } from './SelectCity/PopularCity.defaultStyles';

export const CarListingSearchBoxDesktopContentsAreaForDiffReturnLocation: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
}> = observer(function CarListingSearchBoxDesktopContentsAreaForDiffReturnLocation({
  viewmodel,
}) {
  // TODO: return / rental location difference case
  if (viewmodel.stepManager.currentStep?.type === '5_return_city') {
    return (
      <>
        <PopularCity
          viewmodel={viewmodel.i.returnCitySearch}
          onBlur={() => {
            viewmodel.stepManager.clearStep();
          }}
          cityCount={15}
          onSelectCity={() => {
            if (!viewmodel.differentLocationValidation) {
              viewmodel.showDifferentLocationAlert.set(true);
              viewmodel.i.returnCitySearch.selectedCityCode.set(null);
              viewmodel.i.returnCitySearch.searchInput.set('');
              return;
            }
            viewmodel.stepManager.replaceStep({
              type: '6_return_office',
            });
          }}
          classOverrides={{
            cityButton: popularCityDefaultStyles.desktop.cityButton,
          }}
        />
      </>
    );
  }

  if (viewmodel.stepManager.currentStep?.type === '6_return_office') {
    if (viewmodel.returnLocationViewModel.status === 'loading') {
      return <h1>loading</h1>;
    }
    if (viewmodel.returnLocationViewModel.status === 'failure') {
      return <h1> 에러가 발생하였습니다 </h1>;
    }
    return (
      <RentalOfficeInput
        viewmodel={viewmodel.returnLocationViewModel.value}
        onBlur={() => {
          viewmodel.stepManager.clearStep();
          // see: https://stackoverflow.com/a/60094794/19531789
          viewmodel
            .i
            .pickupLocationId
            .setHasBlurred(true);
        }}
        onSelectRentalOffice={() => {
          viewmodel.stepManager
            .replaceStep({
              type: '3_schedule',
            });
        }}
      />
    );
  }
  return null;
});
