/* eslint-disable operator-linebreak */
import { AxiosInstance } from 'axios';

import { CityCodeType } from '^/types/__BrandedLocationTypes';
import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';

import { api2BaseAxios } from '../api2BaseAxios';
import { zodValidateAxiosResp } from '../zodValidate';

import { decodeRawRentalCarOfficeFromAPI } from './GetCategoriesByCity.convert';
import { GetCategoriesByCityResponse } from './GetCategoriesByCityRawResponseType';

const validator = zodValidateAxiosResp(GetCategoriesByCityResponse, (err) => {
  console.error('/categories/byCity validation error:', err);
});

// FIXME: shorten name!'
export function createGetRentalCarOfficeLocationsByCity(axiosInstance: AxiosInstance) {
  return async function getRentalCarOfficeLocationsByCity(cityCode: CityCodeType) {
    const queryString = new URLSearchParams({
      city_code: cityCode,
    }).toString();
    const response = await axiosInstance.get(`/categories/byCity?${queryString}`);
    const validated = validator(response);

    if (validated.data.status !== 200) {
      return asFailedPromise({
        reason: 'HTTPError',
        err: response.data,
        rawResponse: response,
      } as const);
    }
    const rentalOffices = validated.data.data.map((a) => {
      return {
        ...a,
        location: a.location.map(decodeRawRentalCarOfficeFromAPI),
      };
    });
    return asSuccessPromise({
      rentalOffices,
      validated,
    } as const);
  };
}

export const getRentalCarOfficeLocationsByCityAPI =
  createGetRentalCarOfficeLocationsByCity(api2BaseAxios);

export type GetRentalCarOfficeLocationsByCityAPI = typeof getRentalCarOfficeLocationsByCityAPI;
