import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';

import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppContainer = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <App {...props} />
      </RecoilRoot>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

AppContainer.propTypes = {};

export default AppContainer;
