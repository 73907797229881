import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

import { countriesPhoneCode } from '^/constants/countriesPhoneCode';

export const findCountryWithCallingCode = (callingCode) =>
  countriesPhoneCode.find((code) => code.phone === callingCode);

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  if (phoneNumber.startsWith('+81')) {
    return phoneNumber;
  }
  return `+81 ${phoneNumber}`;
};

export const isKoreanCallingCode = (callingCode: string) =>
  countriesPhoneCode.find((country) => country.phone === callingCode)?.code == 'KR';

export const insertHyphen = (phoneNumber) => {
  if (!phoneNumber) return '';
  const trimmedNumber = phoneNumber.replace(/[^0-9]/g, '');
  if (trimmedNumber.length === 10) {
    return trimmedNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  if (trimmedNumber.length === 11) {
    return trimmedNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  return phoneNumber;
};

export const isValidNumberForCountry = (phoneNumber: string | undefined, callingCode: string) => {
  if (!phoneNumber) return false;
  const isKorean = isKoreanCallingCode(callingCode);
  const country = findCountryWithCallingCode(callingCode);
  const isValidKoreanPhoneNumber = phoneNumber.startsWith('0') || phoneNumber.startsWith('1');
  const validNumberLength = phoneNumber.length === 10 || phoneNumber.length === 11;
  const isValidNumber = country
    ? isValidPhoneNumber(phoneNumber, country.code as CountryCode)
    : isValidPhoneNumber(phoneNumber);

  return isKorean ? isValidKoreanPhoneNumber && validNumberLength : isValidNumber;
};
