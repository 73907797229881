/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useRecoilValue } from 'recoil';

import { TextInput } from '^/commonComponents/inputs/TextInput';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CarListingBottomSheetWrapper } from '^/components/CarSearchBox/base/mobile/BottomSheet/CarListingBottomSheetWrapper';
import { MRTCarListingBottomSheetWrapper } from '^/components/CarSearchBox/myrealtrip/mobile/MRTCarListingBottomSheetWrapper';
import { Layout2Mobile } from '^/components/Layout/Layout2Mobile';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { SPACING, COLORS2 } from '^/constants/commonStyles';
import { renewalUIState } from '^/recoil/atoms';

import { ListingPage2mobileNoCar } from '../../base/mobile/ListingPage2.Mobile.NoCar';
import { SkeletonCarInfoMobile } from '../../base/mobile/Skeleton.CarInfo.Mobile';
import { ListingPageOnlineTravelViewModel } from '../viewmodel/ListingPage.OnlineTravel.viewmodel';

import { ListingPageOnlineTravelMobileHeader } from './ListingPage.OnlineTravel.Mobile.SearchBar';
import { ListingPageOnlineTravelMobileCard } from './ListingPageOnlineTravel.Mobile.Card';

export const ListingPageOnlineTravelMobile: React.FC<{
  viewmodel: ListingPageOnlineTravelViewModel;
}> = observer(function ListingPageOnlineTravelMobile({ viewmodel }) {
  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const pickupCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);
  const returnCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);

  const carsScrollAreaRef = useRef<HTMLDivElement>(null);
  const carClassChipsScrollRef = useRef<HTMLDivElement>(null);

  const isRenewal = useRecoilValue(renewalUIState);

  return (
    <>
      <TextInput
        id="DummyInputForPickupCityIOSKeyboardPopup"
        rootRef={pickupCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel.carListingSearchBoxInputVm.pickupCitySearch.searchInput}
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
        `}
      />
      <TextInput
        id="DummyInputForReturnCityIOSKeyboardPopup"
        rootRef={returnCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel.carListingSearchBoxInputVm.returnCitySearch.searchInput}
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
        `}
      />
      {!isRenewal && (viewmodel.carListingSearchBox.stepManager.getSteps().length || null) && (
        <CarListingBottomSheetWrapper
          viewmodel={viewmodel.carListingSearchBox}
          pickupCityInputRef={pickupCityInputRef}
          pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
          returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
          carsScrollAreaRef={carsScrollAreaRef}
          carClassChipsScrollRef={carClassChipsScrollRef}
        />
      )}
      {isRenewal && (viewmodel.carListingSearchBox.stepManager.getSteps().length || null) && (
        <MRTCarListingBottomSheetWrapper
          pickupCityInputRef={pickupCityInputRef}
          pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
          returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
          viewmodel={viewmodel.carListingSearchBox}
          carsScrollAreaRef={null}
          carClassChipsScrollRef={null}
          rootStep={{ type: '0_none_selected' }}
        />
      )}
      <Layout2Mobile
        withoutFooter
        withoutOverflowScroll
        withoutNavigationFullRefresh
        classOverrides={{
          layout2MobileContentWrapperBg: COLORS2.sub.MrtGray3,
        }}
      >
        <div
          className={
            cx(css`
              width: 100%;
              height: 100%;

              background-color: ${COLORS2.sub.MrtGray3};
              display: flex;
              flex-direction: column;
            `)
            // borders.DEBUG_greenBorderDashed,
          }
        >
          <ViewByState
            errorView={
              <>
                <ListingPageOnlineTravelMobileHeader
                  carClassChipsScrollRef={carClassChipsScrollRef}
                  isFilterButtonVisible={false}
                  viewmodel={viewmodel}
                />
                <ListingPage2mobileNoCar />
              </>
            }
            loadingView={
              <>
                <ListingPageOnlineTravelMobileHeader
                  carClassChipsScrollRef={carClassChipsScrollRef}
                  isFilterButtonVisible={false}
                  viewmodel={viewmodel}
                />
                <div
                  className={css`
                    background-color: ${COLORS2.sub.MrtGray3};
                    width: 100%;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      flex-direction: column;
                      gap: 20px;

                      padding: ${SPACING.spacing24};
                    `}
                  >
                    <SkeletonCarInfoMobile />
                    <SkeletonCarInfoMobile />
                    <SkeletonCarInfoMobile />
                  </div>
                  <LoadingWithVendorLogo />
                </div>
              </>
            }
            data={viewmodel.allCarList}
          >
            {(carsList) => {
              return (
                <>
                  <ListingPageOnlineTravelMobileHeader
                    carClassChipsScrollRef={carClassChipsScrollRef}
                    isFilterButtonVisible={false}
                    viewmodel={viewmodel}
                  />
                  <section
                    ref={carsScrollAreaRef}
                    className={css`
                      height: 100%;
                      overflow-y: auto;
                      display: flex;
                      flex-direction: column;
                      gap: ${SPACING.spacing24};
                      padding: ${SPACING.spacing24};
                    `}
                  >
                    {carsList.map((car) => {
                      return <ListingPageOnlineTravelMobileCard viewmodel={viewmodel} data={car} />;
                    })}
                  </section>
                </>
              );
            }}
          </ViewByState>
        </div>
      </Layout2Mobile>
    </>
  );
});
