export const TimeSelectHour = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',

  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',

  '20',
  '21',
  '22',
  '23',
] as const;

export const TimeSelectHourMRT = [
  '12',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
] as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TimeSelectHour = typeof TimeSelectHour[number];
export type TimeSelectHourNullable = TimeSelectHour | null;

export const TimeSelectMinute = [
  '00',
  '30',
] as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TimeSelectMinute = typeof TimeSelectMinute[number];
export type TimeSelectMinuteNullable = TimeSelectMinute | null;
