import React from 'react';

import { css } from '@emotion/css';

import { font } from '^/constants/commonStyles';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';

const badgeCommonStyle = css`
  padding-left: 14px;
  padding-right: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 40px;

`;
export const ReservationStatusBadge: React.FC<{
  status: ReservationDetailed['res_status']
}> = ({
  status,
}) => {
  if (status === 'booked') {
    return (
      <span
        className={[
          font.Body1_Bold,
          badgeCommonStyle,
          css`
            background-color: #e6f8f3;
            color: #137b5e;
            border: solid 1px #137b5e;
          `,
        ].join(' ')}
      >
        예약 완료
      </span>
    );
  }
  return (
    <span
      className={[
        font.Body1_Bold,
        badgeCommonStyle,
        css`
          background-color: #fbf1ef;
          color: #ec4937;
          border: solid 1px #ec4937;
        `,
      ].join(' ')}
    >
      취소 완료
    </span>
  );
};
