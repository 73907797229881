import { css } from '@emotion/css';
import Skeleton from '@mui/material/Skeleton';

import { COLORS2 } from '^/constants/commonStyles';

export const SkeletonCarInfoMobile: React.FC<{}> = () => {
  return (
    <div
      id="SkeletonCarInfo_Root"
      className={css`
        width: 100%;
        padding: 16px;

        display: flex;
        flex-direction: column;
        gap: 16px;
        
        background-color: ${COLORS2.main.MrtWhite};
        border-radius: 10px;
      `}
    >
      <div
        className={css`
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
        `}
      >
        <Skeleton
          variant="rounded"
          width="70%"
          height={19}
        />
        <Skeleton
          variant="rounded"
          width="70%"
          height={19}
        />
      </div>

      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Skeleton
          variant="rounded"
          width="50%"
          height={100}
        />
      </div>

      <div
        className={css`
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
      `}
      >
        <Skeleton
          variant="rounded"
          width="70%"
          height={15}
        />
      </div>

      <div
        className={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 16px;
      `}
      >
        <Skeleton
          variant="rounded"
          width="48%"
          height={100}
        />
        <Skeleton
          variant="rounded"
          width="48%"
          height={100}
        />
      </div>
    </div>
  );
};
