import { createRouter, defineRoute, param } from 'type-route';

import { getSubdomain } from '^/util';

import {
  BirthdayTypeParam,
  CarSearchBoxStepParams,
  CityCodeParam,
  CityNameParam,
  DatetimeNoTimezoneParam,
  FreshnessParam,
  LocationCategoryNameParam,
  LocationIdParam,
  LocationNameParam,
  LocationParamRelOAG,
  ReservationIDTypeParam,
  UserLastNameTypeParam,
  VendorCompanyIdTypeParam,
} from './RouteParamTypes';

const ListingSearchBoxParams = {
  // pickup
  pDatetime: param.query.ofType(DatetimeNoTimezoneParam),
  pCityCode: param.query.ofType(CityCodeParam),
  pCityName: param.query.ofType(CityNameParam),
  pLocationId: param.query.ofType(LocationIdParam),
  pLocationCode: param.query.ofType(LocationParamRelOAG),
  pLocationName: param.query.optional.ofType(LocationNameParam),
  pIsDiff: param.query.boolean,
  pParentCategoryCode: param.query.ofType(LocationCategoryNameParam),
  // return
  rDatetime: param.query.ofType(DatetimeNoTimezoneParam),
  rCityCode: param.query.optional.ofType(CityCodeParam),
  rCityName: param.query.optional.ofType(CityNameParam),
  rLocationId: param.query.optional.ofType(LocationIdParam),
  rLocationCode: param.query.optional.ofType(LocationParamRelOAG),
  rLocationName: param.query.optional.ofType(LocationNameParam),
  rParentCategoryCode: param.query.optional.ofType(LocationCategoryNameParam),

  birthday: param.query.ofType(BirthdayTypeParam),

  pickupCitySearchText: param.state.optional.string,
  returnCitySearchText: param.state.optional.string,
} as const;

export const typeRouter = createRouter({
  home2: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      steps: param.state.optional // .query // for debugging
        .ofType(CarSearchBoxStepParams),
    },
    () => ['/', '/main2', '/home2'],
  ),
  __listingSearchBoxParams: defineRoute(
    ListingSearchBoxParams,
    () => '/_____PATH_ONLY_FOR_TYPE_EXTRACTION',
  ),
  listing2: defineRoute(
    {
      freshness: param.state.optional // .query
        .ofType(FreshnessParam),
      ...ListingSearchBoxParams,
      steps: param.state.optional // .query // for debugging
        .ofType(CarSearchBoxStepParams),
    },
    () => ['/listing', '/listing2'],
  ),

  reservationCheck: defineRoute(['/reservation_check', '/reservation_check2']),
  myReservationsList: defineRoute({}, () => ['/my-reservation', '/my-reservation2']),

  reservationDetails: defineRoute(
    {
      id: param.query.ofType(ReservationIDTypeParam),
      lastName: param.query.ofType(UserLastNameTypeParam),
      vendorId: param.query.ofType(VendorCompanyIdTypeParam),
      showInsurance: param.state.optional.boolean,
      freshness: param.state.optional // .query
        .ofType(FreshnessParam),
    },
    () => '/reservation_details',
  ),

  help: defineRoute('/help'),

  mrtHome: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      steps: param.state.optional // .query // for debugging
        .ofType(CarSearchBoxStepParams),
    },
    () => (getSubdomain().includes('myrealtrip') ? ['/', '/mrtHome'] : ['/mrtHome']),
  ),

  mrtSelectPickupCity: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectPickupCity',
  ),
  mrtSelectReturnCity: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectReturnCity',
  ),

  mrtSelectPickupOffice: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectPickupOffice',
  ),

  mrtSelectReturnOffice: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectReturnOffice',
  ),

  mrtSelectedPickupOfficeInfo: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectedPickupOfficeInfo',
  ),

  mrtSelectedReturnOfficeInfo: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectedReturnOfficeInfo',
  ),

  mrtSelectSchedule: defineRoute(
    {
      freshness: param.state.optional.ofType(FreshnessParam),
      // steps: param.state.optional // .query // for debugging
      //   .ofType(CarSearchBoxStepParams),
    },
    () => '/mrtSelectSchedule',
  ),

  // checkReservation: defineRoute(
  //   '/reservation_check',
  // ),
  // help: defineRoute('/help'),
  // noticeDetail: defineRoute({
  //   id: param.query.number,
  // },
  // () => '/notice_detail',
  // ),
});

// typeRouter.session.listen(route => {
// })

(window as any).routerSession = typeRouter.session;
