/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { useEffect } from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { MRTCalendarRoot } from '^/components/Calendar/MRTCalendar/MRTCalendar.root';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { MRTCalendarWithTimeMobile } from '^/components/CarSearchBox/myrealtrip/desktop/headerArea/CalendarWithTime/MRTCalendarWithTime.Mobile';
import { COLORS2, SCROLL_BAR } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

const classNames = {
  calendarRootWrapper: cx(
    SCROLL_BAR.hide,
    css`
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      margin-top: 12px;
      padding-bottom: 60px;
    `,
  ),
  calendarWithTimeWrapper: cx(
    css`
      position: fixed;
      bottom: 0; left: 0;

      width: 100%;

      background-color: ${COLORS2.main.MrtWhite};
    `,
  ),
};

export const MRTSelectScheduleMobile: React.FC<
{
  viewmodel: CarListingSearchBoxViewModel,
}
> = observer(function MRTSelectScheduleMobile({
  viewmodel,
}) {
  const hasDifferentPickupReturnLocations = viewmodel.i.hasDifferentPickupReturnLocations.value;

  const tempCalendarState = viewmodel.i.tempCalendarStateViewModel;
  const tempTimeScheduleState = viewmodel.i.tempScheduleTimeViewModel;

  const startDay = tempCalendarState.selection.start;
  const endDay = tempCalendarState.selection.end;
  const returnLocation = viewmodel.i.hasDifferentPickupReturnLocations.value === true
    ? viewmodel.returnLocation : viewmodel.pickupLocation;

  useEffect(() => {
    if (
      hasDifferentPickupReturnLocations
      && (!viewmodel.i.pickupCitySearch.searchInput.value
        || !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
        || !viewmodel.i.returnCitySearch.searchInput.value
        || !viewmodel.returnLocation?.locationDescriptions.get('ko')?.description)
    ) {
      typeRouter.session.back();
      return;
    }
    if (
      !viewmodel.i.pickupCitySearch.searchInput.value
      || !viewmodel.pickupLocation?.locationDescriptions.get('ko')?.description
    ) {
      typeRouter.session.back();
    }
  }, []);

  if (!viewmodel.pickupLocation || !returnLocation) {
    console.error(
      'Step3.schedule:',
      '!viewmodel.pickupLocation || !returnLocation',
      'viewmodel.pickupLocation: ',
      viewmodel.pickupLocation,
      'returnLocation: ',
      returnLocation,
    );
    return null;
  }

  return (
    <div className={css`
        height: 100%;
        overflow-y: scroll;
    `}
    >
      <div
        className={
          css`
            padding: 0 20px;
            
          `
        }
      >
        <div
          className={cx(
            css`
            color: #1E1E1E;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
          `,
          )}
        >
          대여/반납 일시를 선택해주세요.
        </div>
        <div
          className={
            css`
              color: #1E1E1E;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              margin-top: 16px;
            `
          }
        >
          대여/반납 일시
        </div>
        <div>
          <div className={classNames.calendarRootWrapper}>
            <MRTCalendarRoot
              selectedReturnDayOffsetTop={viewmodel.i.calendarStateViewModel.selectedReturnDayOffsetTop}
              hasDifferentPickupReturnLocations={viewmodel.i.hasDifferentPickupReturnLocations}
              pickupLocation={viewmodel.pickupLocation}
              returnLocation={returnLocation}
              calendarStateViewModel={tempCalendarState}
              calendarListViewModel={viewmodel.calendarListViewModel}
              onBlur={() => null}
              isMobile
            />
          </div>

          {/* calendar with time */}
          {startDay && endDay
            && viewmodel.pickupLocation
            && returnLocation
            && (
              <div className={classNames.calendarWithTimeWrapper}>
                <MRTCalendarWithTimeMobile
                  onSubmit={() => {
                    viewmodel
                      .i
                      .calendarStateViewModel
                      .copyFrom(tempCalendarState);
                    viewmodel
                      .i
                      .scheduleTimeViewModel
                      .copyFrom(tempTimeScheduleState);
                    typeRouter.session.back();
                  }}
                  stepManager={viewmodel.stepManager}
                  calendarStateViewModel={tempCalendarState}
                  calendarListViewModel={viewmodel.calendarListViewModel}
                  scheduleTimeViewModel={tempTimeScheduleState}
                  pickupOpenCloseTimes={viewmodel.pickupLocation.openCloseTimes}
                  returnOpenCloseTimes={returnLocation.openCloseTimes}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
});
