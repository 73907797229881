import React from 'react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { airlineMileageProgramMap } from '^/constants/airlineMileageProgram';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
// import { actions, sendTrackingLog } from '^/api/trackingLog';

export const ReservationDetailsDriverInfo: React.FC<{
  data: ReservationDetailed;
  onClickCancelReservation(reservationToCancel: ReservationDetailed, e: React.MouseEvent): void;
}> = observer(function ReservationDetailsDriverInfo({ data, onClickCancelReservation }) {
  const { t } = useTranslation();
  const pickupDate = new Date(data?.pickup_datetime);
  const isCancelDisabled = isReservationCancelDisabled({
    pickupDate,
    cancelEnabled: data.cancelEnabled,
    payType: data.pay_type,
  });
  return (
    <div className={[].join(' ')}>
      <h3 className="text-semilarge font-bold">운전자 정보</h3>

      <Horizon marginTop="30px" marginBottom="30px" />

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">{t('koreanName')}</p>
          <p className="text-default font-light">{data?.korean_name || '-'}</p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">{t('phone')}</p>
          <p className="text-default font-light">{`${data?.phone_code} ${data?.phone_number}`}</p>
        </div>
        <div className="flex">
          <div className="flex basis-1/2 flex-col gap-3">
            <p className="text-big font-bold">{t('lastName')}</p>
            <p className="text-default font-light">{data?.last_name || '-'}</p>
          </div>
          <div className="flex basis-1/2 flex-col gap-3">
            <p className="text-big font-bold">{t('firstName')}</p>
            <p className="text-default font-light">{data?.first_name || '-'}</p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">{t('email')}</p>
          <p className="text-default font-light">{data?.email || '-'}</p>
        </div>
      </div>
      {(data?.hertz_membership || data?.flight_number || data?.frequent_flyer) && (
        <>
          <div className="-mx-6 mb-[30px] mt-6 h-1.5 bg-trimo-bg"></div>
          <h3 className="text-semilarge font-bold">추가 정보</h3>
          <div className="-mx-6 mt-[30px] mb-6 h-1.5 bg-trimo-bg"></div>
          {data?.hertz_membership && (
            <div className="flex flex-col gap-3">
              <p className="text-big font-bold">허츠 골드 멤버십</p>
              <p className="text-big">회원번호</p>
              <p className="text-default font-light">{data.hertz_membership}</p>
            </div>
          )}
          {data?.flight_number && (
            <div className="mt-8 flex flex-col gap-3">
              <p className="text-big font-bold">도착 비행기 정보</p>
              <div className="flex">
                <div className="flex basis-1/2 flex-col gap-3">
                  <p className="text-big">항공사</p>
                  <p className="text-default font-light">{data.flight_brand || '-'}</p>
                </div>
                <div className="flex basis-1/2 flex-col gap-3">
                  <p className="text-big">편명 </p>
                  <p className="text-default font-light">{data.flight_number || '-'}</p>
                </div>
              </div>
            </div>
          )}
          {data?.frequent_flyer && data.frequent_brand && (
            <div className="mt-8 flex flex-col gap-3">
              <p className="text-big font-bold">항공 마일리지 적립</p>
              <div className="flex">
                <div className="flex basis-1/2 flex-col gap-3">
                  <p className="text-big">항공 마일리지 제휴사</p>
                  <p className="text-default font-light">
                    {airlineMileageProgramMap.get(data.frequent_brand)?.name || '-'}
                  </p>
                </div>
                <div className="flex basis-1/2 flex-col gap-3">
                  <p className="text-big">제휴사 회원번호</p>
                  <p className="text-default font-light">{data.frequent_flyer || '-'}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {data.res_status === 'booked' && (
        <div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault(); // 스크롤 상단 이동 방지
              onClickCancelReservation(data, e);
              // sendTrackingLog({
              //   action: actions.RESERVATION_DETAIL.CLICK_RESERVATION_CANCEL,
              // });
            }}
            disabled={isCancelDisabled}
            className="
              mt-[24px] w-full rounded-trimo-5
              bg-trimo-bg p-3
              text-center font-bold
              text-primary-gray
              hover:cursor-pointer hover:bg-primary-skyblue hover:text-white
              focus:text-white
              active:text-white
              disabled:cursor-not-allowed disabled:bg-secondary-gray disabled:text-neutral-300 "
          >
            {t('cancelBooking')}
          </button>
          {isCancelDisabled && (
            <div
              className="
                mt-[12px]
                flex justify-center
                text-small
                text-primary-gray
              "
            >
              픽업일 기준 72시간 이내 취소는 고객센터로 문의해주세요.
            </div>
          )}
        </div>
      )}
    </div>
  );
});
