import { stripMargin } from '^/util/stripMargin';

export const PODOnsitePaymentHoverMessage = stripMargin`
  |차량 대여 요금과 추가 요금(추가 옵션)을 픽업 시
  |현장에서 결제할 예상금액으로 현장 사정에 따라 금액은
  |수시로 변동 될 수 있으며, 운전자 본인 명의의
  |신용카드로만 결제가 가능합니다.`
  .trimStart();

export const PPDOnsitePaymentHoverMessage = stripMargin`
  |차량 대여 요금 이외 추가 요금(추가 옵션)에 대해 픽업 시
  |현장에서 결제할 예상금액으로 현장 사정에 따라 금액은
  |수시로 변동 될 수 있으며, 운전자 본인 명의의
  |신용카드로만 결제가 가능합니다.
  `.trimStart();
