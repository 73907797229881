/* eslint-disable max-len */
import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { LayoutMRTDesktop } from './LayoutMRTDesktop';
import { LayoutMRTMobile } from './LayoutMRTMobile';

export const LayoutMRT: React.FC<React.PropsWithChildren<{
  withoutFooter?: boolean;
  withoutMaxWidth?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutFooterTopLine?: boolean;
  withoutNavigationFullRefresh?: boolean;
}>> = observer(function Layout2(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (isMobile) {
    return (
      <LayoutMRTMobile {...props} />
    );
  }

  return <LayoutMRTDesktop {...props} />;
});
