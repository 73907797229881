import { AxiosResponse } from 'axios';
import { z } from 'zod';

export function zodValidateAxiosResp<
  TSchema extends z.ZodTypeAny,
>(
  schema: TSchema,
  onError: (err: z.ZodError<any>) => void,
) {
  return function zodValidateWrapperFn(
    data: AxiosResponse,
  ) {
    const validationResult = schema.safeParse(data);
    // TODO: email / slack 보내기?
    if (!validationResult.success) {
      onError(validationResult.error);
      console.error(`
        !!!!!!!! Zod Schema Mismatch!
      `, validationResult.error);
      return {
        ZOD_VALIDATION: 'failed',
        ZOD_ERROR: validationResult.error,
        data: data as z.output<TSchema>,
      } as const;
    }
    return {
      ZOD_VALIDATION: 'success',
      ...validationResult,
      data: data as z.output<TSchema>,
    } as const;
  };
}
