import { differenceInMinutes } from 'date-fns';
import { HOURS_OF_DAY } from '../constants/envConfig';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const handleRentTime = (pickupDatetime, returnDatetime) => {
  const minutes = differenceInMinutes(returnDatetime, pickupDatetime);
  let hours = Math.ceil(minutes / 60);
  let days = 0;
  if (hours >= HOURS_OF_DAY) {
    days = Math.round(hours / HOURS_OF_DAY);
    hours = hours - days * HOURS_OF_DAY;
    if (hours < 0) {
      days -= 1;
      hours = HOURS_OF_DAY - Math.abs(hours);
    }
  }
  return [days, hours];
};

export const createKey = (array) => {
  return array.join('-');
};

export const updateSearchParams = (searchCar, setSearchParams, isDiffLocation, isRetry = 0) => {
  setSearchParams({
    ...searchCar,
    isDiff: isDiffLocation ? 1 : '',
    retry: isRetry,
  });
};

export const modifyData = (data) => {
  const modified = data
    .filter((item) => item.location.length)
    .reduce((result, category) => {
      const uniqueCode = [...new Set(category.location.map((item) => item.oag_code))];
      uniqueCode.unshift('fake');
      const updateLocations = uniqueCode.map((code) => {
        if (code === 'fake') return { oag_code: '' };
        const infos = category.location.filter((item) => item.oag_code === code);
        const initial = {
          ...infos[0],
          supplier: [],
          id: category.id,
        };
        const info = infos.reduce((memo, loc) => {
          memo['supplier'].push(loc?.vendors?.logo);
          return memo;
        }, initial);
        return info;
      });
      result[category.id] = updateLocations;
      return result;
    }, {} );
  const categories = Object.keys(modified);
  const result = { };
  if (categories.includes('LAP')) {
    result['LAP'] = modified['LAP'];
  }
  if (categories.includes('LDT')) {
    result['LDT'] = modified['LDT'];
  }
  if (categories.includes('LHT')) {
    result['LHT'] = modified['LHT'];
  }
  if (categories.includes('LAM')) {
    result['LAM'] = modified['LAM'];
  }
  return result;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const DIGITS = ['firstDigit', 'secondDigit', 'thirdDigit', 'fourthDigit'];

export const autoTab = (ref) => (e) => {
  const BACKSPACE_KEY = 8;
  const DELETE_KEY = 46;
  const tabindex = Number($(e.target).attr('data-index') || 0);
  let elem = null;
  if (e.keyCode === BACKSPACE_KEY) {
    const key = DIGITS[tabindex - 1];
    elem = tabindex > 0 && ref[key].current;
  } else if (e.keyCode !== DELETE_KEY) {
    const key = DIGITS[tabindex + 1];
    elem = tabindex < Object.keys(DIGITS).length - 1 && ref[key].current;
  }
  if (elem) {
    elem.focus();
  }
};

export const calculateHours = (start, end) => {
  const diffMinutes = differenceInMinutes(start, end);
  const diffHours = Math.ceil(diffMinutes / 60);
  // const diffTime = Math.abs(end - start);
  // const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  return diffHours;
};

export const getSubdomain = () => {
  const location = window.location.hostname;
  const locationParts = location.split('.').filter((item) => item !== 'www');
  return locationParts[0];
};
