import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { dayTypeFromString } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { asSuccessPromise, getCurrentFreshness } from '^/types/__ResultType';
import { AGENTS } from '^/types/AgentTypes';
import { getSubdomain } from '^/util';
import { getItemByChannel } from '^/util/byChannel';
import { MobxInputValue } from '^/util/MobxInputValue';

import { ListingPage2RouteParams } from '../../ListingPage2RouteParams';

import { ListingPage2FilterViewModel } from './ListingPage2.FilterViewModel';
import { ListingPage2LoadedViewModel } from './ListingPage2.LoadedViewModel';
import { ListingSearchBoxStepManager } from './ListingSearchBoxStepManager';
import { ListingSearchBoxStepManagerDesktop } from './ListingSearchBoxStepManager.desktop';

export class ListingPage2ViewModel {
  constructor(
    public appContext: AppContext,
    public routeParamsValues: ListingPage2RouteParams,
    public calendarsCount: number,
    public isMobile: boolean,
    public calendarPaginationOffset = new MobxInputValue(0),
    public freshness = getCurrentFreshness(),
    public filterViewModel = new ListingPage2FilterViewModel(),
    public calendarStateViewModel = new CalendarStateViewModel(
      calendarPaginationOffset,
      undefined,
      undefined,
      {
        start: dayTypeFromString(routeParamsValues.pDatetime),
        end: dayTypeFromString(routeParamsValues.rDatetime),
      },
    ),
    public calendarListViewModel = new CalendarListViewModel(calendarsCount),
    public carListingSearchBoxInputVm = appContext.carListingSearchBoxInput.value
      || new CarListingSearchBoxInputViewModel(
        appContext,
        freshness,
        calendarStateViewModel,
        routeParamsValues,
      ),
    public stepManager = isMobile
      ? new ListingSearchBoxStepManager(routeParamsValues, carListingSearchBoxInputVm, (n) => {
        typeRouter.session.back(n);
      })
      : new ListingSearchBoxStepManagerDesktop(carListingSearchBoxInputVm),
    public carListingSearchBox = new CarListingSearchBoxViewModel(
      appContext,
      freshness,
      calendarListViewModel,
      carListingSearchBoxInputVm,
      stepManager,
    ),
  ) {
    makeAutoObservable(this);
    if (appContext.carListingSearchBoxInput.value === null) {
      appContext.carListingSearchBoxInput.setValue(this.carListingSearchBox.i);
    }
  }

  setRouteParams(routeParamsValues: ListingPage2RouteParams) {
    if (
      this.routeParamsValues.pDatetime !== routeParamsValues.pDatetime
      || this.routeParamsValues.rDatetime !== routeParamsValues.rDatetime
    ) {
      this.calendarStateViewModel.selection = {
        start: dayTypeFromString(routeParamsValues.pDatetime),
        end: dayTypeFromString(routeParamsValues.rDatetime),
      };
    }
    this.routeParamsValues = {
      ...this.routeParamsValues,
      ...routeParamsValues,
    };
  }

  get allCarList() {
    const r = this.routeParamsValues;
    const carListResult = this.appContext.getCarListCore.getList(
      {
        agent_name: getItemByChannel(getSubdomain(), AGENTS),
        birth_day: r.birthday,
        // pickup
        pickup_city: r.pCityCode,
        pickup_datetime: r.pDatetime,
        pickup_location: r.pLocationCode,
        // return
        return_city: r.rCityCode || r.pCityCode,
        return_datetime: r.rDatetime,
        return_location: r.rLocationCode || r.pLocationCode,
      },
      this.freshness,
      {
        hideStaleValue: true,
      },
    );
    if (carListResult.status !== 'success') {
      return carListResult;
    }
    return asSuccessPromise(
      new ListingPage2LoadedViewModel(
        this.appContext,
        this.routeParamsValues,
        carListResult.value,
        this.filterViewModel,
      ),
      carListResult.freshness,
    );
  }
}
