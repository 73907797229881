import { z } from 'zod';

export const CityCodeType = z.string()
  .brand<'CityCode'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CityCodeType = z.infer<typeof CityCodeType>;

export const CityNameType = z.string()
  .brand<'CityName'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CityNameType = z.infer<typeof CityNameType>;

export const LocationNameType = z.string()
  .brand<'LocationNameType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocationNameType = z.infer<typeof LocationNameType>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const LocationCodeTypeRel_OAG = z.string()
  .brand<'LocationCode(rel. OAG)'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/naming-convention
export type LocationCodeTypeRel_OAG = z.infer<typeof LocationCodeTypeRel_OAG>;

export const CountryCodeType = z.string()
  .brand<'CountryCode'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CountryCodeType = z.infer<typeof CountryCodeType>;

export const LocationCodeType = z.string()
  .brand<'LocationCode(rel. OAG)'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocationCodeType = z.infer<typeof LocationCodeType>;
// L AP: AirPort
// L DT: DownTown
// L HT: HoTel
// L AM: Train Station ...?

export const LocationCategoryNameType = z.enum([
  'LAP',
  'LDT',
  'LHT',
  'LAM',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocationCategoryNameType = z.infer<typeof LocationCategoryNameType>;

export const RentalCarOfficeId = z.string()
  .brand<'RentalCarOfficeId'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarOfficeId = z.infer<typeof RentalCarOfficeId>;
