export function durationToText(
  d: Duration,
) {
  let period = '';

  if (d.months) period += `${d.months}달`;
  if (d.days) period += ` ${d.days}일`;
  if (d.hours) period += ` ${d.hours}시간`;
  if (d.minutes) period += ` ${d.minutes}분`;

  // eslint-disable-next-line consistent-return
  return period;
}
