import { css } from '@emotion/css';
import { mapObjIndexed } from 'ramda';
import styled from 'styled-components';

import { FixedEnumMap } from '^/types/utils/MapTypeUtils';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

interface FlexColProps {
  gap?: string
}
export const FlexCol = styled.div<FlexColProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => (props.gap ? props.gap : 0)};
`;

export const breakpoints = {
  tabletMax: '@media (max-width: 768px)',
} as const;

export const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#1e1e1e',

  LIGHT_GREY: '#e6e6e6',
  LIGHT_GREY_0: '#cecece',
  LIGHT_GREY_2: '#f4f4f4',

  NEUTRAL_100: '#E6E6E6',
  NEUTRAL_200: '#CECECE',
  NEUTRAL_300: '#9F9F9F',
  NEUTRAL_400: '#737373',
  NEUTRAL_500: '#404040',

  GRAY_50: '#fafafa',
  GRAY_100: '#f4f4f4',
  GRAY_200: '#e1e1e1',
  GRAY_300: '#cecece',
  GRAY_400: '#bbbbbb',
  GRAY_500: '#9f9f9f',
  GRAY_600: '#717171',
  GRAY_700: '#575757',
  GRAY_800: '#434343',
  GRAY_900: '#1e1e1e',

  BLUE_50: '#F7FBFF',
  BLUE_100: '#EEF6FE',
  BLUE_200: '#CAE4FB',
  BLUE_300: '#525354',
  BLUE_400: '#A8D4F9',
  BLUE_500: '#55A9F3',
  BLUE_600: '#4D9ADD',

  SKY_BLUE: '#55a9f3',
  SKY_BLUE_LIGHT: '#f2f9ff',

  RED: '#D23823',
} as const;

// TODO: need design system rules...
export const UNITS = new FixedEnumMap([
  '16px',
  '24px',
  '56px',
]);

export const textAlign = {
  center: css`
    text-align: center;
  `,
} as const;

export const fontSize = {
  big: css`
    font-size: 16px;
    line-height: 19px;
  `,
  semiHuge: css`
    font-size: 22px;
    line-height: 26px;
  `,
} as const;

export const fontWeights = {
  bold: css`
    font-weight: 700;
  `,
  normal: css`
    font-weight: normal;
  `,
} as const;

export const borders = {
  solid1pxLightGrey0: css`
    border: solid ${COLORS.LIGHT_GREY_0} 1px;
  `,
  radius10: css`
    border-radius: 10px;
  `,
  no_radius_left: css`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  `,
  no_radius_right: css`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  `,
  full_radius: css`
    border-radius: 50%;
  `,
  full_radius_left: css`
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  `,
  full_radius_right: css`
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  `,
  bottom_border_1px: css`
    border-bottom-width: 1px;
  `,
  // DEBUG
  DEBUG_greenBorderDashed: css`
    border: green 2px dashed;
  `,
  DEBUG_blueBorderDashed: css`
    border: blue 2px dashed;
  `,
  DEBUG_purpleBorderDashed: css`
    border: purple 2px dashed;
  `,
  DEBUG_redBorderDashed: css`
    border: red 2px dashed;
  `,
  DEBUG_orangeBorderDashed: css`
  border: orange 2px dashed;
`,
  DEBUG_pinkDashed: css`
    border: pink 2px dashed;
  `,
  DEBUG_goldDashed: css`
  border: gold 2px dashed;
`,
} as const;

export const hideScrollBar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const PAGE_MAX_WIDTH = 1320;

export const cursor = {
  hover_pointer: css`
    &:hover {
      cursor: pointer; 
    }
  `,
};
export const SPACING = {
  spacing4: '4px',
  spacing6: '6px',
  spacing8: '8px',
  spacing10: '10px',
  spacing12: '12px',
  spacing16: '16px',
  spacing24: '24px',
  spacing32: '32px',
  spacing40: '40px',
  spacing48: '48px',
  spacing56: '56px',
  spacing64: '64px',
  spacing72: '72px',
  spacing80: '80px',
  spacing96: '96px',
  spacing120: '120px',
  spacing154: '154px',
} as const;

export const MARGINS = {
  TOP: mapObjIndexed(
    (a) => css`
      margin-top: ${a};
    `,
    SPACING,
  ),
  BOTTOM: mapObjIndexed(
    (a) => css`
      margin-bottom: ${a};
    `,
    SPACING,
  ),
  TOP_AND_BOTTOM: mapObjIndexed(
    (a) => css`
      margin-top: ${a};
      margin-bottom: ${a};
    `,
    SPACING,
  ),
  LEFT: mapObjIndexed(
    (a) => css`
      margin-left: ${a};
    `,
    SPACING,
  ),
  RIGHT: mapObjIndexed(
    (a) => css`
      margin-right: ${a};
    `,
    SPACING,
  ),
  LEFT_AND_RIGHT: mapObjIndexed(
    (a) => css`
      margin-left: ${a};
      margin-right: ${a};
    `,
    SPACING,
  ),
} as const;

export const PADDINGS = {
  TOP: mapObjIndexed(
    (a) => css`
      padding-top: ${a};
    `,
    SPACING,
  ),
  BOTTOM: mapObjIndexed(
    (a) => css`
      padding-bottom: ${a};
    `,
    SPACING,
  ),
  TOP_AND_BOTTOM: mapObjIndexed(
    (a) => css`
      padding-top: ${a};
      padding-bottom: ${a};
    `,
    SPACING,
  ),
  LEFT: mapObjIndexed(
    (a) => css`
      padding-left: ${a};
    `,
    SPACING,
  ),
  RIGHT: mapObjIndexed(
    (a) => css`
      padding-right: ${a};
    `,
    SPACING,
  ),
  LEFT_AND_RIGHT: mapObjIndexed(
    (a) => css`
      padding-left: ${a};
      padding-right: ${a};
    `,
    SPACING,
  ),
} as const;

export const COLORS2 = {
  main: {
    MrtBlue1: '#55a9f3',
    MrtBlue2: '#a8d4f9',
    MrtBlack: '#1e1e1e',
    MrtWhite: '#fff',
    GuamBlue1: '#55a9f3',
    GuamBlue2: 'rgba(85, 169, 243, 0.30)',
  },
  sub: {
    MrtGray1: '#9f9f9f',
    MrtGray2: '#cecece',
    MrtGray3: '#f4f4f4',
    orange: '#f38537',
  },
} as const;

export const COLORS2_AS_BACKGROUND = {
  main: mapObjIndexed(
    (a) => css`
      background-color: ${a};
    `,
    COLORS2.main,
  ),
  mainWithImportant: mapObjIndexed(
    (a) => css`
      background-color: ${a} !important;
    `,
    COLORS2.main,
  ),
  sub: mapObjIndexed(
    (a) => css`
      background-color: ${a};
    `,
    COLORS2.sub,
  ),
  subWithImportant: mapObjIndexed(
    (a) => css`
      background-color: ${a} !important;
    `,
    COLORS2.sub,
  ),
} as const;
export const COLORS2_AS_FOREGROUND = {
  main: mapObjIndexed(
    (a) => css`
      color: ${a};
    `,
    COLORS2.main,
  ),
  sub: mapObjIndexed(
    (a) => css`
      color: ${a};
    `,
    COLORS2.sub,
  ),
} as const;

/**
 * @deprecated use font2 instead (should not affect text-align, color)
 */
export const font = {
  LargeTitle: css`
    font-family: Pretendard;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: -0.04px;
    text-align: left;
    color: #1e1e1e;
  `,
  LargeTitle2: css`
    font-family: Pretendard;
    font-size: 36px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 48px;
    // letter-spacing: -0.04px;
    text-align: left;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
  `,
  Title1: css`
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.03px;
    text-align: left;
    color: #1e1e1e;
  `,
  Title2: css`
    font-family: Pretendard;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.03px;
    text-align: left;
    color: #1e1e1e;
  `,
  Title3: css`
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Headline1_Bold: css`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Headline1_Regular: css`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Body1_Bold: css`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Body1_Regular: css`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption1_Bold: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption1_Light: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption2_Bold: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption2_Light: css`
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
} as const;

export const font2 = {
  LargeTitle: css`
    font-family: Pretendard;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: -0.04px;
  `,
  Title1: css`
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.03px;
  `,
  Title2: css`
    font-family: Pretendard;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.03px;
  `,
  Title3: css`
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.02px;
  `,
  Headline1_Bold: css`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.02px;
  `,
  Headline1_Regular: css`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.02px;
  `,
  Body1_Bold: css`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.02px;
  `,
  Body1_Regular: css`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.02px;
  `,
  Caption1_Bold: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
  `,
  Caption1_Light: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
  `,
  Caption2_Bold: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
  `,
  Caption2_Light: css`
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -0.01px;
  `,
} as const;

export const INPUT = {
  hideArrow: css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  overflowEllipsis: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
};

export const FLEX = {
  allDirectionCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

export const SCROLL_BAR = {
  hide: css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  `,
};
