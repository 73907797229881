import { decodeRawRentalCarOfficeFromAPI } from '^/api2/getRentalCarOfficeLocationsByCity/GetCategoriesByCity.convert';
import {
  CityCodeType,
  CityNameType,
  CountryCodeType,
  LocationCodeTypeRel_OAG,
  LocationNameType,
  RentalCarOfficeId,
} from '^/types/__BrandedLocationTypes';

/** 후쿠오카 */
export const FUKUOKA_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '40001' as CityCodeType,
  hertz_code: 'FUK',
  location_id: 'AP0239' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '40001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '33.58515915400644',
  longitude: '130.4491508696678',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'FUKUOKA' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0239' as RentalCarOfficeId,
      locale: 'en',
      description: 'FUKUOKA AIRPORT' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0239' as RentalCarOfficeId,
      locale: 'ko',
      description: '후쿠오카 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const KITAKYUSHU_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '40003' as CityCodeType,
  hertz_code: 'FUK',
  location_id: 'AP0051' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '40003' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '33.83922364718638',
  longitude: '131.03327958132243',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'FUKUOKA' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0051' as RentalCarOfficeId,
      locale: 'en',
      description: 'KITAKYUSHU AIRPORT' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0051' as RentalCarOfficeId,
      locale: 'ko',
      description: '기타큐슈 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

export const HAKATA = decodeRawRentalCarOfficeFromAPI({
  city_code: '40001' as CityCodeType,
  hertz_code: 'FUK',
  location_id: 'CT1657' as RentalCarOfficeId,
  id: 'LDT',
  vendor_id: 'OT',
  oag_code: '40001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '33.57938278391047',
  longitude: '130.44505196201703',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'FUKUOKA' as CityNameType,
  category_id: 'LDT',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'CT1657' as RentalCarOfficeId,
      locale: 'en',
      description: 'HAKATA' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'CT1657' as RentalCarOfficeId,
      locale: 'ko',
      description: '하카타' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const GOFUKUMACHI_STATION = decodeRawRentalCarOfficeFromAPI({
  city_code: '40001' as CityCodeType,
  hertz_code: 'FUK',
  location_id: 'ST4644' as RentalCarOfficeId,
  id: 'LAM',
  vendor_id: 'OT',
  oag_code: '40001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '33.59815150274164',
  longitude: '130.4095886948068',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'FUKUOKA' as CityNameType,
  category_id: 'LAM',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'ST4644' as RentalCarOfficeId,
      locale: 'en',
      description: 'GOFUKUMACHI STATION' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'ST4644' as RentalCarOfficeId,
      locale: 'ko',
      description: '고후쿠마치 역' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const GION_STATION = decodeRawRentalCarOfficeFromAPI({
  city_code: '40001' as CityCodeType,
  hertz_code: 'FUK',
  location_id: 'ST4649' as RentalCarOfficeId,
  id: 'LAM',
  vendor_id: 'OT',
  oag_code: '40001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '33.594122515223866',
  longitude: '130.41451739205414',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'FUKUOKA' as CityNameType,
  category_id: 'LAM',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'ST4649' as RentalCarOfficeId,
      locale: 'en',
      description: 'GION STATION' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'ST4649' as RentalCarOfficeId,
      locale: 'ko',
      description: '기온 역' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

/** 오키나와 */
export const OKINAWA_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '47003' as CityCodeType,
  hertz_code: 'OKA',
  location_id: 'AP0069' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '47003' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '26.200399242444423',
  longitude: '127.64666665767103',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'OKINAWA' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0069' as RentalCarOfficeId,
      locale: 'en',
      description: 'NAHA AIRPORT' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0069' as RentalCarOfficeId,
      locale: 'ko',
      description: '나하 공항' as LocationNameType,
      address_1: '150 Kagamizu',
      address_2: 'NAHA',
      address_3: '',
    },
  ],
});

/** 삿포로 */
export const SHIN_CHITOSE_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '01003' as CityCodeType,
  hertz_code: 'SPK',
  location_id: 'AP0001' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '01003' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '42.78060898915774',
  longitude: '141.6885362412151',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'SAPPORO' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0001' as RentalCarOfficeId,
      locale: 'en',
      description: 'SHIN CHITOSE AIRPORT' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0001' as RentalCarOfficeId,
      locale: 'ko',
      description: '신치토세 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

export const MINAMI = decodeRawRentalCarOfficeFromAPI({
  city_code: '01003' as CityCodeType,
  hertz_code: 'SPK',
  location_id: 'CT0006' as RentalCarOfficeId,
  id: 'LDT',
  vendor_id: 'OT',
  oag_code: '01003' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '', // '901-0142',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '',
  longitude: '',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'SAPPORO' as CityNameType,
  category_id: 'LDT',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'CT0006' as RentalCarOfficeId,
      locale: 'en',
      description: 'Sapporo-shi Minami-ku' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'CT0006' as RentalCarOfficeId,
      locale: 'ko',
      description: '삿포로시 미나미구' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

export const TOYOHIRA = decodeRawRentalCarOfficeFromAPI({
  city_code: '01003' as CityCodeType,
  hertz_code: 'SPK',
  location_id: 'CT0005' as RentalCarOfficeId,
  id: 'LDT',
  vendor_id: 'OT',
  oag_code: '01003' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '',
  longitude: '',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'SAPPORO' as CityNameType,
  category_id: 'LDT',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'CT0005' as RentalCarOfficeId,
      locale: 'en',
      description: 'Sapporo-shi Toyohira-ku' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'CT0005' as RentalCarOfficeId,
      locale: 'ko',
      description: '삿포로시 토요히라구' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

export const HIGASHI = decodeRawRentalCarOfficeFromAPI({
  city_code: '01003' as CityCodeType,
  hertz_code: 'SPK',
  location_id: 'CT0003' as RentalCarOfficeId,
  id: 'LDT',
  vendor_id: 'OT',
  oag_code: '01003' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '', // '901-0142',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '',
  longitude: '',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'SAPPORO' as CityNameType,
  category_id: 'LDT',
  vendors: {
    logo: '', // 'https://ct-supplierimage.imgix.net/car/hertz.pdf',
  },
  location_description: [
    {
      location_id: 'CT0003' as RentalCarOfficeId,
      locale: 'en',
      description: 'Sapporo-shi Higashi-ku' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'CT0003' as RentalCarOfficeId,
      locale: 'ko',
      description: '삿포로시 히가시구' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

/** 도쿄 */
export const NARITA_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '12001' as CityCodeType,
  hertz_code: 'TYO',
  location_id: 'AP0023' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '12001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '35.77024761270152',
  longitude: '140.38434295440973',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'TOKYO' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0023' as RentalCarOfficeId,
      locale: 'en',
      description: 'Narita Airport' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0023' as RentalCarOfficeId,
      locale: 'ko',
      description: '나리타 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const HANEDA_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '13013' as CityCodeType,
  hertz_code: 'TYO',
  location_id: 'AP0073' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '13013' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '35.54944554732415',
  longitude: '139.7798171390589',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'TOKYO' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0073' as RentalCarOfficeId,
      locale: 'en',
      description: 'Haneda Airport' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0073' as RentalCarOfficeId,
      locale: 'ko',
      description: '하네다 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

/** 구마모토 */
export const KUMAMOTO_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '43001' as CityCodeType,
  hertz_code: '',
  location_id: 'AP0058' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '43001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '32.835069891495266',
  longitude: '130.85897789662724',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'KUMAMOTO' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0058' as RentalCarOfficeId,
      locale: 'en',
      description: 'Kumamoto Airport' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0058' as RentalCarOfficeId,
      locale: 'ko',
      description: '구마모토 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const KUMAMOTO_STATION = decodeRawRentalCarOfficeFromAPI({
  city_code: '43001' as CityCodeType,
  hertz_code: '',
  location_id: 'ST5006' as RentalCarOfficeId,
  id: 'LAM',
  vendor_id: 'OT',
  oag_code: '43001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '32.79054525277383',
  longitude: '130.6885664221891',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'KUMAMOTO' as CityNameType,
  category_id: 'LAM',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'ST5006' as RentalCarOfficeId,
      locale: 'en',
      description: 'Kumamoto Station' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'ST5006' as RentalCarOfficeId,
      locale: 'ko',
      description: '구마모토 역' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

/** 나고야 */
export const NAGOYA_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '23002' as CityCodeType,
  hertz_code: 'NGO',
  location_id: 'AP0031' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '23002' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '34.8589004727508',
  longitude: '136.81152296786772',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'NAGOYA' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0031' as RentalCarOfficeId,
      locale: 'en',
      description: 'Nagoya Chubu Airport' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0031' as RentalCarOfficeId,
      locale: 'ko',
      description: '나고야 중부 국제공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const NAGOYA_STATION = decodeRawRentalCarOfficeFromAPI({
  city_code: '23001' as CityCodeType,
  hertz_code: 'NGO',
  location_id: 'ST2574' as RentalCarOfficeId,
  id: 'LAM',
  vendor_id: 'OT',
  oag_code: '23001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '35.171037754674515',
  longitude: '136.8815583543863',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'NAGOYA' as CityNameType,
  category_id: 'LAM',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'ST2574' as RentalCarOfficeId,
      locale: 'en',
      description: 'Nagoya Station' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'ST2574' as RentalCarOfficeId,
      locale: 'ko',
      description: '나고야 역' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

/** 오사카 */
export const KANSAI_AIRPORT = decodeRawRentalCarOfficeFromAPI({
  city_code: '27004' as CityCodeType,
  hertz_code: 'OSA',
  location_id: 'AP0034' as RentalCarOfficeId,
  id: 'LAP',
  vendor_id: 'OT',
  oag_code: '27004' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '34.43487677053674',
  longitude: '135.23286108319346',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'OSAKA' as CityNameType,
  category_id: 'LAP',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'AP0034' as RentalCarOfficeId,
      locale: 'en',
      description: 'Kansai Intl Apt' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'AP0034' as RentalCarOfficeId,
      locale: 'ko',
      description: '간사이 국제 공항' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});
export const SHIN_OSAKA_STATION = decodeRawRentalCarOfficeFromAPI({
  city_code: '27001' as CityCodeType,
  hertz_code: 'OSA',
  location_id: 'ST3131' as RentalCarOfficeId,
  id: 'LAM',
  vendor_id: 'OT',
  oag_code: '27001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '34.73349222402943',
  longitude: '135.50025469669825',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'OSAKA' as CityNameType,
  category_id: 'LAM',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'ST3131' as RentalCarOfficeId,
      locale: 'en',
      description: 'Shin Osaka Station' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'ST3131' as RentalCarOfficeId,
      locale: 'ko',
      description: '신오사카 역' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

/** 가고시마 */
export const KAGOSHIMACHUO_STATION = decodeRawRentalCarOfficeFromAPI({
  city_code: '46001' as CityCodeType,
  hertz_code: 'KOJ',
  location_id: 'ST4678' as RentalCarOfficeId,
  id: 'LAM',
  vendor_id: 'OT',
  oag_code: '46001' as LocationCodeTypeRel_OAG,
  country_code: 'JP' as CountryCodeType,
  state: '',
  zip_code: '',
  phone: '',
  alt_phone: '',
  fax: '',
  telex: '',
  web_url: '',
  email: '',
  latitude: '31.583680134438474',
  longitude: '130.54173685425317',
  open_1_mon: '00:00',
  close_1_mon: '24:00',
  open_2_mon: '',
  close_2_mon: '',
  open_3_mon: '',
  close_3_mon: '',
  open_1_tue: '00:00',
  close_1_tue: '24:00',
  open_2_tue: '',
  close_2_tue: '',
  open_3_tue: '',
  close_3_tue: '',
  open_1_wed: '00:00',
  close_1_wed: '24:00',
  open_2_wed: '',
  close_2_wed: '',
  open_3_wed: '',
  close_3_wed: '',
  open_1_thu: '00:00',
  close_1_thu: '24:00',
  open_2_thu: '',
  close_2_thu: '',
  open_3_thu: '',
  close_3_thu: '',
  open_1_fri: '00:00',
  close_1_fri: '24:00',
  open_2_fri: '',
  close_2_fri: '',
  open_3_fri: '',
  close_3_fri: '',
  open_1_sat: '00:00',
  close_1_sat: '24:00',
  open_2_sat: '',
  close_2_sat: '',
  open_3_sat: '',
  close_3_sat: '',
  open_1_sun: '00:00',
  close_1_sun: '24:00',
  open_2_sun: '',
  close_2_sun: '',
  open_3_sun: '',
  close_3_sun: '',
  loc_type_1: null,
  loc_type_2: null,
  city_name: 'KAGOSHIMA' as CityNameType,
  category_id: 'LAM',
  vendors: {
    logo: '',
  },
  location_description: [
    {
      location_id: 'ST4678' as RentalCarOfficeId,
      locale: 'en',
      description: 'Kagoshimachuo Station' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
    {
      location_id: 'ST4678' as RentalCarOfficeId,
      locale: 'ko',
      description: '가고시마 중앙역' as LocationNameType,
      address_1: '',
      address_2: '',
      address_3: '',
    },
  ],
});

export const OnlineTravelOffices = [
  FUKUOKA_AIRPORT,
  KITAKYUSHU_AIRPORT,
  HAKATA,
  GOFUKUMACHI_STATION,
  GION_STATION,
  OKINAWA_AIRPORT,
  SHIN_CHITOSE_AIRPORT,
  MINAMI,
  TOYOHIRA,
  HIGASHI,
  NARITA_AIRPORT,
  HANEDA_AIRPORT,
  KUMAMOTO_AIRPORT,
  KUMAMOTO_STATION,
  NAGOYA_AIRPORT,
  NAGOYA_STATION,
  KANSAI_AIRPORT,
  SHIN_OSAKA_STATION,
  KAGOSHIMACHUO_STATION,
];

export const ontraCodes = [
  ...new Set(
    OnlineTravelOffices.filter((city) => city.vendor_id === 'OT').flatMap((city) => [
      city.hertz_code,
      city.city_code,
    ]),
  ),
];

export const isOntra = (cityCode: string | null) => ontraCodes.includes(cityCode || undefined);
