import { css, cx } from '@emotion/css';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';

export const defaultStyle = {
  locationButton: css`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 56px;
    max-width: 300px;

    box-sizing: border-box;
    background-color: ${COLORS2.sub.MrtGray3} !important;
    border-radius: 5px;
    color: ${COLORS2.sub.MrtGray1};
  `,
  selectedLocationButton: css`
    background-color: ${COLORS2.main.MrtBlue1} !important;
    color: ${COLORS2.main.MrtWhite};
  `,
};

export const classNames = {
  desktop: {
    rootWrapper: css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: ${SPACING.spacing24};
    `,
    locationBtn: cx(
      font.Headline1_Bold,
      defaultStyle.locationButton,
      css`
        &:hover {
          background-color: ${COLORS2.main.MrtBlue1} !important;
          color: ${COLORS2.main.MrtWhite};
        }
      `,
    ),
  },
  mobile: {
    rootWrapper: css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: ${SPACING.spacing16};
    `,
    locationBtn: cx(
      font.Body1_Bold,
      defaultStyle.locationButton,
    ),
  },
};
