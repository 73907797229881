import { UAParser } from 'ua-parser-js';

export const userAgentParser = new UAParser();

export const parsedUserAgentResult = userAgentParser.getResult();

export const deviceType = parsedUserAgentResult
  .device
  .type;

export const hoverDisabledDevice = deviceType === 'mobile'
  || deviceType === 'tablet';
