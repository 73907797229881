import { Fragment } from 'react';
export const convertLineBreaks = (text) => {
  if (!text) return;
  return text.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
};
