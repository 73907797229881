import React from 'react';
import { PropTypes } from 'prop-types';

const FullScreenWrapper = (props) => {
  const { content, leftIcon = <></>, rightIcon = <span> </span>, title = '', isShow, usingChild, children, hasPaddingX = true } = props;
  return (
    <>
      {isShow && (
        <div as='div' className={`${isShow ? 'opacity-100 w-full ' : 'opacity-0 w-0 '} fixed h-screen z-50 transition-opacity inset-0 bg-white duration-300 hide-scrollbar overflow-y-auto`}>
          <div className='px-6 py-[14px]'>
            {leftIcon}
            {children}
          </div>
        </div>
      )}
    </>
  );
};
FullScreenWrapper.propTypes = {
  content: PropTypes.any,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  isShow: PropTypes.bool,
  children: PropTypes.any,
  usingChild: PropTypes.bool,
  title: PropTypes.any,
  hasPaddingX: PropTypes.bool,
  classNames: PropTypes.string,
};

export default FullScreenWrapper;
