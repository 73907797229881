import { z } from 'zod';

import { isDefined } from './utils/isDefined';

export const RawOpenHours = z.object({
  open_1_mon: z.string(),
  close_1_mon: z.string(),
  open_2_mon: z.string(),
  close_2_mon: z.string(),
  open_3_mon: z.string(),
  close_3_mon: z.string(),
  open_1_tue: z.string(),
  close_1_tue: z.string(),
  open_2_tue: z.string(),
  close_2_tue: z.string(),
  open_3_tue: z.string(),
  close_3_tue: z.string(),
  open_1_wed: z.string(),
  close_1_wed: z.string(),
  open_2_wed: z.string(),
  close_2_wed: z.string(),
  open_3_wed: z.string(),
  close_3_wed: z.string(),
  open_1_thu: z.string(),
  close_1_thu: z.string(),
  open_2_thu: z.string(),
  close_2_thu: z.string(),
  open_3_thu: z.string(),
  close_3_thu: z.string(),
  open_1_fri: z.string(),
  close_1_fri: z.string(),
  open_2_fri: z.string(),
  close_2_fri: z.string(),
  open_3_fri: z.string(),
  close_3_fri: z.string(),
  open_1_sat: z.string(),
  close_1_sat: z.string(),
  open_2_sat: z.string(),
  close_2_sat: z.string(),
  open_3_sat: z.string(),
  close_3_sat: z.string(),
  open_1_sun: z.string(),
  close_1_sun: z.string(),
  open_2_sun: z.string(),
  close_2_sun: z.string(),
  open_3_sun: z.string(),
  close_3_sun: z.string(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RawOpenHours = z.infer<typeof RawOpenHours>;

function toOpenClose(
  open?: string,
  close?: string,
) {
  if (!open && !close) {
    return null;
  }
  const openSplit = open?.split(':');
  const openDetail = openSplit && {
    hour: Number(openSplit[0]),
    min: Number(openSplit[1]),
  };

  const closeSplit = close?.split(':');
  const closeDetail = closeSplit && {
    hour: Number(closeSplit[0]),
    min: Number(closeSplit[1]),
  };

  return {
    open,
    close,
    openDetail,
    closeDetail,
  };
}

export type OpenCloseTime = Exclude<ReturnType<typeof toOpenClose>, null>;

export function getOpenCloseTimes(
  r: Partial<RawOpenHours>,
) {
  return {
    mon: [
      toOpenClose(r?.open_1_mon, r?.close_1_mon),
      toOpenClose(r?.open_2_mon, r?.close_2_mon),
      toOpenClose(r?.open_3_mon, r?.close_3_mon),
    ].filter(isDefined),
    tue: [
      toOpenClose(r?.open_1_tue, r?.close_1_tue),
      toOpenClose(r?.open_2_tue, r?.close_2_tue),
      toOpenClose(r?.open_3_tue, r?.close_3_tue),
    ].filter(isDefined),
    wed: [
      toOpenClose(r?.open_1_wed, r?.close_1_wed),
      toOpenClose(r?.open_2_wed, r?.close_2_wed),
      toOpenClose(r?.open_3_wed, r?.close_3_wed),
    ].filter(isDefined),
    thu: [
      toOpenClose(r?.open_1_thu, r?.close_1_thu),
      toOpenClose(r?.open_2_thu, r?.close_2_thu),
      toOpenClose(r?.open_3_thu, r?.close_3_thu),
    ].filter(isDefined),
    fri: [
      toOpenClose(r?.open_1_fri, r?.close_1_fri),
      toOpenClose(r?.open_2_fri, r?.close_2_fri),
      toOpenClose(r?.open_3_fri, r?.close_3_fri),
    ].filter(isDefined),
    sat: [
      toOpenClose(r?.open_1_sat, r?.close_1_sat),
      toOpenClose(r?.open_2_sat, r?.close_2_sat),
      toOpenClose(r?.open_3_sat, r?.close_3_sat),
    ].filter(isDefined),
    sun: [
      toOpenClose(r?.open_1_sun, r?.close_1_sun),
      toOpenClose(r?.open_2_sun, r?.close_2_sun),
      toOpenClose(r?.open_3_sun, r?.close_3_sun),
    ].filter(isDefined),
  };
}

export type OpenCloseTimes = ReturnType<typeof getOpenCloseTimes>;

export function isMinuteEnabled(
  openCloseTimes: OpenCloseTime[],
  hour: number,
  minute: number,
) {
  const hrStr = hour.toString().padStart(2, '0');
  const minStr = minute.toString().padStart(2, '0');
  const time = `${hrStr}:${minStr}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const a of openCloseTimes) {
    if (a.open && a.close) {
      if (a.open <= time
        && time <= a.close) {
        return true;
      }
    } else if (a.open) {
      //
      if (a.open <= time) {
        return true;
      }
    } else if (a.close) {
      if (time <= a.close) {
        return true;
      }
    }
  }
  return false;
}
