/* eslint-disable react/jsx-pascal-case */
import { RefObject, useRef } from 'react';

import { cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { Step0Root } from '^/components/CarSearchBox/base/mobile/Step0.root';
import { Step1RentalCity } from '^/components/CarSearchBox/base/mobile/Step1.rentalCity';
import { Step2RentalOffice } from '^/components/CarSearchBox/base/mobile/Step2.rentalOffice';
import { Step2_5RentalOfficeConfirm } from '^/components/CarSearchBox/base/mobile/Step2_5.rentalOfficeConfirm';
import { Step3Schedule } from '^/components/CarSearchBox/base/mobile/Step3.schdule';
import { Step4BirthDay } from '^/components/CarSearchBox/base/mobile/Step4.birthday';
import { Step5ReturnCity } from '^/components/CarSearchBox/base/mobile/Step5.returnCity';
import { Step6ReturnOffice } from '^/components/CarSearchBox/base/mobile/Step6.returnOffice';
import { Step6_5SelectedReturnOffice } from '^/components/CarSearchBox/base/mobile/Step6_5.SelectedReturnOffice';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';

import { CarListingSearchBoxViewModel } from '../desktop/viewmodel/CarListingSearchBox.viewmodel';

import { classNames } from './CarListingSearchBox.mobile.style';

export const CarListingSearchBoxMobile: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  pickupCityInputRef?: RefObject<HTMLInputElement>;
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>;
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>;
  carsScrollAreaRef: RefObject<HTMLDivElement> | null;
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null;
}> = observer(function CarListingSearchBoxMobile({
  viewmodel,
  pickupCityInputRef,
  pickupCityInputDummyRefForIOSKeyboard,
  returnCityInputDummyRefForIOSKeyboard,
  carsScrollAreaRef,
  carClassChipsScrollRef,
}) {
  const lastLayerRef = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(lastLayerRef, () => {
    viewmodel.stepManager.clearStep();
  });
  const steps = viewmodel.stepManager.getSteps();
  const lastStep = steps[steps.length - 1] || undefined;
  if (!lastStep || lastStep.type === 'FILTER' || lastStep.type === 'activeCarListGroup') {
    return null;
  }
  const guardedLastStep = lastStep && viewmodel.stepManager.guardStep(lastStep);
  if (guardedLastStep !== lastStep) {
    // alert('GUARD');
    setTimeout(() => {
      viewmodel.stepManager.replaceStep(guardedLastStep);
    }, 50);
    return null;
  }
  return (
    <div
      className={[
        classNames.root,
      ].join(' ')}
    >
      {steps.map((step, nth, allSteps) => {
        if (step.type === '0_none_selected') {
          return (
            <div
              key={`step_${nth}`}
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step0Root
                viewmodel={viewmodel}
                pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
                returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
                carsScrollAreaRef={carsScrollAreaRef}
                carClassChipsScrollRef={carClassChipsScrollRef}
              />
            </div>
          );
        }
        if (step.type === '1_rental_city') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step1RentalCity
                viewmodel={viewmodel}
                step={step}
                pickupCityInputRef={pickupCityInputRef}
              />
            </div>
          );
        }
        if (step.type === '2_rental_office') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step2RentalOffice
                viewmodel={viewmodel}
                pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
              />
            </div>
          );
        }
        if (step.type === '2_5_selected_rental_office') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step2_5RentalOfficeConfirm viewmodel={viewmodel} />
            </div>
          );
        }
        if (step.type === '3_schedule') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step3Schedule viewmodel={viewmodel} />
            </div>
          );
        }
        if (step.type === '4_birthday') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step4BirthDay viewmodel={viewmodel} />
            </div>
          );
        }
        if (step.type === '5_return_city') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={cx(
                classNames.container,
              )}
            >
              <Step5ReturnCity viewmodel={viewmodel} />
            </div>
          );
        }
        if (step.type === '6_return_office') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step6ReturnOffice
                viewmodel={viewmodel}
                returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
              />
            </div>
          );
        }
        if (step.type === '6_5_selected_return_office') {
          return (
            <div
              ref={(nth === allSteps.length - 1 || null) && lastLayerRef}
              className={classNames.container}
            >
              <Step6_5SelectedReturnOffice viewmodel={viewmodel} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
});
