import { css } from '@emotion/css';

import { borders, font } from '^/constants/commonStyles';

import { EmptyCar } from '../../common/EmptyCar';

export const ListingPage2mobileNoCar: React.FC<{
}> = () => {
  return (
    <div
      id="ListingPage2mobileNoCar"
      className={[
        css`
          position: absolute;
          top: 141px;
          left:0; bottom: 0;

          flex-grow: 1;
          width: 100%;
        `,
        // borders.DEBUG_pinkDashed,
      ].join(' ')}
    >
      <div
        className={css`
          width: 100%;
          height: 100%;
          overflow-y: auto;

          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <EmptyCar
          classOverrides={{
            prevBtnFontSize: font.Body1_Bold,
          }}
        />
      </div>
    </div>
  );
};
