/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { hideScrollBar } from '^/constants/commonStyles';

import BlackFridayPopup from '../BlackFridayPopup/BlackFridayPopup';
import { MRTFooter3Mobile } from '../Footer3/MRTFooter3.Mobile';
import { Header3Mobile } from '../Header3/Header3.Mobile';

export const LayoutMRTMobile: React.FC<React.PropsWithChildren<{
  withoutFooter?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutNavigationFullRefresh?: boolean;
  classOverrides?: {
    layout2MobileContentWrapperBg?: string;
  }
}>> = observer(function LayoutMRTMobile({
  children,
  withoutFooter,
  withoutOverflowScroll,
  scrollAreaKey,
  withoutNavigationFullRefresh,
  classOverrides,
}) {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const targetDateTime = new Date('2023-11-29T08:59:59');
  targetDateTime.setTime(targetDateTime.getTime() + targetDateTime.getTimezoneOffset() * 60000);
  const targetTimezone = 'Asia/Seoul';
  const options: Intl.DateTimeFormatOptions = {
    timeZone: targetTimezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date());
  const currentDateTime = new Date(formattedDateTime);
  const isBeforeTargetDateTime = currentDateTime < targetDateTime;

  useEffect(() => {
    setPopupVisible(window.location.pathname === '/');
  }, []);

  return (
    <div
      id="Layout2_Mobile_Root"
      className={cx(
        css`
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          overscroll-behavior: contain;
        `,
      )}
    >
      <Header3Mobile
        withoutFullRefresh={withoutNavigationFullRefresh}
      />
      {isPopupVisible
      && isBeforeTargetDateTime
      && (
      <div
        className={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000; 
          `}
      >
        <BlackFridayPopup setPopupVisible={setPopupVisible} />
      </div>
      )}
      <div
        id="Layout2_Mobile_Content"
        key={scrollAreaKey}
        className={cx(
          css`
            position: absolute;
            top: 60px;
            left: 0; right: 0;
            bottom: 0;
            overflow: scroll;
            overscroll-behavior: contain;
            background-color: ${classOverrides?.layout2MobileContentWrapperBg};
          `,
          hideScrollBar,
        )}
      >
        <div
          id="Layout2_Mobile_Content_inner"
          className={css`
            display: flex;
            flex-direction: column;
            /* min-height: 100%; */
            justify-content: space-between;
          `}
          style={withoutOverflowScroll ? {
            height: '100%',
          } : undefined}
        >
          {children}
          {!withoutFooter && <MRTFooter3Mobile />}
        </div>
      </div>
    </div>
  );
});
