import { css } from '@emotion/css';

interface SelectedOfficeAddressProps {
  isJapan: boolean | undefined,
  isTumonTamuning: boolean,
  fullAddress?: string,
  phone?: string,
}

export const SelectedOfficeAddress = ({
  isJapan,
  isTumonTamuning,
  fullAddress,
  phone,
}: SelectedOfficeAddressProps) => {
  if (isJapan) {
    return (
      <div
        className={css`
          color: #9F9F9F;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin: 12px 0 20px;
        `}
      >
        영업소별 상세 정보는 예약 페이지에서 확인하실 수 있습니다.
      </div>
    );
  }

  if (isTumonTamuning) {
    return (
      <div
        className={css`
              color: #9F9F9F;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              margin: 12px 0 20px;
            `}
      >
        공항, 투몬 & 타무닝 지역 내 호텔로 픽업/샌딩이 가능합니다.
      </div>
    );
  }
  return (
    <>
      <div
        className={css`
          margin-top: 12px;
          display: flex;
          gap: 4px;
          align-items: flex-start;
        `}
      >
        <img src="maker-pin_blue_20.svg" alt="maker-pin" />
        <div
          className={css`
            color: #717171;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          `}
        >
          {fullAddress}
        </div>
      </div>
      <div
        className={css`
          margin-top: 6px;
          display: flex;
          gap: 4px;
          align-items: center;
        `}
      >
        <img src="call-blue_20.svg" alt="call" />
        <div
          className={css`
            color: #717171;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          `}
        >
          {phone}
        </div>
      </div>
    </>
  );
};
