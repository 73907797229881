import { z } from 'zod';

import { ReservationIDType } from '^/types/__BrandedTypes';

export const CancelReservationSuccessResponse = z.object({
  success: z.literal(true),
  data: z.object({
    UniqueID: z.object({
      '@_Type': z.string(),
      '@_ID': ReservationIDType,
    }),
    '@_CancelStatus': z.literal('Cancelled'),
  }),
});

export const CancelReservationErrorResponse = z.object({
  success: z.literal(false),
  data: z.object({
    Error: z.object({
      '@_Type': z.string(),
      '@_ShortText': z.string(),
      '@_Code': z.string(),
      '@_RecordID': z.string(),
    }),
  }),
});

export const CancelReservationResponse = z.union([
  CancelReservationErrorResponse,
  CancelReservationSuccessResponse,
]);
