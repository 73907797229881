import { css } from '@emotion/css';

import { FLEX } from '^/constants/commonStyles';
import { ReactComponent as SpinnerBlue } from '^/lotties/loading_spinner_blue.svg';

export const BlueLoadingSpinner: React.FC<{
  classOverrides?: {
    root?: string;
    spinner?: string;
  }
}> = ({
  classOverrides,
}) => {
  return (
    <div className={
      classOverrides?.root
        || FLEX.allDirectionCenter
      }
    >
      <SpinnerBlue
        className={classOverrides?.spinner || css`
          width: 45px;
          height: 45px;
    `}
      />
    </div>
  );
};
