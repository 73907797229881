import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { COLORS2 } from '^/constants/commonStyles';

import { ListingPageOnlineTravelViewModel } from '../viewmodel/ListingPage.OnlineTravel.viewmodel';

import { ListingPageOnlineTravelCardCarInfo } from './ListingPage.OnlineTravel.Desktop.Card.CarInfo';
import { ListingPageOnlineTravelCardShopInfo } from './ListingPage.OnlineTravel.Desktop.Card.ShopInfo';

export const ListingPageOnlineTravelCard: React.FC<{
  data: any;
  viewmodel: ListingPageOnlineTravelViewModel;
}> = observer(function ListingPageOnlineTravelCard({ viewmodel, data }) {
  return (
    <article
      className={cx(
        css`
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 10px;
        `,
      )}
    >
      <ListingPageOnlineTravelCardShopInfo data={data.plan_info} />
      <ListingPageOnlineTravelCardCarInfo viewmodel={viewmodel} data={data} />
    </article>
  );
});
