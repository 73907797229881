export const getItemByChannel = (subdomain: string, object) => {
  const channelKeys = Object.keys(object);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of channelKeys) {
    if (subdomain.includes(key)) {
      return object[key];
    }
  }

  return object.trimo;
};
