import React, { Fragment } from 'react';

import { observer } from 'mobx-react-lite';

import { borders } from '^/constants/commonStyles';
import { MobxInputValue } from '^/util/MobxInputValue';

import { ConfirmDialogStyles } from '../ConfirmDialogPopup/ConfirmDialogPopup.styles';

import { AlertPopupDesktop } from './AlertPopup.desktop';
import { AlertPopupMobile } from './AlertPopup.mobile';

export const AlertPopup: React.FC<{
  title: React.ReactNode;
  visible: MobxInputValue<boolean>;
  closeText?: string;
  handleClose(e: React.MouseEvent): void;
  isMobile: boolean;
  stylesOverrides?: Partial<ConfirmDialogStyles>;
}> = observer(function AlertPopup(p) {
  return (
    <div id="Popup">
      {p.isMobile ? (
        <AlertPopupMobile {...p} closeText="닫기" />
      ) : (
        <AlertPopupDesktop {...p} closeText="닫기" />
      )}
    </div>
  );
});
