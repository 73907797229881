import { makeObservable } from 'mobx';

import { BaseItemsRepository } from '^/BaseListCore/BaseItemRepository';
import { ReservationIDType } from '^/types/__BrandedTypes';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';

// TODO: 'fragment' data (data-from-list-api + data-only-from-details-api)
export class ReservationDetailRepo
  extends BaseItemsRepository<ReservationDetailed, ReservationIDType> {
  constructor(
    public data = new Map<ReservationIDType, ReservationDetailed>(),
  ) {
    super(data);
    makeObservable(this, {

    });
  }
}
