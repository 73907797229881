/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject } from 'react';

import { observer } from 'mobx-react-lite';

import MRTCarListingSearchBoxMobile from '^/components/CarSearchBox/myrealtrip/mobile/MRTCarListingSearchBox.mobile';
import ReactPortal from '^/components/ReactPortal';

import { CarListingSearchBoxViewModel } from '../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '../../base/desktop/viewmodel/SearchBoxStepManager';

export const MRTCarListingBottomSheetWrapper: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  pickupCityInputRef?: RefObject<HTMLInputElement>,
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null,
  rootStep?: SearchBoxSteps,
}> = observer(function MRTCarListingBottomSheetWrapper({
  viewmodel,
  pickupCityInputRef,
  pickupCityInputDummyRefForIOSKeyboard,
  returnCityInputDummyRefForIOSKeyboard,
  carsScrollAreaRef,
  carClassChipsScrollRef,
  rootStep,
}) {
  return (
    <ReactPortal
      wrapperId="for-searchBox"
    >
      <MRTCarListingSearchBoxMobile
        viewmodel={viewmodel}
        pickupCityInputRef={pickupCityInputRef}
        pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
        returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
        carsScrollAreaRef={carsScrollAreaRef}
        carClassChipsScrollRef={carClassChipsScrollRef}
        rootStep={rootStep}
      />
    </ReactPortal>
  );
});
