import { css } from '@emotion/css';
import { differenceInHours, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { COLORS2, font2 } from '^/constants/commonStyles';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { dateWithHyphen } from '^/util/dateFormat';
import { formatPhoneNumber, insertHyphen } from '^/util/phoneNumber';

import { ONLINE_TRAVEL_OPTIONS } from '../../CarDetails/components/OptionalServices.constant';

import ShopInfoMobile from './ShopInfo.Mobile';
import { translateServicesForOntra } from '^/pages/Listing/common/translateServices';

const findService = (serviceList, keyword) => {
  return serviceList.find((s) => s.name === keyword)?.info;
};

const ReservationConfirmMobile = ({ data, isResult, isDetail }) => {
  const { t } = useTranslation();
  const selectedOptions = data.option_info_list.filter(
    (option) => option.quantity && option.quantity > 0,
  );

  const formattedPhoneNumber = formatPhoneNumber(insertHyphen(data.plan_info.shop_tel));
  return (
    <div
      className={
        isDetail ? 'flex flex-col gap-[30px]' : '-mx-6 flex flex-col gap-4 bg-trimo-bg px-6 py-5'
      }
    >
      <div
        className={
          isDetail ? 'flex flex-col gap-1.5' : 'flex flex-col gap-1.5 rounded-trimo bg-white p-4'
        }
      >
        {/* 차량정보 */}
        <h2 className="text-big font-bold capitalize">
          {`[${data.car_info.class_name}] ${data.car_info.model_name}`}
        </h2>
        <h3 className="text-small font-light text-neutral-300">
          {translateServicesForOntra(data.equipment_info_list, data.plan_info.shuttle_flag, t)}
        </h3>
      </div>

      {isDetail && <Horizon />}

      <div
        className={
          isDetail ? 'flex flex-col gap-5' : 'flex flex-col gap-4 rounded-trimo bg-white p-4'
        }
      >
        {/* 예약번호 */}
        {isDetail && (
          <div className="flex flex-col gap-1.5">
            <h2 className={font2.Body1_Bold}>예약 번호</h2>
            <p className={isDetail ? font2.Caption1_Light : 'text-default font-light'}>
              {data.booking_info?.id}
            </p>
          </div>
        )}
        {/* 대여 / 반납 일정 */}
        <div className="flex flex-col gap-1.5">
          <h2 className={[font2.Body1_Bold].join(' ')}>대여 / 반납 일정</h2>
          <p className={font2.Caption1_Light}>
            {`${format(
              new Date(dateWithHyphen(data.schedule_info.pickup.time)),
              'yyyy년 LL월 dd일 HH:mm',
            )} ~ ${format(
              new Date(dateWithHyphen(data.schedule_info.return.time)),
              'yyyy년 LL월 dd일 HH:mm',
            )} (${differenceInHours(
              new Date(dateWithHyphen(data.schedule_info.return.time)),
              new Date(dateWithHyphen(data.schedule_info.pickup.time)),
            )} 시간)`}
          </p>
        </div>
        {/* 대여 정보 */}
        <div>
          <h2 className={[font2.Body1_Bold].join(' ')}>대여 영업소</h2>
          <p className={[font2.Caption1_Light].join(' ')}>
            {data.plan_info.shop_name || '-'}&nbsp;
            {data.plan_info.shop_tel && (
              <a
                className={css`
                  color: ${COLORS2.main.MrtBlue1};
                  text-decoration: underline;
                `}
                // onClick={() => {
                //   sendTrackingLog({
                //     action: actions.DRIVER_DETAIL.CLICK_SHOP_PHONE_NUMBER,
                //     data: formattedPhoneNumber,
                //   });
                // }}
                href={`tel:${formattedPhoneNumber}`}
              >
                {formattedPhoneNumber}
              </a>
            )}
          </p>
          <p className={[font2.Caption1_Light].join(' ')}>{data.plan_info.shop_address || '-'}</p>
          {data.plan_info.shuttle_flag === 1 && (
            <p className={[font2.Caption1_Light].join(' ')} style={{ color: COLORS2.sub.orange }}>
              매장까지 무료 셔틀을 이용할 수 있습니다.
            </p>
          )}
        </div>
        {/* 반납 정보 */}
        <div>
          <h2 className={[font2.Body1_Bold].join(' ')}>반납 영업소</h2>
          <p className={[font2.Caption1_Light].join(' ')}>
            {data.plan_info.shop_name || '-'}&nbsp;
            {data.plan_info.shop_tel && (
              <a
                className={css`
                  color: ${COLORS2.main.MrtBlue1};
                  text-decoration: underline;
                `}
                // onClick={() => {
                //   sendTrackingLog({
                //     action: actions.DRIVER_DETAIL.CLICK_SHOP_PHONE_NUMBER,
                //     data: formattedPhoneNumber,
                //   });
                // }}
                href={`tel:${formattedPhoneNumber}`}
              >
                {formattedPhoneNumber}
              </a>
            )}
          </p>
          <p className={[font2.Caption1_Light].join(' ')}>{data.plan_info.shop_address || '-'}</p>
          {data.plan_info.shuttle_flag === 1 && (
            <p className={[font2.Caption1_Light].join(' ')} style={{ color: COLORS2.sub.orange }}>
              매장까지 무료 셔틀을 이용할 수 있습니다.
            </p>
          )}
        </div>
        {/* 보험 내용 */}
        <div>
          <h2 className={[font2.Body1_Bold].join(' ')}>보험 내용</h2>
          <p className={[font2.Caption1_Light].join(' ')}>기본 보험</p>
        </div>
        {/* 추가 옵션 */}
        <div>
          <h2 className={[font2.Body1_Bold].join(' ')}>추가 옵션</h2>
          <p className={[font2.Caption1_Light].join(' ')}>
            {selectedOptions.length > 0
              ? selectedOptions
                  .map((option) => `${ONLINE_TRAVEL_OPTIONS[option.code]}(${option.quantity})`)
                  .join(' / ')
              : '없음'}
          </p>
        </div>
        {!isResult && <ShopInfoMobile data={data} isDetail={isDetail} />}
      </div>
    </div>
  );
};

export default ReservationConfirmMobile;
