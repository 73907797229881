import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';

import { cancelReservationOT, getReservationOT } from '^/api/reservation';
// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { ServerErrorView } from '^/commonComponents/ui/ErrorViews/ServerErrorView';
import { Footer3Mobile } from '^/components/Footer3/Footer3.Mobile';
import Layout1 from '^/components/Layout';
import Loading from '^/components/Loading';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { AlertPopupDesktop } from '^/components/Popup/AlertPopup/AlertPopup.desktop';
import { AlertPopupMobile } from '^/components/Popup/AlertPopup/AlertPopup.mobile';
import { ConfirmDialogPopup } from '^/components/Popup/ConfirmDialogPopup/ConfirmDialogPopup';
import { MARGINS, COLORS2_AS_FOREGROUND, font } from '^/constants/commonStyles';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { formatDateWithTimezone } from '^/util/dateFormat';
import { MobxInputValue } from '^/util/MobxInputValue';

import BillCancelButton from '../components/ReservationDetails.Bill.Booked.CancelButton';
import ConfirmReservationCancelPopup from '../ConfirmReservationCancelPopup';

import ReservationDetailDesktop from './ReservationDetail.Desktop';
import ReservationDetailMobile from './ReservationDetail.Mobile';

const ReservationDetailOnlineTravel = (props) => {
  const { id, vendorId, lastName } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showCancleConfirmPopup, setShowCancelConfirmPopup] = useState(new MobxInputValue(false));
  const [loading, setLoading] = useState(false);
  const [isAlertPopupShow, setIsAlertPopupShow] = useState(new MobxInputValue(false));
  const [isCancelError, setIsCancelError] = useState(false);

  // useEffect(() => {
  //   sendTrackingLog({
  //     action: actions.RESERVATION_DETAIL.ENTER,
  //   });
  // }, []);

  const onClickCancelReservationConfirm = async (cancel_reason) => {
    setShowCancelConfirmPopup(new MobxInputValue(false));
    setLoading(true);
    try {
      const response = await cancelReservationOT(id, cancel_reason);
      if (response.data.success) {
        setLoading(false);
        setIsAlertPopupShow(new MobxInputValue(true));
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setIsCancelError(true);
    }
  };

  const {
    data: resData,
    isLoading,
    isError,
    error,
  } = useQuery(
    ['reservationDetailOT', id],
    async () => {
      const response = await getReservationOT(id);
      return response.data;
    },
    {
      retry: 1,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      onError: (err) => console.log(err),
    },
  );

  const isCancelDisabled = resData
    ? isReservationCancelDisabled({
        pickupDate: formatDateWithTimezone(resData.schedule_info.pickup.time, 'date'),
        cancelEnabled: true,
        payType: 'POD',
      })
    : false;

  if (isLoading) {
    return <LoadingWithVendorLogo />;
  }
  if (isError) {
    return (
      <ServerErrorView
        onClickBack={() => typeRouter.session.back()}
        onClickRetry={() => window.location.reload()}
      />
    );
  }
  if (isCancelError) {
    return (
      <ServerErrorView
        title="예약 취소 중 에러가 발생하였습니다."
        onClickBack={() => {
          typeRouter.session.back();
        }}
        onClickRetry={() => {
          window.location.reload();
        }}
      />
    );
  }
  if (isMobile) {
    return (
      <>
        <Layout1 withoutFooter>
          {loading && <LoadingWithVendorLogo />}
          <ConfirmReservationCancelPopup
            visible={showCancleConfirmPopup}
            handleCancel={() => {
              // sendTrackingLog({
              //   action: actions.RESERVATION_DETAIL.CLICK_CANCEL_BUTTON_ON_CANCEL_CHECK_MODAL,
              // });
              setShowCancelConfirmPopup(new MobxInputValue(false));
            }}
            handleConfirm={(cancel_reason) => {
              // sendTrackingLog({
              //   action: actions.RESERVATION_DETAIL.CLICK_CONFIRM_BUTTON_ON_CANCEL_CHECK_MODAL,
              // });
              onClickCancelReservationConfirm(cancel_reason);
            }}
            isMobile={isMobile}
          />
          <AlertPopupMobile
            title="예약 취소가 완료되었습니다."
            closeText="닫기"
            visible={isAlertPopupShow}
            handleClose={() => typeRouter.session.back()}
          />
          <ReservationDetailMobile resData={resData} />
          {resData.booking_info.status === 'booked' && (
            <BillCancelButton
              data={resData}
              setShowCancelConfirmPopup={setShowCancelConfirmPopup}
              isCancelDisabled={isCancelDisabled}
            />
          )}
        </Layout1>
        <div className={MARGINS.TOP.spacing32}>
          <Footer3Mobile />
        </div>
      </>
    );
  }
  return (
    <>
      {loading && <LoadingWithVendorLogo />}
      <ReservationDetailDesktop
        resData={resData}
        setShowCancelConfirmPopup={setShowCancelConfirmPopup}
        isCancelDisabled={isCancelDisabled}
      />
      <ConfirmReservationCancelPopup
        visible={showCancleConfirmPopup}
        handleCancel={() => {
          // sendTrackingLog({
          //   action: actions.RESERVATION_DETAIL.CLICK_CANCEL_BUTTON_ON_CANCEL_CHECK_MODAL,
          // });
          setShowCancelConfirmPopup(new MobxInputValue(false));
        }}
        handleConfirm={(cancel_reason) => {
          // sendTrackingLog({
          //   action: actions.RESERVATION_DETAIL.CLICK_CONFIRM_BUTTON_ON_CANCEL_CHECK_MODAL,
          // });
          onClickCancelReservationConfirm(cancel_reason);
        }}
        isMobile={isMobile}
      />
      <AlertPopupDesktop
        title="예약 취소가 완료되었습니다."
        closeText="닫기"
        visible={isAlertPopupShow}
        handleClose={() => typeRouter.session.back()}
      />
    </>
  );
};

export default ReservationDetailOnlineTravel;
