/* eslint-disable max-len */
import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { Layout2Desktop } from './Layout2Desktop';
import { Layout2Mobile } from './Layout2Mobile';

export const Layout2: React.FC<React.PropsWithChildren<{
  withoutFooter?: boolean;
  withoutMaxWidth?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutFooterTopLine?: boolean;
  withoutNavigationFullRefresh?: boolean;
}>> = observer(function Layout2(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (isMobile) {
    return (
      <Layout2Mobile
        {...props}
      />
    );
  }

  return (
    <Layout2Desktop
      {...props}
    />
  );
});
