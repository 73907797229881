/* eslint-disable no-nested-ternary */
import { z, ZodNumber, ZodOptional } from 'zod';

export function IntFromString<
TSchema extends (ZodNumber | ZodOptional<ZodNumber>),
>(schema:TSchema) {
  return (
    z.preprocess((value) => (
      ((typeof value === 'string') ? parseInt(value, 10)
        : (typeof value === 'number') ? value
          : undefined
      )), schema)
  );
}

export function NumberFromString<
TSchema extends (ZodNumber | ZodOptional<ZodNumber>),
>(schema:TSchema) {
  return (
    z.preprocess((value) => (
      ((typeof value === 'string') ? parseInt(value, 10)
        : (typeof value === 'number') ? value
          : undefined
      )), schema)
  );
}

export type NumberString = string & {
  __NumberString: null;
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberString: z.Schema<NumberString> = z.string()
  .refine(
    (a) => !Number.isNaN(Number(a)),
  ) as any;

export const NumberOrNumberString = z.union([
  z.number(),
  NumberString,
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NumberOrNumberString = z.infer<typeof NumberOrNumberString>;

export function parseFloatNumberOrNumberString(
  n: NumberOrNumberString,
) {
  if (typeof (n) === 'string') {
    return parseFloat(n);
  }
  return n;
}
