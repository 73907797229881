import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import Button from '^/components/Button';
import Layout from '^/components/Layout';
import { Layout2 } from '^/components/Layout/Layout2';

import {
  ReservationCheckPageFormViewModel,
} from './ReservationCheck.form.viewmodel';


const ButtonImported = (Button as any);

export const ReservationCheckPageDesktopLayout: React.FC<PropsWithChildren<{
  formViewModel: ReservationCheckPageFormViewModel,
}>> = observer(function ReservationCheckPageDesktopLayout({
  children,
  formViewModel,
}) {
  return (
    <Layout>
      <form
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          max-width: 520px;
          height: 540px;
          padding-top: 60px;

          @media (max-width: 1280px) {
            padding-left: 24px;
            padding-right: 24px;
          }
        `}
        id="ReservationCheckPageDesktopLayout_root"
        onSubmit={(e) => {
          e.preventDefault();
          formViewModel.submit();
        }}
      >
        <h1
          id="ReservationCheckPageDesktopLayout_title"
          className={css`
              font-family: Pretendard;
              font-size: 32px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.44;
              letter-spacing: -0.03px;
              text-align: left;
              color: #1e1e1e;
          `}
        > 예약을 조회하려면 정보가 필요해요
        </h1>
        <h2
          className={css`
            margin-top: 52px;
            font-family: Pretendard;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: -0.02px;
            text-align: left;
            color: #1e1e1e;
          `}
        >
          예약 진행 시에 입력한 정보를 입력해주세요.
        </h2>
        {children}
        <ButtonImported
          id="button"
          type="submit"
          classNames="
            hover:text-white
            rounded-trimo-5
            py-4.5
            tablet:text-semilarge
            mobile:text-big text-gray
            diabled:text-white disabled:secondary-gray-light
            bg-primary-skyblue
            "
          color="skyblue" content="continue"
          disabled={!formViewModel.isValid}
          fullWidth
          onClick={formViewModel.submit}
        >
        </ButtonImported>
      </form>
    </Layout>
  );
});
