import { typeRouter } from './typeRoute/typeRoutes';

const INITIAL_PAGE_LOAD_STATE = {
  INITIAL_PAGE_LOAD_STATE: true,
};
export function resetHistoryStateOnPageLoad() {
  window.history.replaceState(
    INITIAL_PAGE_LOAD_STATE,
    '',
  );
  window.addEventListener('popstate', (ev) => {
    if (ev.state === INITIAL_PAGE_LOAD_STATE
      || ev.state?.INITIAL_PAGE_LOAD_STATE) {
      console.error('popState: INITIAL_PAGE_LOAD_STATE');
    }
  });
  typeRouter.session.reset({
    type: 'browser',
  });
}
