import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SPACING, COLORS2, font } from '^/constants/commonStyles';

import { RentalOfficeInputViewModel } from './RentalOfficeInput.viewmodel';
import { classNames, defaultStyle } from './RentalOfficeInputLocationCategory.style';

export const RentalOfficeInputLocationCategory: React.FC<{
  viewmodel: RentalOfficeInputViewModel,
}> = observer(function RentalOfficeInputLocationCategory({
  viewmodel,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const selectedCategory = viewmodel.selectedCategory.value
    || viewmodel
      .selectedOfficeData
      ?.category_id;

  return (
    <div
      className={
        isMobile
          ? classNames.mobile.rootWrapper
          : classNames.desktop.rootWrapper
      }
    >
      {/* <h1>RentalOfficeInputLocationCategory</h1> */}
      {(viewmodel.data.get('LAP')?.length || 0) > 0 && (
        <button
          className={cx(
            isMobile
              ? classNames.mobile.locationBtn
              : classNames.desktop.locationBtn,
            selectedCategory === 'LAP'
              && defaultStyle.selectedLocationButton,
          )}
          type="button"
          onClick={() => {
            viewmodel
              .selectedCategory
              .set('LAP');
            // viewmodel.selectedOffice.set('');
          }}
        >
          공항
        </button>
      )}
      {(viewmodel.data.get('LDT')?.length || 0) > 0 && (
        <button
          className={cx(
            isMobile
              ? classNames.mobile.locationBtn
              : classNames.desktop.locationBtn,
            selectedCategory === 'LDT'
              && defaultStyle.selectedLocationButton,
          )}
          type="button"
          onClick={() => {
            viewmodel
              .selectedCategory
              .set('LDT');
            // viewmodel.selectedOffice.set('');
          }}
        >
          도시
        </button>
      )}
      {(viewmodel.data.get('LHT')?.length || 0) > 0 && (
        <button
          className={cx(
            isMobile
              ? classNames.mobile.locationBtn
              : classNames.desktop.locationBtn,
            selectedCategory === 'LHT'
              && defaultStyle.selectedLocationButton,
          )}
          type="button"
          onClick={() => {
            viewmodel
              .selectedCategory
              .set('LHT');
            // viewmodel.selectedOffice.set('');
          }}
        >
          호텔
        </button>
      )}
      {(viewmodel.data.get('LAM')?.length || 0) > 0 && (
        <button
          className={cx(
            isMobile
              ? classNames.mobile.locationBtn
              : classNames.desktop.locationBtn,
            selectedCategory === 'LAM'
              && defaultStyle.selectedLocationButton,
          )}
          type="button"
          onClick={() => {
            viewmodel
              .selectedCategory
              .set('LAM');
            // viewmodel.selectedOffice.set('');
          }}
        >
          기차역
        </button>
      )}
    </div>
  );
});
