import { css } from '@emotion/css';

import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { SPACING, COLORS2 } from '^/constants/commonStyles';

import { ListingPage2MobileHeader } from './ListingPage2.Mobile.Header';
import { SkeletonCarInfoMobile } from './Skeleton.CarInfo.Mobile';

const ListingPage2MobileLoading = ({ viewmodel, carClassChipsScrollRef }) => (
  <>
    <ListingPage2MobileHeader
      viewmodel={viewmodel}
      carClassChipsScrollRef={carClassChipsScrollRef}
      isFilterButtonVisible={viewmodel.allCarList.status === 'success'}
    />
    <div
      className={css`
        background-color: ${COLORS2.sub.MrtGray3};
        width: 100%;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 20px;

          padding: ${SPACING.spacing24};
        `}
      >
        <SkeletonCarInfoMobile />
        <SkeletonCarInfoMobile />
        <SkeletonCarInfoMobile />
      </div>
      <LoadingWithVendorLogo />
    </div>
  </>
);

export default ListingPage2MobileLoading;
