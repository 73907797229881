import {
  CarListingSearchBoxInputViewModel,
} from './CarListingSearchBox.InputViewmodel';
import type { SearchBoxSteps } from './SearchBoxStepManager';

export function guardStepImpl(
  maybeNextStep: SearchBoxSteps,
  inputVM: CarListingSearchBoxInputViewModel,
): SearchBoxSteps {
  if (maybeNextStep.type === '2_rental_office') {
    if (inputVM.tempPickupCityCode.value === null && inputVM.pickupCityCode.value === null) {
      return {
        type: '1_rental_city',
      };
    }
    return maybeNextStep;
  }
  if (maybeNextStep.type === '2_5_selected_rental_office') {
    if (inputVM.tempPickupCityCode.value === null && inputVM.pickupCityCode.value === null) {
      console.log('SearchBoxStepManager.ts: 1_rental_city로 이동!');
      return {
        type: '1_rental_city',
      };
    }
    if (inputVM.pickupLocationId.value === null
      && inputVM.tempPickupLocationId.value === null
    ) {
      console.log('SearchBoxStepManager.ts: 2_rental_office로 이동!');

      return {
        type: '2_rental_office',
      };
    }
    return maybeNextStep;
  }
  if (maybeNextStep.type === '6_return_office') {
    if (inputVM.tempReturnCityCode.value === null && inputVM.returnCityCode.value === null) {
      return {
        type: '5_return_city',
      };
    }
    return maybeNextStep;
  }
  if (maybeNextStep.type === '6_5_selected_return_office') {
    if (inputVM.tempReturnCityCode.value === null && inputVM.returnCityCode.value === null) {
      return {
        type: '5_return_city',
      };
    }
    if (inputVM.returnLocationId.value === null
      && inputVM.tempReturnLocationId.value === null
    ) {
      return {
        type: '6_return_office',
      };
    }
    return maybeNextStep;
  }
  if (maybeNextStep.type === '3_schedule') {
    if (inputVM.pickupCityCode.value === null) {
      return {
        type: '1_rental_city',
      };
    }
    if (inputVM.pickupLocationId.value === null) {
      return {
        type: '2_rental_office',
      };
    }
    if (inputVM.hasDifferentPickupReturnLocations.value === true) {
      if (inputVM.returnCityCode.value === null) {
        return {
          type: '5_return_city',
        };
      }
      if (inputVM.returnLocationId.value === null) {
        return {
          type: '6_return_office',
        };
      }
    }
    return maybeNextStep;
  }
  return maybeNextStep;
}
