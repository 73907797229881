/* eslint-disable max-len */
import { css } from 'styled-components';

import TermWrapper from './TermWrapper';
import { useRecoilValue } from 'recoil';
import { subdomainState } from '^/recoil/atoms';
import { MYREALTRIP_PRIVACY_POLICY } from './TermsPrivacy.Myrealtrip';
import { WAUG_PRIVACY_POLICY } from './TermsPrivacy.Waug';
import { getItemByChannel } from '^/util/byChannel';
import { ZZIMCAR_PRIVACY_POLICY } from './TermsPrivacy.Zzimcar';
export const tableStyle = [
  'table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small',
  css`
    th,
    td {
      border-width: 1px;
      border-color: rgb(30, 30, 30);
    }
  `,
].join(' ');
export const TermsPrivacyPopup: React.FC<{
  open: boolean;
  setOpen: (o: boolean) => void;
}> = ({ open, setOpen }) => {
  const subdomain = useRecoilValue(subdomainState);
  const termsObject = {
    myrealtrip: MYREALTRIP_PRIVACY_POLICY,
    waug: WAUG_PRIVACY_POLICY,
    zzimcar: ZZIMCAR_PRIVACY_POLICY,
    trimo: MYREALTRIP_PRIVACY_POLICY,
  };
  // @ts-ignore
  const content = getItemByChannel(subdomain, termsObject);
  return (
    <TermWrapper
      classNames="

        tablet:rounded-[10px] tablet:w-[568px] tablet:h-[90vh]
        mobile:h-[100vh] mobile:w-[100vw] mobile:rounded-none
      "
      open={open}
      onCloseModal={() => setOpen(false)}
      title="개인정보 처리방침"
      content={content}
    />
  );
};
export default TermsPrivacyPopup;
