import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { getWeekDayFromDate } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { ScheduleTimeViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/ScheduleTimeViewModel';
import { BaseSearchBoxStepManager } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { SPACING, COLORS2, FLEX } from '^/constants/commonStyles';
import { OpenCloseTimes } from '^/types/RawOpenHours';

import { TimeSelect } from '../../../../Calendar/BasicCalendar/components/TimeSelect/TimeSelect';

export const CalendarWithTimeChoiceMRT: React.FC<{
  calendarListViewModel: CalendarListViewModel;
  calendarStateViewModel: CalendarStateViewModel;
  stepManager: BaseSearchBoxStepManager;
  onAllDateSelected(args: { startDate: Date; endDate: Date }): void;
  scheduleTimeViewModel: ScheduleTimeViewModel;
  pickupOpenCloseTimes: OpenCloseTimes;
  returnOpenCloseTimes: OpenCloseTimes;
  onSubmit: () => void;
}> = observer(function CalendarWithTimeChoice(props) {
  const isCalendarWidthTimeMaxSize = useMediaQuery({ query: '(max-width: 1240px)' });
  const stateViewModel = props.calendarStateViewModel;
  // eslint-disable-next-line prefer-destructuring
  const startDate = stateViewModel.startDate;
  const formattedStartDate = startDate?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const startMonth = formattedStartDate?.substring(0, 2);
  const startDay = formattedStartDate?.substring(3, 5);

  // eslint-disable-next-line prefer-destructuring
  const endDate = stateViewModel.endDate;
  const formattedEndDate = endDate?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const endMonth = formattedEndDate?.substring(0, 2);
  const endDay = formattedEndDate?.substring(3, 5);

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;

          width: 100%;
          height: 100%;

          box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.05);
        `,
        isCalendarWidthTimeMaxSize &&
          css`
            height: auto;
          `,
      )}
    >
      <div
        className={cx(
          css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: ${SPACING.spacing8};

            height: 104px;
            padding: 8px ${SPACING.spacing16} 0;
          `,
        )}
      >
        <div
          id="pickup-time"
          className={cx(
            css`
              display: flex;
              flex-direction: column;

              width: 283px;
              background-color: #f7fbff;
              padding: 16px;
              border-radius: 12px;
            `,
            !startDate
              ? css`
                  opacity: 1;
                `
              : css`
                  opacity: 1;
                `,
          )}
        >
          <span
            className={css`
              font-size: 14px;
              font-weight: 600;
              color: #434343;
            `}
          >
            대여 일정
          </span>
          <div
            className={cx(css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `)}
          >
            {startDate ? (
              <span
                className={css`
                  color: #1e1e1e;
                  font-size: 16px;
                  font-weight: 600;
                `}
              >
                {startMonth}월 {startDay}일
              </span>
            ) : (
              <span
                className={css`
                  color: #1e1e1e;
                  font-size: 16px;
                  font-weight: 600;
                `}
              >
                미선택
              </span>
            )}
            <div className={css``}>
              <TimeSelect
                type="pickup"
                disabled={!startDate}
                hour={props.scheduleTimeViewModel.pickupHour}
                minute={props.scheduleTimeViewModel.pickupMinute}
                openCloseTimes={
                  (startDate && props.pickupOpenCloseTimes[getWeekDayFromDate(startDate)]) || []
                }
              />
            </div>
          </div>
        </div>
        <div
          id="return-time"
          className={cx(
            css`
              display: flex;
              flex-direction: column;
              justify-items: center;

              width: 283px;
              background-color: #f7fbff;
              padding: 16px;
              border-radius: 12px;
            `,
            !endDate
              ? css`
                  opacity: 1;
                `
              : css`
                  opacity: 1;
                `,
          )}
        >
          <span
            className={css`
              font-size: 14px;
              font-weight: 600;
              color: #434343;
            `}
          >
            반납 일정
          </span>
          <div
            className={cx(css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `)}
          >
            {endDate ? (
              <span
                className={css`
                  color: #1e1e1e;
                  font-size: 16px;
                  font-weight: 600;
                `}
              >
                {endMonth}월 {endDay}일
              </span>
            ) : (
              <span
                className={css`
                  color: #1e1e1e;
                  font-size: 16px;
                  font-weight: 600;
                `}
              >
                미선택
              </span>
            )}
            <div className={css``}>
              <TimeSelect
                type="return"
                disabled={!endDate}
                hour={props.scheduleTimeViewModel.returnHour}
                minute={props.scheduleTimeViewModel.returnMinute}
                openCloseTimes={
                  (endDate && props.returnOpenCloseTimes[getWeekDayFromDate(endDate)]) || []
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(
          css`
            display: flex;
            justify-content: center;
            align-items: center;

            gap: ${SPACING.spacing4};
            padding: 12px 10px 10px;

            color: #717171;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.8px;
            text-align: center;
          `,
        )}
      >
        <div style={{}}>
          <img src="./info-circle_14.svg" alt="info caution icon" />
        </div>
        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <span className={css``}>
            시간 선택은 영업소 오픈 시간만 선택 가능하며, 1년 이내 예약만 할 수 있어요.
          </span>
        </div>
      </div>
      <button
        type="button"
        onClick={async () => {
          if (startDate && endDate) {
            props.onAllDateSelected({
              startDate,
              endDate,
            });
          }
          props.onSubmit();
          // sendTrackingLog({
          //   action: actions['/'].SUBMIT_SCHEDULE,
          // });
        }}
        className={cx(
          FLEX.allDirectionCenter,
          css`
            height: ${SPACING.spacing48};
            margin: 0 10px 12px;
            padding: 0 16px;

            border-radius: 8px;
            background-color: ${startDate && endDate
              ? COLORS2.main.MrtBlue1
              : '#f4f4f4'} !important;
            border: ${startDate && endDate ? '' : '1px solid #cecece'};
            color: ${startDate && endDate ? COLORS2.main.MrtWhite : '#bbb'};
          `,
        )}
      >
        적용
      </button>
    </div>
  );
});
