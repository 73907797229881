import { css } from '@emotion/css';

import { borders } from '^/constants/commonStyles';

export const flexSplitHorizontalInHalf = css`
  display: flex;
  justify-content: space-between;
  > * {
    flex: 1;
  }
`;

// TODO: maybe remove later?
export const flexSpacedRowWithVerticalCenter = [
  css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  // borders.DEBUG_purpleBorderDashed,
].join(' ');

export const flexRightmostItemRow = [
  css`
    display: flex;
    flex-direction: row;
    justify-content: end;
  `,
  // borders.DEBUG_pinkDashed,
].join(' ');

export const CancelButtonStyle = `
  mt-4 w-full rounded-trimo-5
  bg-trimo-bg p-3
  text-center font-bold text-primary-gray
  hover:cursor-pointer hover:bg-primary-skyblue
  hover:text-white
  active:text-white
  disabled:cursor-not-allowed disabled:bg-secondary-gray disabled:text-neutral-300
`;
