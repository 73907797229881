import { RefObject, useRef } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { MRTSearchHeaderMobile } from '^/components/CarSearchBox/myrealtrip/mobile/headerArea/MRTSearchHeader.mobile';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';

import { MRTSelectPickupCity } from './contentsArea/SelectCity/MRTSelectPickupCity.mobile';
import { MRTSelectReturnCity } from './contentsArea/SelectCity/MRTSelectReturnCity.mobile';
import { MRTSelectedPickupOfficeInfoMobile } from './contentsArea/SelectedOffice/MRTSelectedPickupOfficeInfo.mobile';
import { MRTSelectedReturnOfficeInfoMobile } from './contentsArea/SelectedOffice/MRTSelectedReturnOfficeInfo.mobile';
import { MRTSelectPickupOfficeMobile } from './contentsArea/SelectOffice/MRTSelectPickupOffice.mobile';
import { MRTSelectReturnOfficeMobile } from './contentsArea/SelectOffice/MRTSelectReturnOffice.mobile';
import { MRTSelectScheduleMobile } from './contentsArea/SelectSchedule/MRTSelectSchedule.mobile';
import { MRTCarListingSearchBoxMobileRoot } from './MRTCarListingSearchBox.mobile.root';

export const MRTCarListingSearchBoxMobile: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  pickupCityInputRef?: RefObject<HTMLInputElement>;
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>;
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>;
  carsScrollAreaRef: RefObject<HTMLDivElement> | null;
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null;
  rootStep?: SearchBoxSteps,
}> = observer(function MRTCarListingSearchBoxMobile({
  viewmodel,
  pickupCityInputRef,
  rootStep,
}) {
  // const hasDifferentPickupReturnLocations = viewmodel.i.hasDifferentPickupReturnLocations.value;
  const lastLayerRef = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(lastLayerRef, () => {
    viewmodel.stepManager.clearStep();
  });

  const steps = viewmodel.stepManager.getSteps();
  const lastStep = steps[steps.length - 1] || undefined;

  if (!lastStep || lastStep.type === 'FILTER' || lastStep.type === 'activeCarListGroup') {
    return null;
  }
  const guardedLastStep = lastStep && viewmodel.stepManager.guardStep(lastStep);
  if (guardedLastStep !== lastStep) {
    setTimeout(() => {
      viewmodel.stepManager.replaceStep(guardedLastStep);
    }, 50);
    return null;
  }

  const pickupVM = viewmodel.getLocationInputViewModelByCityCode(
    viewmodel.i.tempPickupCityCode.value,
    viewmodel.i.tempPickupLocationId,
  );
  const returnVM = viewmodel.getLocationInputViewModelByCityCode(
    viewmodel.i.tempReturnCityCode.value,
    viewmodel.i.tempReturnLocationId,
  );

  return (
    <div
      className={css`
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(34, 34, 34, 0.2);
      `}
    >
      <div className={css`
          width: 100%;
          height: ${lastStep.type === '0_none_selected' ? 'auto' : '100%'};
          border-radius: 8px 8px 0 0;

          background-color: #fff;
          position: fixed;
          bottom: 0;
        `}
      >
        {lastStep.type === '0_none_selected' && (
        <div
          className={css`
            padding: 24px 16px; 
            width: 100%;
          `}
          ref={lastLayerRef}
        >
          <MRTCarListingSearchBoxMobileRoot viewmodel={viewmodel} />
        </div>
        )}
        {lastStep.type === '1_rental_city' && (
          <>
            <MRTSearchHeaderMobile
              stepManager={viewmodel.stepManager}
              leftCornerBtnType="close"
              rootStep={rootStep}
            />
            <MRTSelectPickupCity
              key={`step_${lastStep.type}`}
              viewmodel={viewmodel}
              step={lastStep}
              pickupCityInputRef={pickupCityInputRef}
            />
          </>

        )}
        {lastStep.type === '2_rental_office' && (
          <>
            <MRTSearchHeaderMobile
              stepManager={viewmodel.stepManager}
              leftCornerBtnType="prevStack"
              rootStep={rootStep}
            />
            <MRTSelectPickupOfficeMobile
              key={`step_${lastStep.type}`}
              viewmodel={viewmodel}
              officeViewmodel={pickupVM}
              step={lastStep}
              pickupCityInputRef={pickupCityInputRef}
            />
          </>
        )}
        {lastStep.type === '2_5_selected_rental_office' && (
          <>
            <MRTSearchHeaderMobile
              stepManager={viewmodel.stepManager}
              leftCornerBtnType="prevStack"
              rootStep={rootStep}
            />
            <MRTSelectedPickupOfficeInfoMobile
              key={`step_${lastStep.type}`}
              officeViewmodel={pickupVM}
              viewmodel={viewmodel}
              step={lastStep}
              pickupCityInputRef={pickupCityInputRef}
              rootStep={rootStep}
            />
          </>
        )}
        {lastStep.type === '3_schedule' && (
          <>
            <MRTSearchHeaderMobile stepManager={viewmodel.stepManager} rootStep={rootStep} />
            <MRTSelectScheduleMobile
              key={`step_${lastStep.type}`}
              viewmodel={viewmodel}
            />
          </>
        )}
        {lastStep.type === '5_return_city' && (
          <>
            <MRTSearchHeaderMobile
              stepManager={viewmodel.stepManager}
              leftCornerBtnType="close"
              rootStep={rootStep}
            />
            <MRTSelectReturnCity
              key={`step_${lastStep.type}`}
              viewmodel={viewmodel}
              step={lastStep}
            />
          </>
        )}
        {lastStep.type === '6_return_office' && (
          <>
            <MRTSearchHeaderMobile
              stepManager={viewmodel.stepManager}
              leftCornerBtnType="prevStack"
              rootStep={rootStep}
            />
            <MRTSelectReturnOfficeMobile
              key={`step_${lastStep.type}`}
              viewmodel={viewmodel}
              officeViewmodel={returnVM}
              step={lastStep}
            />
          </>
        )}
        {lastStep.type === '6_5_selected_return_office' && (
          <>
            <MRTSearchHeaderMobile
              stepManager={viewmodel.stepManager}
              leftCornerBtnType="prevStack"
              rootStep={rootStep}
            />
            <MRTSelectedReturnOfficeInfoMobile
              key={`step_${lastStep.type}`}
              viewmodel={viewmodel}
              officeViewmodel={returnVM}
              rootStep={rootStep}
            />
          </>
        )}
      </div>
    </div>
  );
});

export default MRTCarListingSearchBoxMobile;
