export const MYREALTRIP_PRIVACY_POLICY = (
  <div className="table-max:font-light flex flex-col text-default text-primary-gray tablet-max:max-h-[calc(100vh)] tablet-max:leading-[19px]">
    <p className="text-default font-light tablet-max:text-small">
      아이엠에스모빌리티 주식회사(이하 “회사”)는 이용자의개인정보를 중요시하며, 개인정보의 보호와
      관련하여 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 「개인정보보호법」 등 관련 법
      상의 개인정보 보호 규정을 준수하며, 본 개인정보 처리방침을 따릅니다.
      <br />
      <br />본 개인정보 처리방침은 회사의 개인정보 처리와 관련된 정보를 제공하고 이용자가 가진
      권리와(개인정보 자기결정권) 그 권리를 행사할 수 있는 방법에 대해 설명하며, 회사의 서비스 이용
      중 개인정보와 관련하여 문의가 있을 경우 연락할 수 있는 개인정보 보호책임자 및 담당자의
      연락처를 안내합니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      1. 개인정보 수집 항목에 관한 안내
    </h3>
    <p className="mt-2 text-default font-light tablet-max:text-small">
      모든 이용자는 회사가 제공하는 서비스를 이용할 수 있고, 회사의 다양한 서비스를 제공받을 수
      있습니다. 그리고 이때 회사는 다음의 원칙하에 이용자의 개인정보를 수집하고 있습니다.
    </p>
    <table className="mt-3 w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
      <tr>
        <th className="w-[90px] border border-trimo-dark">분류</th>
        <th className="w-[100px] border border-trimo-dark">수집 및 이용 목적</th>
        <th className="border border-trimo-dark">수집 및 이용 항목</th>
      </tr>
      <tr>
        <td rowSpan={2} className="border border-trimo-dark">
          서비스 이용시 수집 되는 정보
        </td>
        <td className="border border-trimo-dark">렌트카 상품 구매</td>
        <td className="border border-trimo-dark">
          예약자 성명(국문/영문), 생년월일, 휴대폰번호, 이메일 주소, 이용 지역 및 일정, 이용
          요금정보 및 추가서비스, 픽업/샌딩 인원 수, 이용 항공사 및 항공편명, 입국일자, 항공사
          회원번호, 업체 회원번호(Hertz Gold회원, Dollar 익스프레스 멤버십 번호 등)
        </td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">고객센터 문의</td>
        <td className="border border-trimo-dark">
          예약자 성명(국문/영문), 이메일, 휴대폰번호, 예약번호
        </td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">결제 서비스 이용시 수집되는 정보</td>
        <td className="border border-trimo-dark">결제 및 요금 정산</td>
        <td className="border border-trimo-dark">신용카드 결제 시 : 카드사, 카드번호, 할부 개월</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">서비스 이용시 자동 수집되는 정보</td>
        <td className="border border-trimo-dark">원활한 서비스 이용</td>
        <td className="border border-trimo-dark">
          IP Address, 쿠키, 방문 일시, 서비스 이용기록, 이용자의 사용 OS, 기기 고유번호, 서비스
          이용기록, 불만 및 분쟁처리 기록
        </td>
      </tr>
    </table>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      2. 개인정보의 제공
    </h3>
    <p className="mt-2 text-default font-light tablet-max:text-small">
      이용자의 개인정보를 ‘개인정보 수집 항목에 관한 안내’에서 고지한 범위 내에서 사용하며, 동
      범위를 초과하여 타인에게 제공하지 않습니다. 다만 양질의 서비스 제공을 위해 상품 예약 및 구매
      단계에서 필요한 정보를 이용자에게 동의를 구한 후 제3자에게 제공합니다.
    </p>
    <table className="mt-3 w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
      <tr>
        <th className="w-[90px] border border-trimo-dark">제공받는 자</th>
        <th className="w-[100px] border border-trimo-dark">제공 목적</th>
        <th className="border border-trimo-dark">제공 정보</th>
      </tr>
      <tr>
        <td className="border border-trimo-dark">마이리얼트립</td>
        <td className="border border-trimo-dark">해외 렌트카 예약 목적</td>
        <td className="border border-trimo-dark">
          예약자 성명(국문/영문), 생년월일, 휴대폰번호, 이메일 주소, 이용 지역 및 일정, 이용
          요금정보 및 추가서비스, 픽업/샌딩 인원 수, 이용 항공사 및 항공편명, 입국일자, 항공사
          회원번호, 업체 회원번호(Hertz Gold회원, Dollar 익스프레스 멤버십 번호 등)
        </td>
      </tr>
    </table>
    <h4 className="mt-3 text-default font-light tablet-max:mt-4 tablet-max:text-small">
      2.1 동의 거부권 등에 대한 고지
    </h4>
    <p className="mt-2 text-default font-light tablet-max:text-small">
      개인정보 제공은 서비스 이용을 위해 꼭 필요합니다. 개인정보 제공을 거부하실 수 있으나, 이 경우
      서비스 이용이 제한될 수 있습니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      3. 개인정보 이용 목적
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 이용자의 소중한 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는
      사전에 이용자의 동의를 구하는 등 필요한 조치를 취하도록 합니다.
      <br />
      i. 기기의 고유한 번호로 이용자를 식별하고, 각종 고지나 통지, 고충처리의 목적 및 불량 이용자의
      부정한 이용을 방지하기 위하여 사용합니다.
      <br />
      ii. 이용자에게 회사의 다양한 서비스를 제공하고 서비스 이용 과정에서 이용자의 문의 사항이나
      불만을 처리하고 공지사항 등을 전달하기 위해 사용합니다.
      <br />
      iii. 이용자와 약속한 서비스를 제공하고 유료 서비스 구매 및 이용이 이루어지는 경우 이에 따른
      요금 정산을 위해 사용됩니다.
      <br />
      iv. 신규 서비스가 개발되거나 이벤트 행사 시 참여 기회를 알리기 위한 정보 전달 및 마케팅, 광고
      등에도 사용됩니다.
      <br />
      v. 이용자의 이용 패턴과 접속 빈도 분석 및 서비스 이용에 대한 통계 및 이를 통한 맞춤형 서비스
      제공 및 서비스 개선에도 사용됩니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      4. 개인정보 보유 및 기간
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 이용자의 개인정보를 서비스 이용 시작일부터 서비스를 제공하는 기간 동안에만 제한적으로
      이용하고 있습니다. 이용자가 개인정보 삭제를 요청하거나 제공한 개인정보의 수집 및 이용에 대한
      동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당
      이용자의 개인정보는 지체 없이 파기됩니다.
      <br />
      <br />
      단, 관계 법령에 의해 보존할 필요가 있는 경우, 회사는 아래와 같이 관계 법령에서 정한 일정한
      기간동안 이용자 정보를 보관합니다.
    </p>
    <table className="mt-3 w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
      <tr>
        <th className="border border-trimo-dark">보관내용</th>
        <th className="border border-trimo-dark">근거 법령</th>
        <th className="border border-trimo-dark">보관 기간</th>
      </tr>
      <tr>
        <td className="border border-trimo-dark">소비자 불만 및 분쟁처리에 관한 기록</td>
        <td className="border border-trimo-dark">전자상거래 등에서의 소비자보호에 관한 법률</td>
        <td className="border border-trimo-dark text-center">3년</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">대금결제 및 재화 등의 공급에 관한 기록</td>
        <td className="border border-trimo-dark">
          전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조
        </td>
        <td className="border border-trimo-dark text-center">5년</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">웹사이트 방문에 관한 기록</td>
        <td className="border border-trimo-dark">통신비밀보호법 제15조의2 및 시행령 제41조</td>
        <td className="border border-trimo-dark text-center">3개월</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">부정거래기록</td>
        <td className="border border-trimo-dark">부정거래의 배제 등 회사 방침에 의한 보존</td>
        <td className="border border-trimo-dark text-center">5년</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">
          이용자 생년월일, 휴대폰 번호, 이메일주소, 카카오톡 ID
        </td>
        <td className="border border-trimo-dark">
          부정거래의 배제 등 회사 방침에 의한 보존, 정보주체 동의
        </td>
        <td className="border border-trimo-dark text-center">5년</td>
      </tr>
    </table>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      5. 개인정보 처리 위탁
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회회사는 서비스의 제공, 서비스의 유지/관리, 고객 편의 제공 등 서비스 향상을 위하여 아래와 같이
      개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록
      필요한 사항을 규정하고 있습니다.
    </p>
    <table className="mt-2 w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
      <tr>
        <th className="border border-trimo-dark">구분</th>
        <th className="border border-trimo-dark">위탁자</th>
        <th className="border border-trimo-dark">위탁 업무</th>
      </tr>
      <tr>
        <td rowSpan={4} className="border border-trimo-dark">
          결제처리
        </td>
        <td className="border border-trimo-dark">기업은행</td>
        <td className="border border-trimo-dark">가상계좌 결제 및 환불처리</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">(주)헥토파이낸셜</td>
        <td className="border border-trimo-dark">신용카드 결제수단 제공 및 환불처리</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">(주)KG이니시스</td>
        <td className="border border-trimo-dark">신용카드 결제수단 제공 및 환불처리</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">아임포트</td>
        <td className="border border-trimo-dark">신용카드 결제수단 제공 및 환불처리</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">네이버파이낸셜</td>
        <td className="border border-trimo-dark">네이버페이 결제</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">데이터 보관</td>
        <td className="border border-trimo-dark">Amazon Web Services Inc.</td>
        <td className="border border-trimo-dark">데이터 보관</td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">데이터 처리</td>
        <td className="border border-trimo-dark">Google</td>
        <td className="border border-trimo-dark">
          이메일, 내부 커뮤니케이션 및 협업 도구, 데이터 보관
        </td>
      </tr>
      <tr>
        <td className="border border-trimo-dark">알림 발송</td>
        <td className="border border-trimo-dark">CoolSMS</td>
        <td className="border border-trimo-dark">알림톡 서비스 제공</td>
      </tr>
    </table>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      6. 개인정보 파기
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우를 제외하고는 이용자의 개인정보에
      대해 “개인정보의 수집·이용 목적이 달성”된 후 및 개인정보 보유기간이 경과된 후에는 해당 정보를
      재생할 수 없는 기술적인 방법을 이용하여 완전하게 삭제하며, 종이로 출력된 개인정보는 분쇄하거나
      소각하여 지체 없이 파기함을 약속합니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      7. 이용자의 권리
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 서비스 이용에 대해, 이용자의 권리를 다음과 같이 보호하고 있습니다.
      <br />
      i. 언제든지 예약 조회 등의 방법을 통하여 등록되어 있는 자신의 개인정보를 조회할 수 있으며,
      변경 또는 삭제를 요청할 수 있습니다. 다만, 그러한 경우 해당 서비스의 일부 또는 전부 이용이
      어려울 수 있습니다.
      <br />
      ii. 언제든지 개인정보 제공에 관한 동의 철회를 요청할 수 있습니다.
      <br />
      iii. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당
      개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
      정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
      <br />
      iv. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 &quot;개인정보의
      보유 및 이용기간&quot; 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
      처리하고 있습니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      8. 인터넷접속정보 파일 설정 및 이용자 분석
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 이용자 개개인에게 특화된 맞춤 서비스를 제공하기 위해 서비스 이용과정에서 자동으로
      생성하는 정보나 개인식별이 불가능한 기기 정보를 수집하고 저장할 수 있습니다.
    </p>
    <h4 className="mt-3 text-default font-light tablet-max:mt-4 tablet-max:text-small">8.1 쿠키</h4>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      쿠키(Cookie)는 웹사이트를 운영하는데 이용되는 서버가 이용자의 디바이스에 보내는 작은 텍스트
      파일로 이용자들의 디바이스 내에 저장되기도 합니다. 쿠키가 저장된 이후 다시 웹사이트를 방문할
      경우 웹사이트는 이용자의 디바이스를 인식하여 지난 설정과 과거 이용내역을 자동으로 불러옵니다.
      이용자는 쿠키에 대한 선택권을 가지고 있으며, 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
      허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
      있습니다. 현재 사용하는 브라우저의 쿠키 설정 확인 및 변경은 아래와 같은 방법을 통해
      가능합니다.
      <br />
      <br />
      i. 인터넷 익스플로러의 경우 : 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
      <br />
      ii. 구글 크롬의 경우 : 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보
      <br />
      <br />
    </p>
    <h4 className="mt-3 text-default font-light tablet-max:mt-4 tablet-max:text-small">
      8.2 이용자 분석
    </h4>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      온라인 행태정보 기반의 이용자 분석은 ‘쿠키(Cookie)’ 또는 ‘광고식별자(ADID/IDFA)’를 기반으로 웹
      사이트 방문 이력, 구매 및 검색 이력 등 이용자의 관심, 흥미, 기호 및 성향 등(이하, 행태정보)을
      파악하고 분석할 수 있습니다.
    </p>

    <table className="mt-3 w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
      <tr>
        <th className="border border-trimo-dark">제공받는 자</th>
        <th className="border border-trimo-dark">제공 항목</th>
        <th className="border border-trimo-dark">제공받는 자의 이용목적</th>
      </tr>
      <tr>
        <td className="border border-trimo-dark">구글</td>
        <td className="border border-trimo-dark">
          이용자의 웹사이트 방문이력, 이용자의 검색 이력, 광고식별자
        </td>
        <td className="border border-trimo-dark">이용자 행태정보 기반의 맞춤형 서비스 제공</td>
      </tr>
    </table>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      9. 링크 사이트에 대한 책임
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 다만, 링크되어 있는
      웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 &quot;개인정보처리방침&quot;이 적용되지
      않습니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      10. 개인정보의 기술적/관리적 보호 대책
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지
      않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
    </p>
    <h4 className="mt-3 text-default font-light tablet-max:mt-4 tablet-max:text-small">
      10.1 해킹 등에 대비한 대책
    </h4>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기
      위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
      백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고
      있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타
      시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
    </p>
    <h4 className="mt-3 text-default font-light tablet-max:mt-4 tablet-max:text-small">
      10.2 개인정보 처리 담당 직원의 최소화 및 교육
    </h4>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      회사의 개인정보관련 처리 담당 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를
      부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의
      준수를 항상 강조하고 있습니다.
    </p>
    <h4 className="mt-3 text-default font-light tablet-max:mt-4 tablet-max:text-small">
      10.3 개인정보보호 전담기구의 운영
    </h4>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      사내 개인정보보호 전담기구 등을 통하여 개인정보처리방침의 이행사항 및 담당자의 준수여부를
      확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자
      본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해
      회사는 일체의 책임을 지지 않습니다.
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      11. 개인정보 관리 책임자 및 담당부서
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      책임자: 김수환 이사
      <br />
      연락처: 02-548-8348
      <br />
      이메일: kimsw@imsmobility.co.kr
      <br />
      <br />
      기타 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우 또는 개인정보보호법에 따른
      개인정보의 열람청구가 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
      <br />
      개인정보분쟁조정위원회: (국번없이) 1833-6972 (https://www.kopico.go.kr)
      <br />
      개인정보침해 신고센터: (국번없이) 118 (https://privacy.kisa.or.kr)
      <br />
      대검찰청 사이버수사과: (국번없이) 1301 (https://www.spo.go.kr)
      <br />
      경찰청 사이버안전국: (국번없이) 182 (https://cyberbureau.police.go.kr)
    </p>
    <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
      12. 고지의 의무
    </h3>
    <p className="mt-3 text-default font-light tablet-max:text-small">
      개인정보 처리방침을 변경하는 경우, 이용자가 언제든지 변경된 사항을 볼 수 있도록
      조치하겠습니다.
    </p>
    <p className="mt-3 text-left text-default font-light tablet-max:text-small">
      개인정보처리방침 버전번호: 1.3
      <br />
      개인정보처리방침 시행일자: 2023년 12월 18일
    </p>

    <div className="pb-6" />
  </div>
);
