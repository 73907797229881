import { css, cx } from '@emotion/css';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';

export const popularCityDefaultStyles = {
  desktop: {
    rootWrapper: css`
      background-color: ${COLORS2.main.MrtWhite};
      margin-top: ${SPACING.spacing24};
    `,
    gridWrapper: cx(
      font.Headline1_Bold,
      css`
        margin-top: 16px;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(5, minmax(0, 1fr));

        color: ${COLORS2.sub.MrtGray1};`,
    ),
    cityButton: cx(
      css`
        padding: 18px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${COLORS2.sub.MrtGray3};
        border-radius: 5px;
        &:hover {
          background-color: ${COLORS2.main.MrtBlue1};
          cursor: pointer;
          color: ${COLORS2.main.MrtWhite};
        }
      `,
    ),
    title: font.Title3,
  },
  mobile: {
    rootWrapper: css`
      margin-top: 12px;
      padding: 12px ${SPACING.spacing16};
      box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    `,
    gridwrapper: cx(
      font.Caption1_Light,
      css`
        margin-top: 16px;
        display: grid;
        gap: 12px 10px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      `,
    ),
    cityButton: css`
      padding: 18px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${COLORS2.sub.MrtGray3};
      border-radius: 5px;
      text-align: center;
      font-weight: 500;
    `,
    title: font.Caption1_Bold,
  },
} as const;
