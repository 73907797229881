import { useLocation } from 'react-router';
import ReservationResultOnlineTravel from './ReservationResult.OnlineTravel';
import ReservationResultHertz from './ReservationResult.Hertz';

const Result = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const uid = query.get('uid');
  const resId = query.get('id');
  const temp_ppd_flag = query.get('temp_ppd_flag') === 'true' ? true : false;

  // 선불
  if (uid) {
    const PPDResData = JSON.parse(sessionStorage.getItem(uid) || '{}');

    // sessionStorage에 저장된 예약데이터에 예약번호 추가
    sessionStorage.setItem(
      uid,
      JSON.stringify({
        ...PPDResData,
        res_id: resId,
        temp_ppd_flag,
        paymentStatus: 'success',
      }),
    );
    return <ReservationResultHertz reservationData={PPDResData} />;
  } else {
    // 후불
    const PODResData = JSON.parse(sessionStorage.getItem('_BOOKING_') || {});

    if (PODResData.bookingInfo?.supplier_info?.name === 'OT') {
      return <ReservationResultOnlineTravel reservationData={{ ...PODResData, temp_ppd_flag }} />;
    } else {
      return <ReservationResultHertz reservationData={{ ...PODResData, temp_ppd_flag }} />;
    }
  }
};

export default Result;
