import { ValueSerializer } from 'type-route';

import { SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { BirthdayType, DatetimeWithoutTimezone } from '^/types/__BrandedDateTimeTypes';
import {
  CityCodeType,
  CityNameType,
  LocationCategoryNameType,
  LocationCodeTypeRel_OAG,
  LocationNameType,
  RentalCarOfficeId,
} from '^/types/__BrandedLocationTypes';
import { ReservationIDType, UserLastNameType, VendorCompanyIDType } from '^/types/__BrandedTypes';
import { FreshnessType } from '^/types/__ResultType';

export const FreshnessParam: ValueSerializer<FreshnessType> = {
  parse(raw) {
    return Number(raw) as FreshnessType;
  },
  stringify(value) {
    return String(value);
  },
};

export const CityCodeParam: ValueSerializer<CityCodeType> = {
  parse(raw) {
    return raw as CityCodeType;
  },
  stringify(value) {
    return value;
  },
};

export const CityNameParam: ValueSerializer<CityNameType> = {
  parse(raw) {
    return raw as CityNameType;
  },
  stringify(value) {
    return value;
  },
};

// eslint-disable-next-line max-len
export const LocationCategoryNameParam: ValueSerializer<LocationCategoryNameType> = {
  parse(raw) {
    return raw as LocationCategoryNameType;
  },
  stringify(value) {
    return value;
  },
};
export const LocationParamRelOAG: ValueSerializer<LocationCodeTypeRel_OAG> = {
  parse(raw) {
    return raw as LocationCodeTypeRel_OAG;
  },
  stringify(value) {
    return value;
  },
};

export const LocationNameParam: ValueSerializer<LocationNameType> = {
  parse(raw) {
    return raw as LocationNameType;
  },
  stringify(value) {
    return value;
  },
};

export const LocationIdParam: ValueSerializer<RentalCarOfficeId> = {
  parse(raw) {
    return raw as RentalCarOfficeId;
  },
  stringify(value) {
    return value;
  },
};

// FIXME: Date might be wrong type...
export const DatetimeNoTimezoneParam: ValueSerializer<DatetimeWithoutTimezone> = {
  parse(raw) {
    // FIXME: might be wrong...
    return raw as DatetimeWithoutTimezone;
  },
  stringify(value) {
    // FIXME: might be wrong...
    return value;
  },
};

export const BirthdayTypeParam: ValueSerializer<BirthdayType> = {
  parse(raw) {
    return raw as BirthdayType;
  },
  stringify(value) {
    return value;
  },
};

export const CarSearchBoxStepParams: ValueSerializer<SearchBoxSteps[]> = {
  parse(raw) {
    try {
      return JSON.parse(raw) as SearchBoxSteps[];
    } catch (e) {
      return [];
    }
    // const parsed = SearchBoxSteps.safeParse(raw);
    // if (parsed.success) {
    //   return parsed.data;
    // }
    // console.error(parsed.error);
    // throw new Error(parsed.error.message);
  },
  stringify(value) {
    return JSON.stringify(value);
  },
};

export const ReservationIDTypeParam: ValueSerializer<ReservationIDType> = {
  parse(raw) {
    return raw as ReservationIDType;
  },
  stringify(value) {
    return value;
  },
};

export const UserLastNameTypeParam: ValueSerializer<UserLastNameType> = {
  parse(raw) {
    return raw as UserLastNameType;
  },
  stringify(value) {
    return value;
  },
};

export const VendorCompanyIdTypeParam: ValueSerializer<VendorCompanyIDType> = {
  parse(raw) {
    return raw as VendorCompanyIDType;
  },
  stringify(value) {
    return value;
  },
};
