/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import {
  borders,
  MARGINS,
  PADDINGS,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
  COLORS,
} from '^/constants/commonStyles';

import { translateServices } from '../../common/translateServices';
import { changeToUpperCaseOfWordOnlyFistAlpahbet, deleteGrade } from '../../common/utils';
import { ListingPage2CardViewModel } from '../viewmodel/ListingPage2.card.viewmodel';

export const ListingPage2MobileListGroupBottomSheetHeaderArea: React.FC<{
  item: ListingPage2CardViewModel;
}> = ({ item }) => {
  const { t } = useTranslation();
  const { data } = item;
  const convertedModelName =
    data.modelName && deleteGrade(changeToUpperCaseOfWordOnlyFistAlpahbet(data.modelName));

  const carSize = data.vehType.size;
  const carType = data.vehType.type;
  return (
    <div
      id="ListingPageMobileListGroupBottomSheet_HeaderAreaRoot"
      className={[
        PADDINGS.LEFT_AND_RIGHT.spacing24,
        PADDINGS.BOTTOM.spacing16,
        PADDINGS.TOP.spacing6,
        // PADDINGS.TOP_AND_BOTTOM.spacing16,
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <div
        className={[
          css`
            border-radius: 2px;
            height: 4px;
            background-color: #cecece;
            width: 60px;
            margin-left: auto;
            margin-right: auto;
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      />
      <h2
        className={[
          font2.Headline1_Bold,
          MARGINS.TOP.spacing16,
          css`
            word-break: keep-all;
          `,
        ].join(' ')}
      >
        {`[${t(carSize)}] ${convertedModelName} ${data.orSimilarCar ? '또는 동급차량' : ''}`}
      </h2>
      <div
        className={[
          css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          `,
        ].join(' ')}
      >
        <div>
          <h4 className={cx(font2.Caption2_Light, COLORS2_AS_FOREGROUND.sub.MrtGray1)}>
            {translateServices(data.services, t)}
          </h4>
          {/* Badges */}
          {data.badges && (
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 5px;
                margin-top: 11px;
              `}
            >
              {data.badges?.map((badge) => (
                <span
                  className={css`
                    border-radius: 3px;
                    background-color: ${COLORS.BLUE_200};
                    font-size: 12px;
                    color: ${COLORS.BLUE_500};
                    padding: 0px 6px;
                  `}
                >
                  {badge}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
