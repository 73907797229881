import { z } from 'zod';

import { FULL_VEHCLASS_ZOD } from '^/constants/envConfig';
import {
  FreePermissionType,
  PaymentTypes,
  PriceCodeType,
  VendorCompanyIDType,
  VendorCompanyNameType,
} from '^/types/__BrandedTypes';
import { NumberOrNumberString } from '^/util/zod/numberFromString';

export const RawPODInCarListAPI = z.object({
  payment: z.object({
    type: z.literal('POD'),
    coupon: z.boolean(),
    foreignCurrencyCode: z.string(),
    foreignCurrency: NumberOrNumberString,
    domesticPrice: z.number(),
    price_code: PriceCodeType,
    discountDomestic: z.number().int(),
    discountForeign: z.number().int(),
    isDiscounted: z.boolean(),
  }),
  freePermission: FreePermissionType,
});

export const RawPPDInCarListAPI = z.object({
  payment: z.object({
    type: z.literal('PPD'),
    coupon: z.boolean(),
    foreignCurrencyCode: z.string(),
    foreignCurrency: z.number(),
    domesticPrice: z.number(),
    price_code: PriceCodeType,
    discountDomestic: z.number().int(),
    discountForeign: z.number().int(),
    isDiscounted: z.boolean(),
  }),
  freePermission: FreePermissionType,
});

export const RawCarModelName = z.string().brand<'CarModelName'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RawCarModelName = z.infer<typeof RawCarModelName>;
export const RawCoreInCarListAPI = z.object({
  id: z.string(),
  rentingInfo: z.object({
    pickup: z.object({ location: z.string(), datetime: z.string() }),
    return: z.object({ location: z.string(), datetime: z.string() }),
  }),
  modelName: RawCarModelName,
  modelCode: z.string(),
  codeContext: z.string().or(z.null()),
  image: z.string(),
  company: z.object({
    id: VendorCompanyIDType,
    name: VendorCompanyNameType,
  }),
  services: z.array(
    z.object({
      name: z.string(),
      shortName: z.string(),
      iconName: z.string(),
      info: z.string().optional(),
    }),
  ),
  vehType: z.object({
    idType: z.string().or(z.null()),
    type: z.string(),
    idSize: z.string().or(z.null()),
    size: FULL_VEHCLASS_ZOD,
  }),
  destination: z.string(),
  paymentTypes: z.array(PaymentTypes),
  prices: z.array(z.union([z.string(), z.number()])),
  badges: z.array(z.string()),
});

export const RawGetCarListSuccessBodyItem = z
  .object({
    core: RawCoreInCarListAPI,
  })
  .merge(
    z.object({
      POD: RawPODInCarListAPI,
      PPD: RawPPDInCarListAPI,
    }).partial(),
  );

// eslint-disable-next-line max-len, @typescript-eslint/no-redeclare
export type RawGetCarListSuccessBodyItem = z.infer<typeof RawGetCarListSuccessBodyItem>;
