import { ReactNode } from 'react';

import { css } from '@emotion/css';
import { Listbox } from '@headlessui/react';

import { MobxInputValue } from '^/util/MobxInputValue';

import {
  DefaultTypedSelectStyles,
  TypedSelectClassNameOverrides,
} from './TypedSelect.styles';

export function TypedSelect<
  TItem, TList extends TItem[] = TItem[],
>(p: {
  selected: MobxInputValue<TItem>;
  renderSelected: (selected: TItem) => ReactNode;
  list: Readonly<TList>,
  toId: (item: TItem) => string;
  renderItem: (item: TItem, selected: boolean) => ReactNode;
  isDisabledItem?: (item: TItem) => boolean;
  classNameOverrides?: TypedSelectClassNameOverrides;
}) {
  return (
    <Listbox
      as="div"
      id="TypedSelect_root"
      className={
        p.classNameOverrides?.root
        || DefaultTypedSelectStyles.root
      }
      value={p.selected.value}
      onChange={p.selected.set}
    >
      <Listbox.Button
        id="TypedSelect_button"
        className={
          p.classNameOverrides?.button
          || DefaultTypedSelectStyles.button
        }
      >
        {p.renderSelected(p.selected.value)}
      </Listbox.Button>
      <Listbox.Options
        id="TypedSelect_optionsList"
        className={
          p.classNameOverrides?.optionsList
          || DefaultTypedSelectStyles.optionsList
        }
      >
        {p.list.map((item) => (
          <Listbox.Option
            className={
              p.classNameOverrides?.optionItem
              || DefaultTypedSelectStyles.optionItem
            }
            key={p.toId(item)}
            value={item}
            disabled={p.isDisabledItem && p.isDisabledItem(item)}
          >
            {p.renderItem(item, item === p.selected.value)}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
