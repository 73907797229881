/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { Ref } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { RentalCarOfficeLocationType } from '^/AppContext/RentalOfficeLocationRepo';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { CalendarDayViewModel } from '^/components/Calendar/BasicCalendar/components/Day/Calendar.Day.viewModel';
import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import { COLORS2 } from '^/constants/commonStyles';
import { hoverDisabledDevice } from '^/util/parsedUserAgent';

import { styles, getClassNameByState } from './Calendar.Day.styles';

export const CalendarDay: React.FC<{
  elemRef?: Ref<HTMLDivElement>;
  monthInSelectionRange: boolean;
  pickupLocation: RentalCarOfficeLocationType;
  returnLocation: RentalCarOfficeLocationType;
  viewmodel: CalendarDayViewModel;
  stateViewModel: CalendarStateViewModel;
  hasDifferentPickupReturnLocations: CarListingSearchBoxInputViewModel['hasDifferentPickupReturnLocations'];
}> = observer(function CalendarDay({
  elemRef,
  monthInSelectionRange,
  pickupLocation,
  returnLocation,
  viewmodel,
  stateViewModel,
  hasDifferentPickupReturnLocations,
}) {
  const isToday = stateViewModel.today.str === viewmodel.str;
  const SUN_DAY = 0 as const;
  const MON_DAY = 1 as const;
  const viewType = stateViewModel.getViewType(viewmodel.str);
  const officeViewType = stateViewModel.getOfficeViewType(
    viewmodel,
    pickupLocation,
    returnLocation,
    hasDifferentPickupReturnLocations,
  );
  const selectionStartBackgroundDirection = hoverDisabledDevice
    ? null
    : stateViewModel.getSelectionStartBackgroundDirection(viewmodel.str);

  const selectionEndBackgroundDirection = hoverDisabledDevice
    ? null
    : stateViewModel.getSelectionEndBackgroundDirection(viewmodel.str);

  const isInSelectionRange = monthInSelectionRange && stateViewModel.isInSelectionRange(viewmodel.str);
  const isBetweenRange = monthInSelectionRange && stateViewModel.isBetweenRange(viewmodel.str);
  const isInHoverRange = hoverDisabledDevice ? null : stateViewModel.isInHoverRange(viewmodel.str);
  return (
    <>
      {/* <pre>{JSON.stringify({
        viewType,
        selectionStartBackgroundDirection,
      }, null, 2)}
      </pre> */}
      <div
        ref={elemRef}
        tabIndex={-1}
        className={[
          styles.calendarDayRootWrapper,
          // borders.DEBUG_greenBorderDashed,
          viewType === 'start'
          && stateViewModel.selection.end?.str
          && [
            styles.inSelectionBackground,
            styles.leftBorderRadius50,
            (viewmodel.dayOfWeek === SUN_DAY || viewmodel.isLastDateOfMonth)
            && styles.rightBorderRadius50,
          ].join(' '),
          viewType === 'end'
          && viewmodel.dayOfWeek !== MON_DAY
          && !viewmodel.isFirstDateOfMonth
          && cx(styles.inSelectionBackground, styles.rightBorderRadius50),
          selectionStartBackgroundDirection === 'right'
          && cx(
            styles.inSelectionBackground,
            styles.leftBorderRadius50,
            (viewmodel.dayOfWeek === SUN_DAY || viewmodel.isLastDateOfMonth)
            && styles.rightBorderRadius50,
          ),
          selectionStartBackgroundDirection === 'left'
          && cx(styles.inSelectionBackground, styles.rightBorderRadius50, styles.leftBorderRadius50),
          selectionEndBackgroundDirection === 'left'
          && !viewmodel.isFirstDateOfMonth
          && cx(
            styles.inSelectionBackground,
            styles.rightBorderRadius50,
            viewmodel.dayOfWeek === MON_DAY && styles.leftBorderRadius50,
          ),
          viewType === 'disabled' && styles.disabled,
        ].join(' ')}
      >
        <div
          className={[
            css`
              padding-bottom: 100%;
              width: 100%;
            `,
            !hoverDisabledDevice
            && css`
                &:hover {
                  background-color: ${COLORS2.main.MrtBlue1};
                  border-radius: 50%;
                  color: ${COLORS2.main.MrtWhite};
                  cursor: pointer;
                }
              `,
            // borders.DEBUG_blueBorderDashed,
          ].join(' ')}
        >
          <div
            className={[
              styles.calendarDayContentWrapper,
              isToday && styles.today,
              getClassNameByState(viewType),
              (isInSelectionRange || isInHoverRange)
              && viewmodel.dayOfWeek === SUN_DAY
              && styles.rightBorderRadius50,
              (isInSelectionRange || isInHoverRange)
              && viewmodel.dayOfWeek === MON_DAY
              && styles.leftBorderRadius50,
              (isInSelectionRange || isInHoverRange)
              && viewmodel.isFirstDateOfMonth
              && viewmodel.isWeekDay
              && styles.leftBorderRadius50,
              (isInSelectionRange || isInHoverRange)
              && viewmodel.isFirstDateOfMonth
              && viewmodel.dayOfWeek === SUN_DAY
              && cx(styles.leftBorderRadius50, styles.rightBorderRadius50),
              (isInSelectionRange || isInHoverRange)
              && viewmodel.isLastDateOfMonth
              && viewmodel.isWeekDay
              && styles.rightBorderRadius50,
              (isInSelectionRange || isInHoverRange)
              && viewmodel.isLastDateOfMonth
              && viewmodel.dayOfWeek === MON_DAY
              && cx(styles.rightBorderRadius50, styles.rightBorderRadius50),
              officeViewType === 'off' && styles.disabled,
              isBetweenRange && (viewmodel.dayOfWeek === SUN_DAY || viewmodel.isLastDateOfMonth)
              && styles.rightBorderRadius0,
              isBetweenRange && (viewmodel.dayOfWeek === MON_DAY || viewmodel.isFirstDateOfMonth)
              && styles.leftBorderRadius0,
              isToday && (viewType === 'start')
              && css`background-color: ${COLORS2.main.MrtBlue1} !important; color: ${COLORS2.main.MrtWhite} !important;`,

              // borders.DEBUG_orangeBorderDashed,
            ].join(' ')}
            onClick={() => stateViewModel.pushSelectionDay(viewmodel)}
            onMouseOver={
              (!hoverDisabledDevice || undefined) && (() => stateViewModel.onHover(viewmodel))
            }
          >
            {viewmodel.day}
          </div>
        </div>
      </div>
    </>
  );
});
