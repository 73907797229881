import { css } from '@emotion/css';
import { useQuery } from 'react-query';

import { getShopGeocodeOT } from '^/api/reservation';
import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import Button from '^/components/Button';
import Map from '^/components/Map';
import Pin from '^/components/Map/Pin';
import { COLORS2_AS_BACKGROUND, font2 } from '^/constants/commonStyles';

const ShopMap = ({ data }) => {
  const {
    data: shopData,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['getShopGeocode', data.schedule_info.pickup.shop_code, data.plan_info.brand_code],
    async () => {
      const response = await getShopGeocodeOT(
        data.schedule_info.pickup.shop_code,
        data.plan_info.brand_code,
      );
      return response.data;
    },
    {
      retry: 1,
      refetchOnMount: false,
      enabled: !!data.schedule_info.pickup?.shop_code || !!data.plan_info?.brand_code,
    },
  );
  const LoadingForGoogleMap = (
    <BlueLoadingSpinner
      classOverrides={{
        root: css`
          width: 100%;
          height: 250px;
          ${COLORS2_AS_BACKGROUND.main.MrtBlue2}
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `,
        spinner: css`
          width: 25px;
          height: 25px;
          margin: 0 auto;
        `,
      }}
    />
  );

  const ErrorForGoogleMap = (
    <div
      className={css`
        width: 100%;
        height: 250px;
        ${COLORS2_AS_BACKGROUND.main.MrtBlue2}
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <p className={font2.Caption1_Light}>지도를 불러오는 도중 오류가 발생했습니다.</p>
      <Button
        color="secondarySkyblue"
        classNames="rounded-trimo-5 py-[8px] mt-2 text-default"
        content="재시도"
        type="button"
        onClick={refetch}
      />
    </div>
  );

  return (
    <div className="mb-4 w-[100%] mobile:mt-[6px]">
      {isLoading && LoadingForGoogleMap}
      {isError && ErrorForGoogleMap}
      {shopData && (
        <Map
          center={{ lat: Number(shopData.lat), lng: Number(shopData.lng) }}
          containerStyle={{ height: '250px', width: '100%' }}
          options={{
            mapTypeControl: false,
          }}
          zoom={17}
        >
          <Pin {...{ lat: Number(shopData.lat), lng: Number(shopData.lng) }} place={{}} />
        </Map>
      )}
    </div>
  );
};

export default ShopMap;
