import { css, cx } from '@emotion/css';

import {
  borders, SPACING, COLORS2, font, SCROLL_BAR,
} from '^/constants/commonStyles';

const suggestionsList = cx(
  css`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;

    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: 5px;
    max-height: 238px;
    overflow-y: scroll;

    background-color: ${COLORS2.main.MrtWhite};
    border-radius: 8px;
    color: ${COLORS2.main.MrtBlack};

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 12px -1px rgba(0, 0, 0, 0.05), 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
  `,
  font.Body1_Regular,
  SCROLL_BAR.hide,
);
export const defaultCitySearchInputStyle = {
  root: cx(
    css`
    `,
    // borders.DEBUG_blueBorderDashed,
  ),
  textInput: css`
    background-color: transparent;
  `,

  suggestionsList,
  suggestionsListItem: css`
    border-bottom: 1px solid #E1E1E1;
    height: ${SPACING.spacing48};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  noCitySuggestion: cx(
    suggestionsList,
    css`
      /* border-bottom: 1px solid ${COLORS2.sub.MrtGray3}; */
      height: ${SPACING.spacing56};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: ${SPACING.spacing16};
      color: ${COLORS2.sub.MrtGray1};
    `,
  ),
  location_en: cx(
    css`
      color: #717171;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    `,
  ),
} as const;

export type MRTCitySearchInputStyle =
  typeof defaultCitySearchInputStyle;
