import itiriri from 'itiriri';

import { AirlineMileageProgramCode } from '^/types/__BrandedTypes';

export const airlineMileageProgram = [
  // {
  //   id: 0,
  //   code: '' as AirlineMileageProgramCode,
  //   name: '항공 마일리지 제휴사',
  // },
  {
    id: 1,
    code: '2Z' as AirlineMileageProgramCode,
    name: 'Amtrak',
  },
  {
    id: 2,
    code: '9W' as AirlineMileageProgramCode,
    name: 'Jet Airways',
  },
  {
    id: 3,
    code: 'A3' as AirlineMileageProgramCode,
    name: 'Aegean Airlines',
  },
  {
    id: 4,
    code: 'AA' as AirlineMileageProgramCode,
    name: 'American Airlines',
  },
  {
    id: 5,
    code: 'AB' as AirlineMileageProgramCode,
    name: 'Air Berlin topbonus',
  },
  {
    id: 6,
    code: 'AC' as AirlineMileageProgramCode,
    name: 'Air Canada Aeroplan',
  },
  {
    id: 7,
    code: 'AF' as AirlineMileageProgramCode,
    name: 'Flying Blue',
  },
  {
    id: 8,
    code: 'AM' as AirlineMileageProgramCode,
    name: 'Aeromexico Club Premier',
  },
  {
    id: 9,
    code: 'AS' as AirlineMileageProgramCode,
    name: 'Alaska Airlines',
  },
  {
    id: 10,
    code: 'AV' as AirlineMileageProgramCode,
    name: 'Avianca - LifeMiles',
  },
  {
    id: 11,
    code: 'AY' as AirlineMileageProgramCode,
    name: 'Finnair',
  },
  {
    id: 12,
    code: 'B6' as AirlineMileageProgramCode,
    name: 'JetBlue TrueBlue',
  },
  {
    id: 13,
    code: 'BI' as AirlineMileageProgramCode,
    name: 'Royal Brunei Airlines',
  },
  {
    id: 14,
    code: 'BR' as AirlineMileageProgramCode,
    name: 'Eva Air',
  },
  {
    id: 15,
    code: 'BW' as AirlineMileageProgramCode,
    name: 'Caribbean Airlines MILES',
  },
  {
    id: 16,
    code: 'CA' as AirlineMileageProgramCode,
    name: 'Air China',
  },
  {
    id: 17,
    code: 'CI' as AirlineMileageProgramCode,
    name: 'China Airlines',
  },
  {
    id: 18,
    code: 'CX' as AirlineMileageProgramCode,
    name: 'Asia Miles',
  },
  {
    id: 19,
    code: 'CZ' as AirlineMileageProgramCode,
    name: 'China Southern Airlines - Sky Pearl Club',
  },
  {
    id: 20,
    code: 'DE' as AirlineMileageProgramCode,
    name: 'DeutschlandCard',
  },
  {
    id: 21,
    code: 'DJ' as AirlineMileageProgramCode,
    name: 'Velocity Frequent Flyer',
  },
  {
    id: 22,
    code: 'DL' as AirlineMileageProgramCode,
    name: 'Delta Air Lines',
  },
  {
    id: 23,
    code: 'EK' as AirlineMileageProgramCode,
    name: 'Emirates',
  },
  {
    id: 24,
    code: 'EY' as AirlineMileageProgramCode,
    name: 'Etihad Guest',
  },
  {
    id: 25,
    code: 'F9' as AirlineMileageProgramCode,
    name: 'Frontier Airlines',
  },
  {
    id: 26,
    code: 'FI' as AirlineMileageProgramCode,
    name: 'Icelandair Vildarkort',
  },
  {
    id: 27,
    code: 'FL' as AirlineMileageProgramCode,
    name: 'AirTran Airways',
  },
  {
    id: 28,
    code: 'GF' as AirlineMileageProgramCode,
    name: 'Gulf Air',
  },
  {
    id: 29,
    code: 'HA' as AirlineMileageProgramCode,
    name: 'Hawaiian Airlines',
  },
  {
    id: 30,
    code: 'HU' as AirlineMileageProgramCode,
    name: 'Hainan Air',
  },
  {
    id: 31,
    code: 'IB' as AirlineMileageProgramCode,
    name: 'Iberia',
  },
  {
    id: 32,
    code: 'JJ' as AirlineMileageProgramCode,
    name: 'TAM Fidelidade',
  },
  {
    id: 33,
    code: 'JL' as AirlineMileageProgramCode,
    name: 'Japan Airlines Mileage Bank',
  },
  {
    id: 34,
    code: 'JM' as AirlineMileageProgramCode,
    name: 'Air Jamaica 7th Heaven',
  },
  {
    id: 35,
    code: 'KE' as AirlineMileageProgramCode,
    name: 'Korean Air Skypass',
  },
  {
    id: 36,
    code: 'LA' as AirlineMileageProgramCode,
    name: 'LANPASS',
  },
  {
    id: 37,
    code: 'LH' as AirlineMileageProgramCode,
    name: 'Miles & More',
  },
  {
    id: 38,
    code: 'LY' as AirlineMileageProgramCode,
    name: 'El Al Airlines',
  },
  {
    id: 39,
    code: 'ME' as AirlineMileageProgramCode,
    name: 'Middle East Airlines',
  },
  {
    id: 40,
    code: 'MH' as AirlineMileageProgramCode,
    name: 'Malaysian Airlines',
  },
  {
    id: 41,
    code: 'MU' as AirlineMileageProgramCode,
    name: 'China Eastern Airlines',
  },
  {
    id: 42,
    code: 'MX' as AirlineMileageProgramCode,
    name: 'Mexicana Frecuenta',
  },
  {
    id: 43,
    code: 'NH' as AirlineMileageProgramCode,
    name: 'ANA Mileage Club',
  },
  {
    id: 44,
    code: 'NK' as AirlineMileageProgramCode,
    name: 'Spirit Airlines',
  },
  {
    id: 45,
    code: 'OK' as AirlineMileageProgramCode,
    name: 'Czech Airlines (CSA)',
  },
  {
    id: 46,
    code: 'OM' as AirlineMileageProgramCode,
    name: 'Oman Air',
  },
  {
    id: 47,
    code: 'OZ' as AirlineMileageProgramCode,
    name: 'Asiana Airlines',
  },
  {
    id: 48,
    code: 'PR' as AirlineMileageProgramCode,
    name: 'Philippine PalSmiles',
  },
  {
    id: 49,
    code: 'QR' as AirlineMileageProgramCode,
    name: 'Qatar Airways',
  },
  {
    id: 50,
    code: 'RJ' as AirlineMileageProgramCode,
    name: 'Royal Jordanian',
  },
  {
    id: 51,
    code: 'S4' as AirlineMileageProgramCode,
    name: 'SATA',
  },
  {
    id: 52,
    code: 'SK' as AirlineMileageProgramCode,
    name: 'Scandinavian Airlines (SAS)',
  },
  {
    id: 53,
    code: 'SP' as AirlineMileageProgramCode,
    name: 'SpanAir',
  },
  {
    id: 54,
    code: 'SQ' as AirlineMileageProgramCode,
    name: 'Singapore KrisFlyer',
  },
  {
    id: 55,
    code: 'SV' as AirlineMileageProgramCode,
    name: 'Saudi Arabian Alfursan',
  },
  {
    id: 56,
    code: 'SY' as AirlineMileageProgramCode,
    name: 'Sun Country Airlines',
  },
  {
    id: 57,
    code: 'TA' as AirlineMileageProgramCode,
    name: 'TACA - LifeMiles',
  },
  {
    id: 58,
    code: 'TE' as AirlineMileageProgramCode,
    name: 'LAL amber miles',
  },
  {
    id: 59,
    code: 'TG' as AirlineMileageProgramCode,
    name: 'Thai Airways',
  },
  {
    id: 60,
    code: 'TK' as AirlineMileageProgramCode,
    name: 'Turkish Airlines',
  },
  {
    id: 61,
    code: 'TP' as AirlineMileageProgramCode,
    name: 'TAP Air Portugal',
  },
  {
    id: 62,
    code: 'UA' as AirlineMileageProgramCode,
    name: 'United Airlines/Copa Airlines',
  },
  {
    id: 63,
    code: 'UN' as AirlineMileageProgramCode,
    name: 'Transaero',
  },
  {
    id: 64,
    code: 'US' as AirlineMileageProgramCode,
    name: 'US Airways',
  },
  {
    id: 65,
    code: 'VR' as AirlineMileageProgramCode,
    name: 'Virgin America Elevate',
  },
  {
    id: 66,
    code: 'VS' as AirlineMileageProgramCode,
    name: 'Virgin Atlantic',
  },
  {
    id: 67,
    code: 'VV' as AirlineMileageProgramCode,
    name: 'AeroSvit Ukrainian Airlines',
  },
  {
    id: 68,
    code: 'WN' as AirlineMileageProgramCode,
    name: 'Southwest Airlines',
  },
] as const;

export const airlineMileageProgramMap = itiriri(airlineMileageProgram).toMap((a) => a.code);
