/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import { DialogWithCloseButton } from '^/commonComponents/ui/DialogWithCloseButton';
import { borders } from '^/constants/commonStyles';
import { wonCurrency } from '^/pages/Booking/components/Bill/constants';
import { PODOnsitePaymentHoverMessage } from '^/pages/ReservationDetail/onsiteInfoMessages';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { parseFloatNumberOrNumberString } from '^/util/zod/numberFromString';

import { AdditionFees } from './AdditionFees';
import * as styles from './ReservationDetailsMobile.bill.styles';

function isPODReservation(data: ReservationDetailed) {
  if (data.pay_type !== 'POD') {
    throw new Error('DummyErrorForTypeFiltering');
  }
  return data;
}

export const ReservationDetailsMobileBillPODArea: React.FC<{
  data: ReturnType<typeof isPODReservation>;
}> = ({ data }) => {
  const { t } = useTranslation();

  const [isOnSiteInfoDialogOpen, setIsOnSiteInfoDialogOpen] = useState(false);

  const rate_foreigner_amount = parseFloatNumberOrNumberString(data.rate_foreigner_amount);

  const { currency } = data;

  return (
    <div
      className={[
        'flex flex-col gap-4',
        // borders.DEBUG_greenBorderDashed,
      ].join(' ')}
    >
      <DialogWithCloseButton
        isOpen={isOnSiteInfoDialogOpen}
        setIsOpen={setIsOnSiteInfoDialogOpen}
        contentWrapperClassName={styles.OnsiteHoverMessageStyles.contentWrapperClassName}
        content={(
          <pre className={styles.OnsiteHoverMessageStyles.contentClassName}>
            {PODOnsitePaymentHoverMessage}
          </pre>
        )}
      />
      <div className="mt-4 flex flex-row justify-between tablet:mt-0">
        <p className="text-big tablet:text-semilarge">{t('fee')}</p>
        <p className="text-semilarge font-bold">
          {formatKRWWonAmount(rate_foreigner_amount)}{' '}
          {currency === wonCurrency ? t('won') : currency}
        </p>
      </div>
      {data.extra_charge && (data.extra_charge.additional_amount || 0) > 0 && (
        <div
          className={[
            'flex flex-col gap-1.5',
            // borders.DEBUG_blueBorderDashed,
          ].join(' ')}
        >
          <AdditionFees
            extra_charge={data.extra_charge}
            onSiteCurrency={data.extra_equipments_json.currency}
          />
        </div>
      )}

      <Horizon />

      <div className="flex flex-row justify-between">
        <div
          className={[
            css`
              display: flex;
              flex-direction: row;
              gap: 6px;
              align-items: center;
              height: fit-content;
            `,
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <p className="text-big tablet:text-semilarge">현장 결제 예상금액</p>
          <InfoIcon width={20} height={20} onClick={() => setIsOnSiteInfoDialogOpen(true)} />
        </div>
        <div>
          <div className="flex flex-col items-end gap-1.5">
            <p className="text-semihuge font-bold tablet:text-semilarge">
              {formatKRWWonAmount(data?.total_foreigner_amount)}
              &nbsp;
              {currency === wonCurrency ? t('won') : currency}
            </p>
            <p
              className="
                text-small
                font-light
                text-neutral-300
              "
            >
              약 {formatKRWWonAmount(data?.total_amount)} {t('won')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
