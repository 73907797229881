import { css, cx } from '@emotion/css';

import { COLORS, UNITS } from '^/constants/commonStyles';

import { DefaultPhoneInputStyle, PhoneInputClassNameOverrides } from './PhoneInput.styles';

export const DefaultReservationCheckFormStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  titleText: css`
    font-size: 22px;
    font-weight: bold;
    margin-top: 8px;
  `,
  phoneText: css`
    margin-top: 24px;
  `,
  emailText: css`
    margin-top: 24px;
  `,
  emailInput: css`
    /* border: solid blue 2px; */

    padding-left: ${UNITS.get('24px')};
    margin-top: ${UNITS.get('16px')};
    border: solid 1px ${COLORS.LIGHT_GREY_0};
    border-radius: 5px;
    height: 56px;
  `,
  phoneInputOverrides: {
    ...DefaultPhoneInputStyle,
    root: cx(
      DefaultPhoneInputStyle.root,
      css`
        margin-top: ${UNITS.get('16px')};
      `,
    ),
  } as PhoneInputClassNameOverrides,
} as const;

export type ReservationCheckFormStylesOverrides = {
  phoneInputOverrides: PhoneInputClassNameOverrides;
} & typeof DefaultReservationCheckFormStyles;
