import { css } from '@emotion/css';

const parentClass = css`
  width: 0;
  height: 0;
  position: relative;
`;
export const EventAreaExtendingDummyBlock: React.FC<{
  childProps: React.HTMLAttributes<HTMLDivElement>,
}> = ({
  childProps,
}) => {
  const {
    className,
    ...childPropsWithoutClass
  } = childProps;
  return (
    <div
      id="EventAreaExtendingDummyBlock"
      className={parentClass}
    >
      <s
        className={[
          css`
            position: absolute;
          `,
          className,
        ].join(' ')}
        {...childPropsWithoutClass}
      />
    </div>
  );
};
