import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import { BASE_URL, API_KEY, ACCESS_TOKEN_NAME } from '../constants/envConfig';

const axiosConfig = {
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-api-key': API_KEY,
  },
  responseType: 'json',
} as const;

class Axios {
  constructor(public instance = axios.create(axiosConfig)) {
    // interceptors
    instance.interceptors.request.use(async (reqConfig) => {
      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      if (token && reqConfig?.headers) {
        // eslint-disable-next-line no-param-reassign
        reqConfig.headers.Authorization = `Bearer ${token}`;
      }
      return reqConfig;
    });
    instance.interceptors.response.use((res) => this.handleSuccess(res), this.handleError);
    this.instance = instance;
  }

  /**
   * Axios handle interceptors success
   * @param {object} response
   * @return {object}
   */
  // eslint-disable-next-line class-methods-use-this
  async handleSuccess<T>(response: T) {
    return response;
  }

  /**
   * Axios handle interceptors error
   * @param {object} error The error.
   * @return {object}
   */
  // eslint-disable-next-line class-methods-use-this
  async handleError(error) {
    const { response, config } = error;
    // const originalRequest = config;
    // if (response && response.status === 401 && !originalRequest._retry) {
    //   // try refresh tokens
    //   originalRequest._retry = true;
    //   const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
    //   try {
    //     const newTokens = await axios.post(`${BASE_URL}/user/refresh`, {}, {
    //       headers: {
    //         'Authorization': `Bearer ${refreshToken}`,
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //         'x-api-key': API_KEY,
    //       },
    //     });
    //     localStorage.setItem(ACCESS_TOKEN_NAME, newTokens.data.access_token);
    //     localStorage.setItem(REFRESH_TOKEN_NAME, newTokens.data.refresh_token);
    //     this.instance.defaults.headers.common['Authorization'] = `Bearer ${newTokens.data.access_token}`;
    //     return this.instance(originalRequest);
    //   } catch (e) {
    //     localStorage.removeItem(ACCESS_TOKEN_NAME);
    //     localStorage.removeItem(REFRESH_TOKEN_NAME);
    //     window.location.href = '/';
    //     throw e;
    //   }
    // }
    return Promise.reject(response);
  }

  /**
   * Axios base caller api
   * @description Accept method: GET, POST, PUT, PATCH, DELETE
   * @abstract This is object request params
   * @param {1} url a url api
   * @param {2} headers a headers request includes auth_token
   * @param {3} method a method request
   * @param {4} data object params
   */
  async fetch<
    TSuccessResponse,
    TData = undefined,
    THeaders extends AxiosRequestHeaders = AxiosRequestHeaders,
    TUrl extends string = string,
  >({
    url,
    headers,
    method,
    data,
  }:
  | {
    url: TUrl;
    data: TData;
    headers?: THeaders;
    method: 'post' | 'put' | 'patch' | 'delete';
  }
  | {
    data?: TData;
    url: TUrl;
    headers?: THeaders;
    method: 'get';
  }) {
    const defaultParams: AxiosRequestConfig<TData> & {
      crossdomain: boolean;
    } = {
      method,
      data,
      headers,
      crossdomain: true,
      url,
    };

    try {
      const res = await this.instance.request<TSuccessResponse>(defaultParams);
      if (res.status === 200 || res.status === 201) {
        return res;
      }
      throw res;
    } catch (err: any) {
      // FIXME: ...
      throw new Error(err?.data?.message);
    }
  }

  async fetch2<
    TData,
    TSuccessResponse,
    THeaders extends AxiosRequestHeaders = AxiosRequestHeaders,
    TUrl extends string = string,
  >({
    url,
    headers,
    method,
    data,
  }: {
    url: TUrl;
    data: TData;
    headers?: THeaders;
    method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  }) {
    const defaultParams: AxiosRequestConfig<TData> & {
      crossdomain: boolean;
    } = {
      method,
      data,
      headers,
      crossdomain: true,
      url,
    };

    try {
      const res = await this.instance.request<TSuccessResponse>(defaultParams);
      if (res.status === 200 || res.status === 201) {
        return res;
      }

      throw res;
    } catch (err: any) {
      // FIXME: ...
      throw new Error(err?.data?.message);
    }
  }
}

export const defaultAxiosClient = new Axios();
export default defaultAxiosClient;
