import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

const NORMAL_SCALE = 1;
const FOCUS_SCALE = 1.3;

const ANIMATION_DROP = 2;
const ANIMATION_BOUNCE = 1;
const ANIMATION_NONE = null;

const mapIcon = {
  LDT: {
    normal: '/location_love.png',
    hover: './location_love_hover.png',
  },
  LAM: {
    normal: '/bus.png',
    hover: './bus_hover.png',
  },
  LAP: {
    normal: '/airport.png',
    hover: './airport_hover.png',
  },
  LHT: {
    normal: '/hotel.png',
    hover: './hotel_hover.png',
  },
};

/**
 * Item location in the map
 * @param {Object} props Component props
 * @param {number} props.lat Latitude of place
 * @param {number} props.lng Longitude of place
 * @param {Object} props.place Info of place
 * @param {string} props.iconPathNormal Image display path of Pin
 * @param {string} props.iconPathHover Image display path of Pin
 * @param {number} props.pinSize Size of Pin
 * @return {Object}
 */
const Pin = ({ lat, lng, place, pinSize = 5, type = 'LDT' }) => {
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [markerHoverStyle, setMarkerHoverStyle] = useState({
    scale: NORMAL_SCALE,
    animation: ANIMATION_DROP,
    iconPath: mapIcon[type].normal,
  });
  const handleCloseInfo = () => setIsShowInfo(false);

  const handleOpenInfo = () => setIsShowInfo(true);
  return (
    <Marker
      position={{ lat, lng }}
      className="pin"
      icon={{
        url: markerHoverStyle.iconPath,
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 20),
        scaledSize: new window.google.maps.Size(40, 40),
      }}
      animation={markerHoverStyle.animation}
      onClick={() => handleOpenInfo()}
      onMouseOver={() => {
        setMarkerHoverStyle({
          scale: FOCUS_SCALE,
          animation: ANIMATION_BOUNCE,
          iconPath: mapIcon[type].hover,
        });
      }}
      onMouseOut={() => {
        setMarkerHoverStyle({
          scale: NORMAL_SCALE,
          animation: ANIMATION_NONE,
          iconPath: mapIcon[type].normal,
        });
      }}
    >
      {(place.description || place.address_1) && isShowInfo && (
        <InfoWindow onCloseClick={handleCloseInfo} options={{ maxWidth: 'auto' }}>
          <div>
            <h4 className="my-1 mx-0 font-semibold text-sm">{place?.description}</h4>
            <span className="text-xs">{place?.address_1}</span>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};
Pin.propTypes = {
  /**
   * Latitude of place
   */
  lat: PropTypes.number,
  /**
   * Longitude of place
   */
  lng: PropTypes.number,
  /**
   * Info of place
   */
  place: PropTypes.object,
  /**
   * Image display path of Pin
   */
  iconPathNormal: PropTypes.string,
  /**
   * Image display path of Pin when hover
   */
  iconPathHover: PropTypes.string,
  /**
   * Size of Pin
   */
  pinSize: PropTypes.number,
  type: PropTypes.string,
};
Pin.defaultProps = {
  lat: -3,
  lng: -37,
  place: {
    id: 'id_03',
    description: '3_Los Angeles - North La Brea',
    address_1: '363, North La Brea, Los Angeles, USA, 90036',
    category_id: 'category_01',
    category_count: 11,
    lat: -3.8,
    lng: -38.3,
  },
  count: 11,
  price: '60,65',
  iconPathNormal:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Circle-icons-car-custom.svg/1200px-Circle-icons-car-custom.svg.png',
  iconPathHover:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Circle-icons-car.svg/1024px-Circle-icons-car.svg.png',
  pinSize: 78,
};
export default Pin;
