import { makeAutoObservable } from 'mobx';
import { z } from 'zod';

import { INITIAL_VEHCLASS } from '^/constants/envConfig';
import {
  FreePermissionType,
  VendorCompanyNameType,
} from '^/types/__BrandedTypes';
import { MobxInputValue } from '^/util/MobxInputValue';

export const PriceSortFilter = z.enum([
  'lowest',
  'highest',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PriceSortFilter = z.infer<typeof PriceSortFilter>;

export class ListingPage2FilterViewModel {
  constructor(
    public priceSortFilter = new MobxInputValue<PriceSortFilter>('lowest'),
    public minPrice = new MobxInputValue<number | null>(null),
    public maxPrice = new MobxInputValue<number | null>(null),
    public vendorsFilter = new MobxInputValue<Set<VendorCompanyNameType>>(
      new Set(),
    ),
    public carClass = new MobxInputValue<Set<typeof INITIAL_VEHCLASS[number]>>(
      new Set(),
    ),
    public freePermission = new MobxInputValue<Set<FreePermissionType>>(
      new Set(),
    ),
  ) {
    makeAutoObservable(this);
  }
}
