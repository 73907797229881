import { AxiosInstance } from 'axios';
import qs from 'qs';

import { api2BaseAxios } from '^/api2/api2BaseAxios';
import { otClientAPIResponseType } from '^/api2/OTClientAPI/otClientAPI.ResponseType';
import { zodValidateAxiosResp } from '^/api2/zodValidate';
import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';
// import { actions, sendTrackingLog } from '^/api/trackingLog';

const validator = zodValidateAxiosResp(otClientAPIResponseType, (err) => {
  console.error('getCarListing zodError', err);
});
export function createOTClientAPI(axiosInstance: AxiosInstance) {
  return async function otClient(args: {
    path: string;
    method: string;
    body: Record<string, unknown>;
  }) {
    const startTime = performance.now();
    if (args.method === 'post') {
      const response = await axiosInstance.post(args.path, args.body);
      if (response.status !== 200 && response.status !== 201) {
        return asFailedPromise({
          reason: 'HTTPError',
          err: response.data,
          response,
        } as const);
      }
      // if (response.data) {
      //   const endTime = performance.now();
      //   const duration = (endTime - startTime) / 1000;
      //   sendTrackingLog({
      //     action: actions.CAR_LIST.CAR_LIST_COMPLETE,
      //     response_time: Number(duration.toFixed(2)),
      //   });
      // }
      const validated = validator(response);
      if (response.data.length === 0) {
        return asFailedPromise({
          reason: 'carEmpty',
          err: response.data,
          response,
        } as const);
      }
      return asSuccessPromise({
        items: validated.data.data,
        ...validated,
      } as const);
    }

    const params = new URLSearchParams({
      res_id: qs.stringify(args.body),
    });
    const response = await axiosInstance.get(args.path, {
      params,
    });
    const validated = validator(response);
    return asSuccessPromise({
      items: validated.data.data,
    } as const);
  };
}
export const otClientAPI = createOTClientAPI(api2BaseAxios);
