import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { css } from '@emotion/css';
import PropTypes from 'prop-types';

const WrapperPopup = ({ title, content, open, setOpen, classNames, onCloseModal, loading, hasCloseOutside = false, classNamePosition = '', canCloseByClickOutside = false }) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        {/* <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">*/}
        {/*  <div*/}
        {/*    className="fixed inset-0 bg-black bg-opacity-25 z-40 overflow-y-auto w-full h-full"*/}
        {/*  >*/}
        {/*    <div className={`flex justify-center tablet:px-6 tablet-max:px-4 mb-12 text-center relative ${classNamePosition}`}>*/}
        {/*      Hello*/}
        {/*      /!* <div className={`transform relative overflow-y-auto rounded-lg bg-white tablet:px-6 tablet-max:px-4 text-left align-middle shadow-xl transition-all ${classNames}`}>*!/*/}
        {/*      /!*  /!* Title *!/*!/*/}
        {/*      /!*  <h3 className="text-large font-bold text-gray-900">{title}</h3>*!/*/}
        {/*      /!*  /!* Main contain *!/*!/*/}
        {/*      /!*  <div className="mt-4 h-fit overflow-y-auto hide-scrollbar">{content}</div>*!/*/}
        {/*      /!* </div>*!/*/}
        {/*      /!* <div className="hover:cursor-pointer ml-[20px] block h-[36px] tablet-max:hidden" onClick={() => onCloseModal()}>*!/*/}
        {/*      /!*  <img src={'./ic_x_white_32.png'} className={'w-[32px] h-[32px]'} />*!/*/}
        {/*      /!* </div>*!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/* </Transition.Child>*/}
        <div
          id="contentBox"
          className="relative z-40"
          onClick={(event) => {
            if (canCloseByClickOutside) {
              if (event.target.id === 'contentBox') {
                onCloseModal();
              }
            }
          }}
        >
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div
              className="fixed inset-0 bg-black bg-opacity-25"
              onClick={() => {
                if (canCloseByClickOutside) {
                  onCloseModal();
                }
              }}
            >
            </div>
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto overscroll-none"
          >
            <div id="contentBox" className={`flex justify-center tablet:px-6 tablet-max:px-4 mb-12 text-center relative ${classNamePosition} overscroll-none`}>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className={`transform relative overflow-y-auto rounded-lg bg-white tablet:px-6 tablet-max:px-4 text-left align-middle shadow-xl transition-all tablet:py-6 ${classNames} z-20 overscroll-none`}>
                  <div
                    className={css`
                      display: flex;
                      justify-content: space-between;
                    `}
                  >
                    <h3 className="text-large font-bold text-gray-900">{title}</h3>
                    {hasCloseOutside && (
                      <div className="hover:cursor-pointer ml-[20px] block h-[36px] tablet-max:hidden" onClick={() => onCloseModal()}>
                        <img src={'./ic_x_black/ic_x_black_32.svg'} className={'w-[32px] h-[32px]'} />
                      </div>
                    )}
                  </div>
                  {/* Title */}
                  {/* Main contain */}
                  <div className="mt-4 h-fit overflow-y-auto hide-scrollbar overscroll-contain">{content}</div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
WrapperPopup.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  classNames: PropTypes.string,
  onCloseModal: PropTypes.func,
  loading: PropTypes.bool,
  hasCloseOutside: PropTypes.bool,
  classNamePosition: PropTypes.string,
  canCloseByClickOutside: PropTypes.bool,
};
export default WrapperPopup;
