import { BASE_URL } from '^/constants/envConfig';
import { BOOKING_PATH } from '^/constants/routes';
import { getSessionId, getUUID } from '^/util/user';
import { useLocation, useNavigate } from 'react-router';

const PaymentPending = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  (async () => {
    if (!state) {
      // usePayment를 거쳐서 진입하지 않은 경우 state가 전달되지 않으므로 이전 페이지로 돌려보낸다.
      navigate(-1);
    }

    if (state?.uid) {
      // usePayment를 거쳐서 진입하지 않은 경우 uid가 생성되지 않으므로 uid를 체크한다.
      const params = JSON.parse(sessionStorage.getItem(`${state.uid}`) || '{}');
      if (!params) {
        // 예약 및 결제 정보 없음
        navigate(-1);
      }
      const { reservationInfo, bookingInfo, price, hertzCoreId, res_id } = params;

      if (params.paymentStatus) {
        // paymentStatus가 있다면 이미 결제요청한 적 있는 uid이므로 실패 처리한다.
        navigate(`${BOOKING_PATH}?id=${reservationInfo.hertzCoreId}&ispay=fail`, {
          replace: true,
        });
      }

      // 상품 이름
      const vehInfo = bookingInfo.carInfo.vehInfo;
      const productName = `[${
        vehInfo.name.slice(0, 1) === 'Q' ? vehInfo.name.slice(0, 2) : vehInfo.name.slice(0, 1)
      } 그룹] - ${vehInfo.size}`;

      const paymentData = {
        reservation_id: res_id,
        host: window.location.host,
        product_name: productName,
        product_price: price, // 결제금액
        customer_name: reservationInfo.korean_name,
        customer_email: reservationInfo.email,
        customer_phone: reservationInfo.phone_number,
        request_uid: state.uid,
        booking_id: hertzCoreId,
        payment_type: 'reservation',
        session_id: getSessionId(),
      };

      // form submit으로 결제 요청
      const form = document.createElement('form');

      form.action = `${BASE_URL}payment/request`;
      form.method = 'POST';

      // form input에 결제 정보 추가
      if (paymentData) {
        for (const key in paymentData) {
          const input = document.createElement('textarea');
          input.name = key;
          input.value =
            typeof paymentData[key] === 'object'
              ? JSON.stringify(paymentData[key])
              : paymentData[key];
          form.appendChild(input);
        }
      }
      form.style.display = 'none';
      document.body.appendChild(form);

      // sessionStorage에 예약, 결제 정보 저장
      window.sessionStorage.setItem(
        `${state.uid}`,
        JSON.stringify({
          ...params,
          paymentStatus: 'pending',
        }),
      );

      form.submit();
    }
  })();

  return <div>결제가 진행되므로 창을 닫지 말아주세요.</div>;
};

export default PaymentPending;
