import { z } from 'zod';

export const LocaleType = z.enum(['ko', 'en']);

export const VendorCompanyIDType = z
  .enum(['ZE', 'ZR', 'OT', 'RC', 'TM']);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VendorCompanyIDType = z.infer<typeof VendorCompanyIDType>;

export const VendorCompanyNameType = z
  .string()
  .brand<'VendorCompanyNameType'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VendorCompanyNameType = z.infer<typeof VendorCompanyNameType>;

export const PriceCodeType = z
  .string()
  .brand<'PriceCodeType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PriceCodeType = z.infer<typeof PriceCodeType>;

export const FreePermissionType = z.enum([
  'Unlimited',
  'Limited',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FreePermissionType = z.infer<typeof FreePermissionType>;

// TODO: rename? (may conflict with 'creditcard'/'cash'/etc)
export const PaymentTypes = z.enum([
  'POD',
  'PPD',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentTypes = z.infer<typeof PaymentTypes>;

export const ReservationIDType = z
  .string()
  .brand<'ReservationIDType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReservationIDType = z.infer<typeof ReservationIDType>;

export const UserLastNameType = z
  .string()
  .brand<'UserLastNameType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserLastNameType = z.infer<typeof UserLastNameType>;

export const RateQualifierIDType = z
  .string()
  .brand<'RateQualifierIDType'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RateQualifierIDType = z.infer<typeof RateQualifierIDType>;

export const InsurancePackageNames = z.enum([
  // values from translation (ko.json file)
  'ABC',
  'DEF',
  'ASX',
  'AKN',
  'KPTXL',
  'KPLAD',
  'KPLPT',
  'NTG',
  'BEST',
  'HAPL',
  'HAPF',
  'HAPA',
  'NMC',
  'NNC',
  'KOTXL',
  'KOLAD',
  'KOLPT',
  'C2TXL',
  'C2CND',
  'C2LXD',
  'C2LPD',
  'KSHK',
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InsurancePackageNames = z.infer<typeof InsurancePackageNames>;

export const InsuranceSubPackageNames = z.enum([
  'AAO',
  'ADE',
  'CDW',
  'CS',
  'FPO',
  'GTC',
  'LDW',
  'LDW_EU',
  'LDW_SPEC',
  'LI',
  'LI_CA',
  'LI_EU',
  'LI_NZ',
  'LI_SPEC',
  'LIS',
  'MXC',
  'PERS',
  'PI',
  'PPP',
  'PPP_CA',
  'SCDW',
  'TP',
  'UM',
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InsuranceSubPackageNames = z.infer<typeof InsuranceSubPackageNames>;

export const ExtraEquipmentCodes = z.union([
  z.union([z.literal(7), z.literal('7')]),
  z.union([z.literal(8), z.literal('8')]),
  z.union([z.literal(9), z.literal('9')]),
  z.union([z.literal(13), z.literal('13')]),
]).brand<'ExtraEquipmentCodes'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ExtraEquipmentCodes = z.infer<typeof ExtraEquipmentCodes>;

export const AirlineMileageProgramCode = z.string()
  .brand<'FrequentTravelerProgramCode'>();

// eslint-disable-next-line max-len, @typescript-eslint/no-redeclare
export type AirlineMileageProgramCode = z.infer<typeof AirlineMileageProgramCode>;
