import { RefObject } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { ViewByState } from '^/commonComponents/ui/ViewByState';

import { RentalOfficeInput } from '../common/RentalOfficeInput/RentalOfficeInput';
import { CarListingSearchBoxViewModel } from '../desktop/viewmodel/CarListingSearchBox.viewmodel';

import { CarListingBottomSheetSearchConditionHeader } from './BottomSheet/CarListingBottomSheet.SearchCondition.Header';

export const Step6ReturnOffice: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
}> = observer(function Step6ReturnOffice({
  viewmodel,
  returnCityInputDummyRefForIOSKeyboard,
}) {
  return (
    <CarListingBottomSheetSearchConditionHeader
      citySearchInputPlaceholder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '반납 장소 선택'
          : '대여/반납 도시 선택'
      }
      locationViewModel={viewmodel.returnLocation}
      viewmodel={viewmodel}
      citySearch={viewmodel.i.returnCitySearch}
      isCityInputSelected={false}
      onClickCityInputButton={() => {
        viewmodel.stepManager
          .replaceStep({
            type: '5_return_city',
          });
        returnCityInputDummyRefForIOSKeyboard
          .current
          ?.focus();
      }}
      onClickRentalOfficeInputButton={() => {
      }}
      onSelectCity={() => {
      }}
      title="반납 위치"
      isRentalOfficeInputSelected
    >
      <div
        className={css`
            height: 100%;
            /* background-color: pink; */
            margin-top: 12px;
          `}
      >
        <ViewByState
          data={viewmodel.getLocationInputViewModelByCityCode(
            viewmodel.i.returnCityCode.value,
            viewmodel.i.tempReturnLocationId,
          )}
          loadingView={(
            <BlueLoadingSpinner
              classOverrides={{
                root: css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  width: 100%;
                  margin-bottom: 100px;
                `,
                spinner: css`
                  margin-bottom: 70px;
                  width: 45px;
                  height: 45px;
                `,
              }}
            />
          )}
          errorView={() => {
            return <h1> error </h1>;
          }}
        >
          {(returnVM) => {
            return (
              <RentalOfficeInput
                viewmodel={returnVM}
                onBlur={() => { }}
                onSelectRentalOffice={() => {
                  viewmodel.stepManager
                    .replaceStep({
                      type: '6_5_selected_return_office',
                    });
                }}
              />
            );
          }}
        </ViewByState>
      </div>
    </CarListingBottomSheetSearchConditionHeader>
  );
});
