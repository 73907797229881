import { cx, css } from '@emotion/css';

const STEPS = [1, 2, 3];
export const MRTSearchStepperMobile = ({ currentStep }: { currentStep: number }) => {
  return (
    <div
      className={cx(css`
        display: flex;
        align-items: center;
    `)}
    >
      {STEPS.map((step) => {
        return (
          <div
            className={
              cx(
                css`
                  display: flex; 
                  align-items: center;
                `,
              )
            }
          >
            <div
              className={
                cx(
                  css`
                    border-radius: 50%; 
                    width: 20px;
                    height: 20px;
                    display: flex; 
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    background-color: ${step <= currentStep ? '#55A9F3' : '#F4F4F4'};
                    color: ${step <= currentStep ? '#fff' : '#9F9F9F'};
                    border: 1px solid ${step <= currentStep ? '#55A9F3' : '#E1E1E1'};
                  `,
                )
              }
            >
              {step < currentStep ? <img src="check.svg" alt="check" /> : step}
            </div>
            <div
              className={
                cx(
                  css`
                    width: 14px;
                    height: 1.5px;
                    background-color: ${step < currentStep ? '#55A9F3' : '#E1E1E1'};
                    display: ${STEPS.at(-1) === step ? 'none' : 'flex'};
                    margin: 0 4px;
                  `,
                )
              }
            />
          </div>
        );
      })}
    </div>
  );
};
