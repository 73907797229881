/* eslint-disable no-nested-ternary */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { font } from '^/constants/commonStyles';

import { CalendarStateViewModel } from '../Calendar.state.viewmodel';
import { YearMonth } from '../helpers/YearMonth';
import { useRecoilValue } from 'recoil';
import { renewalUIState } from '^/recoil/atoms';

const size32px = css`
  width: 32px;
  height: 32px;
`;

const size16px = css`
  width: 16px;
  height: 16px;
`;

export const CalendarHeader: React.FC<{
  yearMonth: YearMonth;
  calendarStateVM: CalendarStateViewModel;
  showPrevButton?: boolean;
  showNextButton?: boolean;
}> = observer(function CalendarHeader({
  yearMonth,
  calendarStateVM,
  showPrevButton,
  showNextButton,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isRenewal = useRecoilValue(renewalUIState);

  const isCurrentMonth = calendarStateVM.today.monthFrom0 === yearMonth.monthFrom0;

  return (
    <div
      className={cx(
        css`
          display: flex;
          align-items: center;
        `,
        isRenewal &&
          css`
            padding: 6px 8px;
          `,
      )}
    >
      {showPrevButton ? (
        <input
          type="image"
          alt="이전 달로 가기"
          src="./ic_arrow_left_32px.svg"
          onClick={() => {
            const current = calendarStateVM.calendarPaginationOffset.value;
            calendarStateVM.calendarPaginationOffset.set(current - 1);
          }}
          className={isRenewal ? size16px : size32px}
          disabled={isCurrentMonth}
        />
      ) : isMobile ? (
        <></>
      ) : (
        <div className={size32px} />
      )}
      <h1
        className={cx(
          css`
            width: 100%;
            display: flex;
            margin: 0 auto;

            // background-color: pink;
          `,
          isMobile
            ? css`
                justify-content: start;
                margin-top: 12px;
              `
            : css`
                justify-content: center;
              `,
          font.Body1_Bold,
          !isMobile && font.Headline1_Bold,
          isRenewal &&
            css`
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            `,
        )}
      >
        {yearMonth.year}년 {yearMonth.monthFrom0 + 1}월
      </h1>
      {showNextButton ? (
        <input
          type="image"
          alt="다음 달로 가기"
          src="./ic_arrow_right_32px.svg"
          onClick={() => {
            const current = calendarStateVM.calendarPaginationOffset.value;
            calendarStateVM.calendarPaginationOffset.set(current + 1);
          }}
          className={isRenewal ? size16px : size32px}
        />
      ) : (
        <div className={size32px} />
      )}
    </div>
  );
});
