export function capitalizeFirstLettersInWords(str: null): null;
export function capitalizeFirstLettersInWords(str: undefined): undefined;
export function capitalizeFirstLettersInWords(str: string): string;
export function capitalizeFirstLettersInWords(str: string | null | undefined) {
  if (!str) {
    return str;
  }
  return str.split(' ')
    .map((a) => {
      if (a.length <= 1) {
        return a.toUpperCase();
      }
      const firstLetter = a.slice(0, 1).toUpperCase();
      const rest = a.slice(1).toLowerCase();
      return firstLetter + rest;
    })
    .join(' ');
}
