import axios from '../../axios/index';

const BASE_PATH = '/faq';

export const getFaqContents = async () => {
  const response = await axios.fetch({
    url: BASE_PATH,
    method: 'GET',
  });
  return response;
};

export const getKeywords = async () => {
  const response = await axios.fetch({
    url: `v2${BASE_PATH}/keywords`,
    method: 'GET',
  });
  return response;
};
