/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { RefObject, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { SPACING, COLORS2, font } from '^/constants/commonStyles';

import { classNames } from '../desktop/contentsArea/CarSearchBox.contentsArea.style';
import { CitySearchInput } from '../desktop/headerArea/CitySearchInput/CitySearchInput';
import { RentalOfficeInputButton } from '../desktop/headerArea/OfficeInput/RentalOfficeInputButton';
import { ScheduleInputButton } from '../desktop/headerArea/ScheduleInput/ScheduleInputButton';
import { CarListingSearchBoxViewModel } from '../desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '../desktop/viewmodel/SearchBoxStepManager';

import { CarListingBottomSheetHeader } from './BottomSheet/CarListingBottomSheet.header';
import { ages } from './Step4.birthday.ages';

export const Step0SameRentalLocation: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  pickupCityInputDummyRefForIOSKeyboard?: RefObject<HTMLInputElement>;
  carsScrollAreaRef: RefObject<HTMLDivElement> | null;
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null;
}> = observer(function Step0SameRentalLocation({
  viewmodel,
  pickupCityInputDummyRefForIOSKeyboard,
  carsScrollAreaRef,
  carClassChipsScrollRef,
}) {
  useEffect(() => {
    const minimum30Ages = ages.find((obj) => obj.text === '만 30세 이상')?.age;
    if (minimum30Ages && !viewmodel.i.birthdayInput.value) {
      viewmodel.i.birthdayInput.set(minimum30Ages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepManagerStepChangeOptions = {
    afterStepChange(newStep: SearchBoxSteps) {
      if (newStep.type === '1_rental_city') {
        pickupCityInputDummyRefForIOSKeyboard?.current?.focus();
      }
    },
  } as const;

  return (
    <form
      className={cx(
        // borders.DEBUG_blueBorderDashed,
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `,
      )}
      onSubmit={async (e) => {
        if (viewmodel.submitActionVM.status === 'success') {
          viewmodel.stepManager.clearStep();
          // sendTrackingLog({
          //   action: actions['/'].CLICK_SEARCH_CARS,
          // });
          const submitFn = viewmodel.submitActionVM.value;
          setTimeout(() => {
            submitFn.submit();
          }, 50);
          setTimeout(() => {
            carsScrollAreaRef?.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            carClassChipsScrollRef?.current?.scrollTo({
              left: 0,
              behavior: 'smooth',
            });
          }, 100);
        }
        e.preventDefault();
        return false;
      }}
    >
      <CarListingBottomSheetHeader
        title="검색 조건 설정"
        leftCornorBtnType="close"
        stepManager={viewmodel.stepManager}
      />

      <div
        className={css`
          padding: 12px ${SPACING.spacing24};
          overflow-y: scroll;
        `}
      >
        <section>
          <div className={font.Caption1_Light}>대여 / 반납 위치</div>

          <div
            onClick={() => {
              viewmodel.stepManager.pushStepWithOptions(
                {
                  type: '1_rental_city',
                  autofocus: 'CitySearchInput',
                },
                stepManagerStepChangeOptions,
              );
              pickupCityInputDummyRefForIOSKeyboard?.current?.focus();
            }}
            className={cx(
              css`
                display: flex;
                align-items: center;
                background-color: ${COLORS2.sub.MrtGray3};
                padding: 19px 12px;
                height: 56px;
                margin-top: ${SPACING.spacing16};
                border-radius: 5px;
              `,
              // borders.DEBUG_greenBorderDashed,
            )}
          >
            <CitySearchInput
              placeholder="대여/반납 도시 선택"
              disabled
              viewmodel={viewmodel.i.pickupCitySearch}
              onSelectCity={() => {
                viewmodel.stepManager.pushStepWithOptions(
                  {
                    type: '2_rental_office',
                  },
                  stepManagerStepChangeOptions,
                );
              }}
            />
          </div>

          <div
            onClick={() =>
              viewmodel.stepManager.pushStepWithOptions(
                {
                  type: '2_rental_office',
                },
                stepManagerStepChangeOptions,
              )
            }
            className={css`
              margin-top: ${SPACING.spacing16};
            `}
          >
            <RentalOfficeInputButton
              viewmodel={viewmodel.pickupLocation}
              isSelected={viewmodel.stepManager.currentStep?.type === '2_rental_office'}
            />
          </div>

          {/* 대여/반납 장소가 다릅니다. */}
          <div
            onClick={() => {
              if (isOntra(viewmodel.i.pickupCityCode.value)) {
                viewmodel.showDifferentLocationAlert.set(true);
                return;
              }
              viewmodel.i.hasDifferentPickupReturnLocations.set(
                !viewmodel.i.hasDifferentPickupReturnLocations.value,
              );
              // FIXME: calendar reset as requested...(?????)
              //          better: do validation...
              viewmodel.i.scheduleTimeViewModel.reset();
              viewmodel.i.calendarStateViewModel.reset();
            }}
            className={cx(
              css`
                display: flex;
                gap: ${SPACING.spacing8};
                align-items: center;

                margin-top: ${SPACING.spacing8};
                width: fit-content;
              `,
              font.Caption2_Light,
            )}
          >
            <img
              alt="rental-location-different"
              src={
                viewmodel.i.hasDifferentPickupReturnLocations.value
                  ? 'ic_checkbox/ic_checkbox_a2.svg'
                  : 'ic_checkbox/ic_checkbox_n2.svg'
              }
            />
            대여/반납 장소가 다릅니다.
          </div>
        </section>

        <section
          className={css`
            margin-top: ${SPACING.spacing24};
          `}
        >
          <ScheduleInputButton
            viewmodel={viewmodel}
            onClick={() => {
              viewmodel.stepManager.pushStepWithOptions(
                {
                  type: '3_schedule',
                },
                stepManagerStepChangeOptions,
              );
            }}
            isSelected={viewmodel.stepManager.currentStep?.type === '3_schedule'}
          />
        </section>

        <section
          className={css`
            margin-top: ${SPACING.spacing24};
          `}
        >
          <div className={classNames.common.searchBoxInputTitle}>운전자 연령</div>
          <div
            onClick={() => {
              viewmodel.stepManager.pushStepWithOptions(
                {
                  type: '4_birthday',
                },
                stepManagerStepChangeOptions,
              ); // FIXME: maybe can delete
            }}
            className={cx(
              font.Body1_Bold,
              css`
                background-color: ${COLORS2.sub.MrtGray3};
                height: ${SPACING.spacing56};
                margin-top: ${SPACING.spacing16};
                border-radius: 5px;
                display: flex;
                padding-left: 12px;
                align-items: center;
              `,
              // borders.DEBUG_orangeBorderDashed,
            )}
          >
            {viewmodel.i.birthdayInput.value ? (
              ages
                .filter((obj) => obj.age === viewmodel.i.birthdayInput.value)
                .map((obj) => <div>{obj.text}</div>)
            ) : (
              <div>운전자 생년월일을 입력해주세요.</div>
            )}
          </div>
        </section>
      </div>

      {/* Search Button */}
      <button
        type="submit"
        disabled={viewmodel.submitActionVM.status !== 'success'}
        className={cx(
          font.Body1_Bold,
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${COLORS2.main.MrtBlue1} !important;
            min-height: 56px;

            margin-left: ${SPACING.spacing24};
            margin-right: ${SPACING.spacing24};
            margin-top: auto;
            margin-bottom: ${SPACING.spacing16};

            border-radius: 5px;
            color: ${COLORS2.main.MrtWhite};

            &:disabled {
              background-color: ${COLORS2.main.MrtBlue2} !important;
            }
          `,
        )}
      >
        검색
        {/* {viewmodel.submitActionVM.status !== 'success' && (
          <PreTagForDebugging
            className={css`
              position: fixed;
              bottom: 0;
              left: 0;
              color: red;
              background-color: yellow;
              opacity: 0.4;
            `}
            obj={viewmodel.submitActionVM.failure}
            isShow
          />
        )} */}
      </button>
    </form>
  );
});
