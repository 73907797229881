import itiriri from 'itiriri';

import { getOpenCloseTimes } from '^/types/RawOpenHours';

import { RawRentalCarOfficeFromAPI } from './GetCategoriesByCityRawResponseType';

export function decodeRawRentalCarOfficeFromAPI(raw: RawRentalCarOfficeFromAPI) {
  const locationDescriptions = itiriri(raw.location_description)
    // .groupBy((a) => a.locale)
    .toMap((a) => a.locale);
  // TODO: omit
  // - location_description
  // - open_1~3_mon~sun
  return {
    ...raw,
    locationDescriptions,
    openCloseTimes: getOpenCloseTimes(raw),
  } as const;
}
