import { css } from '@emotion/css';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { SPACING, COLORS2, font2 } from '^/constants/commonStyles';
import { MobxInputValue } from '^/util/MobxInputValue';

import { CancelButtonStyle } from '../desktop/components/ReservationDetailDesktop.commonStuff';

const BillCancelButton = ({ data, setShowCancelConfirmPopup, isCancelDisabled }) => {
  return (
    <>
      <button
        type="button"
        onClick={() => {
          // sendTrackingLog({
          //   action: actions.RESERVATION_DETAIL.CLICK_RESERVATION_CANCEL,
          // });
          setShowCancelConfirmPopup(new MobxInputValue(true));
        }}
        disabled={isCancelDisabled}
        className={CancelButtonStyle}
      >
        예약 취소하기
      </button>
      {isCancelDisabled && (
        <div
          className={[
            font2.Caption1_Light,
            css`
              display: flex;
              flex-direction: column;
              align-items: center;
              color: ${COLORS2.main.MrtBlack};
              margin-top: ${SPACING.spacing16};
            `,
          ].join(' ')}
        >
          픽업일 기준 72시간 이내 취소는 고객센터로 문의해주세요.
        </div>
      )}
    </>
  );
};

export default BillCancelButton;
