/* eslint-disable jsx-a11y/no-static-element-interactions */
import { RefObject, useEffect, useRef } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import {
  PopularCity,
} from '../desktop/contentsArea/SelectCity/PopularCity';
import { popularCityDefaultStyles } from '../desktop/contentsArea/SelectCity/PopularCity.defaultStyles';
import {
  CarListingSearchBoxViewModel,
} from '../desktop/viewmodel/CarListingSearchBox.viewmodel';
import { Step1RentalCityStep } from '../desktop/viewmodel/SearchBoxStepManager';

import {
  CarListingBottomSheetSearchConditionHeader,
} from './BottomSheet/CarListingBottomSheet.SearchCondition.Header';

export const Step1RentalCity: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  step: Step1RentalCityStep,
  pickupCityInputRef?: RefObject<HTMLInputElement>,
}> = observer(function Step1RentalCity({
  viewmodel,
  step,
  pickupCityInputRef,
}) {
  const defaultCitySearchInputRef = useRef<HTMLInputElement>(null);
  const citySearchInputRef = pickupCityInputRef || defaultCitySearchInputRef;

  useEffect(() => {
    if (
      step.autofocus === 'CitySearchInput'
      && citySearchInputRef.current
    ) {
      citySearchInputRef.current.focus();
    }
  }, [step.autofocus]);
  return (
    <CarListingBottomSheetSearchConditionHeader
      citySearchInputPlaceholder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '대여 도시 선택'
          : '대여/반납 도시 선택'
      }
      rentalOfficeInputPlaceHolder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '대여 장소 선택'
          : '대여/반납 장소 선택'
      }
      citySearchInputRef={citySearchInputRef}
      citySearchInputProps={{
        autoFocus: true,
      }}
      viewmodel={viewmodel}
      locationViewModel={viewmodel.pickupLocation}
      citySearch={viewmodel.i.pickupCitySearch}
      isCityInputSelected
      onClickCityInputButton={() => {
      }}
      onClickRentalOfficeInputButton={() => {
        if (viewmodel.i.pickupCityCode.value !== null) {
          viewmodel.stepManager
            .replaceStep({
              type: '2_rental_office',
            });
        }
      }}
      onSelectCity={() => {
        if (!viewmodel.differentLocationValidation) {
          viewmodel.showDifferentLocationAlert.set(true);
          viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
          viewmodel.i.pickupCitySearch.searchInput.set('');
          return;
        }
        viewmodel.stepManager
          .replaceStep({
            type: '2_rental_office',
          });
      }}
      title={viewmodel.i.hasDifferentPickupReturnLocations.value === true
        ? '대여 위치'
        : '대여 / 반납 위치'}
      isRentalOfficeInputSelected={false}
    >
      <div className={css`
        /* padding-bottom: 50px; */
      `}
      >
        <PopularCity
          viewmodel={viewmodel.i.pickupCitySearch}
          onBlur={() => null}
          cityCount={15}
          onSelectCity={() => {
            if (!viewmodel.differentLocationValidation) {
              viewmodel.showDifferentLocationAlert.set(true);
              viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
              viewmodel.i.pickupCitySearch.searchInput.set('');
              return;
            }
            viewmodel.stepManager
              .replaceStep({
                type: '2_rental_office',
              });
          }}
          classOverrides={{
            cityButton: popularCityDefaultStyles.mobile.cityButton,
          }}
        />
      </div>
    </CarListingBottomSheetSearchConditionHeader>
  );
});
