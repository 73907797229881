/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { TextInput } from '^/commonComponents/inputs/TextInput';
import { SPACING, COLORS2, font, INPUT } from '^/constants/commonStyles';

import { classNames } from '../../contentsArea/CarSearchBox.contentsArea.style';
import { CarListingSearchBoxViewModel } from '../../viewmodel/CarListingSearchBox.viewmodel';

function getStyleByStatus(viewmodel: CarListingSearchBoxViewModel) {
  if (viewmodel.birthdayAgeValidation.status === 'failure') {
    return css`
      border: 1px solid red;
    `;
  }
  if (viewmodel.stepManager.currentStep?.type === '4_birthday') {
    return css`
      border: 1px solid ${COLORS2.main.MrtBlue1};
      color: ${COLORS2.main.MrtBlack};
    `;
  }
  return '';
}
export const BirthDayInput: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  title?: React.ReactNode;
  onClickBirthDayInput?: () => void;
}> = observer(function BirthDayInput({
  viewmodel,
  title = '운전자 생년월일',
  onClickBirthDayInput,
}) {
  const birthdayInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (viewmodel.stepManager.currentStep?.type === '4_birthday') {
      birthdayInputRef.current?.focus();
    }
  }, [viewmodel.stepManager.currentStep]);

  return (
    <div
      className={cx(
        classNames.common.searchBoxInputWrapper,
        css`
          display: flex;
          gap: 12px;
        `,
      )}
    >
      <div className={classNames.common.searchBoxInputTitle}>{title}</div>
      <div
        onClick={onClickBirthDayInput}
        className={[
          css`
            position: relative;
          `,
        ].join(' ')}
      >
        <TextInput
          autoComplete="birthday"
          rootRef={birthdayInputRef}
          name="BIRTHDAY"
          maxLength={6}
          onBlur={(e) => {
            // sendTrackingLog({
            //   action: actions['/'].INPUT_BIRTHDAY,
            //   data: e.target.value,
            // });
            viewmodel.stepManager.clearStep();
          }}
          className={cx(
            classNames.common.searchBoxInputs,
            INPUT.hideArrow,
            INPUT.overflowEllipsis,
            css`
              outline: none;
              background-color: ${COLORS2.sub.MrtGray3};
              padding: ${SPACING.spacing16};
              border-radius: 5px;
              width: 100%;

              &::placeholder {
                color: ${COLORS2.sub.MrtGray1};
              }
              /* &:focus &:focus-within { */
              &:focus-within {
                background-color: ${COLORS2.main.MrtWhite};
                border: 1px solid ${COLORS2.main.MrtBlue1};
                color: ${COLORS2.main.MrtBlack};
              }
            `,
            font.Body1_Bold,
            // viewmodel.ageInput.isFocused
            getStyleByStatus(viewmodel),
          )}
          placeholder={viewmodel.i.birthdayInput.isFocused ? 'YYMMDD' : '운전자 생년월일'}
          viewmodel={viewmodel.i.birthdayInput}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
        />
        {viewmodel.birthdayAgeValidation.status === 'success' && (
          <div
            className={cx(
              css`
                position: absolute;
                left: 80px;
                top: 0px;
                height: 100%;
                display: flex;
                align-items: center;
              `,
              font.Body1_Bold,
            )}
          >
            (만 {viewmodel.birthdayAgeValidation.value.age}세)
          </div>
        )}
        {viewmodel.birthdayAgeValidation.status === 'failure' && (
          <div
            className={cx(
              font.Caption1_Light,
              css`
                position: absolute;
                color: red;
                left: 0px;
                top: 100%;
                width: max-content;
              `,
            )}
          >
            {viewmodel.birthdayAgeValidation.failure.reason}
          </div>
        )}
      </div>
    </div>
  );
});
