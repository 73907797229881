import { z } from 'zod';

export function defineResponseWithData<
  TResponseData extends z.ZodTypeAny,
  TStatusCode extends number = 200,
>(
  dataType: TResponseData,
  statusCode: TStatusCode,
) {
  return z.object({
    data: dataType,
    status: z.literal(statusCode),
  });
}
