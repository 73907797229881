import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getFaqContents, getKeywords } from '../../api/faq';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import Loading from '../../components/Loading';
const MAX_HISTORY_LENGTH = 10;
const SEARCH_FAQ_HISTORY = 'search_faq';
import { FaqDesktop } from './FAQ.desktop';
import { FaqMobile } from './FAQ.mobile';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';

export const Help = () => {
  const [item, setItem] = useState('');
  const { isLoading, data } = useQuery('faqContents', getFaqContents);
  const { data: keywordData } = useQuery('keywords', getKeywords);
  const [faqContents, setFaqContents] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(0);
  const [defaultOpenQuestion, setDefaultOpenQuestion] = useState([]);
  const [resultTitleQuestionMobile, setResultTitleQuestionMobile] = useState([]);
  const [allQuestion, setAllQuestion] = useState([]);
  const listQuesRef = useRef({});
  const listArticleRef = useRef({});
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  useEffect(() => {
    data && data.data && setFaqContents(data.data);
    data &&
      data.data &&
      setAllQuestion(
        Object.values(data.data).reduce((a, b) => [...a, ...b]),
        []
      ); // Merge all question
  }, [data, isLoading]);

  const handleOpenDisclosure = () => {
    const keywordsList = item.split(' ');
    // find the keyword in the question
    const listQuestion = [];
    Object.values(keywordData.data).map((e, index) => {
      const result = e.filter((el) => keywordsList.includes(el));
      if (result.length > 0) {
        const article = Object.keys(keywordData.data)[index].split('||')[0];
        const articleIndex = Object.keys(faqContents).indexOf(article);
        setSelectedArticle(articleIndex);
        const question = Object.keys(keywordData.data)[index].split('||')[1];
        const questionRef = listQuesRef.current[`${question}`];
        // listArticleRef.current[article].click();
        if (questionRef && questionRef.getAttribute('aria-expanded') === 'false') {
          questionRef.click();
        }
        listQuestion.push(question);
      }
    });
    setDefaultOpenQuestion(listQuestion);
  };
  const handleSearchByKeyword = (key) => {
    const keywordsList = key.split(' ');
    const listQuestion = [];
    Object.values(keywordData.data).map((e, index) => {
      const result = e.filter((el) => keywordsList.includes(el));
      if (result.length > 0) {
        const question = { question: Object.keys(keywordData.data)[index].split('||')[1] };
        const article = Object.keys(keywordData.data)[index].split('||')[0];
        question.articleIndex = Object.keys(faqContents).indexOf(article);
        listQuestion.push(question);
      }
    });
    setDefaultOpenQuestion([]);
    setResultTitleQuestionMobile(listQuestion);
  };

  const handleOpenDisclosureMobile = (question) => {
    // Update article index
    setSelectedArticle(question.articleIndex);

    // Update Default list question
    setDefaultOpenQuestion([question.question]);

    // Open question was selected
    const questionRef = listQuesRef.current[`${question.question}`];
    if (questionRef && questionRef.getAttribute('aria-expanded') === 'false') {
      questionRef.click();
    }
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  if (isLoading && !faqContents) return <LoadingWithVendorLogo />;

  if (isMobile) {
    return (
      <FaqMobile
        selectedArticle={selectedArticle}
        t={t}
        faqContents={faqContents}
        setSelectedArticle={setSelectedArticle}
        listArticleRef={listArticleRef}
        item={item}
        isOpen={isOpen}
        setItem={setItem}
        handleOpenDisclosure={handleOpenDisclosure}
        setIsOpen={setIsOpen}
        handleSearchByKeyword={handleSearchByKeyword}
        resultTitleQuestionMobile={resultTitleQuestionMobile}
        handleOpenDisclosureMobile={handleOpenDisclosureMobile}
        listQuesRef={listQuesRef}
        allQuestion={allQuestion}
        defaultOpenQuestion={defaultOpenQuestion}
        setDefaultOpenQuestion={setDefaultOpenQuestion}
      />
    );
  }
  return (
    <FaqDesktop
      selectedArticle={selectedArticle}
      t={t}
      faqContents={faqContents}
      setSelectedArticle={setSelectedArticle}
      listArticleRef={listArticleRef}
      item={item}
      isOpen={isOpen}
      setItem={setItem}
      handleOpenDisclosure={handleOpenDisclosure}
      setIsOpen={setIsOpen}
      handleSearchByKeyword={handleSearchByKeyword}
      resultTitleQuestionMobile={resultTitleQuestionMobile}
      handleOpenDisclosureMobile={handleOpenDisclosureMobile}
      listQuesRef={listQuesRef}
      allQuestion={allQuestion}
      defaultOpenQuestion={defaultOpenQuestion}
    />
  );
};
