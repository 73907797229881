import { css } from '@emotion/css';

import { PADDINGS, COLORS2_AS_BACKGROUND, COLORS2_AS_FOREGROUND } from '^/constants/commonStyles';

import { BaseDialog } from './BaseDialog';

export const DialogWithCloseButton: React.FC<{
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void;
  contentWrapperClassName: string;
  content: React.ReactNode | ((args: {
    setIsOpen: (isOpen: boolean) => void;
  }) => React.ReactNode);
}> = (p) => {
  const content = typeof p.content === 'function' ? p.content({
    ...p,
  }) : p.content;
  return (
    <BaseDialog
      {...p}
      content={(
        <div
          className={[
            css`
              display: flex;
              flex-direction: column;
            `,
          ].join(' ')}
        >
          {content}
          <button
            type="button"
            className={[
              css`
                border-radius: 5px;
              `,
              COLORS2_AS_BACKGROUND.mainWithImportant.MrtBlue1,
              COLORS2_AS_FOREGROUND.main.MrtWhite,
              PADDINGS.TOP_AND_BOTTOM.spacing16,
            ].join(' ')}
            onClick={() => p.setIsOpen(false)}
          >
            확인
          </button>
        </div>
      )}
    />
  );
};
