import { css } from '@emotion/css';
import { Dialog } from '@headlessui/react';

import { borders, MARGINS } from '^/constants/commonStyles';

export const BaseDialog: React.FC<{
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void;
  contentWrapperClassName: string;
  content: React.ReactNode | ((args: {
    setIsOpen: (isOpen: boolean) => void;
  }) => React.ReactNode);
}> = ({
  isOpen,
  setIsOpen,
  contentWrapperClassName,
  content,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={[
        css`
          position: fixed;
          inset: 0;
          z-index: 1000000000;
          background-color: rgba(0,0,0,0.3);
          display: flex;
          justify-content: center;
          align-items: center;
        `,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <Dialog.Panel
        className={[
          contentWrapperClassName,
          // borders.DEBUG_purpleBorderDashed,
        ].join(' ')}
      >
        {typeof content === 'function'
          ? content({ setIsOpen })
          : content}
      </Dialog.Panel>
    </Dialog>
  );
};
