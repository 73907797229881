import { useState } from 'react';
import ReservationConfirmDesktop from './ReservationConfirm.Desktop';
import ReservationConfirmMobile from './ReservationConfirm.Mobile';
import { useMediaQuery } from 'react-responsive';

const ReservationConfirm = ({
  data,
  options,
  isTitle = true,
  isResult = false,
  isDetail = false,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      className={[
        'flex flex-col tablet:gap-6 mobile:gap-5',
        // borders.DEBUG_greenBorderDashed,
      ].join(' ')}
    >
      {isTitle && <h2 className="font-bold tablet:text-enomous mobile:text-semihuge">예약 확인</h2>}
      {isMobile ? (
        <ReservationConfirmMobile
          data={data}
          // options={options}
          isResult={isResult}
          isDetail={isDetail}
        />
      ) : (
        <ReservationConfirmDesktop
          data={data}
          options={options}
          isResult={isResult}
          isDetail={isDetail}
        />
      )}
    </div>
  );
};

export default ReservationConfirm;
