import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';

/**
 * Display map for rendering location point
 * @return {Object} Component display map
 */
const Map = ({
  zoom = 8,
  center = { lat: 0, lng: 0 },
  containerStyle = { height: '500px', width: '100%' },
  options = {},
  children,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  return (
    <div style={containerStyle}>
      {isLoaded ? (
        <GoogleMap
          // mapContainerStyle={{ width: '100%', height: '100%' }}
          mapContainerClassName={css`
            width: 100%;
            height: 100%;
          `}
          zoom={zoom}
          center={center}
          options={{ ...options }}
        >
          {children}
        </GoogleMap>
      ) : (
        <>
          <BlueLoadingSpinner />
        </>
      )}
    </div>
  );
};
Map.propTypes = {
  /**
   * Zoom ratio in the map
   */
  zoom: PropTypes.number,
  /**
   * Location in the center of the map
   */
  center: PropTypes.object,
  /**
   * Size of the map
   */
  containerStyle: PropTypes.object,
  /**
   * Options of the map
   */
  options: PropTypes.object,
  /**
   * Size of marker in the map
   */
  children: PropTypes.element,
};
export default React.memo(Map);
