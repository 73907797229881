import React from 'react';

import { observer } from 'mobx-react-lite';

import { MobxInputValue } from '^/util/MobxInputValue';

import { ConfirmDialogPopupDesktop } from './ConfirmDialogPopup.desktop';
import { ConfirmDialogPopupMobile } from './ConfirmDialogPopup.mobile';
import { ConfirmDialogStyles } from './ConfirmDialogPopup.styles';

export const ConfirmDialogPopup: React.FC<{
  title: React.ReactNode;
  visible: MobxInputValue<boolean>;
  cancelText?: string;
  confirmText: string;
  isDisabled?: boolean;
  handleConfirm(e: React.MouseEvent): void;
  handleCancel(e: React.MouseEvent): void;
  isMobile: boolean;
  stylesOverrides?: Partial<ConfirmDialogStyles>;
}> = observer(function ConfirmDialogPopup(p) {
  return (
    <>
      {p.isMobile ? (
        <ConfirmDialogPopupMobile cancelText="취소" {...p} />
      ) : (
        <ConfirmDialogPopupDesktop cancelText="취소" {...p} />
      )}
    </>
  );
});
