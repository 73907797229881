/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { css } from '@emotion/css';
import { Tab } from '@headlessui/react';

import { borders } from '^/constants/commonStyles';

import { classNames } from '../../util';

import { FaqQuestion } from './FAQ.Question';
import { HelpPageIDs } from './ids';

export const FaqDesktop:React.FC<{
  selectedArticle: any;
  t: any;
  faqContents: any;
  setSelectedArticle: any;
  listArticleRef: any;
  item: any;
  isOpen: any;
  setItem: any;
  handleOpenDisclosure: any;
  setIsOpen: any;
  handleSearchByKeyword: any;
  resultTitleQuestionMobile: any;
  handleOpenDisclosureMobile: any;
  listQuesRef: any;
  setDefaultOpenQuestion: any;
  allQuestion: any;
  defaultOpenQuestion: any;
}> = ({
  selectedArticle,
  t,
  faqContents,
  setSelectedArticle,
  listArticleRef,
  item,
  setItem,
  handleOpenDisclosure,
  setIsOpen,
  listQuesRef,
  allQuestion,
  defaultOpenQuestion,
}) => {
  return (
    <div
      className={[
        // borders.DEBUG_orangeBorderDashed,
        css`
          @media (max-width: 1280px) {
            padding-left: 24px;
            padding-right: 24px;
          }
        `,
        // borders.DEBUG_purpleBorderDashed,
      ].join(' ')}
    >
      <Tab.Group
        as="div"
        className=""
        selectedIndex={selectedArticle}
        onChange={setSelectedArticle}
      >
        <div
          className={[
            'w-full bg-white flex max-w-[1320px] flex-col gap-[40px] pb-[30px]',
            css`
              position: sticky;
              top: 0;
              z-index: 30;
              `,
            // borders.DEBUG_blueBorderDashed,
          ].join(' ')}
        >
          <div className="flex items-center justify-between">
            <h1
              className="w-full bg-white pt-[60px] text-enomous font-bold tracking-big"
            >
              {t('serviceCenter')}
            </h1>
          </div>
          <Tab.List as="div" className="hide-scrollbar flex flex-row gap-x-9 bg-white">
            <Tab className={({ selected }) => classNames(
              'text-primary-gray pt-0 pb-1.5  text-semilarge  font-bold outline-none min-w-max',
              selected
                ? 'border-b border-primary-skyblue font-bold'
                : 'font-light border-transparent',
            )}
            >
              {t('all')}
            </Tab>
            {faqContents && Object.keys(faqContents)?.map((category, index) => (
              <Tab
                key={index}
                className={({ selected }) => classNames(
                  'text-primary-gray pt-0 pb-1.5  text-semilarge font-bold outline-none min-w-max',
                  selected
                    ? 'border-b border-primary-skyblue font-bold'
                    : 'font-light border-transparent',
                )}
                ref={(ref) => {
                  listArticleRef.current[category] = ref;
                }}
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <div className={[
            'flex gap-6 ',
            css`
              width: 100%;
            `,
            // borders.DEBUG_orangeBorderDashed,
          ].join(' ')}
          >
            <input
              id={HelpPageIDs.searchInput}
              className={[
                'px-6 pt-[27px] pb-[25px] border',
                item ? 'border-primary-gray focus:border-primary-gray' : 'border-neutral-200 focus:border-primary-gray',
                'font-normal rounded-trimo-5 text-big text-primary-gray focus:border focus:outline-none',
                css`
                  /* flex-grow: 1; */
                  width: calc(100% - 75px);
                `,
              ].join(' ')}
              placeholder="검색어를 입력하세요"
              value={item}
              onChange={(e) => setItem(e.target.value)}
            />
            <button
              type="button"
              onClick={handleOpenDisclosure}
              className="h-[73px] min-w-[75px] rounded-trimo-5 bg-trimo-shadow px-[25.5px] text-center text-huge text-primary-gray "
            >
              <img alt="search-logo" src="./ic_search.svg" className="h-6 w-6" />
            </button>
          </div>
        </div>
        <Tab.Panels as="div">
          <Tab.Panel as="div" className="flex flex-row gap-4 ">
            <div className="flex basis-1/2 flex-col">
              {
                [...allQuestion]
                  ?.splice(0, Math.ceil(allQuestion.length / 2))
                  ?.map((item2, index) => (
                    <FaqQuestion
                      key={`question-all-first-${index}`}
                      defaultQuestion={defaultOpenQuestion}
                      item={item2}
                    />
                  ))
              }
            </div>
            <div className="basis-1/2">
              {[...allQuestion]
                ?.splice(Math.ceil(allQuestion.length / 2))
                ?.map((item, index) => (
                  <FaqQuestion
                    key={`question-all-second-${index}`}
                    defaultQuestion={defaultOpenQuestion}
                    item={item}
                  />
                ))}
            </div>
          </Tab.Panel>
          {
                faqContents && Object.values(faqContents)?.map((questions: any, idx) => (
                  <Tab.Panel key={`questions-${idx}`} as="div" className="flex flex-row gap-4 ">
                    <div className="flex basis-1/2 flex-col">
                      {
                        [...questions]?.splice(0, Math.ceil(questions.length / 2))?.map((item, index) => (
                          <FaqQuestion
                            key={`question-first-${index}`} defaultQuestion={defaultOpenQuestion} item={item}
                            listQuesRef={listQuesRef}
                          />
                        ))
                      }
                    </div>
                    <div className="basis-1/2">
                      {
                        [...questions]?.splice(Math.ceil(questions.length / 2))?.map((item, index) => (
                          <FaqQuestion
                            key={`question-second-${index}`} defaultQuestion={defaultOpenQuestion} item={item}
                            listQuesRef={listQuesRef}
                          />
                        ))
                      }
                    </div>
                  </Tab.Panel>
                ))
              }
        </Tab.Panels>

      </Tab.Group>
      {/* <FullScreenWrapper
        isShow={isOpen}
        leftIcon={<img onClick={() => setIsOpen(false)} src="/ic_x_black/ic_x_black_32.svg" className="h-8 w-8 hover:cursor-pointer" />}
      >
        <FaqContentSearchMobile handleSearchQuestion={handleSearchByKeyword} listQuestion={resultTitleQuestionMobile} handleSelectDisclosure={handleOpenDisclosureMobile} />
      </FullScreenWrapper> */}
    </div>
  );
};
