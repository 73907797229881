import { observer } from 'mobx-react-lite';

export const ListingSearchFilterCheckButton: React.FC<{
  condition: boolean;
  onClick?: () => void;
  onChange?: () => void;
}> = observer(function ListingSearchFilterCheckButton({
  condition,
  onClick,
}) {
  if (condition) {
    return (
      <img
        alt="checked"
        src="/ic_checkbox/ic_checkbox_n_checked.svg"
        onClick={onClick}
      />
    );
  }
  return (
    <img
      alt="none-checked"
      src="/ic_checkbox/ic_checkbox_n_empty.svg"
      onClick={onClick}
    />
  );
});
