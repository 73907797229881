import { z } from 'zod';

import {
  CityCodeType,
  CityNameType,
  CountryCodeType,
  LocationCategoryNameType,
  LocationCodeTypeRel_OAG,
  LocationNameType,
  RentalCarOfficeId,
} from '^/types/__BrandedLocationTypes';
import { LocaleType, VendorCompanyIDType } from '^/types/__BrandedTypes';
import { RawOpenHours } from '^/types/RawOpenHours';

import { defineResponseWithData } from '../DefineResponse';

export const RawRentalCarOfficeFromAPI = z
  .object({
    city_code: CityCodeType,
    hertz_code: z.string().optional(),
    location_id: RentalCarOfficeId,
    id: z.unknown(),
    vendor_id: VendorCompanyIDType,
    oag_code: LocationCodeTypeRel_OAG,
    country_code: CountryCodeType,
    state: z.string(),
    zip_code: z.string(),
    phone: z.string(),
    alt_phone: z.string(),
    fax: z.string(),
    telex: z.string(),
    web_url: z.string(),
    email: z.string(),
    latitude: z.string(),
    longitude: z.string(),
    loc_type_1: z.null(),
    loc_type_2: z.null(),
    city_name: CityNameType,
    category_id: LocationCategoryNameType,
    vendors: z.object({ logo: z.string() }),
    location_description: z.array(
      z.object({
        location_id: RentalCarOfficeId,
        locale: LocaleType,
        description: LocationNameType,
        address_1: z.string(),
        address_2: z.string(),
        address_3: z.string(),
      }),
    ),
  })
  .merge(RawOpenHours);

// eslint-disable-next-line @typescript-eslint/no-redeclare, max-len
export type RawRentalCarOfficeFromAPI = z.infer<typeof RawRentalCarOfficeFromAPI>;
export const GetCategoriesByCityResponseBody = z.array(
  z.object({
    id: LocationCategoryNameType,
    name: z.string(),
    icon: z.string(),
    location: z.array(RawRentalCarOfficeFromAPI),
  }),
);

// eslint-disable-next-line max-len, @typescript-eslint/no-redeclare
export type GetCategoriesByCityResponseBody = z.infer<typeof GetCategoriesByCityResponseBody>;

export const GetCategoriesByCityResponse = defineResponseWithData(
  GetCategoriesByCityResponseBody,
  200,
);
