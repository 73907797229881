import { enqueueRequest } from '^/apiQueue';
import {
  BOOKING_PATH,
  HOME_PATH,
  LISTING_PATH,
  MY_RESERVATION_PATH,
  RESERVATION_DETAIL,
  RESULT_PATH,
} from '^/constants/routes';
import { getSessionId, getUUID } from '^/util/user';

export const screen = {
  [LISTING_PATH]: '차량 리스트',
  car_details: '차량 상세',
  driver_details: '예약자 정보 입력',
  [MY_RESERVATION_PATH]: '예약 리스트',
  [RESULT_PATH]: '예약 완료',
  [RESERVATION_DETAIL]: '예약 상세',
  [HOME_PATH]: '홈화면',
};

export const actions = {
  '/': {
    ENTER: '진입',
    SELECT_POPULAR_CITY: '인기도시 선택',
    SEARCH_CITY: '도시 검색',
    SELECT_CITY: '도시 선택',
    SELECT_PICKUP_LOCATION: '대여장소 선택',
    SELECT_PICKUP_DATE: '대여일 선택',
    SELECT_PICKUP_HOUR: '대여시간(시간) 선택',
    SELECT_PICKUP_MINUTE: '대여시간(분) 선택',
    SELECT_RETURN_DATE: '반납일 선택',
    SELECT_RETURN_HOUR: '반납시간(시간) 선택',
    SELECT_RETURN_MINUTE: '반납시간(분) 선택',
    SUBMIT_SCHEDULE: '대여/반납 일정 적용',
    INPUT_BIRTHDAY: '생년월일 입력',
    CLICK_SEARCH_CARS: '렌터카 검색 터치',
  },
  CAR_LIST: {
    ENTER: '진입',
    CAR_LIST_COMPLETE: '차량 리스트 조회 완료',
    CLICK_CAR_RESERVATION: '차량 예약 터치',
    SELECT_POPULAR_CITY: '인기도시 선택',
    SELECT_CITY: '도시 선택',
    SELECT_PICKUP_LOCATION: '대여장소 선택',
    SELECT_PICKUP_DATE: '대여일 선택',
    SELECT_PICKUP_HOUR: '대여시간(시간) 선택',
    SELECT_PICKUP_MINUTE: '대여시간(분) 선택',
    SELECT_RETURN_DATE: '반납일 선택',
    SELECT_RETURN_HOUR: '반납시간(시간) 선택',
    SELECT_RETURN_MINUTE: '반납시간(분) 선택',
    SUBMIT_SCHEDULE: '대여/반납 일정 적용',
    INPUT_BIRTHDAY: '생년월일 입력',
    CLICK_SEARCH_CARS: '렌터카 검색 터치',
    CLICK_SHOP_ADDRESS: '매장 주소 클릭',
  },
  CAR_DETAIL: {
    ENTER: '진입',
    CAR_DETAIL_COMPLETE: '차량 상세 조회 완료',
    CLICK_INSURANCE_PACKAGE: '보험 패키지 선택',
    ADD_OPTION: '옵션 추가',
    SUBTRACT_OPTION: '옵션 삭제',
    SELECT_PICKUP_LOCATION: '픽업 장소 선택',
    SELECT_DROP_LOCATION: '드롭 장소 선택',
    WRITE_NUMBER_OF_PEOPLE: '픽업/샌딩 인원 수 입력',
    CLICK_DRIVER_DETAIL: '예약정보 입력하기 터치',
    CLOSE_FAIL_PAYMENT_POPUP: '결제 실패 팝업 계속 버튼 터치',
  },
  DRIVER_DETAIL: {
    ENTER: '진입',
    OPEN_SHOP_DETAIL: '대여 영업소 정보 자세히 보기',
    CLOSE_SHOP_DETAIL: '대여 영업소 정보 접기',
    CLICK_SHOP_PHONE_NUMBER: '영업소 전화번호 터치',
    CLICK_KOREAN_NAME_INPUT: '국문 이름 터치',
    WRITE_KOREAN_NAME_INPUT: '국문 이름 입력',
    CLICK_LAST_NAME_INPUT: '영문 성 터치',
    WRITE_LAST_NAME_INPUT: '영문 성 입력',
    CLICK_FIRST_NAME_INPUT: '영문 이름 터치',
    WRITE_FIRST_NAME_INPUT: '영문 이름 입력',
    CLICK_PHONE_COUNTRY_CODE_INPUT: '휴대폰 지역코드 터치',
    SELECT_PHONE_COUNTRY_CODE: '휴대폰 지역코드 선택',
    CLICK_PHONE_NUMBER_INPUT: '휴대폰 번호 터치',
    WRITE_PHONE_NUMBER_INPUT: '휴대폰 번호 입력',
    CLICK_EMAIL_INPUT: '이메일 터치',
    WRITE_EMAIL_INPUT: '이메일 입력',
    CLICK_HERTZ_MEMBERSHIP: '허츠 골드 멤버십 터치',
    WRITE_HERTZ_MEMBERSHIP: '허츠 골드 멤버십 입력',
    CLICK_FLIGHT_BRAND: '도착 비행기 정보 항공사 터치',
    SELECT_FLIGHT_BRAND: '도착 비행기 정보 항공사 선택',
    CLICK_FLIGHT_NUMBER: '도착 비행기 정보 항공편명 터치',
    WRITE_FLIGHT_NUMBER: '도착 비행기 정보 항공편명 입력',
    WRITE_DEPARTURE_DATE: '입국 일자 입력',
    CLICK_MILEAGE_BRAND: '항공 마일리지 제휴사 터치',
    SELECT_MILEAGE_BRAND: '항공 마일리지 제휴사 선택',
    CLICK_MILEAGE_NUMBER: '항공 마일리지 제휴사 회원번호 터치',
    WRITE_MILEAGE_NUMBER: '항공 마일리지 제휴사 회원번호 입력',
    OPEN_TEMRS_OF_USE: '이용약관 보기 클릭',
    CLOSE_TEMRS_OF_USE: '이용약관 접기 클릭',
    OPEN_TERMS_OF_CANCELLATION: '취소 및 환불규정 보기 터치',
    CLOSE_TEMRS_OF_CANCELLATION: '취소 및 환불규정 접기 터치',
    OPEN_TEMRS_OF_PERSONAL_INFO_COLLECTION: '개인정보 수집 및 이용 동의 보기 터치',
    CLOSE_TEMRS_OF_PERSONAL_INFO_COLLECTION: '개인정보 수집 및 이용 동의 접기 터치',
    OPEN_TEMRS_OF_PERSONAL_INFO_3RD_PARTIES: '개인정보 제3자 제공 동의 보기 터치',
    CLOSE_TEMRS_OF_PERSONAL_INFO_3RD_PARTIES: '개인정보 제3자 제공 동의 접기 터치',
    CHECK_TERMS_OF_USE: '이용약관 체크',
    UNCHECK_TERMS_OF_USE: '이용약관 체크 해제',
    CHECK_CANCELLATION_POLICY: '취소 및 환불규정 체크',
    UNCHECK_CANCELLATION_POLICY: '취소 및 환불규정 체크 해제',
    CHECK_PRIVACY_POLICY: '개인정보 수집 및 이용동의 체크',
    UNCHECK_PRIVACY_POLICY: '개인정보 수집 및 이용동의 체크 해제',
    CHECK_3RD_PARTY_POLICY: '개인정보 제3자 제공 동의 체크',
    UNCHECK_3RD_PARTY_POLICY: '개인정보 제3자 제공 동의 체크 해제',
    CHECK_AGREE_TERMS: '위 내용을 모두 확인 후 예약을 진행합니다 선택',
    UNCHECK_AGREE_TERMS: '위 내용을 모두 확인 후 예약을 진행합니다 선택 해제',
    CLICK_RESERVATION: '예약하기 터치',
    RESERVATION_COMPLETE: '예약 완료',
  },
  RESERVATION_RESULT: {
    ENTER: '진입',
    CLICK_HOME: '홈으로 터치',
    CLICK_RESERVATION_DETAIL: '예약 조회 터치',
  },
  RESERVATION_LIST: {
    ENTER: '진입',
    BOOKED_RESERVATION_LIST_COMPLETE: '완료된 예약 리스트 조회 완료',
    CANCELLED_RESERVATION_LIST_COMPLETE: '취소된 예약 리스트 조회 완료',
    CLICK_BOOKED_RESERVATION: '완료된 예약 터치',
    CLICK_CANCELLED_RESERVATION: '취소된 예약 터치',
    CLICK_RESERVATION_DETAIL: '예약 상세보기 터치',
  },
  RESERVATION_DETAIL: {
    ENTER: '진입',
    RESERVATION_DETAIL_COMPLETE: '예약 상세 조회 완료',
    OPEN_SHOP_DETAIL: '대여 영업소 정보 자세히 보기',
    CLOSE_SHOP_DETAIL: '대여 영업소 정보 접기',
    CLICK_RESERVATION_CANCEL: '예약 취소하기 터치',
    SELECT_CANCEL_REASON: '예약 취소 사유 선택',
    WRITE_CANCEL_REASON: '예약 취소 사유 입력',
    CLICK_CANCEL_BUTTON_ON_CANCEL_CHECK_MODAL: '예약 취소 확인 모달 취소 버튼 클릭',
    CLICK_CONFIRM_BUTTON_ON_CANCEL_CHECK_MODAL: '예약 취소 확인 모달 확인 버튼 클릭',
    RESERVATION_CANCEL_COMPLETE: '예약 취소 완료',
  },
};

export const checkScreen = () => {
  const { pathname } = window.location;
  const pathKeys = Object.keys(screen);

  for (const key of pathKeys) {
    if (pathname.includes(key)) {
      if (pathname === BOOKING_PATH) {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);

        const label = searchParams.get('label');
        return label ? screen[label] : screen.car_details;
      }
      return screen[key];
    }
  }
};

export const sendTrackingLog = async (trackingData: {
  uuid?: string;
  session_id?: string;
  screen?: string;
  action?: string;
  response_time?: number;
  data?: string | number;
  country?: string;
  fail_reason?: string;
  request_url?: string;
}) => {
  const { country } = trackingData;
  trackingData = {
    screen: checkScreen(),
    ...trackingData,
    uuid: getUUID(),
    session_id: getSessionId(),
    country: country || sessionStorage.getItem('selectedCountry') || '',
  };
  enqueueRequest({
    url: '/log/createTrackingLog',
    method: 'post',
    data: trackingData,
  });
};
