import { useRef } from 'react';

import { css } from '@emotion/css';
import { Tab } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Loading from '^/components/Loading';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { borders, MARGINS } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { classNames } from '../../../util';
import { ReservationDetailsPageViewModel } from '../ReservationDetailsPage.viewmodel';

import { ReservationDetailsBill } from './components/Bill';
import { ReservationDetailsDriverInfo } from './components/DriverInfo';
import { InsuranceInfoModal } from './components/InsuranceInfoModal';
import { ReservationDetailsVehicleInfo } from './components/VehicleInfo';

export const ReservationDetailPageMobile: React.FC<{
  viewmodel: ReservationDetailsPageViewModel;
  onClickCancelReservation: () => void;
}> = observer(function ReservationDetailMobile({ viewmodel, onClickCancelReservation }) {
  const { t } = useTranslation();
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  if (viewmodel.reservationDetail.status === 'loading') {
    return <LoadingWithVendorLogo />;
  }
  if (viewmodel.reservationDetail.status === 'failure') {
    return (
      <div>
        <h1> 에러 발생 </h1>
        <pre>{JSON.stringify(viewmodel.reservationDetail.failure, null, 2)}</pre>
      </div>
    );
  }
  const reservationDetails = viewmodel.reservationDetail.value;
  return (
    <div className="m-[20px]">
      <InsuranceInfoModal
        insuranceServices={reservationDetails.insuranceServices}
        insuranceName={
          reservationDetails.insurance_info_json.code
            ? t(`insurance_name.${reservationDetails.insurance_info_json.code}`)
            : '기본 할인요금'
        }
        isOpen={viewmodel.showInsuranceInfoMobilePopupPage}
        onCloseModal={() => {
          typeRouter.session.back(1);
        }}
      />
      <h2 className="fixed top-[60px] -mx-6 w-full bg-white py-5 px-6 text-semihuge font-bold">
        예약정보
      </h2>
      <Tab.Group
        as="div"
        className={[
          '-mx-6',
          css`
            /* TODO: needs min-height defined! */
            min-height: 380px;
          `,
        ].join(' ')}
        onClick={() => {
          // hackish, but rootDOM is the scroll area
          //    (rootDOM: defined in index.html...)
          setTimeout(() => {
            const rootDOM = document.getElementById('root');
            rootDOM?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }, 5);
        }}
      >
        <div className="fixed top-[126px] w-full bg-white">
          <Tab.List className="flex justify-between px-6">
            <Tab
              key="vehicle"
              className={({ selected }) =>
                classNames(
                  'pb-3 border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => {
                scrollAreaRef.current?.scrollTo(0, 0);
              }}
            >
              차량 및 일정
            </Tab>
            <Tab
              key="driver"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => window.scrollTo(0, 0)}
            >
              운전자 정보
            </Tab>
            <Tab
              key="fee"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => window.scrollTo(0, 0)}
            >
              요금 내역
            </Tab>
          </Tab.List>
          <hr className="h-px bg-secondary-gray-light" />
        </div>
        <Tab.Panels
          className={[
            'mt-[130px] px-6',
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
          ref={scrollAreaRef}
        >
          <Tab.Panel key="vehicle-info">
            <ReservationDetailsVehicleInfo
              data={viewmodel.reservationDetail.value}
              onClickCancelReservation={onClickCancelReservation}
              onClickShowInsuranceInfo={() => {
                viewmodel.setShowInsurancePopup(true);
              }}
            />
          </Tab.Panel>
          <Tab.Panel key="driver-info">
            <ReservationDetailsDriverInfo
              data={viewmodel.reservationDetail.value}
              onClickCancelReservation={onClickCancelReservation}
            />
          </Tab.Panel>
          <Tab.Panel key="fee-info">
            <ReservationDetailsBill
              data={viewmodel.reservationDetail.value}
              onClickCancelReservation={onClickCancelReservation}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
});
