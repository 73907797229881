/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

import qs from 'qs';
import { useLocation, useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';

import Button from '^/components/Button';
import { Layout2 } from '^/components/Layout/Layout2';
import { IMP_CREDENTIAL, IMP_INICIS } from '^/constants/envConfig';
import { PERSONAL_PAYMENT } from '^/constants/routes';
import useScript from '^/hooks/useScript';
import { errorButtonTextState, errorMsgState, errorState } from '^/recoil/atoms';

declare const window: typeof globalThis & {
  IMP: any;
};

const onClickPayment = (payment, setIsError, setErrorMsg, setErrorButtonText, navigate) => {
  const {
    price, phone_code = '+82', customer_name, email, phone_number,
  } = payment;

  // validate price
  if (!price || Number.isNaN(price)) {
    setIsError(true);
    setErrorMsg('priceNotValid');
    return;
  }

  const merchant_uid = phone_code.concat(phone_number, '_', new Date().getTime().toString());
  const baseURL = window.location.origin;
  try {
    const { IMP } = window;
    const m_redirect_url = `${baseURL}${PERSONAL_PAYMENT}/result`;

    // PC 결제요청 -> 결과값을 callback으로 받음 -> callback에서 이후 처리
    // Mobile 결제요청 -> 결과값을 m_redirect_url의 querystring으로 받음
    IMP.init(IMP_CREDENTIAL);
    IMP.request_pay(
      {
        pg: IMP_INICIS,
        pay_method: 'card',
        merchant_uid,
        name: `${customer_name}님 개인결제`,
        amount: price,
        buyer_name: customer_name,
        buyer_email: email,
        buyer_tel: phone_code.concat(phone_number),
        escrow: true,
        language: 'ko',
        // NOTE: 앱 내의 웹뷰에서 실행할 시 해당 주소로 Redirect
        m_redirect_url,
      },
      async (response) => {
        if (response.success) {
          // NOTE: PC에서 결제 성공
          const desktopPaymentResult = {
            imp_uid: response.imp_uid as string,
            merchant_uid: response.merchant_uid,
            imp_success: response.success ? 'true' : 'false',
            error_code: response.error_code || '',
            error_msg: response.error_msg || '',
          };
          const resultQuery = qs.stringify(desktopPaymentResult);

          navigate(`${PERSONAL_PAYMENT}/result?${encodeURI(resultQuery)}`, {
            replace: true,
          });
        } else {
          console.log('Failed', response);
          // NOTE: PC 결제 실패 또는 취소
          setIsError(true);
          setErrorButtonText('닫기');
          setErrorMsg(response.error_msg);
        }
      },
    );
  } catch (e) {
    // 결제 실패 시
    console.log(e);
    setIsError(true);
    setErrorMsg('결제가 실패했습니다. 다시 시도해주세요.');
  } finally {
    setIsError(false);
  }
};

const PersonalPayment = () => {
  useScript('https://code.jquery.com/jquery-1.12.4.min.js');
  useScript('https://cdn.iamport.kr/js/iamport.payment-1.2.0.js');

  const setIsError = useSetRecoilState(errorState);
  const setErrorMsg = useSetRecoilState(errorMsgState);
  const setErrorButtonText = useSetRecoilState(errorButtonTextState);
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);

  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const {
      price, phone_code = '+82', customer_name, email, phone_number,
    } = queryParams;

    if (!price || !phone_code || !customer_name || !email || !phone_number) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('PERSONAL_PAYMENT', JSON.stringify(queryParams));
  }, [queryParams]);

  if (!isValid) {
    return (
      <Layout2>
        <div className="my-[150px] flex flex-col items-center">
          <h1 className="text-large font-bold">
            개인결제 URL이 올바르지 않습니다. 고객센터에 문의해주세요.
          </h1>
          <Button
            classNames="rounded-trimo mt-[10px] text-base font-bold tracking-default px-6 py-[18px]"
            color="textBlackSkyblue"
            onClick={() => {
              window.location.href = window.location.origin;
            }}
            content="홈으로"
          />
        </div>
      </Layout2>
    );
  }
  return (
    <Layout2 withoutOverflowScroll>
      <div className="my-[150px] flex flex-col items-center">
        <h1 className="text-large font-bold">{`${queryParams.customer_name}님 개인결제 페이지`}</h1>
        <Button
          classNames={[
            `mobile:text-big
            tablet:text-semilarge
            rounded-trimo-5
            py-4.5
            text-gray
            disabled:text-white
            disabled:secondary-gray-light
            bg-primary-skyblue
            hover:bg-primary-skyblue
            h-[56px]
            mt-[10px]
          `,
            // borders.DEBUG_goldDashed,
          ].join(' ')}
          color="skyblue"
          content={`${Number(queryParams.price).toLocaleString()}원 결제하기`}
          onClick={() => onClickPayment(
            {
              price: queryParams.price,
              phone_code: queryParams.phone_code,
              phone_number: queryParams.phone_number,
              customer_name: queryParams.customer_name,
              email: queryParams.email,
            },
            setIsError,
            setErrorMsg,
            setErrorButtonText,
            navigate,
          )}
        />
      </div>
    </Layout2>
  );
};

export default PersonalPayment;
