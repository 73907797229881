import { css } from '@emotion/css';

export const defaultCalendarWithTimeMobileStyles = {
  scheduleWrapper: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F7FBFF;
    border-radius: 12px;
    padding: 16px;
  `,
  title: css`
    color: #434343;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  date: css`
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    width: 70%;
  `,
  dateTimeWrapper: css`
    display: flex;
    align-items: center;
  `,
  timeSelectWrapper: css`
  width: 100%;
`,
} as const;
