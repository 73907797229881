import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as XIcon } from '^/assets/icons/ic_x_black_32.svg';
import { BaseDialog } from '^/commonComponents/ui/BaseDialog';
import {
  borders, MARGINS, PADDINGS, COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';

export const InsuranceInfoModal: React.FC<{
  insuranceName: string,
  insuranceServices: string[],
  isOpen: boolean,
  onCloseModal: () => void,
}> = ({
  insuranceName,
  insuranceServices,
  isOpen,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  // const insurance = data.insurance_info_json;
  return (
    <BaseDialog
      isOpen={isOpen}
      setIsOpen={(nextIsOpen) => {
        // impossible to reach here: modal is fullscreen...
      }}
      contentWrapperClassName={[
        css`
          position: fixed;
          inset: 0;
          overflow-y: scroll;
        `,
        COLORS2_AS_BACKGROUND.main.MrtWhite,
        // borders.DEBUG_greenBorderDashed,
      ].join(' ')}
      content={(
        <div
          className={[
          ].join(' ')}
        >
          <div
            className={[
              css`
                position: sticky;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px ${COLORS2.sub.MrtGray2} solid;
              `,
              COLORS2_AS_BACKGROUND.main.MrtWhite,
              font2.Headline1_Bold,
              PADDINGS.LEFT_AND_RIGHT.spacing24,
              PADDINGS.TOP_AND_BOTTOM.spacing12,
              // borders.DEBUG_orangeBorderDashed,
            ].join(' ')}
          >
            <XIcon
              onClick={() => {
                onCloseModal();
              }}
            />
            <span> 보험정보 </span>
            <div />
          </div>
          <div
            className={[
              PADDINGS.LEFT_AND_RIGHT.spacing24,
              PADDINGS.TOP_AND_BOTTOM.spacing24,
            ].join(' ')}
          >
            <span
              className={[
                font2.Headline1_Bold,
                MARGINS.TOP.spacing24,
              ].join(' ')}
            >
              {insuranceName}
            </span>

            <ul
              className={[
                css`
                  list-style-type: disc;
                `,
                MARGINS.TOP_AND_BOTTOM.spacing24,
                MARGINS.LEFT_AND_RIGHT.spacing24,
              ].join(' ')}
            >
              {insuranceServices.map((i) => {
                return (
                  <li
                    className={[
                      MARGINS.TOP.spacing24,
                    ].join(' ')}
                  >
                    <p
                      className={[
                        font2.Body1_Bold,
                        COLORS2_AS_FOREGROUND.main.MrtBlack,
                      ].join(' ')}
                    >
                      {t(`service_name.${i}.ko_name`)}
                    </p>
                    <p
                      className={[
                        font2.Caption1_Light,
                        COLORS2_AS_FOREGROUND.sub.MrtGray1,
                        MARGINS.TOP.spacing6,
                      ].join(' ')}
                    >
                      {t(`service_name.${i}.en_name`)}
                    </p>
                    <p
                      className={[
                        font2.Caption1_Light,
                        COLORS2_AS_FOREGROUND.main.MrtBlack,
                        MARGINS.TOP.spacing12,
                      ].join(' ')}
                    >
                      {t(`service_name.${i}.details`)}
                    </p>

                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    />
  );
};
