import { isPast, isToday } from 'date-fns';

export const isReservationCancelDisabled = ({
  pickupDate,
  cancelEnabled,
  payType,
}: {
  pickupDate: Date,
  cancelEnabled: boolean;
  payType: 'POD' | 'PPD'
}) => {
  if (payType === 'PPD' && !cancelEnabled) return true;
  if (isToday(pickupDate)) return true;
  if (isPast(pickupDate)) return true;
  return false;
};
