import { cancelReservationAPI } from '^/api2/cancelReservation/cancelReservationAPI';
import { getCarListAPI } from '^/api2/getCarList/getCarListAPI';
import { byCityWithJapanCity } from '^/api2/getRentalCarOfficeLocationsByCity/byCityWithJapanCity';
import { getReservationDetailAPI } from '^/api2/getReservationDetail/getReservationDetailAPI';
import { otClientAPI } from '^/api2/OTClientAPI/otClientAPI';
import { searchCityAPI } from '^/api2/SearchOLD/searchCity';
import { createListCoreFromAPI } from '^/BaseListCore/createListCoreFromAPI';
import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import { MobxValue } from '^/util/MobxValue';

import { RentalOfficeLocationsByCityCodeCore } from './RentalOfficeLocationByCityCodeCore';
import { RentalCarOfficeLocationRepo } from './RentalOfficeLocationRepo';
import { ReservationDetailCore } from './ReservationDetailCore';
import { ReservationDetailRepo } from './ReservationDetailRepo';

const searchCityListCore = createListCoreFromAPI(
  searchCityAPI,
)(
  (item) => item.code,
)();

const rentalCarOfficeLocationsRepo = new RentalCarOfficeLocationRepo();
const rentalCarOfficeLocationsCore = new RentalOfficeLocationsByCityCodeCore(
  byCityWithJapanCity,
  rentalCarOfficeLocationsRepo,
);

const getCarListCore = createListCoreFromAPI(
  getCarListAPI,
)(
  (item) => item.key,
)();

const getOTCarListCore = createListCoreFromAPI(
  otClientAPI,
)(
  (item) => item.plan_info.code,
)();

const carListingSearchBoxInput = new MobxValue<CarListingSearchBoxInputViewModel | null>(
  null,
);

const reservationDetailRepo = new ReservationDetailRepo();

const getReservationDetailCore = new ReservationDetailCore(
  getReservationDetailAPI,
  cancelReservationAPI,
  reservationDetailRepo,
);

export const defaultAppContext = {
  apis: {
    searchCityAPI,
  },
  searchCityListCore,
  searchCityRepo: searchCityListCore.repo,

  rentalCarOfficeLocationsRepo,
  rentalCarOfficeLocationsCore,

  getCarListCore,
  getOTCarListCore,

  getReservationDetailCore,

  // hackish, but...
  carListingSearchBoxInput,
} as const;

export type AppContext = typeof defaultAppContext;

if (window) {
  (window as any).defaultAppContext = defaultAppContext;
}
