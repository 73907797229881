import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import { VENDOR_LOGOS } from '^/constants/LogoImages.constants';
import { useMediaQuery } from 'react-responsive';
import { calculateHours } from '../../../../../util';
import { GroupCar, NameCar } from '../../../../../components/ModelCar';
import { HoverToReveal } from '^/commonComponents/ui/HoverToReveal';
import { InsuranceInfoBox } from '^/components/InsuranceInfo/insuranceInfo';
import { css } from '@emotion/css';
import { InsuranceInfoModal } from '^/pages/ReservationDetail/mobile/components/InsuranceInfoModal';
import { TABLET_SIZE } from '^/constants/envConfig';
import { translateServices } from '^/pages/Listing/common/translateServices';

const ReservationConfirm = ({
  isBooking,
  bookingInfo,
  pkgSelected,
  isTitle = true,
  isTempPPD,
  resId,
}) => {
  const { t } = useTranslation();
  const [isTablet, setIsTablet] = useState(window.innerWidth <= TABLET_SIZE);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { carInfo } = bookingInfo;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = useCallback(() => {
    if (window.innerWidth <= TABLET_SIZE) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }, []);

  const [showInsuranceDetailPopup, setShowInsuranceDetailPopup] = useState(false);
  const insurance = carInfo.insurances.find((a) => a.package === pkgSelected);
  const insuranceServices = insurance.packageInfo;

  return (
    <div className="flex flex-col tablet:gap-6 mobile:gap-5">
      {isTitle && <h2 className="font-bold tablet:text-enomous mobile:text-semihuge">예약 확인</h2>}
      {isMobile ? (
        <div className="-mx-6 px-6 py-5 bg-trimo-bg flex flex-col gap-4">
          <InsuranceInfoModal
            insuranceName={pkgSelected ? t(`insurance_name.${pkgSelected}`) : '기본 할인요금'}
            insuranceServices={insuranceServices}
            isOpen={showInsuranceDetailPopup}
            onCloseModal={() => setShowInsuranceDetailPopup(false)}
          />
          <div className="bg-white rounded-trimo p-4 flex flex-col gap-1.5">
            <div className="flex justify-between items-start">
              <GroupCar
                modelName={carInfo.vehInfo.name}
                className="font-bold capitalize text-big"
                size={carInfo.vehInfo?.size}
                type={carInfo.vehInfo?.type}
              />
              <img
                alt="car-image"
                src={VENDOR_LOGOS[carInfo?.vehInfo?.company]}
                className="object-scale-down w-11 h-5"
              />
            </div>
            <NameCar modelName={carInfo?.vehInfo?.name} className="text-small font-light" />
            <p className="text-small font-light text-neutral-300">
              {translateServices(carInfo?.services, t)}
            </p>
          </div>
          <div className="bg-white rounded-trimo p-4 flex flex-col gap-4">
            <div className="flex flex-col gap-3">
              <div className="text-big font-bold">
                {t('rentaled')} / {t('return')} {t('location1')}
              </div>
              <div className="text-default font-light">
                {t(`location_name.${carInfo?.coreInfo?.pickup?.code}`)} /{' '}
                {t(`location_name.${carInfo?.coreInfo?.return?.code}`)}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-big font-bold">
                {t('rentaled')} / {t('return')} {t('schedule')}
              </div>
              <div className="text-default font-light">
                <span>
                  {`${format(
                    new Date(carInfo?.coreInfo?.pickup?.time),
                    'y.MM.dd HH:mm',
                  )} ~ ${format(new Date(carInfo?.coreInfo?.return?.time), 'MM.dd HH:mm')}`}
                </span>
                <span>
                  {' '}
                  (
                  {calculateHours(
                    new Date(carInfo?.coreInfo?.return?.time),
                    new Date(carInfo?.coreInfo?.pickup?.time),
                  )}{' '}
                  {t('hourUnit')})
                </span>
              </div>
            </div>
            <div
              className={[
                'flex flex-col gap-3',
                // borders.DEBUG_orangeBorderDashed,
              ].join(' ')}
            >
              <div className="text-big font-bold">{t('insuranceDetails')}</div>
              <div
                className={[
                  'text-default font-light',
                  css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  `,
                ].join(' ')}
              >
                {t(
                  'insurance_name.' +
                    carInfo?.insurances.find((item) => item.package === pkgSelected)?.package,
                )}
                <InfoIcon
                  className={css`
                    width: 20px;
                    height: 20px;
                    margin-left: 6px;
                  `}
                  onClick={() => {
                    setShowInsuranceDetailPopup(true);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-big font-bold">{t('moreOptions')}</div>
              <div className="text-default font-light">
                {carInfo?.equipsCharge.length > 0 ? (
                  <span className="flex flex-wrap">
                    {carInfo?.equipsCharge
                      .filter((item) => Number(item.quantity) > 0)
                      .map((equip) => `${t(`equipment_${equip.code}`)} (${equip.quantity})`)
                      .join(' / ')}
                  </span>
                ) : (
                  <span>{t('none')}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-primary-white rounded-trimo shadow-trimodropdown tablet:p-[30px] mobile:p-4 text-primary-gray">
          <div className="flex justify-between items-center">
            <GroupCar
              modelName={carInfo?.vehInfo?.name}
              className="font-bold capitalize table:text-enomous mobile:text-semilarge"
              size={carInfo?.vehInfo?.size}
              type={carInfo?.vehInfo?.type}
            />
            <img
              alt="car-image"
              src={VENDOR_LOGOS[carInfo?.vehInfo?.company]}
              className="object-scale-down tablet:h-[26px] tablet:w-[72px] mobile:w-[50px]"
            />
          </div>
          <div className="flex font-light text-default mt-1.5 mb-3">
            <NameCar modelName={carInfo?.vehInfo?.name} className="text-default" />
          </div>
          <p className="mt-2 tablet:text-semilarge mobile:text-small font-normal text-neutral-300">
            {translateServices(carInfo?.services, t)}
          </p>
          <div className="tablet:mt-[30px] mobile:mt-6 flex flex-col tablet:gap-6 mobile:gap-4">
            <div className="flex tablet:justify-between tablet:items-center tablet:gap-[50px]">
              <div className="tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold">
                {t('rentaled')} / {t('return')} {t('location1')}
              </div>
              <div className="grow text-left tablet:text-big mobile:text-default">
                {t(`location_name.${carInfo?.coreInfo?.pickup?.code}`)} /{isTablet && <br />}{' '}
                {t(`location_name.${carInfo?.coreInfo?.return?.code}`)}
              </div>
            </div>
            <div className="flex tablet:justify-between tablet:items-center tablet:gap-[50px]">
              <div className="tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold">
                {t('rentaled')} / {t('return')} {t('schedule')}
              </div>
              <div className="grow text-left tablet:text-big mobile:text-default">
                <span>
                  {`${format(new Date(carInfo?.coreInfo?.pickup.time), 'y.MM.dd HH:mm')} ~ ${
                    isTablet ? <br /> : ''
                  } ${format(new Date(carInfo?.coreInfo?.return?.time), 'MM.dd HH:mm')}`}
                </span>
                <span>
                  {' '}
                  (
                  {calculateHours(
                    new Date(carInfo?.coreInfo?.return?.time),
                    new Date(carInfo?.coreInfo?.pickup?.time),
                  )}{' '}
                  {t('hourUnit')})
                </span>
              </div>
            </div>
            <div
              className={[
                'flex tablet:justify-between tablet:items-center tablet:gap-[50px]',
                // borders.DEBUG_purpleBorderDashed,
                css`
                  position: relative;
                `,
              ].join(' ')}
            >
              <div className="tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold">
                {t('insuranceDetails')}
              </div>
              <div className="grow text-left tablet:text-big mobile:text-default flex flex-row items-center gap-2">
                <p>{pkgSelected ? t(`insurance_name.${pkgSelected}`) : '기본 할인요금'}</p>
                <HoverToReveal
                  toReveal={
                    <InsuranceInfoBox
                      insuranceName={
                        pkgSelected ? t(`insurance_name.${pkgSelected}`) : '기본 할인요금'
                      }
                      insuranceServices={insurance.packageInfo}
                    />
                  }
                  toRevealAreaStyle={[
                    css`
                      position: absolute;
                      top: 0;
                      left: -30px;
                      right: -30px;
                      bottom: -6px;
                    `,
                    // borders.DEBUG_orangeBorderDashed,
                  ].join(' ')}
                />
              </div>
            </div>
            <div className="flex tablet:justify-between tablet:items-center tablet:gap-[50px]">
              <div className="tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold">
                {t('moreOptions')}
              </div>
              <div className="tablet:grow mobile:basis-1/2 text-left tablet:text-big mobile:text-default">
                {carInfo?.equipsCharge.length > 0 ? (
                  <span className="flex flex-wrap">
                    {carInfo?.equipsCharge
                      .filter((item) => Number(item.quantity) > 0)
                      .map((equip) => {
                        return `${t(`equipment_${equip.code}`)} (${equip.quantity})`;
                      })
                      .join(' / ')}
                  </span>
                ) : (
                  <span>{t('none')}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ReservationConfirm.propTypes = {
  isBooking: PropTypes.bool,
  bookingInfo: PropTypes.object.isRequired,
  pkgSelected: PropTypes.string,
  isTitle: PropTypes.bool,
};

export default ReservationConfirm;
