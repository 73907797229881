/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { Fragment, useEffect } from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { MRTGoogleMap } from '^/components/CarSearchBox/myrealtrip/common/MRTGoogleMap';
import { MRTSearchStepperMobile } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SearchStepper/MRTSearchStepper.mobile';
import { SPACING, COLORS2 } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { PromiseResultType } from '^/types/__ResultType';

import MRTSelectedOfficeInfo from './MRTSelectedOfficeInfo';

export const MRTSelectedReturnOfficeInfoMobile: React.FC<
{
  key: string,
  viewmodel: CarListingSearchBoxViewModel,
  officeViewmodel: PromiseResultType<any, any, any>,
  rootStep?: SearchBoxSteps,
}
> = observer(function MRTSelectedReturnOfficeInfoMobile({
  key,
  viewmodel,
  officeViewmodel,
  rootStep,
}) {
  const mobileWebVhSetting = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    mobileWebVhSetting();
  }, []);

  useEffect(() => {
    if (
      !viewmodel.i.tempReturnLocationId.value
      && !viewmodel.i.returnLocationId.value
    ) {
      typeRouter.session.back();
    }
  }, []);

  return (
    <Fragment key={key}>
      <ViewByState
        data={officeViewmodel}
        loadingView={<h1>loading</h1>}
        errorView={() => {
          return <h1>error</h1>;
        }}
      >
        {(returnVM) => {
          if (!returnVM.tempSelectedOfficeId.value) {
            returnVM.tempSelectedOfficeId.set(viewmodel.i.returnLocationId.value);
          }
          const office = returnVM.tempSelectedOfficeData || returnVM.selectedOfficeData;
          const officePosition = office && {
            lat: Number(office.latitude),
            lng: Number(office.longitude),
          };
          return (
            <div
              className={cx(
                css`
              padding: 0 20px;
              margin-top: 68px;
              position: relative;
              height: 100%;
              overflow-y: scroll;
            `,
              )}
            >
              <MRTSearchStepperMobile currentStep={3} />
              <div
                className={cx(
                  css`
                  margin-top: 20px;
                  color: #1E1E1E;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 28px;
                `,
                )}
              >
                반납 영업소 정보를 확인하세요.
              </div>
              <div
                className={
                css`
                  height: auto;
                `
              }
              >
                <div
                  className={
                  css`
                  border: 1px solid #e1e1e1;
                  border-radius: 12px;
                  padding: 20px 20px 24px;
                  margin-top: 24px;
                  margin-bottom: 145px;
                `
                }
                >
                  <MRTSelectedOfficeInfo
                    viewmodel={returnVM}
                  />
                  {officePosition && (
                  <MRTGoogleMap
                    viewmodel={returnVM}
                    officePosition={officePosition}
                  />
                  )}
                </div>
                <div
                  className={
                  css`
                  padding-right: 40px;
                  margin-top: ${SPACING.spacing24};
                  position: fixed;
                  display: flex;
                  width: 100%;
                  bottom: 0;
                  `
                }
                >
                  <button
                    id="step2_5_btn"
                    type="button"
                    onClick={() => {
                      viewmodel.i.returnCityCode.set(viewmodel.i.tempReturnCityCode.value);
                      viewmodel.i.returnLocationId.set(viewmodel.i.tempReturnLocationId.value);
                      viewmodel.i.returnCitySearch.searchInput.set(viewmodel.i.tempReturnCitySearch.tempSearchInput.value);
                      if (rootStep) {
                        viewmodel.stepManager.replaceStep(rootStep);
                      } else {
                        viewmodel.stepManager.replaceStepsInRoute([]);
                      }
                    }}
                    className={css`
                      height: ${SPACING.spacing56};
                      width: 100%;
                      background-color: ${COLORS2.main.MrtBlue1} !important;
                      color: ${COLORS2.main.MrtWhite};
                      border-radius: 8px;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 22px;
                    `}
                  >
                    확인
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </ViewByState>
    </Fragment>

  );
});
