/* eslint-disable jsx-a11y/no-static-element-interactions */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import {
  borders, SPACING, COLORS2, font,
} from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { CarListingSearchBoxViewModel } from '../desktop/viewmodel/CarListingSearchBox.viewmodel';

import { CarListingBottomSheetHeader } from './BottomSheet/CarListingBottomSheet.header';
import { ages } from './Step4.birthday.ages';

export const Step4BirthDay: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
}> = observer(function Step4BirthDay({
  viewmodel,
}) {
  return (
    <div className={cx(
      css`
        height: 100%;
      `,
      // borders.DEBUG_goldDashed,
    )}
    >
      <CarListingBottomSheetHeader
        title="운전자 연령"
        leftCornorBtnType="prevStack"
        stepManager={viewmodel
          .stepManager}
      />
      <div
        className={cx(
          css`
            display: flex;
            flex-direction: column;

            height: calc(100% - 56px);

            padding: ${SPACING.spacing24};
            padding-bottom: 0;
            padding-top: 12px;

            overflow-y: scroll;
          `,
        )}
      >
        <div className={cx(
          font.Caption1_Bold,
          css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: ${SPACING.spacing16};
            color: ${COLORS2.sub.MrtGray1};
          `,
        )}
        >
          {ages.map((obj) => (
            <button
              type="button"
              key={obj.text}
              onClick={() => viewmodel.i.tempBirthdayInput.set(obj.age)}
              className={cx(
                css`
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  background-color: ${COLORS2.sub.MrtGray3} !important;
                  border-radius: 5px;
                  height: ${SPACING.spacing56};
                `,
                viewmodel.i.tempBirthdayInput.value === obj.age && css`
                  background-color: ${COLORS2.main.MrtBlue1} !important;
                  color: ${COLORS2.main.MrtWhite};
                `,
              )}
            >{obj.text}
            </button>
          ))}
        </div>
        <div className={cx(
          font.Caption2_Light,
          css`
            margin-top: 12px;
          `,
        )}
        >
          만 나이는 렌트 예약일 기준입니다.
        </div>
        <div className={
          css`
            margin-top: auto;
            position: sticky;
            bottom: 0;
            background-color: ${COLORS2.main.MrtWhite};
          `
        }
        >
          <button
            type="button"
            onClick={() => {
              if (viewmodel.i.tempBirthdayInput.value) {
                viewmodel.i.birthdayInput.copyFrom(
                  viewmodel.i.tempBirthdayInput,
                );
                typeRouter.session.back();
              }
            }}
            className={css`
              background-color: ${COLORS2.main.MrtBlue1} !important;


              width: 100%;
              min-height: ${SPACING.spacing56};

              margin-bottom: ${SPACING.spacing16};
              border-radius: 5px;
              color: ${COLORS2.main.MrtWhite};
            `}
          >
            적용
          </button>
        </div>
      </div>
    </div>
  );
});
