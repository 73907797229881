import { AxiosInstance } from 'axios';

import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';

import { api2BaseAxios } from '../api2BaseAxios';
import { zodValidateAxiosResp } from '../zodValidate';

import { BASE_PATH } from './SEARCH_BASE_PATH';
import { SearchCityAPIResponse } from './searchCity.responseType';

const searchCityValidator = zodValidateAxiosResp(SearchCityAPIResponse, (err) => {
  // FIXME: send report to devs..?
  console.error('searchCityValidator err: ', err);
});

export function createSearchCityAPI(axiosInstance: AxiosInstance) {
  return async function searchCity(params: { search?: string }) {
    const queryString = new URLSearchParams(params).toString();
    try {
      const response = await axiosInstance.get(`${BASE_PATH}?${queryString}`);
      const validated = searchCityValidator(response);
      if (validated.data.status !== 200) {
        return asFailedPromise({
          reason: 'HTTPError',
          err: response.data,
          rawResponse: response,
          validated,
        } as const);
      }
      return asSuccessPromise({
        items: validated.data.data,
        ...validated,
      } as const);
    } catch (e) {
      console.error('searchCity error: ', e);
      throw e;
    }
  };
}

export const searchCityAPI = createSearchCityAPI(api2BaseAxios);
