import { action, makeObservable } from 'mobx';

import {
  computedFn2,
} from '^/util/mobx-utils/mobxComputedFn2';

export class BaseItemsRepository<
  TSuccess,
  TItemId extends string | number = string,
> {
  constructor(
    public data = new Map<TItemId, TSuccess>(),
    /* eslint-enable @typescript-eslint/indent */
  ) {
    makeObservable(this, {
      getById: false,
      setById: action.bound,
      data: true,
    });
  }

  getById = computedFn2((id: TItemId) => {
    const result = this.data.get(id);
    return result;
  });

  setById(
    id: TItemId,
    item: TSuccess,
  ) {
    this.data.set(id, item);
  }
}

export type ExtractItemsRepository<TRepo> = TRepo extends BaseItemsRepository<
infer TValue, infer TIDType
> ? {
    value: TValue,
    id: TIDType,
  } : never;
