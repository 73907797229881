import { css } from '@emotion/css';

import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { borders, SPACING } from '^/constants/commonStyles';
import { SkeletonCarInfoDesktop } from '^/pages/Listing/base/desktop/Skeleton.CarInfo.Desktop';

export const ListingPageDesktopLoading = () => {
  return (
    <>
      <div
        className={[
          css`
            display: flex;
            flex-direction: column;
            gap: ${SPACING.spacing32};
            width: 100%;
            max-width: 1320px;
            margin-left: auto;
            margin-right: auto;
          `,
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        <SkeletonCarInfoDesktop />
        <SkeletonCarInfoDesktop />
        <SkeletonCarInfoDesktop />
      </div>
      <LoadingWithVendorLogo />
    </>
  );
};
