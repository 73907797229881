export const HOME_PATH = '/';
export const NOT_FOUND_PATH = '/not-found';
export const MAPLOCATION_PATH = '/map';
export const LISTING_PATH = '/listing';
export const BOOKING_PATH = '/booking';
export const RESULT_PATH = '/result';
export const MY_RESERVATION_PATH = '/my-reservation';
export const HELP_PAGE = '/help';
// export const NOTICE = '/notice';
export const NOTICE_DETAIL = '/notice_detail';
export const RESERVATION_DETAIL = '/reservation_detail';
export const RESERVATION_CHECK = '/reservation_check';
export const PERSONAL_PAYMENT = '/personal-payment';
export const PERSONAL_PAYMENT_RESULT = '/personal-payment/result';
export const PAYMENT_PENDING = '/payment-pending';
