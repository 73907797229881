import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { airlineMileageProgramMap } from '^/constants/airlineMileageProgram';
import {
  borders, COLORS, MARGINS, COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';

import { flexSplitHorizontalInHalf } from './ReservationDetailDesktop.commonStuff';

export const ReservationDetailsDriverInfoMemberships: React.FC<{
  data: ReservationDetailed,
}> = observer(function ReservationDetailsDriverInfoMemberships({
  data,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={[
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      {(data?.hertz_membership
        || data?.flight_number
        || (data?.frequent_flyer && data.frequent_brand)
      ) && (
        <hr
          className={[
            MARGINS.BOTTOM.spacing48,
            COLORS2_AS_BACKGROUND.sub.MrtGray2,
            css`
              height: 1px;
            `,
          ].join(' ')}
        />
      )}
      {
        data?.hertz_membership
        && (
          <div>
            <div
              className={[
                css`
                  display: flex;
                  align-items:flex-end;
                  gap: 8px;
                `,
                // borders.DEBUG_greenBorderDashed,
              ].join(' ')}
            >
              <h2
                className={[
                  font2.Title2,
                ].join(' ')}
              >허츠 골드 멤버십
              </h2>
              <span
                className={[
                  COLORS2_AS_FOREGROUND.sub.MrtGray1,
                  font2.Body1_Regular,
                ].join(' ')}
              >
              </span>
            </div>
            <p
              className={[
                font2.Headline1_Bold,
                MARGINS.TOP.spacing32,
              ].join(' ')}
            >회원번호
            </p>
            <p
              className={[
                font2.Body1_Regular,
                MARGINS.TOP.spacing12,
              ].join(' ')}
            >
              {data.hertz_membership || '-'}
            </p>
          </div>
        )
      }
      {
        data?.flight_number && (
          <div
            className={[
              MARGINS.TOP.spacing48,
            ].join(' ')}
          >
            <div
              className={[
                css`
                  display: flex;
                  align-items:flex-end;
                  gap: 8px;
                `,
                // borders.DEBUG_greenBorderDashed,
              ].join(' ')}
            >
              <h2
                className={[
                  font2.Title2,

                ].join(' ')}
              >도착 비행기 정보
              </h2>
              <span
                className={[
                  COLORS2_AS_FOREGROUND.sub.MrtGray1,
                  font2.Body1_Regular,
                ].join(' ')}
              >
              </span>
            </div>
            <div
              className={[
                flexSplitHorizontalInHalf,
              ].join(' ')}
            >
              <div>
                <p
                  className={[
                    font2.Headline1_Bold,
                    MARGINS.TOP.spacing32,
                  ].join(' ')}
                >항공사
                </p>
                <p
                  className={[
                    font2.Body1_Regular,
                    MARGINS.TOP.spacing12,
                  ].join(' ')}
                >
                  {data.flight_brand || '-'}
                </p>
              </div>
              <div>
                <p
                  className={[
                    font2.Headline1_Bold,
                    MARGINS.TOP.spacing32,
                  ].join(' ')}
                >편명
                </p>
                <p
                  className={[
                    font2.Body1_Regular,
                    MARGINS.TOP.spacing12,
                  ].join(' ')}
                >
                  {data.flight_number || '-'}
                </p>
              </div>
            </div>
          </div>
        )
      }
      {
        data.frequent_flyer && data.frequent_brand
        && (
          <div
            className={[
              MARGINS.TOP.spacing48,
            ].join(' ')}
          >
            <div
              className={[
                css`
                  display: flex;
                  align-items:flex-end;
                  gap: 8px;
                `,
                // borders.DEBUG_greenBorderDashed,
              ].join(' ')}
            >
              <h2
                className={[
                  font2.Title2,

                ].join(' ')}
              >항공 마일리지 적립
              </h2>
              <span
                className={[
                  COLORS2_AS_FOREGROUND.sub.MrtGray1,
                  font2.Body1_Regular,
                ].join(' ')}
              >
              </span>
            </div>
            <div
              className={[
                flexSplitHorizontalInHalf,
              ].join(' ')}
            >
              <div>
                <p
                  className={[
                    font2.Headline1_Bold,
                    MARGINS.TOP.spacing32,
                  ].join(' ')}
                >항공 마일리지 제휴사
                </p>
                <p
                  className={[
                    font2.Body1_Regular,
                    MARGINS.TOP.spacing12,
                  ].join(' ')}
                >
                  {airlineMileageProgramMap.get(data.frequent_brand)?.name}
                </p>
              </div>
              <div>
                <p
                  className={[
                    font2.Headline1_Bold,
                    MARGINS.TOP.spacing32,
                  ].join(' ')}
                >제휴사 회원번호
                </p>
                <p
                  className={[
                    font2.Body1_Regular,
                    MARGINS.TOP.spacing12,
                  ].join(' ')}
                >
                  {data.frequent_flyer || '-'}
                </p>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
});
