import { useRef } from 'react';

import { css } from '@emotion/css';
import { Tab } from '@headlessui/react';

import { font2 } from '^/constants/commonStyles';
import CancellationAndRefundOntraPOD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Ontra.POD';
import ReservationConfirmMobile from '^/pages/Booking/onlinetravel/DriversDetails/components/ReservationConfirm.Mobile';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { classNames } from '^/util';

import ReservationDetailBillMobile from './Bill/Bill.Mobile';
import DriverInfo from './DriverInfo/DriverInfo';

const ReservationDetailMobile = ({ resData }) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const totalOptionsFee = resData.option_info_list.reduce(
    (acc, option) => acc + option.foreign_price * option.quantity,
    0,
  );
  return (
    <>
      <h2 className="fixed top-[60px] z-10 -mx-6 w-full bg-white py-5 px-6 text-semihuge font-bold">
        예약정보
      </h2>
      <Tab.Group
        as="div"
        className={[
          '-mx-6',
          css`
            /* TODO: needs min-height defined! */
            min-height: 380px;
          `,
        ].join(' ')}
        onClick={() => {
          // hackish, but rootDOM is the scroll area
          //    (rootDOM: defined in index.html...)
          setTimeout(() => {
            const rootDOM = document.getElementById('root');
            rootDOM?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }, 5);
        }}
      >
        <div className="fixed top-[126px] z-10 w-full bg-white">
          <Tab.List className="flex justify-between px-6">
            <Tab
              key="vehicle"
              className={({ selected }) =>
                classNames(
                  'pb-3 border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => {
                scrollAreaRef.current?.scrollTo(0, 0);
              }}
            >
              차량 및 일정
            </Tab>
            <Tab
              key="driver"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => window.scrollTo(0, 0)}
            >
              운전자 정보
            </Tab>
            <Tab
              key="fee"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => window.scrollTo(0, 0)}
            >
              요금 내역
            </Tab>
          </Tab.List>
          <hr className="h-px bg-secondary-gray-light" />
        </div>
        <Tab.Panels
          className={[
            'mt-[130px] px-6',
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
          ref={scrollAreaRef}
        >
          <Tab.Panel key="vehicle-info">
            <ReservationConfirmMobile data={resData} isResult={false} isDetail />
            {resData?.plan_info.cancel_policy.length > 0 && (
              <>
                <Horizon marginTop="1rem" />
                <h2 className={[font2.Headline1_Bold, 'mt-4'].join(' ')}>취소 및 환불규정</h2>
                <div className="mt-4 rounded-[10px] bg-secondary-gray-light p-4 text-default font-light leading-5 mobile:text-small">
                  <CancellationAndRefundOntraPOD cancelPolicy={resData.plan_info.cancel_policy} />
                </div>
              </>
            )}
          </Tab.Panel>
          <Tab.Panel key="driver-info">
            <DriverInfo data={resData.driver_info} />
          </Tab.Panel>
          <Tab.Panel key="bill-info">
            <ReservationDetailBillMobile data={resData} totalOptionsFee={totalOptionsFee} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};
export default ReservationDetailMobile;
