import { css, cx } from '@emotion/css';

import { SPACING, COLORS2 } from '^/constants/commonStyles';

export const classNames = {
  common: {
    searchBoxInputWrapper: css`
      display: flex;
      width: 100%;
      flex-direction: column;

      color: ${COLORS2.sub.MrtGray1};
      border-radius: 8px;
      &:hover  {
        cursor: default;
      }
    `,
    searchBoxInputTitle: cx(
      css`
        color: #434343;
        font-size: 14px;
        font-family: 500;
      `,
    ),
    searchBoxInputs: css`
      height: 56px;
      display: flex;
      align-items: center;

      box-sizing: border-box;
      padding: ${SPACING.spacing16} 14px;
      
    `,
    searchBoxInputsMRT: css`
      height: 44px;
      display: flex;
      align-items: center;

      box-sizing: border-box;
      padding: ${SPACING.spacing16} 14px;
      `,
  },
};
