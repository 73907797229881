import { RefObject, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { useCombobox } from 'downshift';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { actions, sendTrackingLog } from '^/api/trackingLog';
import { FUKUOKA_AIRPORT, OKINAWA_AIRPORT } from
  '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { InputElemProps } from '^/commonComponents/inputs/TextInput';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { SPACING, COLORS2, INPUT } from '^/constants/commonStyles';
import { CityCodeType, CityNameType, LocationNameType } from '^/types/__BrandedLocationTypes';

import { CitySearchInputViewModel }
  from '../../../../base/desktop/headerArea/CitySearchInput/CitySearchInput.viewmodel';

import * as ids from './MRTCitySearchInput.ids';
import { defaultCitySearchInputStyle } from './MRTCitySearchInput.style';

export const MRTCitySearchInput: React.FC<
{
  inputRef?: RefObject<HTMLInputElement>;
  viewmodel: CitySearchInputViewModel;
  onSelectCity(cityCode?: CityCodeType, cityKoName?: CityNameType): void;
  styleOverride?: Partial<typeof defaultCitySearchInputStyle>;
  placeholderIcon?: string;
  office?: LocationNameType;
  onClear?: () => void;
  disabled?: boolean;
  onTyping?: () => void;
  stepId?: SearchBoxSteps['type'];
} & InputElemProps
> = observer(function CitySearchInput({
  inputRef,
  viewmodel,
  onSelectCity,
  styleOverride,
  placeholderIcon,
  office,
  onClear,
  disabled,
  onTyping,
  stepId,
  ...inputProps
}) {
  const backupInputRef = useRef<HTMLInputElement>(null);
  const inputRefActual = inputRef || backupInputRef;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const styles = {
    ...defaultCitySearchInputStyle,
    ...styleOverride,
  };

  const japanCities = {
    OKA: OKINAWA_AIRPORT.city_code,
    FUK: FUKUOKA_AIRPORT.city_code,
  };

  const {
    getInputProps, highlightedIndex, getItemProps, getMenuProps,
  } = useCombobox({
    items: viewmodel.searchResultList.status === 'success' ? viewmodel.searchResultList.value : [],
    inputValue: `${office ? `${office}, ` : ''}${viewmodel.searchInput.value}`,

    async onSelectedItemChange(e) {
      const cityCode = e.selectedItem?.code && e.selectedItem?.code in japanCities
        ? japanCities[e.selectedItem?.code] as CityCodeType
        : e.selectedItem?.code;
      onSelectCity(cityCode, e.selectedItem?.korean_name as CityNameType);
      sessionStorage.setItem('selectedCountry', e.selectedItem?.country_name || '');
      sendTrackingLog({
        action: actions['/'].SELECT_CITY,
        data: e.selectedItem?.korean_name,
        country: sessionStorage.getItem('selectedCountry') || '',
      });
    },
    onIsOpenChange(e) {
      viewmodel.showAutosuggestList.set(e.isOpen || false);
    },
    stateReducer(state, actionAndChanges) {
      const { type, changes } = actionAndChanges;
      if (type === useCombobox.stateChangeTypes.InputChange) {
        return {
          ...changes,
          selectedItem: null,
        };
      }
      if (type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
        if (!changes.selectedItem) {
          viewmodel.showAutosuggestList.set(true);
          return {
            ...changes,
            isOpen: true,
          };
        }
      }
      return {
        ...changes,
      };
    },
  });

  return (
    <div
      id={ids.ROOT_ID}
      data-step-id={stepId || false}
      className={[
        styles.root,
        css`
          width: 100%;
          /* position: relative; */
          display: flex;
          align-items: center;
          background-color: ${disabled ? '#F4F4F4' : '#fff'};
        `,
        !isMobile
          && css`
            position: relative;
            border: 1px solid #cecece;
            border-radius: 8px;
            height: 48px;
            margin-top: 4px;
            padding: 12px 16px;
          `,
        // borders.DEBUG_pinkDashed,
      ].join(' ')}
    >
      {placeholderIcon && (
        <img
          alt="myrealtrip_city_input_icon"
          src={placeholderIcon}
          className={css`
            margin-right: 6px;
          `}
        />
      )}
      <input
        key="CitySearchInput"
        id="citySearchInput"
        autoComplete="off"
        tabIndex={0}
        spellCheck={false}
        disabled={disabled}
        className={cx(
          INPUT.overflowEllipsis,
          styles.textInput,
          css`
            width: 100%;
            border: none;
            color: ${COLORS2.sub.MrtGray1};
            box-sizing: border-box;
            &:focus {
              outline: none;
            }
            &:disabled {
              background-color: #f4f4f4;
              color: #bbb ;
              -webkit-text-fill-color:#bbb;

            }
          `,
          !isMobile
            && css`
              font-size: 16px;
              line-height: 16px;
              vertical-align: middle;
            `,
          viewmodel.searchInput.value
            ? css`
                -webkit-text-fill-color: ${COLORS2.main.MrtBlack};
                opacity: 1; /* required on iOS */
              `
            : css`
                -webkit-text-fill-color: ${COLORS2.sub.MrtGray1};
                opacity: 1; /* required on iOS */
              `,
        )}
        {...getInputProps({
          placeholder: inputProps.placeholder,
          ref: inputRefActual,
          ...inputProps,
          onChange(e) {
            // TODO: 입력 시작 시 드롭다운으로 바뀌어야함.
            if (onTyping) {
              onTyping();
            }
            viewmodel.searchInputThatResetsCityCode.set(e.currentTarget.value);
          },
          onFocus() {
            viewmodel.searchInput.setIsFocused(true);
          },
          onBlur() {
            viewmodel.searchInput.setIsFocused(false);
          },
        })}
      />
      {viewmodel.searchInput.value && onClear && (
        <img
          alt="clear button"
          src="close_24.svg"
          onClick={(e) => {
            e.stopPropagation();
            viewmodel.searchInput.set('');
            onClear();
          }}
        />
      )}
      {viewmodel.showAutosuggestList.value && (
        <ViewByState
          data={viewmodel.searchResultList}
          errorView={(err) => {
            return <li> error: {err.reason} </li>;
          }}
          loadingView={(
            <h1 className={styles.noCitySuggestion}>
              <BlueLoadingSpinner
                classOverrides={{
                  spinner: css`
                    width: 15px;
                    height: 15px;
                  `,
                }}
              />
            </h1>
          )}
        >
          {(items) => {
            if (!items.length && viewmodel.searchInput.value) {
              return (
                <div
                  id="noCity"
                  className={cx(
                    styles.noCitySuggestion,
                    isMobile
                      ? css`
                          width: 100%;
                          border: 1px solid #cecece;
                          border-radius: 8px;
                          height: 48px;
                          margin-top: 5px;
                        `
                      : css`
                          border: 1px solid #cecece;
                          border-radius: 8px;
                          height: 48px;
                          margin-top: 5px;

                        `,
                  )}
                >
                  일치하는 도시가 없습니다.
                </div>
              );
            }
            return (
              <ul
                {...getMenuProps({
                  className: cx(
                    styles.suggestionsList,
                    isMobile
                      && css`
                          box-sizing: border-box;
                          border-radius: 8px;
                        `,

                    items.length > 0 && css`border: 1px solid #CECECE;`,
                  ),
                })}
              >
                {items.map((item, index) => (
                  <li
                    key={item.code}
                    {...getItemProps({ item, index })}
                    className={cx(
                      css`
                        display: flex;
                        width: 100%;
                        padding: ${SPACING.spacing8};
                        &:first-child {
                          border-radius: 8px 8px 0 0;
                        }
                        &:not(:last-child) {
                          border-bottom: 1px solid #e1e1e1;
                        }
                        height: 48px;
                        gap: 6px;
                      `,
                      INPUT.overflowEllipsis,
                      index === highlightedIndex
                        && css`
                          background-color: ${COLORS2.sub.MrtGray3};
                        `,
                    )}
                  >
                    <img
                      alt="location" src="marker-pin_black_20.svg" width={20}
                      height={20}
                    />
                    <div
                      className={css`
                        display: flex;
                        flex-direction: column;
                        align-self: center;
                        justify-content: center;
                      `}
                    >
                      <span
                        className={cx(
                          css`
                            font-size: 14px;
                            color: #1e1e1e;
                            font-weight: 500;
                            line-height: 14px;
                            margin-top: 4px;
                          `,
                        )}
                      >
                        {item.korean_name}
                      </span>
                      <span className={styles.location_en}>{item.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            );
          }}
        </ViewByState>
      )}
    </div>
  );
});
