import { css } from '@emotion/css';

import {
  borders, MARGINS, PADDINGS, font2,
} from '^/constants/commonStyles';

export const OnsiteHoverMessageStyles = {
  contentWrapperClassName: [
    css`
      background-color: white;
      border-radius: 10px;
      word-break: keep-all;
    `,
    MARGINS.LEFT_AND_RIGHT.spacing24,
    PADDINGS.LEFT_AND_RIGHT.spacing24,
    PADDINGS.TOP_AND_BOTTOM.spacing24,
    // borders.DEBUG_greenBorderDashed,
  ].join(' '),

  contentClassName: [
    css`
      text-align: center;
    `,
    // borders.DEBUG_blueBorderDashed,
    font2.Caption1_Light,
    MARGINS.BOTTOM.spacing24,
  ].join(' '),
};
