import { atom } from 'recoil';

export const authAtom = atom({
  key: 'auth',
  default: true,
});

export const userAtom = atom({
  key: 'user',
  default: {
    first_name: '',
    last_name: '',
    korean_name: '',
    email: '',
    phone_prefix: '+82',
    phone: '',
  },
});

