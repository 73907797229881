import itiriri from 'itiriri';

import { filterNotDefineds } from '^/types/utils/isDefined';

import { RawCarModelName } from './getCarList.successtype';
import type { CarListRawResponseType } from './getCarListAPI';

export type CarListGroupKey = string & { __CarListGroupKey: null };

export function decodeCarModelName(rawModelName: RawCarModelName) {
  const orSimilarCar = rawModelName.toUpperCase().endsWith('OR SIMILAR');
  const modelName = orSimilarCar ? rawModelName.slice(0, -10) : rawModelName;
  return {
    modelName,
    orSimilarCar,
  };
}

export function decodeCarListResponse(validated: CarListRawResponseType) {
  const converted = filterNotDefineds(
    itiriri(validated.data)
      .groupBy((item) => {
        const servicesKey = item.core.services.map((a) => `${a.shortName}::${a.info}`);
        return `${item.core.modelCode}@@@@@@@${item.core.modelName}@@@@${servicesKey}` as CarListGroupKey;
      })
      .map((groupItem) => {
        const carChoices = groupItem[1];
        const firstCarChoice = groupItem[1].first();
        if (!firstCarChoice) {
          return null;
        }
        const {
          modelCode,
          modelName: rawModelName,
          image: carImageUrl,
          services,
          vehType,
          badges,
        } = firstCarChoice.core;

        return {
          key: groupItem[0],
          carChoices,
          modelCode,
          carImageUrl,
          rawModelName,
          services,
          vehType,
          badges,

          serviceNames: services.map((s) => s.shortName),
          ...decodeCarModelName(rawModelName),
        };
      }),
  ); // ?
  return {
    items: converted.toArray(),
  };
}

export type CarListItemType = ReturnType<typeof decodeCarListResponse>['items'][number];

export type ServicesType = CarListItemType['services'][number];
