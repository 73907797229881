import { Horizon } from '^/pages/ReservationList/components/common/Horizon';

const DriverInfo = ({ data }) => {
  return (
    <div>
      <h3 className="text-semilarge font-bold">운전자 정보</h3>

      <Horizon marginTop="30px" marginBottom="30px" />

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">국문 이름</p>
          <p className="text-default font-light">{data?.korean_name}</p>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">전화번호</p>
          <p className="text-default font-light">{`${data?.phone_code} ${data?.phone_number}`}</p>
        </div>
        <div className="flex">
          <div className="flex basis-1/2 flex-col gap-3">
            <p className="text-big font-bold">영문 성</p>
            <p className="text-default font-light">{data?.last_name}</p>
          </div>
          <div className="flex basis-1/2 flex-col gap-3">
            <p className="text-big font-bold">영문 이름</p>
            <p className="text-default font-light">{data?.first_name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-big font-bold">이메일</p>
          <p className="text-default font-light">{data?.email}</p>
        </div>
      </div>
      {data?.flight_number && (
        <>
          <div className="-mx-6 mb-[30px] mt-6 h-1.5 bg-trimo-bg"></div>
          <h3 className="text-semilarge font-bold">추가 정보</h3>
          <div className="-mx-6 mt-[30px] mb-6 h-1.5 bg-trimo-bg"></div>
          <div className="mt-8 flex flex-col gap-3">
            <p className="text-big font-bold">도착 비행기 정보</p>
            <div className="flex">
              <div className="flex basis-1/2 flex-col gap-3">
                <p className="text-big">항공사</p>
                <p className="text-default font-light">{data.flight_brand}</p>
              </div>
              <div className="flex basis-1/2 flex-col gap-3">
                <p className="text-big">편명 </p>
                <p className="text-default font-light">{data.flight_number}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DriverInfo;
