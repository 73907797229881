/* eslint-disable no-nested-ternary */
import itiriri from 'itiriri';
import { makeAutoObservable } from 'mobx';

import { CarListItemType } from '^/api2/getCarList/decodeCarListResponse';
import { AppContext } from '^/AppContext/AppContext';
import { ExtractIterableQuery } from '^/types/utils/ExtractIterableQuery';
import { filterNotDefineds, isDefined } from '^/types/utils/isDefined';
import { maxNullable, minNullable } from '^/util/nullableCompare';

import { ListingPage2RouteParams } from '../../ListingPage2RouteParams';

import { ListingPage2CardViewModel } from './ListingPage2.card.viewmodel';
import { ListingPage2FilterViewModel } from './ListingPage2.FilterViewModel';

// TODO: maybe separation is bad idea...?
export class ListingPage2LoadedViewModel {
  constructor(
    public appContext: AppContext,
    public routeParams: ListingPage2RouteParams,
    public items: CarListItemType[],
    public filterVM: ListingPage2FilterViewModel,
  ) {
    makeAutoObservable(this);
  }

  get itemsIter() {
    return filterNotDefineds(itiriri(this.items));
  }

  get possiblePriceRange() {
    const flattenedPODPayments = filterNotDefineds(
      this.itemsIter
        .map((item) => item.carChoices
          .map((_) => _.POD),
        )
        .flat((_) => _),
    ).map((_) => {
      if (_.payment.isDiscounted) return _.payment.domesticPrice - _.payment.discountDomestic;
      return _.payment.domesticPrice;
    });
    const flattenedPPDPayments = filterNotDefineds(
      this.itemsIter
        .map((item) => item.carChoices
          .map((_) => _.PPD),
        )
        .flat((_) => _),
    ).map((_) => {
      if (_.payment.isDiscounted) return _.payment.domesticPrice - _.payment.discountDomestic;
      return _.payment.domesticPrice;
    });

    const podPriceMax = flattenedPODPayments.max();

    const podPriceMin = flattenedPODPayments.min();

    const ppdPriceMax = flattenedPPDPayments.max();
    const ppdPriceMin = flattenedPPDPayments.min();
    return {
      min: minNullable([podPriceMin, ppdPriceMin]),
      max: maxNullable([podPriceMax, ppdPriceMax]),
    };
  }

  get possibleVendors() {
    const vendors = new Set(this.itemsIter
      .map((item) => item.carChoices
        .map((carChoice) => carChoice.core.company.name),
      ).flat((_) => _),
    );
    return [...vendors];
  }

  get filteredList() {
    const minPrice = this.filterVM.minPrice.value || 0;
    const maxPrice = this.filterVM.maxPrice.value || Number.MAX_VALUE;
    return this.itemsIter
      .filter((c) => {
        if (this.filterVM.vendorsFilter.value.size === 0) {
          return true;
        }
        return c
          .carChoices
          .some((_) => {
            return this
              .filterVM.vendorsFilter.value
              .has(_.core.company.name);
          });
      })
      .map((car) => {
        const carChoices = car.carChoices
          .filter((_) => {
            if (this.filterVM.vendorsFilter.value.size === 0) {
              return true;
            }
            return this
              .filterVM.vendorsFilter.value
              .has(_.core.company.name);
          });
        const ppdPayments = filterNotDefineds(
          carChoices
            .map((_) => _.PPD),
        );
        const ppdMaxPayment = ppdPayments
          .max((a, b) => {
            const aPrice = a.payment.isDiscounted
              ? a.payment.domesticPrice - a.payment.discountDomestic
              : a.payment.domesticPrice;
            const bPrice = b.payment.isDiscounted
              ? b.payment.domesticPrice - b.payment.discountDomestic
              : b.payment.domesticPrice;
            return aPrice - bPrice;
          });
        const ppdMinPayment = ppdPayments
          .min((a, b) => {
            const aPrice = a.payment.isDiscounted
              ? a.payment.domesticPrice - a.payment.discountDomestic
              : a.payment.domesticPrice;
            const bPrice = b.payment.isDiscounted
              ? b.payment.domesticPrice - b.payment.discountDomestic
              : b.payment.domesticPrice;
            return aPrice - bPrice;
          });
        const ppdMax = ppdMaxPayment?.payment.isDiscounted && ppdMaxPayment
          ? Number(ppdMaxPayment.payment.domesticPrice) - Number(ppdMaxPayment.payment.discountDomestic)
          : Number(ppdMaxPayment?.payment.domesticPrice);
        const ppdMin = ppdMinPayment?.payment.isDiscounted && ppdMinPayment
          ? Number(ppdMinPayment.payment.domesticPrice) - Number(ppdMinPayment.payment.discountDomestic)
          : Number(ppdMinPayment?.payment.domesticPrice);

        const podPayments = filterNotDefineds(
          carChoices
            .map((_) => _.POD),
        );
        const podMaxPayment = podPayments
          .max((a, b) => (
            a.payment.domesticPrice - b.payment.domesticPrice
          ));
        const podMinPayment = podPayments
          .min((a, b) => (
            a.payment.domesticPrice - b.payment.domesticPrice
          ));

        return {
          ...car,
          carChoices,
          ppdMaxPayment,
          ppdMax,
          ppdMinPayment,
          ppdMin,
          podMaxPayment,
          podMax: podMaxPayment?.payment.domesticPrice,
          podMinPayment,
          podMin: podMinPayment?.payment.domesticPrice,
          podPayments,
          ppdPayments,
        };
      })
      .filter((c) => {
        if (this.filterVM.vendorsFilter.value.size === 0) {
          return true;
        }
        const hasIncludedVendor = c.carChoices.some((_) => {
          return this.filterVM.vendorsFilter.value.has(_.core.company.name);
        });
        return hasIncludedVendor;
      })
      .filter((car) => {
        if (car.ppdMax && car.ppdMax <= maxPrice
            && car.ppdMin && minPrice <= car.ppdMin) {
          return true;
        }
        if (car.podMax && car.podMax <= maxPrice
            && car.podMin && minPrice <= car.podMin) {
          return true;
        }
        return false;
      })
      .filter((car) => {
        const {
          vehType,
        } = car;
        if (this.filterVM.carClass.value.size === 0) {
          return true;
        }
        const carClassFilter = this.filterVM.carClass;
        if ((
          // '경차' in ko.json file
          vehType.size === 'mini'
          || vehType.size === 'minielite'
          || vehType.size === 'economy'
          || vehType.size === 'economy elite'
          || vehType.size === 'subcompact'
        ) && carClassFilter.value.has('mini')) {
          return true;
        }
        if ((
          // '준중형차'
          vehType.size === 'compact elite'
        ) && carClassFilter.value.has('midsize')) {
          return true;
        }
        if ((
          // '중형차'
          vehType.size === 'standard'
          || vehType.size === 'standard elite'
          || vehType.size === 'fullsize'
          || vehType.size === 'fullsize elite'
          || vehType.size === 'intermediate'
          || vehType.size === 'intermediate elite'
        ) && carClassFilter.value.has('intermediate')) {
          return true;
        }
        if ((
          vehType.size === 'premium'
          || vehType.size === 'premium elite'
          || vehType.size === 'luxury elite'
          || vehType.size === 'luxury'
        ) && carClassFilter.value.has('luxury')) {
          return true;
        }
        return carClassFilter.value.has(vehType.size as any);
      })
      .map((car) => {
        const carChoices = car.carChoices
          .map((choice) => {
            const podPrice = choice.POD?.payment.domesticPrice;
            const podIsInRange = (podPrice && (
              minPrice <= podPrice
                && podPrice <= maxPrice
            )) || false;
            const ppdPrice = choice.PPD?.payment.isDiscounted
              ? Number(choice.PPD?.payment.domesticPrice) - Number(choice.PPD?.payment.discountDomestic)
              : Number(choice.PPD?.payment.domesticPrice);
            const ppdIsInRange = (ppdPrice && (
              minPrice <= ppdPrice
              && ppdPrice <= maxPrice
            )) || false;
            return {
              choice,
              podIsInRange,
              ppdIsInRange,
            };
          })
          .filter((i) => i.podIsInRange
            || i.ppdIsInRange
            || false)
          .map((i) => {
            return {
              ...i.choice,
              POD: i.podIsInRange ? i.choice.POD : undefined,
              PPD: i.ppdIsInRange ? i.choice.PPD : undefined,
            };
          });
        return {
          ...car,
          carChoices,
        };
      })
      .sort((a, b) => {
        if (this.filterVM.priceSortFilter.value === 'lowest') {
          const aPriceMin = Math.min(
            ...[a.ppdMin, a.podMin].filter(isDefined),
          );
          const bPriceMin = Math.min(
            ...[b.ppdMin, b.podMin].filter(isDefined),
          );
          return aPriceMin - bPriceMin;
        }
        const aPriceMax = Math.max(
          ...[a.ppdMax, a.podMax].filter(isDefined),
        );
        const bPriceMax = Math.max(
          ...[b.ppdMax, b.podMax].filter(isDefined),
        );
        return bPriceMax - aPriceMax;
      });
  }

  get filteredListVMs() {
    return this.filteredList
      .map((car) => {
        return new ListingPage2CardViewModel(
          this.appContext,
          car,
          this.routeParams,
        );
      });
  }

  get filteredListAsMap() {
    const toReturn = this.filteredListVMs
      .toMap(
        (k) => k.data.key,
      );
    return toReturn;
  }
}

export type FilteredCarListItemType = ExtractIterableQuery<ListingPage2LoadedViewModel['filteredList']>;
