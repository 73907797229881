import { css, cx } from '@emotion/css';

import { COLORS2, font } from '^/constants/commonStyles';

export const styles = {
  calendarDayRootWrapper: cx(
    css`
      display: flex;
      justify-content: center;
      width: 14.28%;
      position: relative;
      margin-top: 2px;
    `,
    font.Caption1_Light,
  ),
  calendarDayContentWrapper: css`
      position: absolute;
      top: 0; left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
  `,
  today: css`

    background-color: ${COLORS2.main.MrtBlue2};
    border-radius: 50%;
  `,
  start: css`

    background-color: ${COLORS2.main.MrtBlue1};
    color: ${COLORS2.main.MrtWhite};
    border-radius: 50%;
  `,
  end: css`

    background-color: ${COLORS2.main.MrtBlue1};
    color: ${COLORS2.main.MrtWhite};
    border-radius: 50%;
  `,
  inSelectionBackground: css`
    background-color: rgba(168, 212, 249, 0.5);

  `,
  normal: css`
  `,
  disabled: css`
    color: ${COLORS2.sub.MrtGray2};
    cursor: not-allowed;
    pointer-events: none;
  `,
  hover: css`
  `,
  inHoverSelection: css`
    background-color: rgb(168, 212, 249, 0.5);
  `,
  rightBorderRadius50: css`
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  `,
  leftBorderRadius50: css`
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  `,
} as const;
export function getClassNameByState(state: keyof typeof styles) {
  return styles[state];
}
