import parseISODate from 'date-fns/parseISO';

import { YearMonth } from '../../helpers/YearMonth';
import { CalendarDayViewModel } from '../Day/Calendar.Day.viewModel';

function getStartWeekDay(
  yearMonth: YearMonth,
) {
  const month = String(yearMonth.monthFrom0 + 1)
    .padStart(2, '0');
  const start = parseISODate(
    `${yearMonth.year}-${month}-01`,
  );
  if (Number.isNaN(start)) {
    // alert('!');
    // debugger;
  }
  if (Number.isNaN(start.getDay())) {
    // alert('start.getDay() is NaN');
    // debugger;
  }
  return start.getDay();
}

export class CalendarMonthViewModel {
  readonly dayViewModels: CalendarDayViewModel[];

  constructor(
    public readonly yearMonth: YearMonth,
    public readonly startWeekDay = getStartWeekDay(yearMonth),
    public readonly lastDate = new Date(
      yearMonth.year, yearMonth.monthFrom0 + 1, 0,
    ),
    public readonly lastDateDay = lastDate.getDate(),
  ) {
    this.dayViewModels = [
      ...new Array(lastDateDay),
    ].map((_, dayFrom0) => {
      const day = dayFrom0 + 1;
      return new CalendarDayViewModel(
        this,
        day,
        day === this.lastDateDay,
      );
    });
  }
}
