import { css } from '@emotion/css';

import { COLORS } from '^/constants/commonStyles';

export const Horizon: React.FC<{
  marginTop?: string,
  marginBottom?: string,
  height?: string,
}> = ({
  marginTop,
  marginBottom,
  height = '6px',
}) => {
  return (
    <div
      className={
        css`
          height: ${height};
          background-color: ${COLORS.LIGHT_GREY_2};

          margin-left: -24px;
          margin-right: -24px;
          margin-top: ${marginTop};
          margin-bottom: ${marginBottom};
        `
      }
    />
  );
};
