import { ListingPage2RouteParams } from '^/pages/Listing/ListingPage2RouteParams';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { FreshnessType, getCurrentFreshness } from '^/types/__ResultType';

export class CarListingSearchBoxSubmitActionVM {
  constructor(public params: ListingPage2RouteParams) { }

  submit() {
    typeRouter.routes
      .listing2({
        ...this.params,
        freshness: ((this.params.freshness || getCurrentFreshness()) + 1) as FreshnessType,
      })
      .push();
  }
}
