/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
import { CityCodeType } from '^/types/__BrandedLocationTypes';
import { ExtractPromiseSuccess, asSuccessPromise } from '^/types/__ResultType';
import { ExtractPromise } from '^/types/utils/ExtractPromise';

import {
  GetRentalCarOfficeLocationsByCityAPI,
  getRentalCarOfficeLocationsByCityAPI,
} from './byCity';
import {
  FUKUOKA_AIRPORT,
  HAKATA,
  HIGASHI,
  MINAMI,
  SHIN_CHITOSE_AIRPORT,
  TOYOHIRA,
  OKINAWA_AIRPORT,
  GOFUKUMACHI_STATION,
  GION_STATION,
  KITAKYUSHU_AIRPORT,
  NARITA_AIRPORT,
  HANEDA_AIRPORT,
  KUMAMOTO_AIRPORT,
  KUMAMOTO_STATION,
  NAGOYA_AIRPORT,
  NAGOYA_STATION,
  KANSAI_AIRPORT,
  SHIN_OSAKA_STATION,
  KAGOSHIMACHUO_STATION,
} from './OnlineTravelOfficeLocations.constants';

type RentalOffices = ExtractPromiseSuccess<
  ExtractPromise<ReturnType<GetRentalCarOfficeLocationsByCityAPI>>
>['rentalOffices'];

export const byCityWithJapanCity: GetRentalCarOfficeLocationsByCityAPI = async (cityCode) => {
  /** 오키나와 */
  if (cityCode === ('47003' as CityCodeType) || cityCode === ('OKA' as CityCodeType)) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [OKINAWA_AIRPORT],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 후쿠오카 */
  if (cityCode === ('40001' as CityCodeType) || cityCode === ('FUK' as CityCodeType)) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [FUKUOKA_AIRPORT, KITAKYUSHU_AIRPORT],
      },
      {
        id: 'LDT',
        name: '도시',
        icon: '',
        location: [HAKATA],
      },
      {
        id: 'LAM',
        name: '기차역',
        icon: '',
        location: [GOFUKUMACHI_STATION, GION_STATION],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 삿포로 */
  if (cityCode === ('01003' as CityCodeType) || cityCode === ('SPK' as CityCodeType)) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [SHIN_CHITOSE_AIRPORT],
      },
      {
        id: 'LDT',
        name: '도시',
        icon: '',
        location: [MINAMI, TOYOHIRA, HIGASHI],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 도쿄 */
  if (
    cityCode === ('12001' as CityCodeType) ||
    cityCode === ('13013' as CityCodeType) ||
    cityCode === ('TYO' as CityCodeType)
  ) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [NARITA_AIRPORT, HANEDA_AIRPORT],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 구마모토 */
  if (cityCode === ('43001' as CityCodeType)) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [KUMAMOTO_AIRPORT],
      },
      {
        id: 'LAM',
        name: '기차역',
        icon: '',
        location: [KUMAMOTO_STATION],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 나고야 */
  if (
    cityCode === ('23001' as CityCodeType) ||
    cityCode === ('23002' as CityCodeType) ||
    cityCode === ('NGO' as CityCodeType)
  ) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [NAGOYA_AIRPORT],
      },
      {
        id: 'LAM',
        name: '기차역',
        icon: '',
        location: [NAGOYA_STATION],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 오사카 */
  if (cityCode === ('27004' as CityCodeType) || cityCode === ('OSA' as CityCodeType)) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAP',
        name: '공항',
        icon: '',
        location: [KANSAI_AIRPORT],
      },
      {
        id: 'LAM',
        name: '기차역',
        icon: '',
        location: [SHIN_OSAKA_STATION],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  /** 가고시마 */
  if (cityCode === ('46001' as CityCodeType) || cityCode === ('KOJ' as CityCodeType)) {
    const rentalOffices: RentalOffices = [
      {
        id: 'LAM',
        name: '기차역',
        icon: '',
        location: [KAGOSHIMACHUO_STATION],
      },
    ];
    return asSuccessPromise({
      rentalOffices,
      validated: {
        success: true,
      } as any,
    });
  }
  return getRentalCarOfficeLocationsByCityAPI(cityCode);
};
