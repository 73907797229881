/* eslint-disable @typescript-eslint/naming-convention */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ViewByState } from '^/commonComponents/ui/ViewByState';
import {
  borders, COLORS, SPACING, COLORS2,
  font,
} from '^/constants/commonStyles';

import { SelectedOfficeInfoRoot } from '../common/RentalOfficeInput/SelectedOfficeInfoRoot';
import { CarListingSearchBoxViewModel } from '../desktop/viewmodel/CarListingSearchBox.viewmodel';

import { CarListingBottomSheetHeader } from './BottomSheet/CarListingBottomSheet.header';

export const Step2_5RentalOfficeConfirm: React.FC<{
  // viewmodel: RentalOfficeInputViewModel,
  viewmodel: CarListingSearchBoxViewModel
}> = observer(function Step2_5RentalOfficeConfirm({
  viewmodel,
}) {
  return (
    <div className={cx(
      css`
        height: 100%;
        display: flex;
        flex-direction: column;
      `,
      // borders.DEBUG_blueBorderDashed,
    )}
    >
      <CarListingBottomSheetHeader
        title="영업소 정보"
        leftCornorBtnType="prevStack"
        stepManager={viewmodel
          .stepManager}
      />
      <ViewByState
        data={viewmodel.getLocationInputViewModelByCityCode(
          viewmodel.i.pickupCityCode.value,
          viewmodel.i.tempPickupLocationId,
        )}
        loadingView={<h1>loading</h1>}
        errorView={() => {
          return <h1>error</h1>;
        }}
      >
        {(pickupVM) => {
          return (
            <div className={css`
              overflow-y: scroll;
              height: calc(100% - 56px);
              display: flex;
              flex-direction: column;
            `}
            >
              <div
                className={cx(
                  css`
                    padding: 12px ${SPACING.spacing24} 4px;
                    background-color: ${COLORS2.main.MrtWhite};
                  `,
                  // borders.DEBUG_blueBorderDashed,
                )}
              >
                <div className={cx(
                  font.Headline1_Bold,
                  css`
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                  `,
                )}
                >
                  {pickupVM.selectedOfficeData
                    ?.locationDescriptions
                    .get('ko')
                    ?.description}
                </div>
              </div>

              <SelectedOfficeInfoRoot
                viewmodel={pickupVM}
              />

              <div className={css`
                  padding: ${SPACING.spacing24} ${SPACING.spacing24} ${SPACING.spacing16};
                  position: sticky;
                  bottom: 0px;
                  background-color: ${COLORS2.main.MrtWhite};
                  margin-top: auto;
                `}
              >
                <button
                  id="step2_5_btn"
                  type="button"
                  onClick={() => {
                    viewmodel.i.pickupLocationId
                      .copyFrom(
                        viewmodel.i.tempPickupLocationId,
                      );
                    viewmodel.stepManager.replaceStep({ type: '3_schedule' });
                  }}
                  className={css`
                    height: ${SPACING.spacing56};
                    width: 100%;

                    background-color: ${COLORS2.main.MrtBlue1} !important;
                    color: ${COLORS2.main.MrtWhite};
                    border-radius: 5px;
                  `}
                >
                  선택
                </button>
              </div>
            </div>
          );
        }}
      </ViewByState>
    </div>
  );
});
