/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/media-has-caption */
import { RefObject, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { useCombobox } from 'downshift';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import {
  isOntra,
  OnlineTravelOffices,
} from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { SearchCityAPIResponseItem } from '^/api2/SearchOLD/searchCity.responseType';
import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { InputElemProps } from '^/commonComponents/inputs/TextInput';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { SPACING, COLORS2, font, INPUT } from '^/constants/commonStyles';
import { CityCodeType, LocationNameType } from '^/types/__BrandedLocationTypes';

import * as ids from './CitySearchInput.ids';
import { defaultCitySearchInputStyle } from './CitySearchInput.style';
import { CitySearchInputViewModel } from './CitySearchInput.viewmodel';

export const CitySearchInput: React.FC<
  {
    inputRef?: RefObject<HTMLInputElement>;
    viewmodel: CitySearchInputViewModel;
    onSelectCity(city?: CityCodeType | SearchCityAPIResponseItem): void;
    styleOverride?: Partial<typeof defaultCitySearchInputStyle>;
    office?: LocationNameType;
  } & InputElemProps
> = observer(function CitySearchInput({
  inputRef,
  viewmodel,
  onSelectCity,
  styleOverride,
  office,
  ...inputProps
}) {
  const backupInputRef = useRef<HTMLInputElement>(null);
  const inputRefActual = inputRef || backupInputRef;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const styles = {
    ...defaultCitySearchInputStyle,
    ...styleOverride,
  };

  const { getInputProps, highlightedIndex, getItemProps, getMenuProps } = useCombobox({
    items: viewmodel.searchResultList.status === 'success' ? viewmodel.searchResultList.value : [],
    inputValue: `${office ? `${office}, ` : ''}${viewmodel.searchInput.value}`,

    async onSelectedItemChange(e) {
      const cityCode =
        e.selectedItem?.code && isOntra(e.selectedItem?.code)
          ? OnlineTravelOffices.find((office) => office.hertz_code === e.selectedItem?.code)
              ?.city_code
          : e.selectedItem?.code;

      viewmodel.selectedCityCode.set(cityCode || null);
      viewmodel.searchInput.set(e.selectedItem?.korean_name || '');
      onSelectCity(cityCode);
      // sendTrackingLog({
      //   action: actions['/'].SELECT_CITY,
      //   data: e.selectedItem?.korean_name,
      // });
    },
    onIsOpenChange(e) {
      viewmodel.showAutosuggestList.set(e.isOpen || false);
    },
    stateReducer(state, actionAndChanges) {
      const { type, changes } = actionAndChanges;
      if (type === useCombobox.stateChangeTypes.InputChange) {
        return {
          ...changes,
          selectedItem: null,
        };
      }
      if (type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
        if (!changes.selectedItem) {
          viewmodel.showAutosuggestList.set(true);
          return {
            ...changes,
            isOpen: true,
          };
        }
      }
      return {
        ...changes,
      };
    },
  });

  return (
    <div
      id={ids.ROOT_ID}
      className={[
        styles.root,
        isMobile &&
          css`
            width: 100%;
          `,
      ].join(' ')}
    >
      <input
        key="CitySearchInput"
        id="citySearchInput"
        autoComplete="off"
        tabIndex={0}
        spellCheck={false}
        className={cx(
          INPUT.overflowEllipsis,
          font.Body1_Bold,
          styles.textInput,
          css`
            width: 100%;
            border: none;
            color: ${COLORS2.sub.MrtGray3};
            box-sizing: border-box;
            &:focus {
              outline: none;
            }
            &:disabled {
              color: purple;
            }
          `,
          viewmodel.searchInput.value
            ? css`
                -webkit-text-fill-color: ${COLORS2.main.MrtBlack};
                opacity: 1; /* required on iOS */
              `
            : css`
                -webkit-text-fill-color: ${COLORS2.sub.MrtGray1};
                opacity: 1; /* required on iOS */
              `,
        )}
        {...getInputProps({
          placeholder: inputProps.placeholder,
          ref: inputRefActual,
          ...inputProps,
          onChange(e) {
            viewmodel.searchInputThatResetsCityCode.set(e.currentTarget.value);
          },
          onFocus() {
            viewmodel.searchInput.setIsFocused(true);
          },
          onBlur() {
            viewmodel.searchInput.setIsFocused(false);
          },
        })}
      />
      {viewmodel.showAutosuggestList.value && (
        <ViewByState
          data={viewmodel.searchResultList}
          errorView={(err) => {
            return <li> error: {err.reason} </li>;
          }}
          loadingView={
            <h1 className={styles.noCitySuggestion}>
              <BlueLoadingSpinner
                classOverrides={{
                  spinner: css`
                    width: 15px;
                    height: 15px;
                  `,
                }}
              />
            </h1>
          }
        >
          {(items) => {
            if (!items.length && viewmodel.searchInput.value) {
              return (
                <div
                  id="noCity"
                  className={cx(
                    styles.noCitySuggestion,
                    isMobile
                      ? css`
                          border: 1px solid ${COLORS2.sub.MrtGray3};
                        `
                      : css`
                          filter: drop-shadow(10px 30px 30px rgba(0, 0, 0, 0.1));
                        `,
                  )}
                >
                  일치하는 도시가 없습니다.
                </div>
              );
            }
            return (
              <ul
                {...getMenuProps({
                  className: cx(
                    styles.suggestionsList,
                    isMobile
                      ? css`
                          border: 1px solid ${COLORS2.sub.MrtGray3};
                          box-sizing: border-box;
                        `
                      : css`
                          z-index: 100;
                        `,
                  ),
                })}
              >
                {items.map((item, index) => (
                  <li
                    key={item.code}
                    {...getItemProps({ item, index })}
                    className={cx(
                      css`
                        display: flex;
                        padding: 0 ${SPACING.spacing16};
                        gap: 12px;
                        border-bottom: 1px solid ${COLORS2.sub.MrtGray3};
                        height: ${SPACING.spacing56};
                      `,
                      INPUT.overflowEllipsis,
                      index === highlightedIndex &&
                        css`
                          background-color: ${COLORS2.main.MrtBlue2}33;
                        `,
                    )}
                  >
                    <img alt="location" src="/ic_location.svg" width={24} height={24} />
                    <div
                      className={css`
                        display: flex;
                        flex-direction: column;
                        align-self: center;
                      `}
                    >
                      <span>{item.korean_name}</span>
                      <span className={styles.location_en}>{item.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            );
          }}
        </ViewByState>
      )}
    </div>
  );
});
