import { SelectionRange } from '../Calendar.state.viewmodel';

import { YearMonth } from './YearMonth';

export function isYearMonthInsideSelectionRange(
  ym: YearMonth,
  selectionRange: SelectionRange,
) {
  if (!selectionRange.start || !selectionRange.end) {
    return false;
  }
  if (ym.year < selectionRange.start.year) {
    return false;
  }
  if (ym.year === selectionRange.start.year
    && ym.monthFrom0 < selectionRange.start.yearMonth.monthFrom0
  ) {
    return false;
  }
  if (ym.year > selectionRange.end.year) {
    return false;
  }
  if (ym.year === selectionRange.end.year
    && ym.monthFrom0 > selectionRange.end.yearMonth.monthFrom0
  ) {
    return false;
  }
  return true;
}
