import React, {
  ReactNode,
  RefObject,
  useRef, useState,
} from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import {
  borders, SPACING, COLORS2, font,
} from '^/constants/commonStyles';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';

export const defaultDropdown2Styles = {
  root: cx(
    css`
      position: relative;
      background-color: ${COLORS2.main.MrtWhite};
      padding: ${SPACING.spacing24};
      border-radius: 5px;
      box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
    `,
    // borders.DEBUG_greenBorderDashed,
  ),
  openContainer: cx(
    css`
      position: absolute;
      top: 100%; left: 0;

      width: 100%;
      background-color: ${COLORS2.main.MrtWhite};
      padding: 0 ${SPACING.spacing24} ${SPACING.spacing16};
      box-shadow: 5px 10px 15px 0 rgba(0, 0, 0, 0.1);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    `,
    // borders.DEBUG_orangeBorderDashed,
  ),
  titleContainer: cx(
    css`
      display: flex;
      justify-content: space-between;
    `,
    font.Headline1_Bold,
  ),
} as const;

export const ListingFilterDropdown = observer(function ListingFilterDropdown(
  p: React.PropsWithChildren<{
    title: ReactNode;
    classOverrides?: Partial<typeof defaultDropdown2Styles>;
  }>,
) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  // useClickOutsideComponent(ref, () => {
  //   setTimeout(() => {
  //     console.log(2);
  //     setIsOpen(false);
  //   }, 150);
  // });
  return (
    <div
      className={cx(
        p.classOverrides?.root || defaultDropdown2Styles.root,
        isOpen
          ? css`
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `
          : css`
            border-radius: 5px;
          `,
      )}
    >
      <div
        className={
          p.classOverrides?.titleContainer
          || defaultDropdown2Styles.titleContainer
        }
        tabIndex={-1}
        role="listbox"
        onClick={() => {
          setIsOpen(!isOpen);
          // }, 50);
        }}
      >
        <div>{p.title}</div>
        <img
          alt=""
          src={!isOpen ? './ic_arrow_down.png' : './ic_arrow_up.png'}
          className="h-6 w-6"
        />
      </div>
      {(isOpen || null) && (
        <div
          ref={ref}
          className={
            p.classOverrides?.openContainer
            || defaultDropdown2Styles.openContainer
          }
        >
          {p.children}
        </div>
      )}
    </div>
  );
});
