import { v4 as uuidv4 } from 'uuid';
import { getSubdomain } from '../util/index';
import { format } from 'date-fns';

export const setUUID = () => {
  const uuid = `${getSubdomain()}_${uuidv4()}`;
  localStorage.setItem('_uuid_', JSON.stringify(uuid));
};

export const setSessionId = () => {
  const trkid =
    Math.random().toString(36).substring(2, 13) + '_' + format(new Date(), 'yyyyMMddHmmss');
  sessionStorage.setItem('_trkid_', JSON.stringify(trkid));
};

export const getUUID = () => {
  try {
    return JSON.parse(localStorage.getItem('_uuid_') || '');
  } catch {
    setUUID();
    return JSON.parse(localStorage.getItem('_uuid_') || '');
  }
};

export const getSessionId = () => {
  try {
    return JSON.parse(sessionStorage.getItem('_trkid_') || '');
  } catch (e) {
    setSessionId();
    return JSON.parse(sessionStorage.getItem('_trkid_') || '');
  }
};
