import { PropsWithChildren } from 'react';

import { css } from '@emotion/css';

import {
  ReactComponent as CircularAlert,
} from '^/assets/circle_alert.svg';
import { borders, MARGINS, COLORS2_AS_FOREGROUND } from '^/constants/commonStyles';

export const ErrorViewStyles = {
  root: [
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
    `,
    // borders.DEBUG_greenBorderDashed,
  ].join(' '),
  titleArea: [
    MARGINS.TOP.spacing32,
    COLORS2_AS_FOREGROUND.main.MrtBlack,
    css`
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: -0.02px;
    `,

  ].join(' '),
  descriptionArea: [
    MARGINS.TOP.spacing16,
    COLORS2_AS_FOREGROUND.sub.MrtGray1,
    css`
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -0.01px;
    `,
  ].join(' '),
} as const;
export const BaseErrorView: React.FC<PropsWithChildren<{
  title: string;
  description?: string;
  stylesOverride?: Partial<typeof ErrorViewStyles>;
}>> = ({
  title,
  description,
  stylesOverride,
  children,
}) => {
  const styles = {
    ...ErrorViewStyles,
    ...stylesOverride,
  };
  return (
    <div
      className={styles.root}
    >
      <CircularAlert
        className={[
          MARGINS.TOP.spacing154,
        ].join(' ')}
      />
      <div
        className={styles.titleArea}
      >
        {title}
      </div>
      <div
        className={styles.descriptionArea}
      >
        {description}
      </div>
      {children}
    </div>
  );
};
