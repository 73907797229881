import { css, cx } from '@emotion/css';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';

export const defaultStyle = {
  locationButton: css`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 56px;
    max-width: 300px;

    box-sizing: border-box;
    background-color: ${COLORS2.sub.MrtGray3} !important;
    border-radius: 5px;
    color: ${COLORS2.sub.MrtGray1};
  `,
  selectedLocationButton: css`
    background-color: ${COLORS2.main.MrtBlue1} !important;
    color: ${COLORS2.main.MrtWhite};
  `,
  scrollbarStyle: css`
  &::-webkit-scrollbar {
    width: 12px;
    height: 20px;
    padding: 4px 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 80px;
    background: #e1e1e1;
    border-radius: 99px;
    border: 2px solid #fff;
  }
`,
};

export const classNames = {
  desktop: {
    rootWrapper: css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: ${SPACING.spacing24};
    `,
    locationBtn: cx(
      font.Headline1_Bold,
      defaultStyle.locationButton,
      css`
        &:hover {
          background-color: ${COLORS2.main.MrtBlue1} !important;
          color: ${COLORS2.main.MrtWhite};
        }
      `,
    ),
  },
  mobile: {
    rootWrapper: css`
      height: calc(var(--vh) * 100 - 228px);
      overflow-y: scroll;
    `,
    locationName: css`
      display: flex;
      align-items: center;
      color: #575757;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      height: 40px;
    `,
  },
};
