import { css } from '@emotion/css';
import Skeleton from '@mui/material/Skeleton';

import { SPACING, COLORS2 } from '^/constants/commonStyles';

export const SkeletonCarInfoDesktop: React.FC<{}> = () => {
  return (
    <div
      id="SkeletonCarInfo_Root"
      className={[
        ' grid auto-rows-max grid-cols-6 justify-start  tablet:grid-rows-1',
        css`
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 10px;
          padding: ${SPACING.spacing24};
          padding-left: 53px;
        `,
      ].join(' ')}
    >
      <div className="col-span-2 flex flex-col items-start justify-center pr-2 tablet:col-span-1">
        <Skeleton
          variant="rounded"
          width="100%"
          height={170}
        />
      </div>

      <div className={[
        'col-span-4 flex flex-col gap-y-1 tablet:col-span-3',
        css`
          justify-content: center;
          margin-left: 10%;
        `,
      ].join(' ')}
      >
        <Skeleton
          variant="rounded"
          width="45%"
          height={32}
        />
        <Skeleton
          variant="rounded"
          width="55%"
          height={36}
        />
        <Skeleton
          variant="rounded"
          width="45%"
          height={30}
        />
      </div>

      <div
        className={[
          'col-span-2 flex items-center justify-center',
          css`
            gap: ${SPACING.spacing24};
          `,
        ].join(' ')}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height={170}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height={170}
        />
      </div>
    </div>

  );
};
