import { css, cx } from '@emotion/css';

import { SPACING, font } from '^/constants/commonStyles';

export const classNames = {
  btnRendererBasic: cx(
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: ${SPACING.spacing56};

      padding: ${SPACING.spacing16};
      box-sizing: border-box;
      // flex-grow: 1 !important;
      @media (max-width: 768px) {
        padding: 0 ${SPACING.spacing8};
        // width: 50%;
      }
    `,
    font.Body1_Bold,
  ),
  timeSelected: cx(css`
  font-size: 14px; 
  font-style: normal;
  font-weight: 500; 
  line-height: 20px;
    `),
  timeNotSelected: cx(css`
  font-size: 14px; 
  font-style: normal;
  font-weight: 500; 
  line-height: 20px;
  color: #cecece;
    `),
};
