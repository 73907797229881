/* eslint-disable operator-linebreak */
interface CancelPolicyItem {
  start: number;
  stop: number;
  value: string;
  cmt: string;
}

const CancellationAndRefundOntraPOD = ({ cancelPolicy }: { cancelPolicy: CancelPolicyItem[] }) => {
  return (
    <>
      <p className="text-default font-light tablet-max:text-small">
        트리모를 통해 예약한 경우, 트리모 취소 및 환불 규정에 따라 환불이 진행됩니다. 예약 취소
        시점에 따라 다음과 같이 취소 수수료를 제한 후 환불이 진행될 수 있습니다.
      </p>
      <div className="mt-3 text-default font-light tablet-max:text-small">
        <p className="font-bold">예약 변경</p>
        <p className="mt-2">- 예약 변경은 취소 후 재 예약하셔야 합니다.</p>
        <br />
        <p className="font-bold">예약 취소</p>
        <p className="mt-2">
          - 취소는 반드시 트리모를 통해 진행하셔야 합니다. 다른 경로(타 홈페이지 또는 고객센터 등)를
          통해 취소할 경우 불이익이 발생할 수 있습니다.
        </p>
        <p>- 픽업시간 기준, 수수료가 발생할 수 있습니다.</p>
        {cancelPolicy.map((p, i) => {
          let time = '';
          const cancelFee =
            Number(p.value) > 0 ? `결제 금액의 ${p.value}%에 해당하는 취소 수수료 발생` : '없음';
          if (p.stop === 0) {
            time = '픽업 당일 취소 시';
          }
          if (p.stop === -1) {
            time = `픽업 ${p.start}일 전 취소 시`;
          }
          if (p.stop > 0) {
            time = `픽업 ${p.start}~${p.stop}일 전 취소 시`;
          }
          if (p.start === p.stop) {
            time = `픽업 ${p.start}일 전 취소 시`;
          }

          const policy = time ? `${time}: ${cancelFee}` : p.cmt;

          return <p className="pl-[8px]">{`${i + 1}) ${policy}`}</p>;
        })}
        <p>
          - 현장 결제의 취소 수수료가 발생하는 시점 취소 시, 현지 영업소에서 취소 수수료 부과와
          관련된 내용을 이메일로 발송합니다. 이메일에는 영업소가 보낸 계좌 정보가 포함되어 있으며,
          해당 계좌로 해외 송금합니다. (※ 해외 송금 수수료가 발생할 수 있습니다.)
        </p>
      </div>
    </>
  );
};

export default CancellationAndRefundOntraPOD;
