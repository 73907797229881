import { useRef, useState } from 'react';

import { css } from '@emotion/css';
import { Tab } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

import { CitySearchInputViewModel } from '^/components/CarSearchBox/base/desktop/headerArea/CitySearchInput/CitySearchInput.viewmodel';
import { COLORS2 } from '^/constants/commonStyles';
import { CityCodeType } from '^/types/__BrandedLocationTypes';

import { CarListingSearchBoxViewModel } from '../../../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';

import MRTPopularCityPanel from './MRTPopularCity.Panel.Desktop';
import MRTPopularCityTabList from './MRTPopularCity.TabList';

interface MRTPopularCityProps {
  viewmodel: CarListingSearchBoxViewModel,
  inputViewmodel: CitySearchInputViewModel,
  onSelectCity: (cityCode: CityCodeType) => void;
}
export const MRTPopularCity: React.FC<MRTPopularCityProps> = observer(
  function MRTPopularCity({ viewmodel, inputViewmodel, onSelectCity }: MRTPopularCityProps) {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const cityContainerRef = useRef<HTMLDivElement>(null);

    return (
      <div className={css`
        display: flex;
        width: 1012px;
        height: 277px;
        background-color: ${COLORS2.main.MrtWhite};
        border-radius: 8px;
      `}
      >
        <div className={css`
          display: flex;
          width: 100%;
          height: 100%;
          font-size: 14px;
        `}
        >
          <Tab.Group
            vertical
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            defaultIndex={0}
          >
            <MRTPopularCityTabList cityContainerRef={cityContainerRef} />
            <MRTPopularCityPanel
              viewmodel={inputViewmodel}
              cityContainerRef={cityContainerRef}
              onBlur={() => {
                viewmodel.stepManager.clearStep();
              }}
              onSelectCity={onSelectCity}
              setSelectedIndex={setSelectedIndex}
            />
          </Tab.Group>
        </div>
      </div>
    );
  });
