/* eslint-disable max-len */
import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import { CUSTOMER_SERVICE_BY_CHANNEL } from '^/constants/customerServiceText.constants';
import { IMS_LOGO } from '^/constants/LogoImages.constants';
import { HELP_PAGE } from '^/constants/routes';
import { subdomainState } from '^/recoil/atoms';
import { getItemByChannel } from '^/util/byChannel';
import { convertLineBreaks } from '^/util/textFormat';

import { TermsPrivacyPopup } from '../Popup/TermPopup/TermsPrivacy';
import { TermsServicePopup } from '../Popup/TermPopup/TermsService';

export const Footer3MobileDefaultStyles = {
  FooterRoot: css`
    display: flex;
    flex-direction: column;

    gap: ${SPACING.spacing32};
    padding: ${SPACING.spacing56} 0;
    background-color: ${COLORS2.sub.MrtGray3};
    padding: ${SPACING.spacing24};
  `,
} as const;
export const Footer3Mobile: React.FC<{
  stylesOverride?: Partial<typeof Footer3MobileDefaultStyles>;
}> = ({ stylesOverride }) => {
  const style = {
    ...Footer3MobileDefaultStyles,
    ...stylesOverride,
  };
  const [isPrivacyPopUpOpen, setIsPrivacyPopUpOpen] = useState<boolean>(false);
  const [isTermPopUpOpen, setIsTermPopUpOpen] = useState<boolean>(false);
  const subdomain = useRecoilValue(subdomainState);

  return (
    <>
      <TermsPrivacyPopup open={isPrivacyPopUpOpen} setOpen={setIsPrivacyPopUpOpen} />
      <TermsServicePopup open={isTermPopUpOpen} setOpen={setIsTermPopUpOpen} />

      <div className={style.FooterRoot}>
        {getItemByChannel(subdomain, CUSTOMER_SERVICE_BY_CHANNEL) && (
          <div>
            <div
              className={cx(
                css`
                  gap: ${SPACING.spacing24};
                `,
                font.Caption1_Bold,
              )}
            >
              고객센터 운영안내
            </div>
            <div
              className={cx(
                css`
                  margin-top: ${SPACING.spacing24};
                `,
                font.Caption2_Light,
              )}
            >
              {convertLineBreaks(getItemByChannel(subdomain, CUSTOMER_SERVICE_BY_CHANNEL))}
            </div>
          </div>
        )}

        <div>
          <div
            className={cx(
              css`
                display: flex;
                gap: ${SPACING.spacing24};
              `,
              font.Caption1_Bold,
            )}
          >
            <button type="button" onClick={() => setIsPrivacyPopUpOpen(true)}>
              개인정보 처리방침
            </button>
            <button type="button" onClick={() => setIsTermPopUpOpen(true)}>
              이용약관
            </button>
            <button
              type="button"
              onClick={() => {
                window.location.href = HELP_PAGE;
              }}
            >
              자주묻는 질문
            </button>
          </div>
          <div
            className={css`
              display: flex;
              flex-direction: column;
              gap: ${SPACING.spacing16};
            `}
          >
            <div
              className={css`
                margin-top: 24px;
              `}
            >
              <img
                alt="ims-one-logo"
                className={css`
                  height: 20px;
                `}
                src={IMS_LOGO}
              />
            </div>
            <div
              className={cx(
                css`
                  display: flex;
                  flex-direction: column;
                  gap: ${SPACING.spacing16};
                `,
                font.Caption2_Light,
              )}
            >
              상호명 아이엠에스모빌리티 주식회사 | 대표 조영탁 |<br />
              개인정보보호책임자 조영탁 | 사업자등록번호 206-86-77227 |<br />
              통신판매업신고번호 2019-서울광진-2048
              <br />
              주소 서울특별시 광진구 아차산로 355, 3층 광진아크로텔
              <br />
              이메일 info@trimo.ai
              <br />
            </div>
            <div
              className={cx(
                css`
                  display: flex;
                  flex-direction: column;
                `,
                font.Caption2_Light,
              )}
            >
              아이엠에스모빌리티 주식회사는 통신판매중개자로 통신판매의
              <br />
              당사자가 아닙니다. 상품의 예약, 이용 및 환불 등 상품·거래정보 및 거래에 대하여 책임을
              지지않으며 관련한 의무와 책임은 각 판매자에게 있습니다.
              <br />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};
