/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useRecoilValue } from 'recoil';

import { TextInput } from '^/commonComponents/inputs/TextInput';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CarListingBottomSheetWrapper } from '^/components/CarSearchBox/base/mobile/BottomSheet/CarListingBottomSheetWrapper';
import { MRTCarListingBottomSheetWrapper } from '^/components/CarSearchBox/myrealtrip/mobile/MRTCarListingBottomSheetWrapper';
import Layout from '^/components/Layout';
import { SPACING, COLORS2 } from '^/constants/commonStyles';
import { renewalUIState, subdomainState } from '^/recoil/atoms';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { ListingPage2ViewModel } from '../viewmodel/ListingPage2.viewmodel';

import { ListingPage2MobileFilterBottomSheet } from './Filter/ListingPage2MobileFilter.BottomSheet';
import { ListingPage2MobileCard } from './ListingPage2.Mobile.Card';
import { ListingPage2MobileHeader } from './ListingPage2.Mobile.Header';
import { ListingPage2MobileListGroupBottomSheet2 } from './ListingPage2.Mobile.ListGroup.BottomSheet2';
import ListingPage2MobileLoading from './ListingPage2.Mobile.Loading';
import { ListingPage2mobileNoCar } from './ListingPage2.Mobile.NoCar';

export const ListingPage2Mobile: React.FC<{
  viewmodel: ListingPage2ViewModel;
}> = observer(function ListingPage2Mobile({ viewmodel }) {
  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const pickupCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);
  const returnCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);

  const carsScrollAreaRef = useRef<HTMLDivElement>(null);
  const carClassChipsScrollRef = useRef<HTMLDivElement>(null);

  const isRenewal = useRecoilValue(renewalUIState);

  useEffect(() => {
    if (!carsScrollAreaRef.current) return;
    carsScrollAreaRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [viewmodel.filterViewModel.carClass.value.size]);

  useEffect(() => {
    const unlisten = typeRouter.session.listen((nextRoute) => {
      if (nextRoute.action === 'pop') {
        viewmodel.stepManager.updatePushCountBy(-1);
      }
    });
    return unlisten;
  });

  const carGroupKey =
    viewmodel.stepManager.currentStep?.type === 'activeCarListGroup'
      ? viewmodel.stepManager.currentStep.carGroupKey
      : null;
  return (
    <>
      <TextInput
        id="DummyInputForPickupCityIOSKeyboardPopup"
        rootRef={pickupCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel.carListingSearchBoxInputVm.pickupCitySearch.searchInput}
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
        `}
      />
      <TextInput
        id="DummyInputForReturnCityIOSKeyboardPopup"
        rootRef={returnCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel.carListingSearchBoxInputVm.returnCitySearch.searchInput}
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
        `}
      />
      <ListingPage2MobileListGroupBottomSheet2
        viewmodel={viewmodel}
        carListGroupKey={carGroupKey}
        onClose={() => {
          viewmodel.stepManager.clearStep();
        }}
      />
      {viewmodel.stepManager.currentStep?.type === 'FILTER' && (
        <ListingPage2MobileFilterBottomSheet viewmodel={viewmodel} />
      )}
      {!isRenewal && (viewmodel.carListingSearchBox.stepManager.getSteps().length || null) && (
        <CarListingBottomSheetWrapper
          viewmodel={viewmodel.carListingSearchBox}
          pickupCityInputRef={pickupCityInputRef}
          pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
          returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
          carsScrollAreaRef={carsScrollAreaRef}
          carClassChipsScrollRef={carClassChipsScrollRef}
        />
      )}
      {isRenewal && (viewmodel.carListingSearchBox.stepManager.getSteps().length || null) && (
        <MRTCarListingBottomSheetWrapper
          pickupCityInputRef={pickupCityInputRef}
          pickupCityInputDummyRefForIOSKeyboard={pickupCityInputDummyRefForIOSKeyboard}
          returnCityInputDummyRefForIOSKeyboard={returnCityInputDummyRefForIOSKeyboard}
          viewmodel={viewmodel.carListingSearchBox}
          carsScrollAreaRef={null}
          carClassChipsScrollRef={null}
          rootStep={{ type: '0_none_selected' }}
        />
      )}
      <Layout
        withoutFooter
        withoutOverflowScroll
        withoutNavigationFullRefresh
        classOverrides={{
          layout2MobileContentWrapperBg: COLORS2.sub.MrtGray3,
        }}
      >
        <div
          className={
            cx(css`
              width: 100%;
              height: 100%;

              background-color: ${COLORS2.sub.MrtGray3};
              display: flex;
              flex-direction: column;
            `)
            // borders.DEBUG_greenBorderDashed,
          }
        >
          <ViewByState
            errorView={
              <>
                <ListingPage2MobileHeader
                  viewmodel={viewmodel}
                  carClassChipsScrollRef={carClassChipsScrollRef}
                  isFilterButtonVisible={viewmodel.allCarList.status === 'success'}
                />
                <ListingPage2mobileNoCar />
              </>
            }
            loadingView={
              <ListingPage2MobileLoading
                viewmodel={viewmodel}
                carClassChipsScrollRef={carClassChipsScrollRef}
              />
            }
            data={viewmodel.allCarList}
          >
            {(carsList) => {
              return (
                <>
                  <ListingPage2MobileHeader
                    carClassChipsScrollRef={carClassChipsScrollRef}
                    isFilterButtonVisible={viewmodel.allCarList.status === 'success'}
                    viewmodel={viewmodel}
                  />
                  {carsList.filteredListVMs.first() === undefined ? (
                    <ListingPage2mobileNoCar />
                  ) : (
                    <section
                      ref={carsScrollAreaRef}
                      className={css`
                        height: 100%;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        gap: ${SPACING.spacing24};
                        padding: ${SPACING.spacing24};
                      `}
                    >
                      {carsList.filteredListVMs
                        // FIXME: check what the following line was about
                        // .filter((b) => b.data.POD?.payment.domesticPrice)
                        .map((car) => {
                          return (
                            <ListingPage2MobileCard
                              key={car.data.key}
                              viewmodel={car}
                              onOpenDetails={() => {
                                viewmodel.stepManager.pushStep({
                                  type: 'activeCarListGroup',
                                  carGroupKey: car.data.key,
                                });
                              }}
                            />
                          );
                        })}
                    </section>
                  )}
                </>
              );
            }}
          </ViewByState>
        </div>
      </Layout>
    </>
  );
});
