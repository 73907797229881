/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { MAX_HISTORY_LENGTH, SEARCH_FAQ_HISTORY } from './constants';

export const FaqContentSearchMobile: React.FC<{
  listQuestion: any;
  handleSearchQuestion: any;
  handleSelectDisclosure: any;
}> = ({
  listQuestion,
  handleSearchQuestion,
  handleSelectDisclosure,
}) => {
  const [showHistory, setShowHistory] = useState(true);
  const [keySearch, setKeySearch] = useState('');
  const [questionsRecentSearch, setQuestionsRecentSearch] = useState([]);

  const searchHistory = (key) => {
    const history = JSON.parse(localStorage.getItem(SEARCH_FAQ_HISTORY) || '[]').filter((el) => el !== key);
    const workingHistory = (history.length === MAX_HISTORY_LENGTH) ? history.splice(1) : history;
    const updateHistory = workingHistory.concat(key);
    setQuestionsRecentSearch(updateHistory);
    localStorage.setItem(SEARCH_FAQ_HISTORY, JSON.stringify(updateHistory));
  };

  const onSearch = (key) => {
    // Call search in parent component
    if (key.trim()) {
      setShowHistory(false);
      handleSearchQuestion(key); // Call function search question
      searchHistory(key); // Update localStorage
    } else {
      setShowHistory(true);
    }
  };
  const clearAllHistory = () => {
    localStorage.removeItem(SEARCH_FAQ_HISTORY);
    setQuestionsRecentSearch([]);
  };
  const deleteKeyword = (key) => {
    const history = JSON.parse(localStorage.getItem(SEARCH_FAQ_HISTORY) || '[]');
    const updateHistory = history.filter((str) => str !== key);
    setQuestionsRecentSearch(updateHistory);
    localStorage.setItem(SEARCH_FAQ_HISTORY, JSON.stringify(updateHistory));
  };
  const reusedKeySearch = (key) => {
    setKeySearch(key);
    onSearch(key);
  };
  useEffect(() => {
    setQuestionsRecentSearch(JSON.parse(localStorage.getItem(SEARCH_FAQ_HISTORY) || '[]'));
    return () => {
    };
  }, []);
  return (
    <div>
      <div className="mt-5 mb-2 flex rounded-trimo-5  bg-trimo-shadow pt-3 pb-[11px]">
        {/* Search */}
        <input
          tabIndex={-1}
          placeholder="최근검색어"
          className="grow bg-trimo-shadow px-4 py-2 text-default text-primary-gray outline-none placeholder:text-trimo-gray focus:outline-none"
          onChange={(event) => setKeySearch(event.target.value)}
          value={keySearch}
        />
        <div className="flex items-center px-4" onClick={() => onSearch(keySearch)}>
          <img src="./ic_search_gray.svg" alt="icon-search" className="h-6 w-6" />
        </div>
      </div>
      {/*  Search History */}
      {showHistory && (
        <div className="mt-2">
          <div className="flex justify-between py-4">
            <span className="text-default font-bold">최근검색어</span>
            <span className="pl-4 text-default " onClick={clearAllHistory}>전체삭제</span>
          </div>
          {/* List recent search  */}
          {
            questionsRecentSearch.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-between">
                    <span className="grow py-[10px] text-big" onClick={() => reusedKeySearch(item)}>{item}</span>
                    <img
                      src="/ic_x_gray.svg" alt="icon-search" className="ml-4 h-6 w-6 fill-trimo-gray"
                      onClick={() => deleteKeyword(item)}
                    />
                  </div>
                </div>
              );
            })
          }
        </div>
      )}
      {/* Result search */}
      {!showHistory && (
        <div className="mb-2 mt-[14px]">
          {
            listQuestion.map((item, index) => {
              return (
                <div className="py-[10px]" key={index} onClick={() => handleSelectDisclosure(item)}>
                  <span className="text-big">{item.question}</span>
                </div>
              );
            })
          }
        </div>
      )}
    </div>
  );
};
