import { lazy, Suspense, useEffect, useState } from 'react';
import setDefaultDatefnsOptions from 'date-fns/setDefaultOptions';
import koDatefnsLocale from 'date-fns/locale/ko';
import { Route, Routes, useLocation } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import './App.css';
import Layout from './components/Layout/index';
import { Layout2 } from './components/Layout/Layout2';
import {
  // NEW_RESERVATION,
  BOOKING_PATH,
  HELP_PAGE,
  HOME_PATH,
  MY_RESERVATION_PATH,
  NOTICE_DETAIL,
  NOT_FOUND_PATH,
  RESERVATION_DETAIL,
  RESULT_PATH,
  PERSONAL_PAYMENT,
  PERSONAL_PAYMENT_RESULT,
  PAYMENT_PENDING,
} from './constants/routes';
import { BOOKING, ROOT } from './constants/envConfig';
import { Help } from './pages/FAQ/index';
import Result from './pages/Result/index';

import {
  errorButtonTextState,
  errorMsgState,
  errorState,
  footerColorState,
  handleCloseState,
  subdomainState,
  renewalUIState,
} from './recoil/atoms/index';
import { useMediaQuery } from 'react-responsive';
import { getSubdomain } from './util';
import qs from 'qs';
import { typeRouter } from './typeRoute/typeRoutes';
import { TypeRouteRenderer } from './typeRoute/TypeRouteRenderer';
import { defaultAppContext } from './AppContext/AppContext';
import { setSessionId, setUUID } from './util/user';
import PersonalPayment from './pages/PersonalPayment/PersonalPayment.index';
import PersonalPaymentResult from './pages/PersonalPayment/PersonalPayment.result';
import { createTheme, ThemeProvider } from '@mui/material';
import PaymentPending from './pages/PaymentPending';
setDefaultDatefnsOptions({
  locale: koDatefnsLocale,
});

// import Home from './pages/Home';
const Error = lazy(() => import('./pages/Error'));
const NotFound = lazy(() => import('./pages/NotFound'));
// const MapLocation = lazy(() => import('./pages/MapLocation'));
// const Notice = lazy(() => import('./pages/Notice'));
const NoticeDetail = lazy(() => import('./pages/Notice/NoticeDetail'));
const Booking = lazy(() => import('./pages/Booking'));

const queryString = qs.parse(window.location.search.slice(1));
if (queryString.cityCode && queryString.cityName) {
  sessionStorage.setItem('preSelectedCity', JSON.stringify(queryString));
} else {
  sessionStorage.removeItem('preSelectedCity');
}
if (queryString.pid) {
  sessionStorage.setItem('_AGENT_DATA_', JSON.stringify(queryString));
}
const { RouteProvider: TypeRouteProvider } = typeRouter;

function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [loaded, setLoaded] = useState(false);

  const [, setFooterColor] = useRecoilState(footerColorState);
  const [isRenewalUI, setIsRenewalUI] = useRecoilState(renewalUIState);

  const subdomain = getSubdomain();
  const setSubdomain = useSetRecoilState(subdomainState);

  useEffect(() => {
    sessionStorage.setItem('selectedCountry', '');
  }, []);

  useEffect(() => {
    const checkSubdomain = () => {
      if (subdomain !== ROOT) {
        setSubdomain(subdomain);
      } else {
        setSubdomain('trimo');
      }
      setLoaded(true);
    };

    const setTrackingId = () => {
      if (!localStorage.getItem('_uuid_')) {
        setUUID();
      }
      if (!sessionStorage.getItem('_trkid_')) {
        setSessionId();
      }
    };

    checkSubdomain();
    setTrackingId();
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== BOOKING_PATH) {
      localStorage.removeItem(BOOKING);
      sessionStorage.removeItem(BOOKING);
    }
  }, [location]);

  useEffect(() => {
    const checkFooterColor = () => {
      if (isMobile) {
        switch (location.pathname) {
          case HOME_PATH:
          case RESULT_PATH:
          case MY_RESERVATION_PATH:
          case RESERVATION_DETAIL:
          case HELP_PAGE:
            setFooterColor('trimo-bg');
            break;
          default:
            setFooterColor('white');
        }
      } else {
        setFooterColor('white');
      }
    };

    checkFooterColor();
  }, [location, isMobile]);

  useEffect(() => {
    const checkRenewalUI = () => {
      if (subdomain.includes('myrealtrip') || subdomain.includes('waug')) {
        setIsRenewalUI(true);
      } else {
        setIsRenewalUI(false);
      }
    };

    checkRenewalUI();
  }, [subdomain]);

  const isErrorOpen = useRecoilValue(errorState);
  const handleClose = useRecoilValue(handleCloseState);
  const buttonText = useRecoilValue(errorButtonTextState);
  const errorMsg = useRecoilValue(errorMsgState);

  const muiTheme = createTheme({
    typography: {
      fontFamily: 'Pretendard, Poppins, Arial, sans-serif',
    },
  });

  return (
    <>
      <TypeRouteProvider>
        <ThemeProvider theme={muiTheme}>
          <Suspense fallback={<div></div>}>
            <Error
              isOpen={isErrorOpen}
              handleClose={handleClose}
              content={errorMsg}
              buttonText={buttonText}
            />
            <Routes>
              <Route
                path={NOT_FOUND_PATH}
                element={
                  <Layout>
                    <NotFound />
                  </Layout>
                }
              />
              <Route path={PERSONAL_PAYMENT} element={<PersonalPayment />} />
              <Route path={PERSONAL_PAYMENT_RESULT} element={<PersonalPaymentResult />} />
              <Route path={PAYMENT_PENDING} element={<PaymentPending />} />
              <Route path={BOOKING_PATH} element={<Booking key="BOOKING" />} />
              <Route
                path={RESULT_PATH}
                element={
                  <Layout2 withoutFooter withoutMaxWidth>
                    <Result />
                  </Layout2>
                }
              />
              {/* FIXME: please Delete */}
              <Route
                path={HELP_PAGE}
                element={
                  <Layout2>
                    <Help />
                  </Layout2>
                }
              />
              <Route
                path={NOTICE_DETAIL}
                element={
                  <Layout withoutMaxWidth>
                    <NoticeDetail />
                  </Layout>
                }
              />
              <Route path="*" element={<TypeRouteRenderer appContext={defaultAppContext} />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </TypeRouteProvider>
    </>
  );
}

export default App;
