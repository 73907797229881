/* eslint-disable implicit-arrow-linebreak */
import itiriri from 'itiriri';

import { ServicesType } from '^/api2/getCarList/decodeCarListResponse';

// eslint-disable-next-line no-template-curly-in-string
const NUMBER_PLACEHOLDER = '${num}';

type TranslationFuncType = (n: string) => string;

function moveElementToStart(array, value) {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
    array.unshift(value);
  }
  return array;
}
function getServiceInfoString(service: ServicesType, t: TranslationFuncType) {
  if (!service.info) {
    return null;
  }
  const infoAsNum = Number(service.info);
  if (infoAsNum) {
    if (t(service.shortName).includes(NUMBER_PLACEHOLDER)) {
      return '';
    }
    return t(service.info);
  }
  return t(service.info);
}
function getSimplifiedServiceInfoString(
  service: { name: string; info?: string },
  t: TranslationFuncType,
) {
  if (!service.info) {
    return null;
  }
  const infoAsNum = Number(service.info);
  if (infoAsNum) {
    if (t(service.name).includes(NUMBER_PLACEHOLDER)) {
      return '';
    }
    return t(service.info);
  }
  return t(service.info);
}
export function translateServices(
  services: ServicesType[],
  // FIXME: use ReturnType<typeof useTranslation>['t']
  //    after upgrading react-i18next version to 12
  t: TranslationFuncType,
) {
  const b = itiriri(services)
    .filter((service) => (service.info && service.info !== 'undefined') || false)
    .map((service) => {
      if (!service.info) {
        return null;
      }
      if (service.shortName === 'fueltype') {
        return `${getServiceInfoString(service, t)}`;
      }
      const shortName = t(service.shortName).replace(NUMBER_PLACEHOLDER, service.info);
      return `${getServiceInfoString(service, t)} ${shortName}`;
    });
  const serviceArray = moveElementToStart(b.toArray(), t('Electric'));
  return serviceArray.map((service, i) => {
    if (service.includes(t('Electric'))) {
      return <b>{service}</b>;
    }
    if (i === 0) {
      return <span>{`${service}`}</span>;
    }
    return <span>{` / ${service}`}</span>;
  });
}

export function translateSimplifiedServices(
  services: { name: string; info?: string }[],
  t: TranslationFuncType,
) {
  const b = itiriri(services)
    .filter((service) => (service.info && service.info !== 'undefined') || false)
    .map((service) => {
      if (!service.info) {
        return null;
      }
      if (service.name.toLowerCase() === 'fueltype') {
        return `${getSimplifiedServiceInfoString(service, t)}`;
      }
      const shortName = t(service.name).replace(NUMBER_PLACEHOLDER, service.info as string);
      return `${getSimplifiedServiceInfoString(service, t)} ${shortName}`;
    })
    .toArray();
  const serviceArray = moveElementToStart(b, t('Electric'));
  return serviceArray.map((service, i) => {
    if (service.includes(t('Electric'))) {
      return <b>{service}</b>;
    }
    if (i === 0) {
      return `${service}`;
    }
    return ` / ${service}`;
  });
}

// for Ontra

type OntraServiceType = {
  name: string;
  info?: string | number;
};

type ServiceInfoType = {
  capacity: string | number;
  displacement: string;
  fueltype: string;
  insurance: number;
  transmission: string;
};

const findService = (services: OntraServiceType[], keyword: string) =>
  services.find((s) => s.name === keyword)?.info;

export function translateServicesForOntra(
  services: OntraServiceType[],
  shuttleFlag: boolean,
  t: TranslationFuncType,
) {
  const serviceInfo: ServiceInfoType = services.reduce((acc, service) => {
    acc[service.name] = service.info;
    return acc;
  }, {} as ServiceInfoType);

  const isElectric = (findService(services, 'fueltype') as string) === 'Electric';
  return (
    <>
      {isElectric && (
        <span>
          <b>{`${t(serviceInfo.fueltype as string)}`}</b> /{' '}
        </span>
      )}
      <span>{`${t(serviceInfo.transmission as string)} 변속기`}</span>
      <span>{` / ${Number(serviceInfo.capacity)} 인승`}</span>
      <span>{` / ${serviceInfo.insurance ? '보험 포함' : '보험 미포함'}`}</span>
      <span>{` / ${shuttleFlag ? '셔틀 포함' : '셔틀 미포함'}`}</span>
    </>
  );
}
