export function changeToUpperCaseOfWordOnlyFistAlpahbet(word: string) {
  if (!word.length) return '';

  return word.trim().split(' ')
    .map((splitedWord) => splitedWord.toLowerCase())
    .map((lowerCaseWord) => {
      const firstAlphabet = lowerCaseWord[0]?.toUpperCase();
      const res = firstAlphabet + lowerCaseWord.slice(1);

      return res;
    })
    .join(' ');
}

export function deleteGrade(modelName: string) {
  return modelName.split(' ').slice(1).join(' ');
}
