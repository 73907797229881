export function dateWithHyphen(target) {
  if (!target) {
    return target;
  }
  const date = target.replace(/[^0-9]/g, '').trim();

  if (date.length < 9) {
    return date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
  }
  if (date.length < 13) {
    return date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
  }
  return date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6');
}

export function formatDateWithTimezone(dateString, returnType = 'string') {
  // 정규식을 사용하여 'yyyy-mm-ddThh:mm:ss' 형식인지 확인
  const regexWithTimezone = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (!regexWithTimezone.test(dateWithHyphen(dateString))) {
    // 'yyyy-mm-ddThh:mm:ss' 형식이 아닌 경우 변환
    const date = new Date(dateWithHyphen(dateString));
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    dateString = year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds;
  }
  if (returnType === 'string') {
    return dateString;
  } else if (returnType === 'date') {
    return new Date(dateString);
  } else {
    throw new Error('유효하지 않은 반환 타입입니다.');
  }
}
