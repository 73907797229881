import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { RentalCarOfficeId } from '^/types/__BrandedLocationTypes';

import { RentalOfficeInputViewModel } from '../../base/common/RentalOfficeInput/RentalOfficeInput.viewmodel';

import { defaultStyle, classNames } from './MRTOffice.style';
import { MRTOfficeItem } from './MRTOfficeItem';

export const MRTOfficeList: React.FC<{
  viewmodel: RentalOfficeInputViewModel,
  onSelectRentalOffice(id: RentalCarOfficeId): void;
}> = observer(function MRTOfficeList({
  viewmodel,
  onSelectRentalOffice,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const mobileWebVhSetting = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    mobileWebVhSetting();
  }, []);

  const locationNameStyle = isMobile ? classNames.mobile.locationName : css`
  font-size: 14px;
  color:#575757;
  margin-bottom: 12px;
`;

  const locationContainerStyle = isMobile ? css`
  padding: 8px 20px 12px;
` : '';

  return (
    <div
      className={
        isMobile
          ? cx(classNames.mobile.rootWrapper, defaultStyle.scrollbarStyle)
          : css`
            width: 100%;
            display: flex;
            flex-direction: column;
            gap:24px;
          `
      }
    >
      {(viewmodel.data.get('LAP')?.length || 0) > 0 && (
        <div className={locationContainerStyle}>
          <div className={locationNameStyle}>
            공항
          </div>
          {viewmodel.data.get('LAP')?.map((locationItem) => (
            <MRTOfficeItem
              category="LAP"
              locationItem={locationItem}
              onSelectRentalOffice={onSelectRentalOffice}
              viewmodel={viewmodel}
            />
          ),
          )}
        </div>
      )}
      {(viewmodel.data.get('LDT')?.length || 0) > 0 && (
        <div className={locationContainerStyle}>
          <div className={locationNameStyle}>
            도시
          </div>
          {
            viewmodel.data.get('LDT')?.map((locationItem) => (
              <MRTOfficeItem
                category="LDT"
                locationItem={locationItem}
                onSelectRentalOffice={onSelectRentalOffice}
                viewmodel={viewmodel}
              />
            ),
            )
          }
        </div>
      )}
      {(viewmodel.data.get('LHT')?.length || 0) > 0 && (
        <div className={locationContainerStyle}>
          <div className={locationNameStyle}>
            호텔
          </div>
          {
            viewmodel.data.get('LHT')?.map((locationItem) => (
              <MRTOfficeItem
                category="LHT"
                locationItem={locationItem}
                onSelectRentalOffice={onSelectRentalOffice}
                viewmodel={viewmodel}
              />
            ))
          }
        </div>
      )}
      {(viewmodel.data.get('LAM')?.length || 0) > 0 && (
        <div className={locationContainerStyle}>
          <div className={locationNameStyle}>
            기차역
          </div>
          {
            viewmodel.data.get('LAM')?.map((locationItem) => (
              <MRTOfficeItem
                category="LAM"
                locationItem={locationItem}
                onSelectRentalOffice={onSelectRentalOffice}
                viewmodel={viewmodel}
              />
            ))
          }
        </div>
      )}
    </div>
  );
});
