import {
  Observable, of,
} from 'rxjs';

import { BaseItemsRepository } from './BaseItemRepository';
import { ListAPIFnType, ExtractAPIFn, BaseListCore } from './BaseListCore';

export function createListCoreFromAPI<

  // TAPIFn extends ListAPIFnType<TArgs, TItem, TFail, TLoading>,
  TAPIFn extends ListAPIFnType<any, any, any, any>,
  TArgs extends ExtractAPIFn<TAPIFn>['args'] = ExtractAPIFn<TAPIFn>['args'],
  TItem extends ExtractAPIFn<TAPIFn>['item'] = ExtractAPIFn<TAPIFn>['item'],
  TFail extends ExtractAPIFn<TAPIFn>['fail'] = ExtractAPIFn<TAPIFn>['fail'],
  TLoading extends ExtractAPIFn<TAPIFn>['loading']
  = ExtractAPIFn<TAPIFn>['loading'],
>(
  apiFn: TAPIFn,
) {
  return function createListCoreFromAPIStep2<
    TIDType extends string | number = string,
  >(itemToId: (item: TItem) => TIDType) {
    return function createListCoreFromAPIStep3<
      TRepo extends BaseItemsRepository<TItem, TIDType>,
    >(
      repo: TRepo = new BaseItemsRepository() as TRepo,
    ) {
      return new BaseListCore<
      TArgs,
      TItem,
      TFail,
      TLoading,
      TAPIFn,
      TIDType,
      TRepo
      >(
        apiFn,
        itemToId,
        repo,
      );
    };
  };
}
