import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { RentalOfficeInputViewModel } from '^/components/CarSearchBox/base/common/RentalOfficeInput/RentalOfficeInput.viewmodel';

import { changeToUpperCaseOfWordOnlyFistAlpahbet } from '../../../../../../pages/Listing/common/utils';
import { TUMON_TAMUNING_LOCATION_ID } from '../../../common/MRTOfficeItem';

import { SelectedOfficeAddress } from './SelectedOfficeAddress';
import { SelectedOfficeOpenHours } from './SelectedOfficeOpenHours';

const MRTSelectedOfficeInfo: React.FC<{
  viewmodel: RentalOfficeInputViewModel,
  isJapan?: boolean,
}> = observer(function MRTSelectedOfficeInfo({
  viewmodel,
  isJapan,
}) {
  const selectedOfficeData = viewmodel.tempSelectedOfficeData || viewmodel.selectedOfficeData;
  const officeFullAddress = `${changeToUpperCaseOfWordOnlyFistAlpahbet(
    selectedOfficeData?.locationDescriptions?.get('ko')?.address_1 || '')}
    ${changeToUpperCaseOfWordOnlyFistAlpahbet(
    selectedOfficeData?.locationDescriptions?.get('ko')?.address_2 || '')}
      ${changeToUpperCaseOfWordOnlyFistAlpahbet(
    selectedOfficeData?.locationDescriptions?.get('ko')?.address_3 || '')}`;
  const isTumonTamuning = selectedOfficeData?.location_id === TUMON_TAMUNING_LOCATION_ID;

  return (
    <>
      <div
        className={css`
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #434343;
        `}
      >
        {selectedOfficeData?.locationDescriptions?.get('ko')?.description}
      </div>
      <div
        className={css`
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #717171;
        `}
      >
        {changeToUpperCaseOfWordOnlyFistAlpahbet(
          selectedOfficeData?.locationDescriptions?.get('en')?.description || '')}
      </div>
      <SelectedOfficeAddress
        isJapan={isJapan}
        isTumonTamuning={isTumonTamuning}
        fullAddress={officeFullAddress}
        phone={selectedOfficeData?.phone}
      />
      <SelectedOfficeOpenHours
        isJapan={isJapan}
        openCloseTimes={selectedOfficeData?.openCloseTimes}
      />
    </>
  );
});
export default MRTSelectedOfficeInfo;
