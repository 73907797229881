import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { decodeRawRentalCarOfficeFromAPI } from '^/api2/getRentalCarOfficeLocationsByCity/GetCategoriesByCity.convert';
import { COLORS } from '^/constants/commonStyles';
import { LocationCategoryNameType, RentalCarOfficeId } from '^/types/__BrandedLocationTypes';

import { RentalOfficeInputViewModel } from '../../base/common/RentalOfficeInput/RentalOfficeInput.viewmodel';

const CategoryImageMap = {
  LAP: 'airport.svg',
  LDT: 'city.svg',
  LHT: 'mdi_bed.svg',
  LAM: 'train.svg',
};

export const TUMON_TAMUNING_LOCATION_ID = '7730061';
export const MRTOfficeItem: React.FC<{
  viewmodel: RentalOfficeInputViewModel;
  onSelectRentalOffice(id: RentalCarOfficeId): void;
  category: LocationCategoryNameType;
  locationItem: ReturnType<typeof decodeRawRentalCarOfficeFromAPI>;
}> = observer(function MRTOfficeItem({ viewmodel, onSelectRentalOffice, category, locationItem }) {
  return (
    <div
      key={locationItem.location_id}
      className={css`
        width: 100%;
        padding: 8px 0;
        position: relative;
        &:hover {
          background-color: #f4f4f4;
          border-radius: 8px;
        }
      `}
    >
      <button
        type="button"
        className={css`
          display: flex;
          align-items: center;
          height: 52px;
          width: 100%;
          height: auto;
        `}
        key={locationItem.location_id}
        onClick={async () => {
          viewmodel.tempSelectedOfficeId.set(locationItem.location_id);
          onSelectRentalOffice(locationItem.location_id);
          // sendTrackingLog({
          //   action: actions['/'].SELECT_PICKUP_LOCATION,
          //   data: locationItem.locationDescriptions.get('ko')?.description,
          //   country: sessionStorage.getItem('selectedCountry') || '',
          // });
        }}
      >
        <div
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border-radius: 6px;
            background-color: #eef6fe;
          `}
        >
          <img width={16} height={16} src={CategoryImageMap[category]} alt={category} />
        </div>
        <span
          className={css`
            margin: auto 12px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #1e1e1e;
          `}
        >
          {locationItem.locationDescriptions.get('ko')?.description}
        </span>
        {/* checked일 떄만 노출 */}
        {locationItem.location_id === viewmodel.tempSelectedOfficeData?.location_id && (
          <div
            className={css`
              width: 20px;
              height: 20px;
              position: absolute;
              right: 12px;
            `}
          >
            <img src="/CkCheck.svg" alt="checked" />
          </div>
        )}
      </button>
    </div>
  );
});
