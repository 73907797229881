import React from 'react';

import { cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ReservationCheckPageIDs } from './ids';
import {
  PhoneInput,
} from './PhoneInput';
import {
  DefaultReservationCheckFormStyles,
  ReservationCheckFormStylesOverrides,
} from './ReservationCheck.form.styles';
import {
  ReservationCheckPageFormViewModel,
} from './ReservationCheck.form.viewmodel';

export const ReservationCheckForm: React.FC<{
  viewmodel: ReservationCheckPageFormViewModel,
  classNamesOverride?: ReservationCheckFormStylesOverrides,
}> = observer(function ReservationCheckPage({
  viewmodel,
  classNamesOverride,
}) {
  return (
    <div
      id="ReservationCheckForm__root"
      className={
        classNamesOverride?.root
        || DefaultReservationCheckFormStyles.root
      }
    >
      <span
        id="ReservationCheckForm__phoneText"
        className={
          classNamesOverride?.phoneText
          || DefaultReservationCheckFormStyles.phoneText
        }
      >휴대폰 번호
      </span>
      <PhoneInput
        viewmodel={viewmodel.phone}
        classNameOverrides={
          classNamesOverride?.phoneInputOverrides
          || DefaultReservationCheckFormStyles.phoneInputOverrides
        }
      />
      <span
        className={
          classNamesOverride?.phoneText
          || DefaultReservationCheckFormStyles.emailText
        }
      >이메일
      </span>
      <input
        id={ReservationCheckPageIDs.emailInput}
        placeholder="이메일"
        name="email"
        autoComplete="on"
        type="email"
        className={
          classNamesOverride?.emailInput
          || DefaultReservationCheckFormStyles.emailInput
        }
        value={viewmodel.email.value}
        onChange={(e) => {
          viewmodel.email.set(e.currentTarget.value);
        }}
        onBlur={() => viewmodel.email.setHasBlurred(true)}
      />
    </div>
  );
});
