/* eslint-disable max-len */
import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { COLORS2_AS_FOREGROUND, font2 } from '^/constants/commonStyles';
import { CURRENCY_CODE_KEYS } from '^/constants/currencyCode.constants';
import {
  RawReservationDetailExtraChange,
} from '^/types/ReservationDetailed/RawReservationDetailed';

import { flexSpacedRowWithVerticalCenter } from './desktop/components/ReservationDetailDesktop.commonStuff';

const defaultStyles = {
  titleText: [
    font2.Headline1_Regular,
    COLORS2_AS_FOREGROUND.main.MrtBlack,
  ].join(' '),

  totalText: [
    font2.Headline1_Bold,
    COLORS2_AS_FOREGROUND.main.MrtBlack,
  ].join(' '),

  itemTitleText: [
    font2.Caption1_Light,
    COLORS2_AS_FOREGROUND.sub.MrtGray1,
  ].join(' '),

  itemTotalText: [
    font2.Caption1_Light,
    COLORS2_AS_FOREGROUND.sub.MrtGray1,
  ].join(' '),

  row: [
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `,
  ].join(' '),
} as const;

export const AdditionalFees2: React.FC<{
  extra_charge: RawReservationDetailExtraChange,
  onSiteCurrency: CURRENCY_CODE_KEYS,
  stylesOverride?: Partial<typeof defaultStyles>,
}> = ({
  extra_charge,
  onSiteCurrency,
  stylesOverride,
}) => {
  const styles = {
    ...defaultStyles,
    ...stylesOverride,
  };
  return (
    <div className="flex flex-col gap-2">
      <div className={flexSpacedRowWithVerticalCenter}>
        <p className={[
          styles.titleText,
        ].join(' ')}
        >추가 요금
        </p>
        <p className={[
          styles.totalText,
        ].join(' ')}
        >
          {extra_charge.additional_amount
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
        </p>
      </div>
      {extra_charge.extra_charge_detail.map((item) => {
        return (
          <div
            key={item.name}
            className={styles.row}
          >
            <p
              className={[
                styles.itemTitleText,
              ].join(' ')}
            >
              {`${item.name} (${item.quantity})`}
            </p>
            <p
              className={[
                styles.itemTotalText,
              ].join(' ')}
            >
              {item.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
            </p>
          </div>
        );
      })}
      {extra_charge.dropFee > 0
        && (
        <div className={styles.row}>
          <p className={styles.itemTitleText}>Drop fee</p>
          <p className={styles.itemTotalText}>
            {extra_charge.dropFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
          </p>
        </div>
        )}
      {extra_charge.etc_amount > 0
        && (
        <div className={styles.row}>
          <p className={styles.itemTitleText}>기타 (세금 등)</p>
          <p className={styles.itemTotalText}>
            {extra_charge.etc_amount.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {onSiteCurrency}
          </p>
        </div>
        )}
    </div>
  );
};
