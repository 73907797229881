import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CitySearchInputViewModel } from '^/components/CarSearchBox/base/desktop/headerArea/CitySearchInput/CitySearchInput.viewmodel';
import { MRTGoogleMap } from '^/components/CarSearchBox/myrealtrip/common/MRTGoogleMap';
import { MRTOfficeList } from '^/components/CarSearchBox/myrealtrip/common/MRTOfficeList';
import MRTSelectedOfficeInfo from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SelectedOffice/MRTSelectedOfficeInfo';
import { COLORS2 } from '^/constants/commonStyles';
import { RentalCarOfficeId } from '^/types/__BrandedLocationTypes';
import { PromiseResultType } from '^/types/__ResultType';

import { CarListingSearchBoxViewModel } from '../../../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { defaultStyle } from '../../../common/MRTOffice.style';

export const MRTSelectOfficeList: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  officeViewmodel: PromiseResultType<any, any, any>;
  searchInputViewmodel: CitySearchInputViewModel;
  onResetCity: () => void;
  onSelectRentalOffice: (officeId: RentalCarOfficeId) => void;
  onSubmit: () => void;
  showOfficeInfo: boolean;
  selectedCityName: string;
  selectedRentalOffice: RentalCarOfficeId | null;
  isSubmitBtnDisabled: boolean;
}> = observer(function MRTSelectOfficeList({
  viewmodel,
  officeViewmodel,
  searchInputViewmodel,
  onResetCity,
  onSelectRentalOffice,
  onSubmit,
  showOfficeInfo,
  selectedCityName,
  selectedRentalOffice,
  isSubmitBtnDisabled,
}) {
  return (
    <div
      className={css`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 1012px;
        height: 456px;
        background-color: ${COLORS2.main.MrtWhite};
        border-radius: 8px;
      `}
    >
      <div
        className={css`
          width: 100%;
          padding: 24px 24px 0 24px;
          height: 376px;
          display: flex;
          gap: 24px;
        `}
      >
        <div
          className={cx(
            css`
              display: flex;
              flex-direction: column;
              gap: 24px;
              padding-right: 24px;
              width: 374px;
              height: 100%;
              overflow-y: scroll;
            `,
            defaultStyle.scrollbarStyle,
          )}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              className={css`
                display: flex;
              `}
            >
              <img width={20} height={20} src="marker-pin_black_20.svg" alt="marker-pin" />
              <div
                className={css`
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                  margin-left: 4px;
                `}
              >
                {selectedCityName}
              </div>
            </div>
            <div
              tabIndex={-1}
              role="button"
              className={css`
                height: 28px;
                border-radius: 4px;
                padding: 6px 8px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #575757;
                background-color: #f4f4f4;
              `}
              onClick={onResetCity}
            >
              도시변경
            </div>
          </div>
          {/* 영업소 리스트 */}
          <ViewByState
            data={officeViewmodel}
            loadingView={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <BlueLoadingSpinner
                classOverrides={{
                  root: css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    margin-bottom: 100px;
                  `,
                  spinner: css`
                    margin-bottom: 70px;
                    width: 45px;
                    height: 45px;
                  `,
                }}
              />
            }
            errorView={<h1> 에러가 발생하였습니다 </h1>}
          >
            {(officeDataVM) => (
              <MRTOfficeList viewmodel={officeDataVM} onSelectRentalOffice={onSelectRentalOffice} />
            )}
          </ViewByState>
        </div>
        <div
          className={css`
            flex: 1;
            padding-bottom: 24px;
          `}
        >
          {/* 선택한 영업소 정보 */}
          {showOfficeInfo && (
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 12px;
                width: 100%;
                height: 100%;
                padding: 24px;
                background-color: #f4f4f4;
                border-radius: 8px;
              `}
            >
              <ViewByState
                data={officeViewmodel}
                loadingView={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <BlueLoadingSpinner
                    classOverrides={{
                      root: css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                        margin-bottom: 100px;
                      `,
                      spinner: css`
                        margin-bottom: 70px;
                        width: 45px;
                        height: 45px;
                      `,
                    }}
                  />
                }
                errorView={<h1>에러가 발생하였습니다</h1>}
              >
                {(officeDataVM) => {
                  if (!officeDataVM.tempSelectedOfficeId.value) {
                    officeDataVM.tempSelectedOfficeId.set(selectedRentalOffice);
                  }
                  // eslint-disable-next-line operator-linebreak
                  const selectedOffice =
                    officeDataVM.tempSelectedOfficeData || officeDataVM.selectedOfficeData;
                  const selectedOfficePosition = selectedOffice && {
                    lat: Number(selectedOffice.latitude),
                    lng: Number(selectedOffice.longitude),
                  };
                  const isJapan = isOntra(searchInputViewmodel.selectedCityCode.value);
                  return (
                    <>
                      <div
                        className={cx(
                          css`
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            width: 222px;
                            height: 100%;
                            overflow-y: scroll;
                          `,
                          defaultStyle.scrollbarStyle,
                          css`
                            &::-webkit-scrollbar-thumb {
                              border: 2px solid #f4f4f4;
                            }
                          `,
                        )}
                      >
                        <MRTSelectedOfficeInfo viewmodel={officeDataVM} isJapan={isJapan} />
                      </div>
                      <div
                        className={css`
                          flex: 1;
                          width: 100%;
                          height: 100%;
                        `}
                      >
                        {selectedOfficePosition && (
                          <MRTGoogleMap
                            viewmodel={officeDataVM}
                            officePosition={selectedOfficePosition}
                            containerStyle={{ width: '100%', height: '100%' }}
                          />
                        )}
                      </div>
                    </>
                  );
                }}
              </ViewByState>
            </div>
          )}
        </div>
      </div>

      <div
        className={css`
          position: absolute;
          top: calc(100% - 56px);
          left: 50%;
          transform: translate(-50%, -24px);
          border-radius: 0px 0px 8px 8px;
          width: 100%;
          padding: 16px 20px;
          background-color: ${COLORS2.main.MrtWhite};
          box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.05);
        `}
      >
        <button
          type="button"
          className={css`
            width: 100%;
            height: 48px;
            border-radius: 8px;
            background-color: ${COLORS2.main.MrtBlue1} !important;
            color: ${COLORS2.main.MrtWhite};

            &:disabled {
              background-color: #b1d7f9 !important;
            }
          `}
          disabled={isSubmitBtnDisabled}
          onClick={onSubmit}
        >
          확인
        </button>
      </div>
    </div>
  );
});

export default MRTSelectOfficeList;
