import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLettersInWords } from '^/util/capitalizeFirstLetterInWords';

export const GroupCar = ({ modelName, size, type, className = '', isMobile }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4 className={className}>
        {`[${t(size)}] ${capitalizeFirstLettersInWords(size)} ${
          type && type !== 'car' ? type : ''
        }`}
      </h4>
      {isMobile ? <img src={'./ic_arrow_right.png'} className="mt-[1.5px]" width="24" /> : <></>}
    </>
  );
};
{
  /* <img src={'./ic_arrow_right.png'}/> */
}

export const NameCar = ({ className = '', modelName }) => {
  const [nameCar, setNameCar] = useState(null);
  useEffect(() => {
    if (modelName) {
      let initial = 2;
      if (modelName.substring(0, 2).trim().length === 2) {
        initial = 3;
      }
      modelName.slice(-10) === 'OR SIMILAR'
        ? setNameCar(modelName.slice(initial, -10))
        : setNameCar(modelName.slice(initial));
    }
  }, [modelName]);
  return (
    <p className={`lowercase first-line:capitalize ${className}`}>
      <span>
        {capitalizeFirstLettersInWords(nameCar)}{' '}
        {modelName.slice(-10) === 'OR SIMILAR' && '또는 동급차량'}
      </span>
    </p>
  );
};

GroupCar.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  modelName: PropTypes.string,
  isMobile: PropTypes.bool,
};

NameCar.propTypes = {
  className: PropTypes.string,
  modelName: PropTypes.string,
};
