import { AxiosInstance } from 'axios';

import { ReservationIDType, UserLastNameType, VendorCompanyIDType } from '^/types/__BrandedTypes';
import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';
import { convertReservationDetailedFromRaw, RawReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';

import { api2BaseAxios } from '../api2BaseAxios';
import { defineResponseWithData } from '../DefineResponse';
import { zodValidateAxiosResp } from '../zodValidate';

const responseType = defineResponseWithData(
  RawReservationDetailed,
  201,
);

const validator = zodValidateAxiosResp(
  responseType,
  (err) => {
    console.error('getReservation zodError', err);
  },
);

export function createGetReservationDetailAPI(
  axiosInstance: AxiosInstance,
) {
  return async function getReservationDetail(args: {
    reservation_id: ReservationIDType,
    last_name: UserLastNameType,
    vendor_id: VendorCompanyIDType,
  }) {
    const [rawBookedResponse, rawCancelledResponse] = await Promise.allSettled([
      axiosInstance
        .post('/v2/reservation/detail/booked', args, {
          timeout: 2000,
        }),
      axiosInstance
        .post('/v2/reservation/detail/canceled', args, {
          timeout: 2000,
        }),
    ]);
    const rawResponse = rawBookedResponse.status === 'fulfilled'
      && rawBookedResponse.value.data.status === 201 ? rawBookedResponse.value
      : rawCancelledResponse.status === 'fulfilled' && rawCancelledResponse.value;
    if (rawResponse === false) {
      return asFailedPromise({
        reason: 'BookedAndCancelledRequestsFailed',
        rawBookedResponse,
        rawCancelledResponse,
      } as const);
    }
    const validated = validator(rawResponse);
    if (validated.data.status !== 201) {
      return asFailedPromise({
        reason: 'HTTPError',
        error: rawResponse.data,
        rawResponse,
      } as const);
    }
    // debugger;
    return asSuccessPromise({
      ...validated,
      data: {
        ...validated.data,
        data: convertReservationDetailedFromRaw({
          ...validated.data.data,
          insurance_info_json:
            validated.data.data.insurance_info_json
            || JSON.parse(validated.data.data.insurance_info),
          extra_equipments_json:
            validated.data.data.extra_equipments_json
            || JSON.parse(validated.data.data.extra_equipmens),
          services_json:
            validated.data.data.services_json
            || JSON.parse(validated.data.data.services),
          ...{ // overrides for debugging
            // cancel_confirm: false,
            // cancel_confirm: true,
          },
        }),
      },
    });
  };
}

export const getReservationDetailAPI = createGetReservationDetailAPI(
  api2BaseAxios,
);

export type GetReservationDetailAPIType = typeof getReservationDetailAPI;
