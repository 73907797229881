/* eslint-disable operator-linebreak */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  borders,
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
  COLORS,
} from '^/constants/commonStyles';

import { translateServices } from '../../common/translateServices';
import { changeToUpperCaseOfWordOnlyFistAlpahbet, deleteGrade } from '../../common/utils';
import { ListingPage2CardViewModel } from '../viewmodel/ListingPage2.card.viewmodel';

export const ListingPage2MobileCard: React.FC<{
  viewmodel: ListingPage2CardViewModel;
  onOpenDetails: () => void;
}> = observer(function ListingPage2MobileCard({ viewmodel, onOpenDetails }) {
  const { t } = useTranslation();
  const { data, lowestPricePayment } = viewmodel;
  const { services } = data;

  const convertedModelName =
    data.modelName && deleteGrade(changeToUpperCaseOfWordOnlyFistAlpahbet(String(data.modelName)));

  const carSize = data.vehType.size;
  const carType = data.vehType.type;

  const isDiscounted = lowestPricePayment?.payment.isDiscounted;
  const currencyCode = lowestPricePayment?.payment.foreignCurrencyCode;

  const discountForeign = Number(lowestPricePayment?.payment.discountForeign);
  const foreignPrice = Number(lowestPricePayment?.payment.foreignCurrency);
  const finalForeignPrice = isDiscounted ? Math.ceil(foreignPrice - discountForeign) : foreignPrice;

  const discountDomestic = Number(lowestPricePayment?.payment.discountDomestic);
  const domesticPrice = Number(lowestPricePayment?.payment.domesticPrice);
  const finalDomesticPrice = isDiscounted ? domesticPrice - discountDomestic : domesticPrice;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => {
        onOpenDetails();
      }}
      className={[
        css`
          background-color: ${COLORS2.main.MrtWhite};
          padding: ${SPACING.spacing16};
          border-radius: 10px;
        `,
      ].join(' ')}
    >
      <div
        id="CarListing2"
        className={cx(
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `,
          // borders.DEBUG_redBorderDashed,
        )}
      >
        <h1
          className={[
            font2.Headline1_Bold,
            css`
              text-align: center;
              word-break: keep-all;
            `,
          ].join(' ')}
        >
          {`[${t(carSize)}] ${convertedModelName} ${data.orSimilarCar ? '또는 동급차량' : ''}`}
        </h1>

        <h4 className={cx(font2.Caption2_Light, COLORS2_AS_FOREGROUND.sub.MrtGray1)}>
          {translateServices(services, t)}
        </h4>
        {/* Badges */}
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 11px;
          `}
        >
          {data.badges?.map((badge) => (
            <span
              className={css`
                border-radius: 3px;
                background-color: ${COLORS.BLUE_200};
                font-size: 12px;
                color: ${COLORS.BLUE_500};
                padding: 0 6px;
              `}
            >
              {badge}
            </span>
          ))}
        </div>
        <img
          alt="car"
          src={data.carImageUrl}
          className={[
            css`
              width: 152px;
              height: 128px;

              max-height: ${SPACING.spacing120};
              object-fit: contain;
            `,
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
          onError={(e) => {
            e.currentTarget.src = '/NOT_FOUND_CAR.png';
          }}
        />
        <span className={[COLORS2_AS_FOREGROUND.main.MrtBlack, font2.Caption2_Light].join(' ')}>
          {viewmodel.groupedByVendors.keys().length()}개 렌트카 업체
        </span>
      </div>
      <div
        role="button"
        className={[
          css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 66px;
          `,
          COLORS2_AS_BACKGROUND.sub.MrtGray3,
          // MARGINS.TOP.spacing12,
          // PADDINGS.TOP_AND_BOTTOM.spacing12,
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        {isDiscounted && (
          <h4
            className={css`
              color: #717171;
              font-size: 14px;
              font-weight: 400;
              line-height: 100%;
              text-decoration: line-through;
            `}
          >
            최저{' '}
            <span
              className={css`
                font-weight: 700;
                line-height: 100%;
              `}
            >
              {foreignPrice} {currencyCode}
            </span>
          </h4>
        )}
        <h2
          className={[
            font2.Title3,
            isDiscounted
              ? COLORS2_AS_FOREGROUND.main.GuamBlue1
              : COLORS2_AS_FOREGROUND.main.MrtBlack,
            isDiscounted &&
              css`
                line-height: 22px;
              `,
          ].join(' ')}
        >
          <span className={[font2.Caption1_Bold, MARGINS.RIGHT.spacing6].join(' ')}>최저</span>
          {finalForeignPrice} {currencyCode}
        </h2>
        <h3
          className={[
            font2.Caption1_Light,
            COLORS2_AS_FOREGROUND.sub.MrtGray1,
            isDiscounted &&
              css`
                line-height: 90%;
              `,
          ].join(' ')}
        >
          약 {finalDomesticPrice?.toLocaleString()} 원
        </h3>
      </div>
    </div>
  );
});
