import { makeAutoObservable } from 'mobx';

import { CancelReservationAPIType } from '^/api2/cancelReservation/cancelReservationAPI';
import { GetReservationDetailAPIType } from '^/api2/getReservationDetail/getReservationDetailAPI';
import { ReservationIDType, UserLastNameType, VendorCompanyIDType } from '^/types/__BrandedTypes';
import {
  asSuccessPromise,
  FreshnessType,
  getCurrentFreshness,
  NotInitiatedPromiseStale,
} from '^/types/__ResultType';
import { ExtractPromise } from '^/types/utils/ExtractPromise';
import { computedFn2 } from '^/util/mobx-utils/mobxComputedFn2';

import { ReservationDetailRepo } from './ReservationDetailRepo';
import { CancellationReasonType } from '^/types/CancellationReasonType';

type ResultType = ExtractPromise<ReturnType<GetReservationDetailAPIType>>;
export class ReservationDetailCore {
  constructor(
    public getReservationDetailsAPI: GetReservationDetailAPIType,
    public cancelReservationAPI: CancelReservationAPIType,
    public reservationDetailsRepo: ReservationDetailRepo,
    public reservationDetailResult = new Map<ReservationIDType, ResultType>()
  ) {
    makeAutoObservable(this);
  }

  getReservationDetail = computedFn2(
    (
      reservationId: ReservationIDType,
      lastName: UserLastNameType,
      vendorId: VendorCompanyIDType,
      freshness: FreshnessType
    ) => {
      this.fetchReservationDetail(reservationId, lastName, vendorId, freshness);
      const result = this.reservationDetailResult.get(reservationId);
      if (!result) {
        return NotInitiatedPromiseStale;
      }
      if (result.status !== 'success') {
        return result;
      }
      this.reservationDetailsRepo.setById(reservationId, result.value.data.data);
      return asSuccessPromise(result.value.data.data, freshness);
    }
  );

  fetchReservationDetail = computedFn2(
    async (
      reservationId: ReservationIDType,
      lastName: UserLastNameType,
      vendorId: VendorCompanyIDType,
      freshness: FreshnessType
    ) => {
      const result = await this.getReservationDetailsAPI({
        reservation_id: reservationId,
        last_name: lastName,
        vendor_id: vendorId,
      });

      this.reservationDetailResult.set(reservationId, result);
    }
  );

  async cancelReservation(
    reservation_id: ReservationIDType,
    last_name: UserLastNameType,
    vendor_id: VendorCompanyIDType,
    cancel_reason: CancellationReasonType
  ) {
    const cancelResult = await this.cancelReservationAPI({
      reservation_id,
      last_name,
      vendor_id,
      cancel_reason,
    });
    const oldResult = this.reservationDetailResult.get(reservation_id);
    const freshness = ((oldResult?.freshness || getCurrentFreshness()) + 1) as FreshnessType;
    this.fetchReservationDetail(reservation_id, last_name, vendor_id, freshness);
    return cancelResult;
  }
}
