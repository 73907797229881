/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Disclosure, Transition } from '@headlessui/react';

export const FaqQuestion:React.FC<{
  defaultQuestion:any;
  item:any;
  listQuesRef?:any;
}> = ({
  defaultQuestion,
  item,
  listQuesRef,
}) => {
  return (
    <Disclosure as="div" defaultOpen={defaultQuestion.includes(item.question)}>
      {
        ({ open }) => (
          <>
            <Disclosure.Button
              className="flex w-full items-center justify-between py-[27px] pr-6 text-big tablet-max:px-0 tablet-max:pt-[20px] tablet-max:pb-[21px] tablet-max:text-big tablet-max:tracking-default"
              ref={(ref) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                listQuesRef
                  ? (listQuesRef.current[`${item.question}`] = ref)
                  : null;
              }}
            >
              <span className="tablet:font-bold tablet-max:text-start tablet-max:text-default">{item.question}</span>
              <img src={open ? './ic_arrow_up.png' : './ic_arrow_down.png'} className="h-6 w-6" />
            </Disclosure.Button>
            {/* <Disclosure.Panel className='w-full p-6 tablet-max:px-0 bg-trimo-shadow'> */}
            <Transition
              show={open}
              enter="transition-all duration-300 ease-out max-h-0"
              enterFrom="transform opacity-0 max-h-0"
              enterTo="transform opacity-100 max-h-[2000px]"
              leave="transition-all duration-300 ease-out"
              leaveFrom="transform max-h-[2000px] opacity-1"
              leaveTo="transform max-h-0 opacity-0"
            >
              <Disclosure.Panel static className="w-full bg-trimo-shadow p-6 pr-16 text-big font-normal leading-7 tablet-max:-mx-6 tablet-max:w-auto tablet-max:py-[20px] tablet-max:text-default tablet-max:font-light tablet-max:leading-6">
                {/* {item.content} */}
                {
                  Array.isArray(item?.answer) ? (
                    <>{
                      item.answer?.map((ans, idx) => (
                        <p key={`ans-${idx}`}>{ans}</p>
                      ))
                    }
                    </>
                  ) : (
                    <>
                      {
                        Object.keys(item.answer)?.map((ans, idx) => {
                          if (ans === 'title') return <p key={`ans-${idx}`}>{item.answer[ans]}</p>;
                          return (
                            <div key={`ans-${idx}`}>
                              <p className="font-bold">{ans}</p>
                              {
                                item.answer[ans]?.map((content, index) => (<p key={`content-${index}`}>{content}</p>))
                              }
                            </div>
                          );
                        },
                        )
                      }
                    </>
                  )
                }
              </Disclosure.Panel>
            </Transition>
          </>

        )
      }
    </Disclosure>
  );
};
