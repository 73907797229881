import React, { useLayoutEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { COLORS2 } from '^/constants/commonStyles';

import { CarListingSearchBoxViewModel } from '../../../base/desktop/viewmodel/CarListingSearchBox.viewmodel';

import { ContentsByStep } from './ContentsByStep';

export const MRTCarListingSearchBoxDesktopContentsArea: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
}> = observer(function MRTCarListingSearchBoxDesktopContentsArea({
  viewmodel,
}) {
  const {
    stepManager,
  } = viewmodel;

  const [inputPosition, setInputPosition] = useState({
    left: 24,
    height: 48,
    top: 89,
  });
  useLayoutEffect(() => {
    let searchStep = '';
    switch (viewmodel.stepManager.currentStep?.type) {
      case '1_rental_city':
      case '2_rental_office':
        searchStep = '1_rental_city';
        break;
      case '3_schedule':
        searchStep = '3_schedule';
        break;
      case '5_return_city':
      case '6_return_office':
        searchStep = '5_return_city';
        break;
      default:
        break;
    }
    const el = document.querySelector<HTMLElement>(`[data-step-id="${searchStep}"]`);
    if (el) {
      const position = {
        left: el.offsetLeft,
        height: el.offsetHeight,
        top: el.offsetTop,
      };
      setInputPosition(position);
    }
  }, [viewmodel.stepManager.currentStep?.type]);

  return (
    <div className={cx([
      (stepManager.currentStep?.type === undefined
        || stepManager.currentStep?.type === '1_rental_city_search'
        || stepManager.currentStep?.type === '5_return_city_search')
        ? css`
          display: none;
        `
        : css`
          position: absolute;
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 8px;
          border: 1px solid var(--gray-200, #E1E1E1);
          top: ${inputPosition.top + inputPosition.height + 4}px;
          left: ${inputPosition.left}px;
          z-index:20;
        `],
    )}
    >
      <ContentsByStep viewmodel={viewmodel} step={stepManager.currentStep?.type || '0_none_selected'} />
    </div>
  );
});
