/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { Fragment, RefObject, useEffect } from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { MRTGoogleMap } from '^/components/CarSearchBox/myrealtrip/common/MRTGoogleMap';
import { MRTSearchStepperMobile } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SearchStepper/MRTSearchStepper.mobile';
import { SPACING, COLORS2 } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { PromiseResultType } from '^/types/__ResultType';

import MRTSelectedOfficeInfo from './MRTSelectedOfficeInfo';

export const MRTSelectedPickupOfficeInfoMobile: React.FC<{
  key: string;
  viewmodel: CarListingSearchBoxViewModel;
  officeViewmodel: PromiseResultType<any, any, any>;
  step: { type: '2_5_selected_rental_office' };
  pickupCityInputRef?: RefObject<HTMLInputElement>;
  rootStep?: SearchBoxSteps;
}> = observer(function MRTSelectedPickupOfficeInfoMobile({
  key,
  viewmodel,
  officeViewmodel,
  rootStep,
}) {
  const mobileWebVhSetting = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    mobileWebVhSetting();
  }, []);

  useEffect(() => {
    if (!viewmodel.i.tempPickupLocationId.value && !viewmodel.i.pickupLocationId.value) {
      typeRouter.session.back();
    }
  }, []);

  return (
    <Fragment key={key}>
      <div
        className={cx(
          css`
            padding: 0 20px;
            margin-top: 68px;
            position: relative;
            height: 100%;
            overflow-y: scroll;
          `,
        )}
      >
        <MRTSearchStepperMobile currentStep={3} />
        <div
          className={cx(
            css`
              margin-top: 20px;
              color: #1e1e1e;
              font-weight: 700;
              font-size: 20px;
              line-height: 28px;
            `,
          )}
        >
          대여 영업소 정보를 확인하세요.
        </div>
        <ViewByState
          data={officeViewmodel}
          loadingView={<h1>loading</h1>}
          errorView={() => {
            return <h1>error</h1>;
          }}
        >
          {(pickupVM) => {
            if (!pickupVM.tempSelectedOfficeId.value) {
              pickupVM.tempSelectedOfficeId.set(viewmodel.i.pickupLocationId.value);
            }
            const selectedOffice = pickupVM.tempSelectedOfficeData || pickupVM.selectedOfficeData;
            const selectedOfficePosition = selectedOffice && {
              lat: Number(selectedOffice.latitude),
              lng: Number(selectedOffice.longitude),
            };
            const isJapan = isOntra(viewmodel.i.tempPickupCityCode.value);
            return (
              <div
                className={css`
                  height: auto;
                `}
              >
                <div
                  className={css`
                    border: 1px solid #e1e1e1;
                    border-radius: 12px;
                    padding: 20px 20px 24px;
                    margin-top: 24px;
                    margin-bottom: 145px;
                  `}
                >
                  <MRTSelectedOfficeInfo viewmodel={pickupVM} isJapan={isJapan} />
                  {selectedOfficePosition && (
                    <MRTGoogleMap
                      viewmodel={pickupVM}
                      officePosition={selectedOfficePosition}
                      containerStyle={{ width: '100%', aspectRatio: '4/3', height: 'auto' }}
                    />
                  )}
                </div>
                <div
                  className={css`
                    padding-right: 40px;
                    margin-top: ${SPACING.spacing24};
                    position: fixed;
                    display: flex;
                    width: 100%;
                    bottom: 0;
                  `}
                >
                  <button
                    id="step2_5_btn"
                    type="button"
                    onClick={() => {
                      viewmodel.i.pickupCityCode.set(viewmodel.i.tempPickupCityCode.value);
                      viewmodel.i.pickupLocationId.set(viewmodel.i.tempPickupLocationId.value);
                      viewmodel.i.pickupCitySearch.searchInput.set(
                        viewmodel.i.tempPickupCitySearch.tempSearchInput.value,
                      );
                      if (rootStep) {
                        viewmodel.stepManager.replaceStep(rootStep);
                      } else {
                        // viewmodel.stepManager.clearStep();
                        viewmodel.stepManager.replaceStepsInRoute([]);
                      }
                    }}
                    className={css`
                      height: ${SPACING.spacing56};
                      width: 100%;
                      background-color: ${COLORS2.main.MrtBlue1} !important;
                      color: ${COLORS2.main.MrtWhite};
                      border-radius: 8px;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 22px;
                    `}
                  >
                    확인
                  </button>
                </div>
              </div>
            );
          }}
        </ViewByState>
      </div>
    </Fragment>
  );
});
