import { action, makeObservable } from 'mobx';

import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import {
  BaseSearchBoxStepManager,
  SearchBoxSteps,
} from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';

import { HomeRouteParams } from './Home.RouteParams';

export class HomeSearchBoxStepManagerDesktop extends BaseSearchBoxStepManager<HomeRouteParams> {
  constructor(
    public routeParams: HomeRouteParams,
    public carListingSearchInputVM: CarListingSearchBoxInputViewModel,
    public steps: SearchBoxSteps[] = [],
  ) {
    super();
    makeObservable(this, {
      updatePushCountBy: true,
      steps: true,
      getInputViewModel: true,
      getSteps: true,
      pushStepsToRoute: action.bound,
      replaceStepsInRoute: action.bound,
      updateRouteParams: action.bound,
      guardStep: action.bound,
      pushStep: action.bound,
      clearStep: action.bound,
      replaceStep: action.bound,
      currentStep: true,
      routeParams: true,
      carListingSearchInputVM: true,
    });
  }

  getInputViewModel(): CarListingSearchBoxInputViewModel {
    return this.carListingSearchInputVM;
  }

  getSteps() {
    return this.steps || [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  updateRouteParams(routeParams: HomeRouteParams) {
    // this.routeParams = routeParams;
  }

  pushStepsToRoute(newSteps: SearchBoxSteps[]) {
    this.steps = [...newSteps];
  }

  replaceStepsInRoute(newSteps: SearchBoxSteps[]) {
    this.steps = [...newSteps];
  }

  // eslint-disable-next-line class-methods-use-this
  updatePushCountBy(): void {
    // do nothing
  }
}
