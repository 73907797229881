import { css } from '@emotion/css';

import {
  SPACING, MARGINS, COLORS2, COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';

import { flexSplitHorizontalInHalf } from '../desktop/components/ReservationDetailDesktop.commonStuff';

const ReservationDetailsDriverInfo = ({ driverInfo }) => {
  const itemTitleStyle = [
    font2.Headline1_Bold,
    css`
      color: ${COLORS2.main.MrtBlack};
    `,
  ].join(' ');
  const itemTextStyle = [
    font2.Body1_Regular,
    css`
      color: ${COLORS2.main.MrtBlack};
    `,
  ].join(' ');
  return (
    <div
      className={[
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <div
        className={[
          flexSplitHorizontalInHalf,
          css`
            margin-top: ${SPACING.spacing32};
          `,
        ].join(' ')}
      >
        <div className="flex flex-col gap-3">
          <p className={itemTitleStyle}>국문 이름</p>
          <p className={itemTextStyle}>{driverInfo?.korean_name}</p>
        </div>
        <div className="flex flex-col gap-3">
          <p className={itemTitleStyle}>휴대폰 번호</p>
          <p className={itemTextStyle}>{`${driverInfo?.phone_code} ${driverInfo?.phone_number}`}</p>
        </div>
      </div>

      <div
        className={[
          flexSplitHorizontalInHalf,
          css`
            margin-top: ${SPACING.spacing32};
          `,
        ].join(' ')}
      >
        <div className="flex">
          <div className="flex basis-1/2 flex-col gap-3">
            <p className={itemTitleStyle}>영문 성</p>
            <p className={itemTextStyle}>{driverInfo?.last_name}</p>
          </div>
          <div className="flex basis-1/2 flex-col gap-3">
            <p className={itemTitleStyle}>영문 이름</p>
            <p className={itemTextStyle}>{driverInfo?.first_name}</p>
          </div>
        </div>
      </div>
      <div
        className={[
          css`
            margin-top: ${SPACING.spacing32};
          `,
        ].join(' ')}
      >
        <div className="flex flex-col gap-3">
          <p className={itemTitleStyle}>이메일</p>
          <p className={itemTextStyle}>{driverInfo?.email}</p>
        </div>
      </div>
      {driverInfo?.flight_number && (
        <>
          <div
            className={[
              css`
                margin-top: ${SPACING.spacing48};
                background-color: ${COLORS2.sub.MrtGray2};
                height: 1px;
                width: 100%;
              `,
            ].join(' ')}
          />
          <div
            className={[
              css`
                margin-top: ${SPACING.spacing32};
              `,
            ].join(' ')}
          >
            <div className={[MARGINS.TOP.spacing48].join(' ')}>
              <div
                className={[
                  css`
                    display: flex;
                    align-items: flex-end;
                    gap: 8px;
                  `,
                  // borders.DEBUG_greenBorderDashed,
                ].join(' ')}
              >
                <h2 className={[font2.Title2].join(' ')}>도착 비행기 정보</h2>
                <span
                  className={[COLORS2_AS_FOREGROUND.sub.MrtGray1, font2.Body1_Regular].join(' ')}
                >
                </span>
              </div>
              <div className={[flexSplitHorizontalInHalf].join(' ')}>
                <div>
                  <p className={[font2.Headline1_Bold, MARGINS.TOP.spacing32].join(' ')}>항공사</p>
                  <p className={[font2.Body1_Regular, MARGINS.TOP.spacing12].join(' ')}>
                    {driverInfo.flight_brand}
                  </p>
                </div>
                <div>
                  <p className={[font2.Headline1_Bold, MARGINS.TOP.spacing32].join(' ')}>편명</p>
                  <p className={[font2.Body1_Regular, MARGINS.TOP.spacing12].join(' ')}>
                    {driverInfo.flight_number}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReservationDetailsDriverInfo;
