import React from 'react';

import { css, cx } from '@emotion/css';

import { SPACING, font } from '^/constants/commonStyles';

import { TimeSelectMinuteNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const MinuteRenderer: React.FC<{
  value: TimeSelectMinuteNullable;
  isOpen?: boolean;
  isListItem?: boolean;
}> = ({ value, isOpen, isListItem }) => {
  return (
    <div
      className={isListItem
        ? cx(classNames.btnRendererBasic,
          css`
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #1E1E1E;
          `)
        : classNames.btnRendererBasic}
    >
      <span>{value}분</span>
      {!isListItem && (
        <img
          alt="hour-list-toggle"
          src={isOpen
            ? 'arrow_drop_up_20.svg'
            : 'arrow_drop_down_20.svg'}
        />
      )}
    </div>
  );
};
