/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

// import { actions, sendTrackingLog } from '^/api/trackingLog';
import { ReactComponent as CalendarSVG } from '^/assets/ic_calendar_24px.svg';
import { SPACING, COLORS2, font, INPUT } from '^/constants/commonStyles';

import { CarListingSearchBoxDesktopContentsArea } from '../contentsArea/CarSearchBox.desktop.contentsArea';
import { CarListingSearchBoxDesktopContentsAreaForDiffReturnLocation } from '../contentsArea/CarSearchBox.desktop.contentsArea.forDiffReturnLocation';
import { CarListingSearchBoxViewModel } from '../viewmodel/CarListingSearchBox.viewmodel';
import { SearchBoxSteps } from '../viewmodel/SearchBoxStepManager';

import { BirthDayInput } from './BirthdayInput/BirthDayInput';
import { CitySearchInputButton } from './CitySearchInput/CitySearchInputButton';
import { RentalOfficeInputButton } from './OfficeInput/RentalOfficeInputButton';
import { ScheduleInputButton } from './ScheduleInput/ScheduleInputButton';

export const PickupReturnDifferentInputsDesktop: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  smaller?: boolean;
}> = observer(function PickupReturnDifferentInputsDesktop({ viewmodel, smaller }) {
  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const returnCityInputRef = useRef<HTMLInputElement>(null);

  const { stepManager } = viewmodel;
  useEffect(() => {
    if (
      stepManager.currentStep?.type === '1_rental_city' &&
      stepManager.currentStep.autofocus === 'CitySearchInput'
    ) {
      pickupCityInputRef.current?.focus();
    }
    if (stepManager.currentStep?.type === '5_return_city') {
      returnCityInputRef.current?.focus();
    }
  }, [stepManager.currentStep?.type]);

  const stepManagerStepChangeOptions = {
    afterStepChange(newStep: SearchBoxSteps) {
      if (newStep.type === '1_rental_city') {
        viewmodel.i.pickupCitySearch.searchInput.setIsFocused(true);
        pickupCityInputRef.current?.focus();
      }
      if (newStep.type === '5_return_city') {
        viewmodel.i.returnCitySearch.searchInput.setIsFocused(true);
        returnCityInputRef.current?.focus();
      }
    },
  } as const;
  return (
    <form
      className={cx(
        css`
          position: absolute;
          width: 100%;
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 10px;
          padding: ${SPACING.spacing24};
          &:focus-visible {
            outline: none;
          }
        `,
      )}
      onSubmit={async (e) => {
        if (viewmodel.submitActionVM.status === 'success') {
          // sendTrackingLog({
          //   action: actions['/'].CLICK_SEARCH_CARS,
          // });
          viewmodel.submitActionVM.value.submit();
          if (document.activeElement && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }
        e.preventDefault();
        return false;
      }}
    >
      <div
        id="top-line"
        className={cx(
          css`
            display: grid;
            grid-template-columns: 1fr 1fr min-content;
            align-items: end;
            gap: 24px;
            width: 100%;
          `,
        )}
      >
        <div
          className={cx(
            css`
              display: grid;
              align-items: end;
              gap: 24px;
              grid-template-columns: 1fr 1fr;
            `,
          )}
        >
          {/* 도시검색 */}
          <div>
            <CitySearchInputButton
              placeholder="대여 도시 선택"
              smaller={smaller}
              inputRef={pickupCityInputRef}
              viewmodel={viewmodel.i.pickupCitySearch}
              title={smaller ? null : '대여 위치'}
              showSuggestions={stepManager.currentStep?.type === '1_rental_city'}
              onSelectCity={() => {
                if (!viewmodel.differentLocationValidation) {
                  viewmodel.showDifferentLocationAlert.set(true);
                  viewmodel.i.pickupCitySearch.selectedCityCode.set(null);
                  viewmodel.i.pickupCitySearch.searchInput.set('');
                  return;
                }
                stepManager.replaceStepWithOptions(
                  {
                    type: '2_rental_office',
                  },
                  stepManagerStepChangeOptions,
                );
              }}
              onClickCitySearchInputBtn={() => {
                pickupCityInputRef.current?.focus();

                if (stepManager.currentStep?.type !== '1_rental_city') {
                  stepManager.replaceStepWithOptions(
                    {
                      type: '1_rental_city',
                      autofocus: 'CitySearchInput',
                    },
                    stepManagerStepChangeOptions,
                  );
                }
              }}
            />
          </div>

          {/* 대여/반납 장소 선택 */}
          <div
            onClick={() => {
              stepManager.replaceStepWithOptions(
                {
                  type: '2_rental_office',
                },
                stepManagerStepChangeOptions,
              );
            }}
            className={INPUT.overflowEllipsis}
          >
            <RentalOfficeInputButton
              placeholder="대여 장소 선택"
              viewmodel={viewmodel.pickupLocation}
              isSelected={stepManager.currentStep?.type === '2_rental_office'}
            />
          </div>
        </div>
        <div>
          <ScheduleInputButton
            viewmodel={viewmodel}
            title={smaller ? null : undefined}
            smaller={smaller}
            onClick={() => {
              stepManager.replaceStepWithOptions(
                {
                  type: '3_schedule',
                },
                stepManagerStepChangeOptions,
              );
            }}
            isSelected={viewmodel.stepManager.currentStep?.type === '3_schedule'}
          />
          {viewmodel.i.calendarStateViewModel.selection.end &&
            viewmodel.rentalPeriodText &&
            !smaller && (
              <div
                className={cx(
                  font.Body1_Regular,
                  css`
                    position: absolute;
                    display: flex;
                    gap: 4px;
                    top: ${SPACING.spacing120};
                  `,
                )}
              >
                <CalendarSVG />
                {/* FIXME: apply time */}
                <span>{viewmodel.rentalPeriodText}</span>
              </div>
            )}
        </div>

        {/* 검색 버튼 */}
        <input
          type="image"
          alt="차량 조회"
          src="./ic_search_white.svg"
          disabled={viewmodel.submitActionVM.status !== 'success'}
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;

            border-radius: 5px;
            background-color: ${COLORS2.main.MrtBlue1};
            width: ${SPACING.spacing56};
            height: ${SPACING.spacing56};
            padding: ${SPACING.spacing16};

            &:disabled {
              background-color: ${COLORS2.main.MrtBlue2};
              cursor: not-allowed;
            }
          `}
        />
      </div>
      <div
        className={cx(
          css`
            width: 100%;
          `,
          smaller &&
            css`
              margin-top: 12px;
            `,
        )}
      >
        <CarListingSearchBoxDesktopContentsArea viewmodel={viewmodel} />
      </div>
      <div
        id="bottom-line"
        className={cx(
          css`
            display: grid;
            grid-template-columns: 1fr 1fr min-content;
            align-items: end;
            gap: 24px;
            width: 100%;
          `,
          smaller
            ? css``
            : css`
                margin-top: 50px;
              `,
          // borders.DEBUG_blueBorderDashed,
        )}
      >
        <div
          className={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            align-items: end;
          `}
        >
          {/* 도시검색 */}
          <div key="5_return_city">
            <CitySearchInputButton
              placeholder="반납 도시 선택"
              inputRef={returnCityInputRef}
              inputElemProps={{
                autoFocus: stepManager.currentStep?.type === '5_return_city',
              }}
              viewmodel={viewmodel.i.returnCitySearch}
              title={smaller ? null : '반납 위치'}
              smaller={smaller}
              showSuggestions={stepManager.currentStep?.type === '5_return_city'}
              onSelectCity={() => {
                if (!viewmodel.differentLocationValidation) {
                  viewmodel.showDifferentLocationAlert.set(true);
                  viewmodel.i.returnCitySearch.selectedCityCode.set(null);
                  viewmodel.i.returnCitySearch.searchInput.set('');
                  return;
                }
                stepManager.replaceStepWithOptions(
                  {
                    type: '6_return_office',
                  },
                  stepManagerStepChangeOptions,
                );
              }}
              onClickCitySearchInputBtn={() => {
                returnCityInputRef.current?.focus();

                stepManager.replaceStepWithOptions(
                  {
                    type: '5_return_city',
                  },
                  stepManagerStepChangeOptions,
                );
              }}
            />
          </div>

          {/* 대여/반납 장소 선택 */}
          <div
            onClick={() => {
              stepManager.replaceStepWithOptions(
                {
                  type: '6_return_office',
                },
                stepManagerStepChangeOptions,
              );
            }}
            className={INPUT.overflowEllipsis}
          >
            <RentalOfficeInputButton
              placeholder="반납 장소 선택"
              viewmodel={viewmodel.returnLocation}
              isSelected={stepManager.currentStep?.type === '6_return_office'}
            />
          </div>
        </div>
        <div>
          <BirthDayInput
            viewmodel={viewmodel}
            title={smaller ? null : undefined}
            onClickBirthDayInput={() => {
              stepManager.replaceStepWithOptions(
                {
                  type: '4_birthday',
                },
                stepManagerStepChangeOptions,
              );
            }}
          />
        </div>

        <div
          id="for-emtpty-space"
          className={css`
            width: ${SPACING.spacing56};
            height: ${SPACING.spacing56};
          `}
        />
      </div>
      {/* 대여/반납 장소가 다릅니다. */}
      {(!smaller || null) && (
        <div
          className={css`
            display: flex;
            gap: ${SPACING.spacing8};

            margin-top: 12px;
            width: fit-content;

            &:hover {
              cursor: pointer;
            }
          `}
          onClick={() => {
            viewmodel.i.hasDifferentPickupReturnLocations.set(
              !viewmodel.i.hasDifferentPickupReturnLocations.value,
            );
            // FIXME: calendar reset as requested...(?????)
            //          better: do validation...
            viewmodel.i.scheduleTimeViewModel.reset();
            viewmodel.i.calendarStateViewModel.reset();
          }}
        >
          <img
            alt="rental-location-different"
            src={
              viewmodel.i.hasDifferentPickupReturnLocations.value
                ? 'ic_checkbox/ic_checkbox_a2.svg'
                : 'ic_checkbox/ic_checkbox_n2.svg'
            }
          />
          대여/반납 장소가 다릅니다.
        </div>
      )}

      <CarListingSearchBoxDesktopContentsAreaForDiffReturnLocation viewmodel={viewmodel} />
    </form>
  );
});
