/* eslint-disable no-nested-ternary */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { SPACING, font } from '^/constants/commonStyles';
import { renewalUIState } from '^/recoil/atoms';

import { TimeSelectMinuteNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const MinuteRenderer: React.FC<{
  value: TimeSelectMinuteNullable;
  isOpen?: boolean;
  isListItem?: boolean;
  disabled?: boolean;
  isNotSelected?: boolean;
}> = ({ value, isOpen, isListItem, disabled, isNotSelected }) => {
  const isRenewal = useRecoilValue(renewalUIState);

  const isEmpty = value === null;

  return (
    <div
      className={cx(
        isListItem
          ? [classNames.btnRendererBasic, font.Body1_Regular]
          : classNames.btnRendererBasic,
        disabled
          ? isRenewal
            ? css`
                color: #cecece !important;
              `
            : css`
                opacity: 0.3;
              `
          : '',
        isRenewal &&
          css`
            height: 40px;
            padding: 0 3px 0 8px !important;
          `,
        isRenewal &&
          isListItem &&
          css`
            height: 30px;
          `,
      )}
    >
      <div>
        {isEmpty && isRenewal && <span className={cx(classNames.timeNotSelected)}>00</span>}
        <span
          className={cx(
            isRenewal && classNames.timeSelected,
            isRenewal && isNotSelected && classNames.timeNotSelected,
          )}
        >
          {value}분
        </span>
      </div>
      {!isListItem && (
        <img
          alt="minute-list-toggle"
          src={
            isRenewal
              ? isOpen
                ? './arrow_drop_up_20.svg'
                : './arrow_drop_down_20.svg'
              : isOpen
              ? './ic_arrow_up.png'
              : './ic_arrow_down.png'
          }
          className={css`
            @media (max-width: 768px) {
              width: ${SPACING.spacing16};
              height: ${SPACING.spacing16};
            }
          `}
        />
      )}
    </div>
  );
};
