import { z } from 'zod';

import { defineResponseWithData } from '../DefineResponse';

export const otClientAPIResponseItem = z.object({
  plan_info: z.object({
    code: z.string(),
    name: z.string().or(z.null()),
    brand_code: z.string().or(z.null()),
    shop_name: z.string().or(z.null()),
    shop_code: z.string().or(z.null()),
    shop_address: z.string().or(z.null()),
    shuttle_flag: z.number(),
  }),
  car_info: z.object({
    model_name: z.string().or(z.null()),
    model_code: z.string().or(z.null()),
    class_name: z.string().or(z.null()),
    class_code: z.string().or(z.null()),
    image: z.string().or(z.null()),
  }),
  payment_info: z.object({
    domestic_price: z.number(),
    foreign_price: z.number(),
    foreign_currency_code: z.string().or(z.null()),
  }),
  company_info: z.object({
    id: z.string().or(z.null()),
    name: z.string().or(z.null()),
  }),
  equipment_info_list: z.array(
    z.union([
      z.object({ name: z.string().or(z.null()), info: z.string().or(z.null()) }),
      z.object({ name: z.string().or(z.null()), info: z.number() }),
    ]),
  ),
});

export const otClientAPIResponseType = defineResponseWithData(
  otClientAPIResponseItem.array(),
  201,
);
