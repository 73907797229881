/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { SPACING, font } from '^/constants/commonStyles';
import { ListingSearchFilterCheckButton } from '^/pages/Listing/common/ListingSearchFilterCheckButton';
import { MobxInputValue } from '^/util/MobxInputValue';

export const CheckboxesWithAllToggleForFilter = observer(
  function CheckboxesWithAllToggleForFilter<T extends string>(
    p: {
      carsScrollAreaRef: RefObject<HTMLDivElement> | null,
      possibleValues: Readonly<T[]>,
      checked: MobxInputValue<Set<T>>,
      overrides?: {
        ItemRenderer?: React.ComponentType<{
          value: T | Readonly<T>,
          checked: MobxInputValue<Set<T>>,
        }>,
        AllCheckRenderer?: React.ComponentType<{
          allChecked: boolean;
          setAllChecked(checked: boolean): void;
        }>
      }
    },
  ) {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const { t } = useTranslation();
    const ItemRenderer = p.overrides?.ItemRenderer;
    const AppCheckRenderer = p.overrides?.AllCheckRenderer;

    const allChecked = p.possibleValues.length === p.checked.value.size;
    const isEmpty = p.checked.value.size === 0;

    return (
      <ul
        className={
          isMobile
            ? css`
                display: grid;
                grid-template-columns: repeat(2, 33%);
                justify-content: space-between;
                margin-top: ${SPACING.spacing16};
              `
            : css`
                display: flex;
                flex-direction: column;
                gap: 12px;
            `
          }
      >
        <li className={cx(
          font.Body1_Regular,
          css`
            display: flex;
            justify-content: space-between;
          `,
        )}
        >
          {AppCheckRenderer ? (
            <>
              <AppCheckRenderer
                allChecked={isEmpty}
                setAllChecked={(checked) => {
                  if (!checked) {
                    p.checked.set(new Set(p.possibleValues));
                  } else {
                    p.checked.set(new Set());
                  }
                }}
              />
            </>
          ) : (
            <>
              <span>전체</span>
              <ListingSearchFilterCheckButton
                condition={isEmpty}
                onClick={() => {
                  if (allChecked) {
                    p.checked.set(new Set());
                  } else {
                    p.checked.set(new Set(p.possibleValues));
                  }
                  p.carsScrollAreaRef?.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              />
            </>
          )}
          {/* <input
            type="checkbox"
            checked={allChecked}
            onChange={() => {
              if (allChecked) {
                p.checked.set(new Set());
              } else {
                p.checked.set(new Set(p.possibleValues));
              }
            }}
          /> */}
          {/* </label> */}

        </li>
        {p.possibleValues.map((value) => {
          if (ItemRenderer) {
            return (
              <ItemRenderer
                key={value}
                value={value}
                checked={p.checked}
              />
            );
          }
          const translatedValue = t(value) || value;
          const checked = p.checked.value.has(value);
          return (
            <li
              key={value}
              className={cx(
                css`
                  display: flex;
                  justify-content: space-between;
                `,
                font.Body1_Regular,
              )}
            >
              {/* <label> */}
              <span>{translatedValue}</span>
              <ListingSearchFilterCheckButton
                condition={checked}
                onClick={() => {
                  if (checked) {
                    p.checked.value.delete(value);
                  } else {
                    p.checked.value.add(value);
                  }
                  p.carsScrollAreaRef?.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              />
            </li>
          );
        })}
      </ul>
    );
  },
);
