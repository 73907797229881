/* eslint-disable react/jsx-no-bind */
import { useEffect } from 'react';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { Footer3Mobile } from '^/components/Footer3/Footer3.Mobile';
import Layout1 from '^/components/Layout';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { MARGINS } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { increaseFreshness } from '^/types/__ResultType';

import { loadPhoneEmailFromStorage } from '../ReservationCheck/ReservationCheck.form.viewmodel';

import ConfirmReservationCancelPopup from './ConfirmReservationCancelPopup';
import { ReservationDetailPageDesktop } from './desktop/ReservationDetailsPage.Desktop';
import { ReservationDetailPageMobile } from './mobile/ReservationDetailsPage.Mobile';
import { ReservationDetailsPageViewModel } from './ReservationDetailsPage.viewmodel';
import { useSetRecoilState } from 'recoil';
import { errorButtonTextState, errorMsgState, errorState, handleCloseState } from '^/recoil/atoms';

export const ReservationDetailsPage: React.FC<{
  viewmodel: ReservationDetailsPageViewModel;
}> = observer(function ReservationDetailsPage({ viewmodel }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const setIsError = useSetRecoilState(errorState);
  const setErrorMsg = useSetRecoilState(errorMsgState);
  const setHandleErrorClose = useSetRecoilState(handleCloseState);
  const setErrorButtonText = useSetRecoilState(errorButtonTextState);

  useEffect(() => {
    if (!loadPhoneEmailFromStorage()) {
      window.location.pathname = '/reservation_check';
    }
  }, []);

  function onClickCancelReservation() {
    viewmodel.showCancelConfirmPopup.set(true);
  }

  async function onClickCancelReservationConfirm(cancelReason) {
    const cancelResult = await viewmodel.cancelReservation(cancelReason);
    viewmodel.showCancelConfirmPopup.set(false);
    setErrorButtonText('확인');
    if (cancelResult.status === 'success') {
      setErrorMsg('예약이 취소되었습니다.');
      setHandleErrorClose(() => () => typeRouter.session.replace('/reservation_check'));
    }
    if (cancelResult.status === 'failure') {
      setErrorMsg(
        cancelResult.failure.error.message || '예약 취소에 실패했습니다. 고객센터로 문의해주세요.',
      );
    }
    setIsError(true);
    runInAction(() => {
      // eslint-disable-next-line no-param-reassign
      viewmodel.freshness = increaseFreshness(viewmodel.freshness);
    });
  }

  if (isMobile) {
    return (
      <>
        <Layout1 withoutFooter>
          {viewmodel.showLoading.value === true && <LoadingWithVendorLogo />}
          <ConfirmReservationCancelPopup
            visible={viewmodel.showCancelConfirmPopup}
            handleCancel={() => viewmodel.showCancelConfirmPopup.set(false)}
            handleConfirm={onClickCancelReservationConfirm}
            isMobile={isMobile}
          />
          <ReservationDetailPageMobile
            viewmodel={viewmodel}
            onClickCancelReservation={onClickCancelReservation}
          />
        </Layout1>
        <div className={MARGINS.TOP.spacing32}>
          <Footer3Mobile />
        </div>
      </>
    );
  }
  return (
    <>
      {viewmodel.showLoading.value === true && <LoadingWithVendorLogo />}
      <ReservationDetailPageDesktop
        viewmodel={viewmodel}
        onClickCancelReservation={onClickCancelReservation}
      />
      <ConfirmReservationCancelPopup
        visible={viewmodel.showCancelConfirmPopup}
        handleCancel={() => viewmodel.showCancelConfirmPopup.set(false)}
        handleConfirm={onClickCancelReservationConfirm}
        isMobile={isMobile}
      />
    </>
  );
});
