import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { BaseSearchBoxStepManager, SearchBoxSteps } from '^/components/CarSearchBox/base/desktop/viewmodel/SearchBoxStepManager';
import { typeRouter } from '^/typeRoute/typeRoutes';

export const MRTSearchHeaderMobile:React.FC<{
  stepManager: BaseSearchBoxStepManager;
  leftCornerBtnType?: 'close' | 'prevStack';
  rootStep?: SearchBoxSteps | undefined,
}> = observer(function MRTSearchHeaderMobile({
  stepManager,
  leftCornerBtnType,
  rootStep,
}) {
  return (
    <div className={cx(
      css`
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0px;
        width: 100%;
        background-color: #fff;
        z-index: 100;
      `,
    )}
    >
      <button
        type="button"
        onClick={() => {
          if (leftCornerBtnType === 'close') {
            if (rootStep) {
              stepManager.replaceStep(rootStep);
            } else {
              stepManager.replaceStepsInRoute([]);
            }
          } else {
            typeRouter.session.back();
          }
        }}
      >
        <img src="chevron-left_24.svg" alt="back button" />
      </button>
      <button
        type="button"
        onClick={() => {
          if (rootStep) {
            stepManager.replaceStep(rootStep);
          } else {
            stepManager.replaceStepsInRoute([]);
          }
        }}
      >
        <img src="x-close_24.svg" alt="close button" />
      </button>
    </div>
  );
});
