import { AxiosInstance } from 'axios';

import { api2BaseAxios } from '../api2BaseAxios';

import { GetCarListArgsType, WithRedisKey } from './ArgsType';

export function createGetCarListStep1(
  axiosInstance: AxiosInstance,
) {
  return async function getCarListStep1Post(
    args: GetCarListArgsType & WithRedisKey,
  ) {
    const response = await axiosInstance
      .post('/search/cars', args);
    return response;
  };
}

export const getCarListStep1 = createGetCarListStep1(
  api2BaseAxios,
);
