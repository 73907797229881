import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { SPACING, COLORS2, font2 } from '^/constants/commonStyles';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';

import { flexSplitHorizontalInHalf } from './ReservationDetailDesktop.commonStuff';
import { ReservationDetailsDriverInfoMemberships } from './ReservationDetailDesktop.DriverInfo.memberships';

const itemTitleStyle = [
  font2.Headline1_Bold,
  css`
    color: ${COLORS2.main.MrtBlack};
  `,
].join(' ');

const itemTextStyle = [
  font2.Body1_Regular,
  css`
    color: ${COLORS2.main.MrtBlack};
  `,
].join(' ');
export const ReservationDetailsDriverInfo: React.FC<{
  data: ReservationDetailed,
}> = observer(function ReservationDetailsDriverInfo({
  data,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={[
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <div
        className={[
          flexSplitHorizontalInHalf,
          css`
            margin-top: ${SPACING.spacing32};
          `,
        ].join(' ')}
      >
        <div className="flex flex-col gap-3">
          <p className={itemTitleStyle}>
            {t('koreanName')}
          </p>
          <p
            className={itemTextStyle}
          >{data?.korean_name || '-'}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <p className={itemTitleStyle}>휴대폰 번호</p>
          <p className={itemTextStyle}>{`${data?.phone_code} ${data?.phone_number}`}</p>
        </div>
      </div>

      <div
        className={[
          flexSplitHorizontalInHalf,
          css`
            margin-top: ${SPACING.spacing32};
          `,
        ].join(' ')}
      >
        <div className="flex">
          <div className="flex basis-1/2 flex-col gap-3">
            <p className={itemTitleStyle}>{t('lastName')}</p>
            <p className={itemTextStyle}>{data?.last_name || '-'}</p>
          </div>
          <div className="flex basis-1/2 flex-col gap-3">
            <p className={itemTitleStyle}>{t('firstName')}</p>
            <p className={itemTextStyle}>{data?.first_name || '-'}</p>
          </div>
        </div>
      </div>
      <div
        className={[
          css`
            margin-top: ${SPACING.spacing32};
          `,
        ].join(' ')}
      >
        <div className="flex flex-col gap-3">
          <p className={itemTitleStyle}>{t('email')}</p>
          <p className={itemTextStyle}>{data?.email || '-'}</p>
        </div>
      </div>
      <div className="my-[24px] text-right">
        {data?.supplier === 'ZE' && (
          <p
            className={[
              font2.Body1_Bold,
              'underline underline-offset-1 hover:cursor-pointer',
            ].join(' ')}
          >
            <a
              href="https://images.hertz.com/pdfs/asia/KR_HertzGuide2019.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Hertz 해외렌터카 가이드
            </a>
          </p>
        )}
      </div>
      <ReservationDetailsDriverInfoMemberships
        data={data}
      />
    </div>
  );
});
