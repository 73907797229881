import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import {
  SPACING, MARGINS, COLORS2_AS_FOREGROUND, font2,
} from '^/constants/commonStyles';
import { InsuranceSubPackageNames } from '^/types/__BrandedTypes';

export const InsuranceInfoBox: React.FC<{
  insuranceName: string,
  insuranceServices: InsuranceSubPackageNames[],
}> = ({
  insuranceName,
  insuranceServices,
}) => {
  const { t } = useTranslation();
  return (
    <section
      id="insurance_details_popup"
      className={[
        css`
          position: absolute;
          top: 100%;
          left: 0; right: 0;
          padding: ${SPACING.spacing24};
          background-color: white;
          z-index: 500;
          border-radius: 10px;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
        `,
        // borders.DEBUG_greenBorderDashed,
      ].join(' ')}
    >
      <p
        className={font2.Headline1_Bold}
      >
        {insuranceName}
      </p>
      <ul
        className={[
          MARGINS.TOP.spacing24,
          MARGINS.LEFT.spacing16,
          css`
            list-style-type: disc;
            display: grid;
            grid-row-gap: ${SPACING.spacing16};
            grid-template-columns: 1fr 1fr;
          `,
        ].join(' ')}
      >
        {insuranceServices.map((i) => {
          return (
            <li
              key={t(`service_name.${i}.ko_name`)}
            >
              <p
                className={[
                  font2.Body1_Bold,
                  COLORS2_AS_FOREGROUND.main.MrtBlack,
                ].join(' ')}
              >
                {t(`service_name.${i}.ko_name`)}
              </p>
              <p
                className={[
                  font2.Caption1_Light,
                  COLORS2_AS_FOREGROUND.sub.MrtGray1,
                  MARGINS.TOP.spacing6,
                ].join(' ')}
              >
                {t(`service_name.${i}.en_name`)}
              </p>
              <p
                className={[
                  font2.Caption1_Light,
                  COLORS2_AS_FOREGROUND.main.MrtBlack,
                  MARGINS.TOP.spacing12,
                ].join(' ')}
              >
                {t(`service_name.${i}.details`)}
              </p>

            </li>
          );
        })}
      </ul>
    </section>
  );
};
