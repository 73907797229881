// hook for click outside component
import { MutableRefObject, RefObject, useEffect } from 'react';

export function useClickOutsideComponent<T extends HTMLElement>(
  ref: MutableRefObject<T> | RefObject<T>,
  callback: () => void,
) {
  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof Element && e.target.closest('#Popup')) {
      // DO NOT RESET
      return;
    }
    if (ref.current
      && !ref.current.contains(e.target as any)
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
}
