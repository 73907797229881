import PropTypes from 'prop-types';
import ReservationCard from '../ReservationCard';
import WrapperPopup from '../../../../components/Popup/ReservationPopup';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import {
  getReservationDetailBooked,
  getReservationDetailCanceled,
} from '../../../../api/reservation';
import { useSetRecoilState } from 'recoil';
import { errorMsgState, errorState } from '../../../../recoil/atoms';
import { borders } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

const ReservationList = ({ list /* setIdRes, setPopOpen, setCancelInfo, open, setOpen */ }) => {
  // const [open, setOpen] = useState(false);
  const [selectedRes, setSelectedRes] = useState();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [listRes, setListRes] = useState(null);
  const { t } = useTranslation();
  const setIsError = useSetRecoilState(errorState);
  const setErrorMsg = useSetRecoilState(errorMsgState);
  const handleClick = (res) => {
    if (res.supplier == 'OT') {
      typeRouter.routes
        .reservationDetails({
          id: res.res_id,
          lastName: res.last_name,
          vendorId: res.supplier,
        })
        .push();
    } else {
      typeRouter.routes
        .reservationDetails({
          id: res.res_id,
          lastName: res.last_name,
          vendorId: res.supplier,
        })
        .push();
    }
  };

  useEffect(() => {
    setListRes(list);
  }, [list]);

  return (
    <div
      className={[
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <div className="flex flex-col tablet-max:gap-[30px] gap-6">
        {listRes?.length > 0 &&
          listRes.map((item, index) => (
            <ReservationCard key={item.res_id} data={item} onButton={handleClick} index={index} />
          ))}
      </div>
    </div>
  );
};
ReservationList.propTypes = {
  list: PropTypes.array,
  setIdRes: PropTypes.func,
  setPopOpen: PropTypes.func,
  setCancelInfo: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
export default ReservationList;
