/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';

import {
  ReactComponent as CircularAlert,
} from '^/assets/circle_alert.svg';
import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

const EmptyCar2DefaultStyle = {
  rootWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  prevBtn: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${SPACING.spacing56};
    width: 164px;
    border-radius: 5px;
    color: ${COLORS2.main.MrtWhite};
    margin-top: 50px;

    background-color: ${COLORS2.main.MrtBlue1} !important;
  `,
  horizon: css`
    width:  218px;
    height: 2px;
    margin-top: ${SPACING.spacing24};
    margin-bottom: ${SPACING.spacing32};
    background-color: ${COLORS2.sub.MrtGray2};
  `,
};
export const EmptyCar: React.FC<{
  classOverrides?: {
    prevBtnFontSize: string;
  }
}> = ({
  classOverrides,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      className={EmptyCar2DefaultStyle.rootWrapper}
    >
      <CircularAlert
        className={css`
          width: 56px;
          height: 56px;
        `}
      />
      <div className={css`
        margin-top: ${SPACING.spacing32};

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
      >
        <h3 className={
          isMobile
            ? font.Headline1_Bold
            : font.Title2
          }
        >
          조회된 차량이 없습니다.
        </h3>
        <p className={[
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: ${SPACING.spacing16};
          `,
          isMobile
            ? font.Caption2_Light
            : font.Caption1_Light,
        ].join(' ')}
        >
          <span className="block">다른 도시 및 일정으로 재검색해 주세요.</span>
        </p>
      </div>

      <button
        type="button"
        className={cx(
          classOverrides?.prevBtnFontSize,
          EmptyCar2DefaultStyle.prevBtn,
        )}
        onClick={() => typeRouter
          .session.replace('/')}
      >
        이전 페이지로
      </button>
    </div>
  );
};
