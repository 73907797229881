import React, { useRef, useState, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { MRTCarListingSearchBoxDesktop } from
  '^/components/CarSearchBox/myrealtrip/desktop/MRTCarListingSearchBox.desktop';
import { Footer2 } from '^/components/Footer/Footer2';
import { LayoutMRTDesktop } from '^/components/Layout/LayoutMRTDesktop';
import { SPACING, MARGINS, COLORS2 } from '^/constants/commonStyles';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';

import { ListingPageDesktopLoading } from '../base/desktop/ListingPage.Desktop.Loading';
import { ListingPage2DesktopCard } from '../base/desktop/ListingPage2.Desktop.Card';
import { ListingPage2DesktopFilter } from '../base/desktop/ListingPage2.Desktop.Filter';
import { ListingPage2DesktopNoCar } from '../base/desktop/ListingPage2.Desktop.NoCar';
import { ListingPage2ViewModel } from '../base/viewmodel/ListingPage2.viewmodel';

const classNames = {
  maxWidthAndCenter: css`
      width: 100%;
      max-width: 1320px;
      margin-left: auto;
      margin-right: auto;
    `,
  listingPage2DesktopCardWrapper: css`
      width: 100%;
  
      max-width: 1320px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: ${SPACING.spacing56};
  
      display: flex;
      flex-direction: column;
      gap: ${SPACING.spacing32};
    `,
};

export const ListingPageMRTDesktop: React.FC<{
  viewmodel: ListingPage2ViewModel;
}> = observer(function MRTListingPageDesktop({ viewmodel }) {
  const [hoverSearchBox, setHoverSearchBox] = useState(false);
  const carListngSearchBoxRef = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(carListngSearchBoxRef, () => {
    viewmodel.carListingSearchBox.stepManager.clearStep();
  });

  const isOpenCarListingBottomSheetContenArea = viewmodel.stepManager.currentStep !== null
      && viewmodel.stepManager.currentStep?.type !== '4_birthday';

  const carsScrollAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    carsScrollAreaRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [
    viewmodel.routeParamsValues.pCityCode,
    viewmodel.routeParamsValues.pLocationId,
    viewmodel.routeParamsValues.pDatetime,
    viewmodel.routeParamsValues.rCityCode,
    viewmodel.routeParamsValues.rLocationId,
    viewmodel.routeParamsValues.rDatetime,
    viewmodel.routeParamsValues.birthday,
    viewmodel.routeParamsValues.freshness,
    viewmodel.routeParamsValues.pIsDiff,
  ]);

  return (
    <LayoutMRTDesktop
      withoutFooter
      withoutMaxWidth
      withoutOverflowScroll
      withoutNavigationFullRefresh
    >
      <div
        ref={carsScrollAreaRef}
        className={cx(
          css`
              background-color: ${COLORS2.sub.MrtGray3};
              width: 100%;
              height: 100%;
              overflow-y: scroll;
              display: flex;
              flex-direction: column;
            `,
        )}
      >
        <div
          className={cx(
            css`
                position: sticky;
                top: 0px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                background-color: ${COLORS2.sub.MrtGray3};
                padding-top: ${SPACING.spacing24};
                padding-bottom: ${SPACING.spacing32};
                z-index: 30;
              `,
          )}
        >
          <div className={css`
            width: 1060px;
            margin: 0 auto;
          `}
          >
            <div
              ref={carListngSearchBoxRef}
                // tabIndex={-1}
              onFocus={() => setHoverSearchBox(true)}
              onBlur={(e) => {
                if (e.currentTarget.contains(e.relatedTarget)) {
                  return;
                }
                setHoverSearchBox(false);
              }}
            >
              <MRTCarListingSearchBoxDesktop
                smaller
                viewmodel={viewmodel.carListingSearchBox}
                onBlur={() => viewmodel.carListingSearchBox.stepManager.clearStep()}
              />
            </div>

            <PreTagForDebugging
              obj={{
                allCarListStatus: viewmodel.allCarList.status,
                getSteps: viewmodel.stepManager.getSteps(),
                currentStep: viewmodel.stepManager.currentStep,
              }}
              SHOW_DEBUGGING_VIEW={false}
              className={css`
                  position: absolute;
                  top: 500px;
                `}
            />
            {viewmodel.allCarList.status === 'success' && (
            <ListingPage2DesktopFilter
              carsScrollAreaRef={carsScrollAreaRef}
              isOpenCarListingBottomSheetContenArea={isOpenCarListingBottomSheetContenArea}
              loadedViewModel={viewmodel.allCarList.value}
              filterViewModel={viewmodel.filterViewModel}
              hasDifferentPickupReturnLocations={
                    viewmodel.carListingSearchBox.i.hasDifferentPickupReturnLocations
                  }
            />
            )}
          </div>
        </div>
        <div className={css`
          width: 1060px;
          margin: 0 auto;
        `}
        >
          <ViewByState
            errorView={(e) => {
              if (e.reason === 'Timeout') {
                return (
                  <div className={[MARGINS.BOTTOM.spacing154].join(' ')}>
                    <ListingPage2DesktopNoCar />
                  </div>
                );
              }
              return (
                <div className={[MARGINS.BOTTOM.spacing154].join(' ')}>
                  <ListingPage2DesktopNoCar />
                </div>
              );
            }}
            loadingView={<ListingPageDesktopLoading />}
            data={viewmodel.allCarList}
          >
            {(carsList) => {
              if (!carsList.filteredListVMs.first()) {
                return <ListingPage2DesktopNoCar />;
              }
              return (
                <section className={classNames.listingPage2DesktopCardWrapper}>
                  {carsList.filteredListVMs
                  // FIXME: check what the following line was about
                  // .filter((b) => b.data.POD?.payment.domesticPrice)
                    .map((c) => {
                      return <ListingPage2DesktopCard key={c.data.key} viewmodel={c} />;
                    })}
                </section>
              );
            }}
          </ViewByState>
        </div>

        <div
          className={[
          ].join(' ')}
        >
          <Footer2 />
        </div>
      </div>
    </LayoutMRTDesktop>
  );
});
