import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { COLORS2 } from '^/constants/commonStyles';

import { PickupReturnDifferentInputsDesktop } from './headerArea/PickupReturnDifferentInputs.desktop';
import { PickupReturnSameInputsDesktop } from './headerArea/PickupReturnSameInputs.desktop';
import { CarListingSearchBoxViewModel } from './viewmodel/CarListingSearchBox.viewmodel';

export const CarListingSearchBoxDesktop: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  onBlur: () => void;
  smaller?: boolean;
}> = observer(function CarListingSearchBoxDesktop({ viewmodel, onBlur, smaller }) {
  return (
    <div
      tabIndex={-1}
      onBlur={(e) => {
        // see: https://stackoverflow.com/a/60094794/19531789
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        if (onBlur) {
          onBlur();
        }
      }}
      className={cx(
        css`
            position: relative;
            margin-bottom: 24px;
            width: 100%;
            max-width: 1320px;
            min-height: 80px;

            z-index: 10;
            background-color: ${COLORS2.main.MrtWhite};
            filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.1));
            border-radius: 10px;

            &:focus-visible {
              outline: none;
            }
          `,
      )}
    >
      {viewmodel.i.hasDifferentPickupReturnLocations.value
        ? (<PickupReturnDifferentInputsDesktop viewmodel={viewmodel} smaller={smaller} />)
        : (<PickupReturnSameInputsDesktop viewmodel={viewmodel} smaller={smaller} />)}
    </div>
  );
});
