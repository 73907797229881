import addDays from 'date-fns/addDays';
import { z } from 'zod';

import {
  NthDayOfWeek,
  DatetimeWithoutTimezone,
} from '^/types/__BrandedDateTimeTypes';

import { YearMonth, yearMonthFromDate } from '../../../BasicCalendar/helpers/YearMonth';
import { WEEK_KO } from '../../MRTCalendar.constants';

export const DayTypeStr = z
  .string()
  .brand<'DayTypeStr'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DayTypeStr = z.infer<typeof DayTypeStr>;

function stringifyDayType(
  ym: YearMonth,
  day: number,
) {
  const monthStr = (ym.monthFrom0 + 1).toString().padStart(2, '0');
  const dayStr = day.toString().padStart(2, '0');
  return `${ym.year}-${monthStr}-${dayStr}` as DayTypeStr;
}

function getNthDayOfWeek(yearMonth: YearMonth, day: number) {
  const date = new Date(
    stringifyDayType(
      yearMonth,
      day,
    ));
  return date.getDay() as NthDayOfWeek;
}
export class DayType {
  constructor(
    public readonly yearMonth: YearMonth,
    public readonly day: number,
    public readonly dayOfWeek = getNthDayOfWeek(yearMonth, day),
    public readonly year = yearMonth.year,
    public readonly monthFrom0 = yearMonth.monthFrom0,
    public readonly str = stringifyDayType(yearMonth, day),
    public readonly dayKoFromStr = WEEK_KO[dayOfWeek],
  ) {
  }
}

export function dayTypeFromDate(
  d: Date,
) {
  return new DayType(
    yearMonthFromDate(d),
    d.getDate(),
  );
}

export function dayTypeFromString(
  d: DatetimeWithoutTimezone,
) {
  return dayTypeFromDate(new Date(d));
}

export function dayToDate(day: DayType) {
  return new Date(day.str);
}

export function addDaysToDay(day: DayType, days: number) {
  const date = dayToDate(day);
  return dayTypeFromDate(addDays(date, days));
}

const weekDays = [
  'sun', // 0
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
] as const;
export function getWeekDayFromDate(
  date: Date,
) {
  return weekDays[date.getDay()];
}

export function getWeekDayFromDay(
  d: DayType,
) {
  return getWeekDayFromDate(
    dayToDate(d),
  );
}
