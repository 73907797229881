/* eslint-disable no-nested-ternary */
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import {
  borders,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { VENDOR_LOGOS } from '^/constants/LogoImages.constants';
import { filterNotDefineds, isDefined } from '^/types/utils/isDefined';

import { ListingPage2CardViewModel } from '../viewmodel/ListingPage2.card.viewmodel';

import { marginRight72Until830px } from './ListingPage2.Desktop.Card.styles';
// import { actions, sendTrackingLog } from '^/api/trackingLog';

export const ListingPage2DesktopCardBodyArea: React.FC<{
  viewmodel: ListingPage2CardViewModel;
}> = observer(function ListingPage2DesktopCardBodyArea({ viewmodel }) {
  const navigate = useNavigate();
  const { data, lowestPricePayment, lowestDomesticPrice } = viewmodel;

  return (
    <div>
      <PreTagForDebugging
        obj={{
          viewmodel_lowestDomesticPrice: lowestPricePayment,
          pods: filterNotDefineds(data.carChoices.map((_) => _.POD)).toArray(),
          ppds: filterNotDefineds(data.carChoices.map((_) => _.PPD)).toArray(),
        }}
        // SHOW_DEBUGGING_VIEW
        SHOW_DEBUGGING_VIEW={false}
      />
      {viewmodel.groupedByVendors.map(([brandId, carChoices]) => {
        const sortedChoices = [carChoices.minPODChoice, carChoices.minPPDChoice]
          .filter(isDefined)
          .sort((a, b) => {
            // put PPD first
            if (a.payment.type === 'PPD') {
              return -1;
            }
            return 1;
          });
        return (
          <div
            className={[
              css`
                display: flex;
                /* align-justify: center; */
                align-items: center;
                justify-content: center;
                border-top: ${COLORS2.sub.MrtGray3} 2px solid;
              `,
            ].join(' ')}
          >
            <div
              className={[
                css`
                  width: 28vw;
                  max-width: 214px;
                  display: flex;
                  justify-content: center;
                `,
              ].join(' ')}
            >
              <img
                alt="vendor_logo"
                src={VENDOR_LOGOS[brandId]}
                className={[
                  css`
                    width: 75px;
                    margin-left: auto;
                    margin-right: auto;
                  `,
                ].join(' ')}
              />
            </div>
            <div
              className={[
                css`
                  width: 100%;
                  border-left: ${COLORS2.sub.MrtGray3} 2px solid;
                `,
              ].join(' ')}
            >
              {sortedChoices.map((choiceItem, nth) => {
                const isDiscounted = choiceItem.payment.isDiscounted;
                const foreignDiscountPrice = Math.ceil(
                  Number(choiceItem.payment.foreignCurrency) -
                    Number(choiceItem.payment.discountForeign),
                );
                const domesticDiscountPrice =
                  Number(choiceItem.payment.domesticPrice) -
                  Number(choiceItem.payment.discountDomestic);

                return (
                  <div
                    className={[
                      css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                      `,
                      nth !== 0 &&
                        css`
                          border-top: ${COLORS2.sub.MrtGray3} 2px solid;
                        `,
                      PADDINGS.TOP_AND_BOTTOM.spacing12,
                      PADDINGS.LEFT.spacing32,
                    ].join(' ')}
                  >
                    <span className={[font2.Headline1_Bold].join(' ')}>
                      {choiceItem.payment.type === 'POD' ? '현장 결제' : '즉시 결제'}
                    </span>
                    <div
                      className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      `}
                    >
                      {domesticDiscountPrice === lowestDomesticPrice && (
                        <span
                          className={[
                            COLORS2_AS_BACKGROUND.main.MrtBlue1,
                            COLORS2_AS_FOREGROUND.main.MrtWhite,
                            font2.Caption1_Light,
                            PADDINGS.LEFT_AND_RIGHT.spacing6,
                            PADDINGS.TOP_AND_BOTTOM.spacing6,
                            css`
                              border-radius: 5px;
                            `,
                          ].join(' ')}
                        >
                          최저가
                        </span>
                      )}
                      <div
                        id="priceTags"
                        className={[
                          css`
                            display: flex;
                            flex-direction: column;
                            align-items: end;
                          `,
                          isDiscounted ? MARGINS.LEFT.spacing16 : MARGINS.LEFT.spacing8,
                          // borders.DEBUG_blueBorderDashed,
                        ].join(' ')}
                      >
                        {isDiscounted && (
                          <h4
                            className={css`
                              text-decoration: line-through;
                              color: #717171;
                              font-weight: 700;
                              line-height: 123%;
                              font-size: 13px;
                            `}
                          >
                            {choiceItem.payment.foreignCurrency.toLocaleString()}{' '}
                            {choiceItem.payment.foreignCurrencyCode}
                          </h4>
                        )}
                        <span
                          className={[
                            font2.Headline1_Bold,
                            isDiscounted && COLORS2_AS_FOREGROUND.main.GuamBlue1,
                            isDiscounted &&
                              css`
                                line-height: 80%;
                                font-size: 20px;
                              `,
                          ].join(' ')}
                        >
                          {isDiscounted
                            ? foreignDiscountPrice.toLocaleString()
                            : choiceItem.payment.foreignCurrency.toLocaleString()}
                          &nbsp;
                          {choiceItem.payment.foreignCurrencyCode}
                        </span>
                        <span
                          className={[
                            font2.Caption1_Light,
                            COLORS2_AS_FOREGROUND.sub.MrtGray1,
                            isDiscounted &&
                              css`
                                line-height: 140%;
                              `,
                          ].join(' ')}
                        >
                          약{' '}
                          {isDiscounted
                            ? domesticDiscountPrice.toLocaleString()
                            : choiceItem.payment.domesticPrice.toLocaleString()}{' '}
                          원
                        </span>
                      </div>
                      <button
                        className={[
                          COLORS2_AS_FOREGROUND.main.MrtBlue1,
                          MARGINS.LEFT.spacing16,
                          font2.Body1_Bold,
                          css`
                            border: ${COLORS2.main.MrtBlue1} solid 1px;
                            border-radius: 5px;
                            width: 92px;
                            height: 42px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `,
                          marginRight72Until830px,
                        ].join(' ')}
                        onClick={() => {
                          // sendTrackingLog({
                          //   action: actions.CAR_LIST.CLICK_CAR_RESERVATION,
                          //   data: choiceItem.core.modelName,
                          // });
                          viewmodel.gotoBooking(choiceItem.payment.type, navigate, choiceItem);
                        }}
                        type="button"
                      >
                        예약
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
});
