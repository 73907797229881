import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowDownBlack } from '^/assets/icons/ic_arrow_down_black.svg';
import { ReactComponent as ArrowDownGray } from '^/assets/icons/ic_arrow_down_gray.svg';

export const ArrowDwon: React.FC<{
  color: 'black' | 'gray'
}> = observer(function Arrow({
  color,
}) {
  if (color === 'black') {
    return <ArrowDownBlack />;
  }
  return <ArrowDownGray />;
});
