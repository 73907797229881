export const IMS_LOGO = '/ims_logo@3x.png';
export const LOGO_BY_CHANNEL = {
  myrealtrip: '/myrealtrip/myrealtrip_trimo_logo_black.png',
  waug: '/waug/waug_trimo_logo_black.png',
  zzimcar: '/zzimcar/zzimcar_trimo_logo.png',
  trimo: '/trimo/trimo_logo_black.png',
};
export const VENDOR_LOGOS = {
  ZE: 'vendors/logo_hertz@3x.png',
  ZR: 'vendors/logo_dollar@3x.png',
  ZT: 'vendors/logo_thrifty@3x.png',
  TM: 'vendors/logo_tumon.png',
  RC: 'vendors/logo_rich.png',
} as const;
