/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import { RefObject, useEffect } from 'react';

import { cx, css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { MRTOfficeList } from '^/components/CarSearchBox/myrealtrip/common/MRTOfficeList';
import { MRTSearchStepperMobile } from '^/components/CarSearchBox/myrealtrip/mobile/contentsArea/SearchStepper/MRTSearchStepper.mobile';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { PromiseResultType } from '^/types/__ResultType';

export const MRTSelectReturnOfficeMobile: React.FC<
{
  key: string,
  viewmodel: CarListingSearchBoxViewModel,
  officeViewmodel: PromiseResultType<any, any, any>,
  step: { type: '6_return_office' },
  pickupCityInputRef?: RefObject<HTMLInputElement>
}
> = observer(function MRTSelectOfficeMobile({
  key,
  viewmodel,
  officeViewmodel,
}) {
  const mobileWebVhSetting = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    mobileWebVhSetting();
  }, []);

  useEffect(() => {
    if (
      !viewmodel.i.tempReturnCitySearch.selectedCityCode.value
      || !viewmodel.i.tempReturnCitySearch.tempSearchInput.value
    ) {
      typeRouter.session.back();
    }
  }, []);

  return (
    <div key={key}>
      <div
        className={cx(
          css`
            padding: 0 20px;
            margin-top: 68px;
          `,
        )}
      >
        <MRTSearchStepperMobile currentStep={2} />
        <div
          className={cx(
            css`
              margin-top: 20px;
              color: #1E1E1E;
              font-weight: 700;
              font-size: 20px;
              line-height: 28px;
            `,
          )}
        >
          반납 영업소를 선택해주세요.
        </div>
        <div
          className={
            cx(css`
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              height: 60px;
              margin-top: 24px;
            `)
          }
        >
          <div className={cx(css`display: flex;`)}>
            <img src="marker-pin_black_20.svg" alt="marker-pin" />
            <div
              className={
                cx(css`
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                margin-left: 4px;
              `)
              }
            >
              {viewmodel.i.tempReturnCitySearch.tempSearchInput.value}
            </div>
          </div>
          <div
            className={
              css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 28px;
                border-radius: 4px;
                padding: 0 8px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #575757;
                background-color: #F4F4F4;
              `
            }
            onClick={() => {
              viewmodel.i.returnCitySearch.selectedCityCode.set(null);
              viewmodel.i.returnCitySearch.searchInput.set('');
              viewmodel.i.tempReturnCitySearch.selectedCityCode.set(null);
              viewmodel.i.tempReturnCitySearch.searchInput.set('');
              viewmodel.i.returnContinent.set('');
              viewmodel.i.returnContry.set('');
              typeRouter.session.back();
            }}
          >
            도시변경
          </div>
        </div>
      </div>
      <div className={cx(css`height: 8px; background-color: #F4F4F4; border-top: 1px solid #E1E1E1;`)} />
      <div>
        <ViewByState
          data={officeViewmodel}
          loadingView={(
            <BlueLoadingSpinner
              classOverrides={{
                root: css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  width: 100%;
                  margin-bottom: 100px;
                `,
                spinner: css`
                  margin-bottom: 70px;
                  width: 45px;
                  height: 45px;
                `,
              }}
            />
          )}
          errorView={() => {
            return <h1> error </h1>;
          }}
        >
          {(returnVM) => {
            return (
              <MRTOfficeList
                viewmodel={returnVM}
                onSelectRentalOffice={(officeId) => {
                  viewmodel.i.tempReturnLocationId.set(officeId);
                  viewmodel.stepManager.pushStep({ type: '6_5_selected_return_office' });
                }}
              />
            );
          }}
        </ViewByState>
      </div>
    </div>
  );
});
