import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import { changeToUpperCaseOfWordOnlyFistAlpahbet } from '^/pages/Listing/common/utils';

import { GoogleMap } from '../GoogleMap/GoogleMap';

import { RentalOfficeInputViewModel } from './RentalOfficeInput.viewmodel';

const WEEK = {
  en: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  ko: ['일', '월', '화', '수', '목', '금', '토'],
} as const;

const classNames = {
  root: cx(
    css`
      position: relative;
      width: 100%;

      margin-top: auto;
      padding-top: ${SPACING.spacing24};

      @media (max-width: 768px) {
        top: 0;
        margin-top: 0;
        padding-top: 0;
      }
    `,
  ),
  container: cx(
    css`
      width: 100%;
      background-color: ${COLORS2.sub.MrtGray3};
      @media (max-width: 768px) {
        padding: 0;
        position: relative;
        background-color: ${COLORS2.main.MrtWhite};
        margin: 0;
      }
    `,
  ),
};
export const SelectedOfficeInfoRoot: React.FC<{
  viewmodel: RentalOfficeInputViewModel,
}> = observer(function SelectedOfficeInfo({
  viewmodel,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const office = viewmodel.hoveredOfficeData
    || viewmodel.selectedOfficeData;
  const officePosition = office && {
    lat: Number(office.latitude),
    lng: Number(office.longitude),
  };

  const category = ['LAP', 'LDT', 'LHT', 'LAM'] as const;
  const allOfficeInfo = category
    .map((item) => viewmodel.data.get(item))
    .flat();

  return (
    <div className={classNames.root}>

      <div className={classNames.container}>
        {allOfficeInfo
          .filter((a) => {
            if (viewmodel.hoverOfficeId.value) {
              return a?.location_id === viewmodel.hoverOfficeId.value;
            }
            return a?.location_id === viewmodel.selectedOfficeId.value;
          })
          .map((a) => (
            <div
              id="day"
              key={a?.location_id}
              className={cx(
                css`
                  display: flex;
                  // position: relative;
                  justify-content: space-between;

                  @media (max-width: 768px) {
                    flex-direction: column;
                    gap: ${SPACING.spacing16};
                  }
                `,
              )}
            >
              <div className={css`
                flex-basis: 66.6%;
                padding: ${SPACING.spacing24};

                @media (max-width: 768px) {
                  padding: 0 ${SPACING.spacing24};

                }
              `}
              >
                <div>
                  <h1
                    className={font.Headline1_Bold}
                  >
                    {!isMobile
                      && a?.locationDescriptions.get('ko')?.description}
                  </h1>
                  <h2
                    className={cx(
                      isMobile
                        ? cx(
                          font.Body1_Regular,
                          css`
                            color: ${COLORS2.sub.MrtGray1};
                          `,
                        )
                        : font.Body1_Regular,
                      css`
                      `,
                    )}
                  >
                    {changeToUpperCaseOfWordOnlyFistAlpahbet(
                      a?.locationDescriptions.get('en')?.description || '')}
                  </h2>
                  <span className={
                    isMobile
                      ? font.Caption1_Light
                      : font.Caption2_Light
                  }
                  >
                    {changeToUpperCaseOfWordOnlyFistAlpahbet(
                      a?.locationDescriptions.get('ko')?.address_1 || '')}
                    &nbsp;
                    {changeToUpperCaseOfWordOnlyFistAlpahbet(
                      a?.locationDescriptions.get('ko')?.address_2 || '')}
                    &nbsp;
                    {changeToUpperCaseOfWordOnlyFistAlpahbet(
                      a?.locationDescriptions.get('ko')?.address_3 || '')}
                    &nbsp;
                    Phone: {a?.phone}
                  </span>
                </div>
                <div className={cx(
                  css`
                  display: grid;
                  grid-template-columns: repeat(7, 1fr);
                  gap: 12px;
                  margin-top: ${SPACING.spacing16};

                  @media (max-width: 768px) {
                    grid-template-columns: repeat(4, 1fr);
                    margin-top: ${SPACING.spacing24};
                    gap: ${SPACING.spacing8};
                  }
                `,
                )}
                >
                  {/* FIXME: 배열 구조 확인 필요. */}
                  {WEEK.en.map((weekDay, i) => (
                    <div
                      key={weekDay}
                      className={cx(
                        font.Caption1_Light,
                        css`
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          min-height: 81px;
                          text-align: center;

                          background-color: ${COLORS2.main.MrtWhite};
                          border-radius: 5px;

                          @media (max-width: 768px) {
                            background-color: ${COLORS2.sub.MrtGray3};
                            min-height: 63px;
                          }
                        `,
                      )}
                    >
                      <div className={cx(
                        isMobile
                          ? font.Caption1_Bold
                          : font.Headline1_Bold,
                      )}
                      >
                        {WEEK.ko[i]}
                      </div>
                      {a?.openCloseTimes[weekDay].length
                        ? a?.openCloseTimes[weekDay].map(
                          (openingHour) => (
                            <div
                              key={`${openingHour.open}-${openingHour.close}`}
                              className={
                                isMobile ? cx(
                                  font.Caption2_Light,
                                  css`
                                    text-align: center;
                                  `,
                                ) : ''
                              }
                            >
                              {openingHour.open}~{openingHour.close}
                            </div>
                          ))
                        : <div>OFF</div>}
                    </div>
                  ))}
                </div>
              </div>

              <div className={css`
                flex-basis: 33.3%;
              `}
              >
                {officePosition && (
                  <GoogleMap
                    viewmodel={viewmodel}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
});
